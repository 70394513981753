import React, { useEffect, useCallback } from 'react';
import { EditorTextInput, EditorTextArea } from '../UIkit';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);

const EditorEnvelope = (props) => {
  // 画像ファイルをBase64に変換する
  const convertImgToBase64 = (fileObject) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Text = event.currentTarget.result;
        resolve(base64Text);
      };

      reader.readAsDataURL(fileObject);
    });
  };

  //写真を選択した時の処理
  const choseImage = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const blobUrl = window.URL.createObjectURL(file);
      // console.log(blobUrl);
      const targetName = event.target.name;

      const base64Text = await convertImgToBase64(file);
      // console.log(base64Text);

      //該当image要素を取得
      const targetPreviewSVG = document.getElementById(targetName);
      const targetImageSVG = targetPreviewSVG.querySelector('image');
      const parentTargetImageSVG = targetImageSVG.closest('.image_group');

      //基準の図形を取得
      const baseShape = targetPreviewSVG.querySelector('.base_shape');

      //画像のwidthとheightを取得
      const image = new Image();
      // image.src = '';
      image.src = blobUrl;
      // image.src = 'http://localhost:3000/pocket_ec/images/items/20221125172840.jpg';

      image.onload = () => {
        // 既存のサイズ・位置調整を解除
        if (targetName === 'both_image') {
          parentTargetImageSVG.removeAttribute('transform');
        }
        targetImageSVG.removeAttribute('transform');

        // 画像データをセット
        targetImageSVG.setAttribute('href', base64Text);
        targetImageSVG.setAttribute('width', image.naturalWidth);
        targetImageSVG.setAttribute('height', image.naturalHeight);

        // 描画後のサイズを取得
        const imgBoundingBox = targetImageSVG.getBoundingClientRect();
        const imageWidth = imgBoundingBox.width;
        const imageHeiget = imgBoundingBox.height;
        // const imageWidth = image.naturalWidth;
        // const imageHeiget = image.naturalHeight;

        //基準図形の大きさを取得
        const baseShapeInfo = baseShape.getBoundingClientRect();

        let imageSize = imageWidth;
        let baseShapeSize = baseShapeInfo.width;

        //画像配置のx,y座標
        // let positionX = 0;
        // let positionY = 0;

        // 短辺の長さを基準図形に合わせる
        if (imageHeiget < imageWidth) {
          imageSize = imageHeiget;
          baseShapeSize = baseShapeInfo.height;
        }

        //幅を基準に倍率を計算する
        const magnification = baseShapeSize / imageSize;
        // const magnification = (baseShapeSize / imageSize) * 1.2;

        //画像配置位置の調整
        // if (imageHeiget < imageWidth) {
        //   positionX = (baseShapeInfo.width - imageWidth * magnification) / 2;
        // } else {
        //   positionY = (baseShapeInfo.height - imageHeiget * magnification) / 2;
        // }

        // 検証用に画像サイズ位置変更
        // console.log(imageWidth, imageHeiget, positionX, positionY);
        // imageWidth = 100;
        // imageHeiget = 100;
        // positionX = 0;
        // positionY = 200;

        //src,width,heigetを挿入
        // targetImageSVG.setAttribute('href', blobUrl);
        // targetImageSVG.setAttribute('href', base64Text);

        // targetImageSVG.setAttribute('width', imageWidth);
        // targetImageSVG.setAttribute('height', imageHeiget);
        targetImageSVG.setAttribute('transform', `matrix(${magnification},0,0,${magnification},0,0)`);

        // parentTargetImageSVG.setAttribute('transform', `matrix(1,0,0,1,${positionX},${positionY})`);
        // parentTargetImageSVG.setAttribute('transform', `matrix(1,0,0,1,0,0)`);

        // 二人の写真は角度を付けて表示
        if (targetName === 'both_image') {
          const rotateShape = targetPreviewSVG.querySelector('.rotate_shape'); /* 見本の長方形のtransformを使用 */
          parentTargetImageSVG.setAttribute('transform', rotateShape.getAttribute('transform'));
          // parentTargetImageSVG.setAttribute('transform', `translate(-7.91 12.81) rotate(-6.45)`);
          // parentTargetImageSVG.setAttribute('transform', `matrix(1,0,0,1,${positionX},${positionY}) rotate(-6.45)`);
        }
      };
    }
  };

  return (
    <>
      <h2>プロフィール写真を追加</h2>
      <div className="edit_box">
        <h3>新郎プロフィール写真</h3>
        <div className="col_2">
          <div className="editor_file_input_item">
            <span>【写真比率】横：縦＝1：1</span>
            <input
              type="file"
              name="groom_image_main"
              accept="image/*"
              onChange={(event) => {
                choseImage(event);
              }}
            />
          </div>
        </div>
      </div>
      <div className="edit_box">
        <h3>新婦プロフィール写真</h3>
        <div className="col_2">
          <div className="editor_file_input_item">
            <span>【写真比率】横：縦＝1：1</span>
            <input
              type="file"
              name="bride_image_main"
              accept="image/*"
              onChange={(event) => {
                choseImage(event);
              }}
            />
          </div>
        </div>
      </div>
      <div className="edit_box">
        <h3>二人の写真</h3>
        <div className="col_2">
          <div className="editor_file_input_item">
            <span>【写真比率】横：縦＝1.67：1</span>
            <input
              type="file"
              name="both_image"
              accept="image/*"
              onChange={(event) => {
                choseImage(event);
              }}
            />
          </div>
        </div>
      </div>
      <div className="edit_box">
        <h3>プロフィール写真1</h3>
        <div className="col_2">
          <div className="editor_file_input_item">
            <span>【写真比率】横：縦＝1.46：1</span>
            <input
              type="file"
              name="profile_image_1"
              accept="image/*"
              onChange={(event) => {
                choseImage(event);
              }}
            />
          </div>
        </div>
      </div>
      <div className="edit_box">
        <h3>プロフィール写真2</h3>
        <div className="col_2">
          <div className="editor_file_input_item">
            <span>【写真比率】横：縦＝1.46：1</span>
            <input
              type="file"
              name="profile_image_2"
              accept="image/*"
              onChange={(event) => {
                choseImage(event);
              }}
            />
          </div>
        </div>
      </div>
      <div className="edit_box">
        <h3>プロフィール写真3</h3>
        <div className="col_2">
          <div className="editor_file_input_item">
            <span>【写真比率】横：縦＝1.46：1</span>
            <input
              type="file"
              name="profile_image_3"
              accept="image/*"
              onChange={(event) => {
                choseImage(event);
              }}
            />
          </div>
        </div>
      </div>
      <div className="edit_box">
        <h3>プロフィール写真4</h3>
        <div className="col_2">
          <div className="editor_file_input_item">
            <span>【写真比率】横：縦＝1.46：1</span>
            <input
              type="file"
              name="profile_image_4"
              accept="image/*"
              onChange={(event) => {
                choseImage(event);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditorEnvelope;
