import React, { useEffect, useCallback } from 'react';
import { EditorTextInput, EditorTextArea } from '../UIkit';
import {
  adjustFusenPositions,
  changeFusenVisibility,
  getSvgWrapElem,
  SVGWrapElementMatrixChange,
} from '../../templates/Public/EditorFunction';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
import { ReactReduxContext } from 'react-redux';
registerLocale('ja', ja);

const EditorTag = (props) => {
  // //付箋の表示を切り替える処理
  // const changeShowSVG = (target, value) => {
  //   //付箋の数に応じて位置を調整する処理
  //   const drowTags = () => {
  //     //付箋の大きさ
  //     const tagWidth = 258.42;
  //     const tagHeight = 156.16;
  //     //各付箋の間
  //     const tagColumnGap = 50;
  //     const tagRowGap = 30;

  //     //付箋SVGグループの全取得
  //     const tags = document.getElementsByClassName('tag_item');

  //     //付箋SVGグループの中で.noneが付与されていない要素の取得
  //     const getShowTags = (tags) => {
  //       let showTags = [];
  //       for (let i = 0; i < tags.length; i++) {
  //         if (tags[i].classList.contains('none') === false) {
  //           showTags.push(tags[i]);
  //         }
  //       }
  //       return showTags;
  //     };
  //     const showTags = getShowTags(tags);

  //     //表示する付箋の数によって、svgのviewBox,width,heightの値を変更
  //     const showTagsCount = showTags.length; //表示する付箋の数

  //     //付箋SVGの取得
  //     const tagSVG = document.getElementById('fusen_svg');

  //     let newWidth = tagWidth;
  //     let newHeight = tagHeight;

  //     if (1 < showTagsCount && showTagsCount < 3) {
  //       //横幅を計算
  //       newWidth = tagWidth * 2 + tagColumnGap;
  //       //高さを計算
  //       newHeight = tagHeight;
  //     } else if (3 <= showTagsCount && showTagsCount < 5) {
  //       //横幅を計算
  //       newWidth = tagWidth * 2 + tagColumnGap;
  //       //高さを計算
  //       newHeight = tagHeight * 2 + tagRowGap;
  //     } else if (5 <= showTagsCount && showTagsCount < 7) {
  //       //横幅を計算
  //       newWidth = tagWidth * 3 + tagColumnGap * 2;
  //       //高さを計算
  //       newHeight = tagHeight * 2 + tagRowGap;
  //     } else if (7 <= showTagsCount && showTagsCount < 10) {
  //       //横幅を計算
  //       newWidth = tagWidth * 3 + tagColumnGap * 2;
  //       //高さを計算
  //       newHeight = tagHeight * 3 + tagRowGap * 2;
  //     }
  //     // viewBoxにセット
  //     tagSVG.setAttribute('viewBox', `0 0 ${newWidth} ${newHeight}`);
  //     //widthにセット
  //     tagSVG.setAttribute('width', newWidth);
  //     //heightにセット
  //     tagSVG.setAttribute('height', newHeight);

  //     const svgWrap = tagSVG.parentNode;
  //     SVGWrapElementMatrixChange(svgWrap);

  //     //付箋グループの位置をtransform値を変更して移動
  //     //移動先のx座標を計算する処理
  //     const destinationX = (width, gap, numberOC, indexNumber) => {
  //       const surplus = indexNumber % numberOC;
  //       return (width + gap) * surplus;
  //     };

  //     //移動先のy座標を計算する処理
  //     const destinationY = (height, gap, numberOC, indexNumber) => {
  //       const surplus = (indexNumber / numberOC) | 0;
  //       return (height + gap) * surplus;
  //     };

  //     const getNumberOc = (count) => {
  //       if (count < 5) {
  //         return 2;
  //       } else {
  //         return 3;
  //       }
  //     };

  //     for (let i = 0; i < showTagsCount; i++) {
  //       showTags[i].setAttribute(
  //         'transform',
  //         `${
  //           'matrix(1,0,0,1,' +
  //           destinationX(tagWidth, tagColumnGap, getNumberOc(showTagsCount), i) +
  //           ',' +
  //           destinationY(tagHeight, tagRowGap, getNumberOc(showTagsCount), i) +
  //           ')'
  //         }`
  //       );
  //     }
  //   };

  //   //SVG内英語表記グループを習得
  //   const targetSVG = document.getElementById(target);
  //   if (value === 'show') {
  //     targetSVG.classList.remove('none');
  //   } else if (value === 'invisible') {
  //     targetSVG.classList.add('none');
  //   }
  //   drowTags();
  // };

  // 付箋必要・不必要切替時の処理
  const switchFusenSelection = (svgId, isVisible) => {
    changeFusenVisibility(svgId, isVisible);
    adjustFusenPositions();
  };

  // 付箋SVG読み込み完了後に、各付箋の表示切替を行う
  // useEffect(() => {
  //   if (document.getElementById('fusen_svg') === null) return;
  //   // drowTags();
  //   adjustFusenPositions();
  // }, [document.getElementById('fusen_svg')]);

  return (
    <>
      <h2>付箋を選択</h2>
      <div className="edit_box">
        <h3>挙式参列のお願い</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="show_ceremony_tag">
              <input
                type="radio"
                name="ceremony_tag"
                value="1"
                id="show_ceremony_tag"
                checked={props.ceremonyTag === '1'}
                onChange={props.onChangeCeremonyTag}
                onClick={() => {
                  // changeShowSVG('挙式参列', 'show');
                  switchFusenSelection('fusen_01_ceremony_svg', true);
                }}
              />
              必要
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="invisible_ceremony_tag">
              <input
                type="radio"
                name="ceremony_tag"
                value="0"
                id="invisible_ceremony_tag"
                checked={props.ceremonyTag === '0'}
                onChange={props.onChangeCeremonyTag}
                onClick={() => {
                  // changeShowSVG('挙式参列', 'invisible');
                  switchFusenSelection('fusen_01_ceremony_svg', false);
                }}
              />
              不必要
            </label>
          </div>
        </div>
        <div className="tag_number_input_area">
          <div className="col_1">
            <EditorTextInput
              name="ceremony_tag_number"
              rows="1"
              value={props.ceremonyTagNumber}
              onChange={props.onChangeCeremonyTagNumber}
              readOnly={props.ceremonyTag === '0' ? true : false}
            />
          </div>
          <span>枚</span>
        </div>
        <div className="col_2">
          <EditorTextInput
            label="時"
            name="ceremony_tag_hour"
            maxLength={2}
            rows="1"
            value={props.ceremonyTagHour}
            onChange={props.onChangeCeremonyTagHour}
            readOnly={props.ceremonyTag === '0' ? true : false}
          />
          <EditorTextInput
            label="分"
            name="ceremony_tag_minutes"
            maxLength={2}
            rows="1"
            value={props.ceremonyTagMinutes}
            onChange={props.onChangeCeremonyTagMinutes}
            readOnly={props.ceremonyTag === '0' ? true : false}
          />
        </div>
      </div>
      <div className="edit_box">
        <h3>送迎バスのご案内</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="show_courtesy_bus_tag">
              <input
                type="radio"
                name="courtesy_bus_tag"
                value="1"
                id="show_courtesy_bus_tag"
                checked={props.courtesyBusTag === '1'}
                onChange={props.onChangeCourtesyBusTag}
                onClick={() => {
                  // changeShowSVG('送迎バス', 'show');
                  switchFusenSelection('fusen_02_courtesy_bus_svg', true);
                }}
              />
              必要
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="invisible_courtesy_bus_tag">
              <input
                type="radio"
                name="courtesy_bus_tag"
                value="0"
                id="invisible_courtesy_bus_tag"
                checked={props.courtesyBusTag === '0'}
                onChange={props.onChangeCourtesyBusTag}
                onClick={() => {
                  // changeShowSVG('送迎バス', 'invisible');
                  switchFusenSelection('fusen_02_courtesy_bus_svg', false);
                }}
              />
              不必要
            </label>
          </div>
        </div>
        <div className="tag_number_input_area">
          <div className="col_1">
            <EditorTextInput
              name="courtesy_bus_tag_number"
              rows="1"
              value={props.courtesyBusTagNumber}
              onChange={props.onChangeCourtesyBusTagNumber}
              readOnly={props.courtesyBusTag === '0' ? true : false}
            />
          </div>
          <span>枚</span>
        </div>
        <div className="col_2">
          <EditorTextInput
            label="時"
            name="courtesy_bus_tag_hour"
            maxLength={2}
            rows="1"
            value={props.courtesyBusTagHour}
            onChange={props.onChangeCourtesyBusTagHour}
            readOnly={props.courtesyBusTag === '0' ? true : false}
          />
          <EditorTextInput
            label="分"
            name="courtesy_bus_tag_minutes"
            maxLength={2}
            rows="1"
            value={props.courtesyBusTagMinutes}
            onChange={props.onChangeCourtesyBusTagMinutes}
            readOnly={props.courtesyBusTag === '0' ? true : false}
          />
        </div>
        <div className="col_1">
          <EditorTextInput
            label="場所"
            name="courtesy_bus_tag_place"
            maxLength="50"
            rows="1"
            value={props.courtesyBusTagPlace}
            onChange={props.onChangeCourtesyBusTagPlace}
            readOnly={props.courtesyBusTag === '0' ? true : false}
          />
        </div>
      </div>
      <div className="edit_box">
        <h3>余興のお願い</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="show_entertainment_tag">
              <input
                type="radio"
                name="entertainment_tag"
                value="1"
                id="show_entertainment_tag"
                checked={props.entertainmentTag === '1'}
                onChange={props.onChangeEntertainmentTag}
                onClick={() => {
                  // changeShowSVG('余興', 'show');
                  switchFusenSelection('fusen_03_entertainment_svg', true);
                }}
              />
              必要
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="invisible_entertainment_tag">
              <input
                type="radio"
                name="entertainment_tag"
                value="0"
                id="invisible_entertainment_tag"
                checked={props.entertainmentTag === '0'}
                onChange={props.onChangeEntertainmentTag}
                onClick={() => {
                  // changeShowSVG('余興', 'invisible');
                  switchFusenSelection('fusen_03_entertainment_svg', false);
                }}
              />
              不必要
            </label>
          </div>
        </div>
        <div className="tag_number_input_area">
          <div className="col_1">
            <EditorTextInput
              name="entertainment_tag_number"
              rows="1"
              value={props.entertainmentTagNumber}
              onChange={props.onChangeEntertainmentTagNumber}
              readOnly={props.entertainmentTag === '0' ? true : false}
            />
          </div>
          <span>枚</span>
        </div>
      </div>
      <div className="edit_box">
        <h3>集合写真のお願い</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="show_group_photo_tag">
              <input
                type="radio"
                name="group_photo_tag"
                value="1"
                id="show_group_photo_tag"
                checked={props.groupPhotoTag === '1'}
                onChange={props.onChangeGroupPhotoTag}
                onClick={() => {
                  // changeShowSVG('集合写真', 'show');
                  switchFusenSelection('fusen_04_group_photo_svg', true);
                }}
              />
              必要
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="invisible_group_photo_tag">
              <input
                type="radio"
                name="group_photo_tag"
                value="0"
                id="invisible_group_photo_tag"
                checked={props.groupPhotoTag === '0'}
                onChange={props.onChangeGroupPhotoTag}
                onClick={() => {
                  // changeShowSVG('集合写真', 'invisible');
                  switchFusenSelection('fusen_04_group_photo_svg', false);
                }}
              />
              不必要
            </label>
          </div>
        </div>
        <div className="tag_number_input_area">
          <div className="col_1">
            <EditorTextInput
              name="group_photo_tag_number"
              rows="1"
              value={props.groupPhotoTagNumber}
              onChange={props.onChangeGroupPhotoTagNumber}
              readOnly={props.groupPhotoTag === '0' ? true : false}
            />
          </div>
          <span>枚</span>
        </div>
        <div className="col_2">
          <EditorTextInput
            label="時"
            name="group_photo_tag_hour"
            maxLength={2}
            rows="1"
            value={props.groupPhotoTagHour}
            onChange={props.onChangeGroupPhotoTagHour}
            readOnly={props.groupPhotoTag === '0' ? true : false}
          />
          <EditorTextInput
            label="分"
            name="group_photo_tag_minutes"
            maxLength={2}
            rows="1"
            value={props.groupPhotoTagMinutes}
            onChange={props.onChangeGroupPhotoTagMinutes}
            readOnly={props.groupPhotoTag === '0' ? true : false}
          />
        </div>
      </div>
      <div className="edit_box">
        <h3>乾杯のお願い</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="show_toast_tag">
              <input
                type="radio"
                name="toast_tag"
                value="1"
                id="show_toast_tag"
                checked={props.toastTag === '1'}
                onChange={props.onChangeToastTag}
                onClick={() => {
                  // changeShowSVG('乾杯', 'show');
                  switchFusenSelection('fusen_05_toast_svg', true);
                }}
              />
              必要
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="invisible_toast_tag">
              <input
                type="radio"
                name="toast_tag"
                value="0"
                id="invisible_toast_tag"
                checked={props.toastTag === '0'}
                onChange={props.onChangeToastTag}
                onClick={() => {
                  // changeShowSVG('乾杯', 'invisible');
                  switchFusenSelection('fusen_05_toast_svg', false);
                }}
              />
              不必要
            </label>
          </div>
        </div>
        <div className="tag_number_input_area">
          <div className="col_1">
            <EditorTextInput
              name="toast_tag_number"
              rows="1"
              value={props.toastTagNumber}
              onChange={props.onChangeToastTagNumber}
              readOnly={props.toastTag === '0' ? true : false}
            />
          </div>
          <span>枚</span>
        </div>
      </div>
      <div className="edit_box">
        <h3>受付のお願い</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="show_reception_tag">
              <input
                type="radio"
                name="reception_tag"
                value="1"
                id="show_reception_tag"
                checked={props.receptionTag === '1'}
                onChange={props.onChangeReceptionTag}
                onClick={() => {
                  // changeShowSVG('受付', 'show');
                  switchFusenSelection('fusen_06_reception_svg', true);
                }}
              />
              必要
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="invisible_reception_tag">
              <input
                type="radio"
                name="reception_tag"
                value="0"
                id="invisible_reception_tag"
                checked={props.receptionTag === '0'}
                onChange={props.onChangeReceptionTag}
                onClick={() => {
                  // changeShowSVG('受付', 'invisible');
                  switchFusenSelection('fusen_06_reception_svg', false);
                }}
              />
              不必要
            </label>
          </div>
        </div>
        <div className="tag_number_input_area">
          <div className="col_1">
            <EditorTextInput
              name="reception_tag_number"
              rows="1"
              value={props.receptionTagNumber}
              onChange={props.onChangeReceptionTagNumber}
              readOnly={props.receptionTag === '0' ? true : false}
            />
          </div>
          <span>枚</span>
        </div>
        <div className="col_2">
          <EditorTextInput
            label="時"
            name="reception_tag_hour"
            maxLength={2}
            rows="1"
            value={props.receptionTagHour}
            onChange={props.onChangeReceptionTagHour}
            readOnly={props.receptionTag === '0' ? true : false}
          />
          <EditorTextInput
            label="分"
            name="reception_tag_minutes"
            maxLength={2}
            rows="1"
            value={props.receptionTagMinutes}
            onChange={props.onChangeReceptionTagMinutes}
            readOnly={props.receptionTag === '0' ? true : false}
          />
        </div>
      </div>
      <div className="edit_box">
        <h3>スピーチのお願い</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="show_speech_tag">
              <input
                type="radio"
                name="speech_tag"
                value="1"
                id="show_speech_tag"
                checked={props.speechTag === '1'}
                onChange={props.onChangeSpeechTag}
                onClick={() => {
                  // changeShowSVG('スピーチ', 'show');
                  switchFusenSelection('fusen_07_speech_svg', true);
                }}
              />
              必要
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="invisible_speech_tag">
              <input
                type="radio"
                name="speech_tag"
                value="0"
                id="invisible_speech_tag"
                checked={props.speechTag === '0'}
                onChange={props.onChangeSpeechTag}
                onClick={() => {
                  // changeShowSVG('スピーチ', 'invisible');
                  switchFusenSelection('fusen_07_speech_svg', false);
                }}
              />
              不必要
            </label>
          </div>
        </div>
        <div className="tag_number_input_area">
          <div className="col_1">
            <EditorTextInput
              name="speech_tag_number"
              rows="1"
              value={props.speechTagNumber}
              onChange={props.onChangeSpeechTagNumber}
              readOnly={props.speechTag === '0' ? true : false}
            />
          </div>
          <span>枚</span>
        </div>
      </div>
      <div className="edit_box">
        <h3>祝辞のお願い</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="show_congratulations_tag">
              <input
                type="radio"
                name="congratulations_tag"
                value="1"
                id="show_congratulations_tag"
                checked={props.congratulationsTag === '1'}
                onChange={props.onChangecongratulationsTag}
                onClick={() => {
                  // changeShowSVG('祝辞', 'show');
                  switchFusenSelection('fusen_08_congratulations_svg', true);
                }}
              />
              必要
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="invisible_congratulations_tag">
              <input
                type="radio"
                name="congratulations_tag"
                value="0"
                id="invisible_congratulations_tag"
                checked={props.congratulationsTag === '0'}
                onChange={props.onChangecongratulationsTag}
                onClick={() => {
                  // changeShowSVG('祝辞', 'invisible');
                  switchFusenSelection('fusen_08_congratulations_svg', false);
                }}
              />
              不必要
            </label>
          </div>
        </div>
        <div className="tag_number_input_area">
          <div className="col_1">
            <EditorTextInput
              name="congratulations_tag_number"
              rows="1"
              value={props.congratulationsTagNumber}
              onChange={props.onChangecongratulationsTagNumber}
              readOnly={props.congratulationsTag === '0' ? true : false}
            />
          </div>
          <span>枚</span>
        </div>
      </div>
      <div className="edit_box">
        <h3>万歳三唱のお願い</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="show_banzai_tag">
              <input
                type="radio"
                name="banzai_tag"
                value="1"
                id="show_banzai_tag"
                checked={props.banzaiTag === '1'}
                onChange={props.onChangebanzaiTag}
                onClick={() => {
                  // changeShowSVG('万歳三唱', 'show');
                  switchFusenSelection('fusen_09_banzai_svg', true);
                }}
              />
              必要
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="invisible_banzai_tag">
              <input
                type="radio"
                name="banzai_tag"
                value="0"
                id="invisible_banzai_tag"
                checked={props.banzaiTag === '0'}
                onChange={props.onChangebanzaiTag}
                onClick={() => {
                  // changeShowSVG('万歳三唱', 'invisible');
                  switchFusenSelection('fusen_09_banzai_svg', false);
                }}
              />
              不必要
            </label>
          </div>
        </div>
        <div className="tag_number_input_area">
          <div className="col_1">
            <EditorTextInput
              name="banzai_tag_number"
              rows="1"
              value={props.banzaiTagNumber}
              onChange={props.onChangebanzaiTagNumber}
              readOnly={props.banzaiTag === '0' ? true : false}
            />
          </div>
          <span>枚</span>
        </div>
      </div>
    </>
  );
};

export default EditorTag;
