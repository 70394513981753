import React, { useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  addNewCircleTable,
  addTableLayout,
  deleteTableLayout,
  existTable,
  toggleTableDeleteMode,
  toggleTableMoveMode,
  toggleTableNameEditMode,
} from './EditorAddTableFunction';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);

const EditorSeatingLayout = (props) => {
  //svg操作のjsの読み込み
  // importScript(JsDir + '/EditorSeatFunction.js');

  const [selectLayoutFlag, setSelectLayoutFlag] = useState(0);
  const [seatingFont, setSeatingFont] = useState(props.fontData.find((font) => font.name === 'HanaMin').data);

  // 各ボタンが有効かどうか
  const [isTableMoveBtnAvailable, setIsTableMoveBtnAvailable] = useState(true);
  const [isTableAddBtnAvailable, setIsTableAddBtnAvailable] = useState(true);
  const [isTableDeleteBtnAvailable, setIsTableDeleteBtnAvailable] = useState(true);
  const [isTableNameEditBtnAvailable, setIsTableNameEditBtnAvailable] = useState(true);

  // ボタン色定義
  const START_BTN_COLOR = '#55ff55'; /* 開始ボタン色 */
  const END_BTN_COLOR = '#ff5555'; /* 終了ボタン色 */
  const DISABLE_BTN_COLOR = '#a1a1a1'; /* 無効ボタン色 */

  // 各ボタン色
  const [tableMoveBtnColor, setTableMoveBtnColor] = useState(START_BTN_COLOR);
  const [tableAddBtnColor, setTableAddBtnColor] = useState(START_BTN_COLOR);
  const [tableDeleteBtnColor, setTableDeleteBtnColor] = useState(START_BTN_COLOR);
  const [tableNameEditBtnColor, setTableNameEditBtnColor] = useState(START_BTN_COLOR);

  // テーブル名入力値
  const [tableNameInputValue, setTableNameInputValue] = useState('');

  // テーブル名編集時にテーブルが選択されているかどうか
  const [isTableSelectedToEditName, setIsTableSelectedToEditName] = useState(false);

  // テーブル位置調整ボタンスタイル
  const tableMoveBtnStyle = {
    backgroundColor: tableMoveBtnColor,
  };

  // テーブル追加ボタンスタイル
  const tableAddBtnStyle = {
    backgroundColor: tableAddBtnColor,
  };

  // テーブル削除ボタンスタイル
  const tableDeleteBtnStyle = {
    backgroundColor: tableDeleteBtnColor,
  };

  // テーブル名編集ボタンスタイル
  const tableNameEditBtnStyle = {
    backgroundColor: tableNameEditBtnColor,
  };

  // テーブルレイアウト変更処理
  const changeTableLayout = (tableLayout) => {
    deleteTableLayout();
    addTableLayout(tableLayout);
    //フラグの変更
    setSelectLayoutFlag(1);
    // window.location.reload();
  };

  // テーブルレイアウトラジオボタンクリック時の処理
  const handleTableLayoutChange = (tableLayout) => {
    // いずれかのテーブルモード起動中かどうか
    const isAnyTableMode = () => {
      // console.log(props.isTableMoveMode, props.isTableNameEditMode, props.isTableDeleteMode);
      return props.isTableMoveMode || props.isTableNameEditMode || props.isTableDeleteMode;
    };

    // 配席済みゲストが存在するかどうか
    const existDeployedGuest = () => {
      const deployedGuestListElem = document.getElementById('already_deploy_guest_card_list'); // 配席済みゲストリスト要素
      return deployedGuestListElem.childElementCount !== 0;
    };

    // 配席リストの配席済みゲストを未配席に移動
    const moveDeployedGuestToUndeployedList = () => {
      // 親要素の全子要素を削除する
      const removeAllChildElems = (parentElem) => {
        while (parentElem.firstChild) {
          parentElem.removeChild(parentElem.firstChild);
        }
      };

      // 配席済みゲスト要素を取得
      const deployedGuestListUlElem = document.querySelector('#already_deploy_guest_card_list');
      const deployedGuestLiElems = deployedGuestListUlElem.childNodes;

      // 未配席リストに移動
      const undeployedGuestListUlElem = document.querySelector('#guest_card_list');
      while (deployedGuestLiElems.length !== 0) {
        const liElem = deployedGuestLiElems[0];
        liElem.setAttribute('draggable', true); /* 配席時にドラッグ可能に戻す */
        undeployedGuestListUlElem.appendChild(liElem);
      }

      // 配席済みリストを空に
      // removeAllChildElems(deployedGuestListUlElem);
    };

    // 各テーブルモード起動中でないかどうか
    if (isAnyTableMode()) {
      window.alert('各テーブルモードを終了してください。');
      return false;
    }

    // 配席済みゲストが存在するかどうか
    const isDeployed = existDeployedGuest();

    // テーブルが存在する場合
    if (existTable()) {
      // レイアウト変更時には毎回アラートを出す
      const CONFIRM_MSG_1 =
        'レイアウトを変更すると現在の配席状態がすべて削除され、テーブル位置が初期状態に戻ります。\nレイアウトを変更しますか？';
      if (!window.confirm(CONFIRM_MSG_1)) {
        return false;
      }

      // 配席済みゲストが存在する場合はアラート
      const CONFIRM_MSG_2 = '配席済みの招待客が存在します。\n現在の配席状態が削除されますが本当によろしいですか？';
      if (isDeployed) {
        if (!window.confirm(CONFIRM_MSG_2)) {
          window.alert('canceled');
          // ラジオボタンを前の選択状態に戻す処理が必要
          return false;
        }
      }
    }

    // テーブルレイアウト変更
    changeTableLayout(tableLayout);

    // レイアウトラジオボタン選択値更新
    props.setSeatingLayout(tableLayout);

    // 配席済みゲストが存在する場合は未配席リストに戻す
    isDeployed && moveDeployedGuestToUndeployedList();

    return true;
  };

  // 各ボタン色変更
  const changeBtnColors = (isTableMoveMode, isTableDeleteMode, isTableNameEditMode) => {
    if (isTableMoveMode) {
      // テーブル移動モードの場合
      setTableMoveBtnColor(END_BTN_COLOR);
      setTableAddBtnColor(DISABLE_BTN_COLOR);
      setTableDeleteBtnColor(DISABLE_BTN_COLOR);
      setTableNameEditBtnColor(DISABLE_BTN_COLOR);
    } else if (isTableDeleteMode) {
      // テーブル削除モードの場合
      setTableMoveBtnColor(DISABLE_BTN_COLOR);
      setTableAddBtnColor(DISABLE_BTN_COLOR);
      setTableDeleteBtnColor(END_BTN_COLOR);
      setTableNameEditBtnColor(DISABLE_BTN_COLOR);
    } else if (isTableNameEditMode) {
      // テーブル削除モードの場合
      setTableMoveBtnColor(DISABLE_BTN_COLOR);
      setTableAddBtnColor(DISABLE_BTN_COLOR);
      setTableDeleteBtnColor(DISABLE_BTN_COLOR);
      setTableNameEditBtnColor(END_BTN_COLOR);
    } else {
      // 各モードでない場合
      setTableMoveBtnColor(START_BTN_COLOR);
      setTableAddBtnColor(START_BTN_COLOR);
      setTableDeleteBtnColor(START_BTN_COLOR);
      setTableNameEditBtnColor(START_BTN_COLOR);
    }
  };

  // 各ボタンの有効・無効切替
  const changeBtnAvailability = (isTableMoveMode, isTableDeleteMode, isTableNameEditMode) => {
    if (isTableMoveMode) {
      // テーブル移動モードの場合、テーブル移動ボタン以外を無効化
      setIsTableMoveBtnAvailable(true);
      setIsTableAddBtnAvailable(false);
      setIsTableDeleteBtnAvailable(false);
      setIsTableNameEditBtnAvailable(false);
    } else if (isTableDeleteMode) {
      // テーブル削除モードの場合、テーブル削除ボタン以外を無効化
      setIsTableMoveBtnAvailable(false);
      setIsTableAddBtnAvailable(false);
      setIsTableDeleteBtnAvailable(true);
      setIsTableNameEditBtnAvailable(false);
    } else if (isTableNameEditMode) {
      // テーブル名編集モードの場合、テーブル名編集ボタン以外を無効化
      setIsTableMoveBtnAvailable(false);
      setIsTableAddBtnAvailable(false);
      setIsTableDeleteBtnAvailable(false);
      setIsTableNameEditBtnAvailable(true);
    } else {
      // 各モードでない場合、全ボタンを有効化
      setIsTableMoveBtnAvailable(true);
      setIsTableAddBtnAvailable(true);
      setIsTableDeleteBtnAvailable(true);
      setIsTableNameEditBtnAvailable(true);
    }
  };

  // テーブル移動モードボタンクリック時の処理
  const handleTableMoveBtnClick = () => {
    if (isTableMoveBtnAvailable) {
      // 切替成功の場合
      if (toggleTableMoveMode(props.isTableMoveMode ? 'end' : 'start')) {
        props.setIsTableMoveMode((prev) => !prev);
      }
    } else {
      window.alert('現在テーブル移動はできません。');
    }
  };

  // テーブル削除モードボタンクリック時の処理
  const handleTableDeleteBtnClick = () => {
    if (isTableDeleteBtnAvailable) {
      // 切替成功の場合
      if (toggleTableDeleteMode(props.isTableDeleteMode ? 'end' : 'start')) {
        props.setIsTableDeleteMode((prev) => !prev);
      }
    } else {
      window.alert('現在テーブル削除はできません。');
    }
  };

  // テーブル名編集モードボタンクリック時の処理
  const handleTableNameEditBtnClick = () => {
    if (isTableNameEditBtnAvailable) {
      // 切替成功の場合
      if (toggleTableNameEditMode(props.isTableNameEditMode ? 'end' : 'start')) {
        props.setIsTableNameEditMode((prev) => !prev);
      }
    } else {
      window.alert('現在テーブル名の編集はできません。');
    }
  };

  // テーブル追加ボタンクリック時の処理
  const handleTableAddBtnClick = () => {
    if (isTableAddBtnAvailable) {
      addNewCircleTable();
      if (toggleTableMoveMode('start')) {
        props.setIsTableMoveMode(true);
      }
    } else {
      window.alert('現在テーブル追加はできません。');
    }
  };

  // SVG読み込み完了後の処理
  useEffect(() => {
    if (props.isSvgLoaded) {
      if (props.isTemplate) {
        // 初期テーブルレイアウトに変更
        changeTableLayout(props.seatingLayout);
      }

      // 席点線表示
      changeGuestRectVisibility(true);
    }
  }, [props.isSvgLoaded, props.isTemplate]);

  // モード切替時の処理
  useEffect(() => {
    // 各ボタン色を変更
    changeBtnColors(props.isTableMoveMode, props.isTableDeleteMode, props.isTableNameEditMode);

    // 各ボタンの有効・無効切替
    changeBtnAvailability(props.isTableMoveMode, props.isTableDeleteMode, props.isTableNameEditMode);
  }, [props.isTableMoveMode, props.isTableDeleteMode, props.isTableNameEditMode]);

  // テーブル名編集時のテーブル選択
  // useEffect(() => {
  //   console.log(document.querySelector('#table_name_edit_selection'));
  // }, [document.querySelector('#table_name_edit_selection')]);

  // useEffect(() => {
  //   console.log(props.seatingLayout);
  // }, [props.seatingLayout]);

  return (
    <>
      <h2>
        ＜席次表＞
        <br />
        テーブルレイアウト
      </h2>
      <div className="edit_box">
        <h3>テーブルレイアウトを選択</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="layout_3-3-3">
              <input
                type="radio"
                name="table_layout"
                value="3-3-3"
                id="layout_3-3-3"
                checked={props.seatingLayout === '3-3-3'}
                // onChange={props.onChangeSeatingLayout}
                onClick={() => {
                  handleTableLayoutChange('3-3-3');
                }}
              />
              3-3-3
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="layout_3-4-3">
              <input
                type="radio"
                name="table_layout"
                value="3-4-3"
                id="layout_3-4-3"
                checked={props.seatingLayout === '3-4-3'}
                // onChange={props.onChangeSeatingLayout}
                onClick={() => {
                  handleTableLayoutChange('3-4-3');
                }}
              />
              3-4-3
            </label>
          </div>
        </div>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="layout_4-4-4">
              <input
                type="radio"
                name="table_layout"
                value="4-4-4"
                id="layout_4-4-4"
                checked={props.seatingLayout === '4-4-4'}
                // onChange={props.onChangeSeatingLayout}
                onClick={() => {
                  handleTableLayoutChange('4-4-4');
                }}
              />
              4-4-4
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="layout_4-3-4">
              <input
                type="radio"
                name="table_layout"
                value="4-3-4"
                id="layout_4-3-4"
                checked={props.seatingLayout === '4-3-4'}
                // onChange={props.onChangeSeatingLayout}
                onClick={() => {
                  handleTableLayoutChange('4-3-4');
                }}
              />
              4-3-4
            </label>
          </div>
        </div>
        {/* <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="layout_3-4-3-4">
              <input
                type="radio"
                name="table_layout"
                value="3-4-3-4"
                id="layout_3-4-3-4"
                checked={props.seatingLayout === '3-4-3-4'}
                onChange={props.onChangeSeatingLayout}
                onClick={() => {
                  handleTableLayoutChange('3-4-3-4');
                }}
              />
              3-4-3-4
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="layout_3-4-4-4">
              <input
                type="radio"
                name="table_layout"
                value="3-4-4-4"
                id="layout_3-4-4-4"
                checked={props.seatingLayout === '3-4-4-4'}
                onChange={props.onChangeSeatingLayout}
                onClick={() => {
                  handleTableLayoutChange('3-4-4-4');
                }}
              />
              3-4-4-4
            </label>
          </div>
        </div> */}
      </div>
      <div className="edit_box">
        <>
          <h3>テーブルを移動</h3>
          <div onClick={() => handleTableMoveBtnClick()} className="mode_btn" style={tableMoveBtnStyle}>
            {props.isTableMoveMode ? 'テーブル移動モード終了' : 'テーブル移動モード開始'}
          </div>
        </>
      </div>
      <div className="edit_box">
        <h3>テーブルを追加</h3>
        <div onClick={() => handleTableAddBtnClick()} className="mode_btn" style={tableAddBtnStyle}>
          テーブルを追加
        </div>
      </div>
      <div className="edit_box">
        <h3>テーブルを削除</h3>
        <div onClick={() => handleTableDeleteBtnClick()} className="mode_btn" style={tableDeleteBtnStyle}>
          {props.isTableDeleteMode ? 'テーブル削除モード終了' : 'テーブル削除モード開始'}
        </div>
        {props.isTableDeleteMode && <p>削除するテーブルをクリック</p>}
      </div>
      <div className="edit_box">
        <h3>テーブル名を編集</h3>
        <div onClick={() => handleTableNameEditBtnClick()} className="mode_btn" style={tableNameEditBtnStyle}>
          {props.isTableNameEditMode ? 'テーブル名編集モード終了' : 'テーブル名編集モード開始'}
        </div>
        {/* {isTableSelectedToEditName && ( */}
        <input
          id="tableNameInput"
          onChange={(event) => {
            drawTableName(event.target.value, seatingFont);
          }}
          type="text"
          placeholder="9文字まで"
          maxLength="9"
          hidden
          disabled
        />
        {/* )} */}
      </div>
    </>
  );
};

export default EditorSeatingLayout;
