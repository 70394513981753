import opentype, { load } from 'opentype.js';
import { FontsDir, HomeDir } from '../../common';

// 必要なフォントデータをすべて取得する
export const loadAllFontData = async (fontList) => {
  const fontDataList = [];

  for (let i = 0; i < fontList.length; i++) {
    const fontPath = FontsDir + '/' + fontList[i].fileName;

    // フォントデータ取得
    await loadFont(fontPath).then((fontData) => {
      if (fontData === null) {
        return null;
      } else {
        fontDataList.push({ name: fontList[i].name, data: fontData });
      }
    });
  }

  return fontDataList;
};

// フォントデータ(ttf)取得読み込み
export const loadFont = (fontPath) => {
  return new Promise((resolve) => {
    opentype.load(fontPath, function (err, fontData) {
      if (err) {
        console.error('Font could not be loaded: ' + err);
        resolve(null);
      } else {
        resolve(fontData);
      }
    });
  });
};

// loadFont(defaultFontPath).then(fontData => {
//   font = fontData
// })

// すべての付箋SVGが読み込まれたか確認
export const isAllFusenSvgLoaded = () => {
  const svgIdList = [
    'fusen_01_ceremony_svg',
    'fusen_02_courtesy_bus_svg',
    'fusen_03_entertainment_svg',
    'fusen_04_group_photo_svg',
    'fusen_05_toast_svg',
    'fusen_06_reception_svg',
    'fusen_07_speech_svg',
    'fusen_08_congratulations_svg',
    'fusen_09_banzai_svg',
  ];

  for (let i = 0; i < svgIdList.length; i++) {
    const svgElem = document.getElementById(svgIdList[i]);
    if (!svgElem) return false;
  }

  return true;
};

const SVGMagnificationCalc = (element) => {
  //キャンバス要素を取得
  const canvasElement = document.getElementById('canvas');

  //キャンバス要素の幅、高さを取得
  const canvasWidth = canvasElement.offsetWidth;
  const canvasHeight = canvasElement.offsetHeight;

  //渡された要素を取得
  const targetElement = element;

  //渡された要素内のSVG要素を取得
  const SVGElement = targetElement.querySelector('svg');

  const SVGWidth = Number(SVGElement.getAttribute('width'));
  const SVGHeight = Number(SVGElement.getAttribute('height'));

  //画面に入る倍率を高さを基準に計算
  const heightMagnification = (canvasHeight - 150) / SVGHeight;

  //もし、高さ基準で変更した場合に横幅が入らなかった場合
  if (canvasWidth * 0.8 < SVGWidth * heightMagnification) {
    return (canvasWidth * 0.8) / SVGWidth;
  } else {
    return heightMagnification;
  }
};

//横幅に合わせて、SVGをラップしているdivのtransform値を変更する処理
export const SVGWrapElementMatrixChange = (element) => {
  //渡された要素を取得
  const targetElement = element;

  //変更する倍率を取得
  const magnification = SVGMagnificationCalc(element);

  //キャンバス要素を取得
  const canvasElement = document.getElementById('canvas');

  //キャンバス要素の幅、高さを取得
  const canvasWidth = canvasElement.offsetWidth;

  //横幅によって真ん中に表示されるようにx座標を計算する
  const xValue = (canvasWidth - targetElement.offsetWidth * magnification) / 2;
  //transform値を変更
  element.style.transform = `${'matrix(' + magnification + ', 0, 0, ' + magnification + ', ' + xValue + ', 50)'}`;
  // element.style.transform = `${'matrix(1, 0, 0, 1, '+xValue+', 50)'}`;
};

// 要素のtransform matrix値を取得
export const getTransformMatrix = (elem) => {
  const style = window.getComputedStyle(elem);
  return new WebKitCSSMatrix(style.transform);
};

// SVG Wrap要素を取得
export const getSvgWrapElem = (svgId) => {
  const svgElem = document.getElementById(svgId);
  return svgElem.closest('.svg_wrap');
};

// SVG表示領域サイズを取得
export const getSvgCanvasSize = () => {
  const canvasElem = document.querySelector('#canvas');
  return [canvasElem.offsetWidth, canvasElem.offsetHeight];
};

// SVG Wrap要素の幅を取得
export const getSvgWrapSize = (svgWrapElem) => {
  const matrix = getTransformMatrix(svgWrapElem);
  const magnificationX = matrix.a;
  const magnificationY = matrix.d;
  const width = svgWrapElem.offsetWidth * magnificationX;
  const height = svgWrapElem.offsetHeight * magnificationY;
  return [width, height];
};

// SVG Wrap要素のtransform matrix xの値を更新
export const changeSvgWrapElemXPosition = (svgWrapElem, xValue) => {
  const matrix = getTransformMatrix(svgWrapElem); /* 現在のmatrix */
  const newMatrix = `matrix(${matrix.a},${matrix.b},${matrix.c},${matrix.d},${xValue},${matrix.f})`; /* 変更後のmatrix */
  // console.log(matrix, newMatrix);
  svgWrapElem.style.transform = newMatrix; /* matrixを更新 */
};

// SVG Wrap要素のtransform matrix yの値を更新
export const changeSvgWrapElemYPosition = (svgWrapElem, xValue) => {
  const matrix = getTransformMatrix(svgWrapElem); /* 現在のmatrix */
  const newMatrix = `matrix(${matrix.a},${matrix.b},${matrix.c},${matrix.d},${xValue},${matrix.f})`; /* 変更後のmatrix */
  // console.log(matrix, newMatrix);
  svgWrapElem.style.transform = newMatrix; /* matrixを更新 */
};

// SVG Wrap要素のtransform matrix yの値を更新
export const changeSvgWrapElemMatrix = (svgWrapElem, a, b, c, d, e, f) => {
  const matrix = `matrix(${a},${b},${c},${d},${e},${f})`; /* 変更後のmatrix */
  svgWrapElem.style.transform = matrix; /* matrixを更新 */
};

// はがき表示位置を調整
export const adjustPostcardSeparatePosition = () => {
  // SVG Wrap要素を取得
  const postcardGroomWrapElem = getSvgWrapElem('postcard_separate_groom_svg');
  const postcardBrideWrapElem = getSvgWrapElem('postcard_separate_bride_svg');

  const [canvasWidth, canvasHeight] = getSvgCanvasSize(); /* SVG表示領域の幅 */
  const [postcardGroomWidth, postcardGroomHeight] = getSvgWrapSize(postcardGroomWrapElem); /* 新郎側はがき幅 */
  const [postcardBrideWidth, postcardBrideHeight] = getSvgWrapSize(postcardBrideWrapElem); /* 新婦側はがき幅 */

  const postcardMargin = postcardGroomWidth * 0.1; /* はがき間の余白 */
  const sideMargin = (canvasWidth - (postcardGroomWidth + postcardMargin + postcardBrideWidth)) / 2; /* 両端の余白 */

  const groomX = sideMargin; /* 新郎側はがきX */
  const brideX = sideMargin + postcardGroomWidth + postcardMargin; /* 新婦側はがきX */

  // 新郎側・新婦側はがきの位置を変更
  changeSvgWrapElemXPosition(postcardGroomWrapElem, groomX);
  changeSvgWrapElemXPosition(postcardBrideWrapElem, brideX);
};

// 付箋表示切替
export const changeFusenVisibility = (svgId, isVisible) => {
  // console.log(svgId, isVisible);
  const svgWrapElem = getSvgWrapElem(svgId);
  if (isVisible) {
    svgWrapElem.classList.remove('none');
  } else {
    svgWrapElem.classList.add('none');
  }
};

// 各付箋の位置調整
export const adjustFusenPositions = () => {
  // 表示状態の付箋SVGのwrap要素を取得
  const getFusenSvgWrapElems = () => {
    const svgWrapElems = document.querySelectorAll('.svg_wrap');
    const fusenSvgWrapElems = [];
    for (let i = 0; i < svgWrapElems.length; i++) {
      const svgWrapElem = svgWrapElems[i];
      const svgElem = svgWrapElem.querySelector('svg');
      if (svgElem.dataset.name.match(/fusen_/) && !svgWrapElem.classList.contains('none')) {
        // console.log(svgWrapElem);
        // console.log(svgElem.dataset.name);
        fusenSvgWrapElems.push(svgWrapElem);
      }
    }
    return fusenSvgWrapElems;
  };

  // 付箋幅を計算
  const calcFusenWidth = (colNum, canvasWidth, fusenMarginX) => {
    const fusenAreaWidth = canvasWidth * 0.9;
    return (fusenAreaWidth - fusenMarginX * (colNum - 1)) / colNum;
  };

  // 上側余白を計算
  const calcTopMargin = (canvasHeight, rowNum, fusenHeight, fusenMarginY) => {
    const totalFusenHeight = fusenHeight * rowNum + fusenMarginY * (rowNum - 1);
    return (canvasHeight - totalFusenHeight) / 2;
  };

  // 付箋位置を計算
  const calcFusenPosition = (
    colNum,
    fusenIndex,
    fusenWidth,
    fusenHeight,
    fusenMarginX,
    fusenMarginY,
    leftMargin,
    topMargin
  ) => {
    const colIndex = fusenIndex % colNum; /* 付箋の列番号 */
    const rowIndex = Math.floor(fusenIndex / colNum); /* 付箋の行番号 */
    // console.log(colIndex, rowIndex);

    const x = leftMargin + (fusenWidth + fusenMarginX) * colIndex;
    const y = topMargin + (fusenHeight + fusenMarginY) * rowIndex;
    return [x, y];
  };

  const fusenSvgWrapElems = getFusenSvgWrapElems();
  // console.log(fusenSvgWrapElems);

  const fusenNum = fusenSvgWrapElems.length; /* 付箋数 */

  if (fusenNum.length === 0) return;

  // サイズをもとに戻す
  for (let i = 0; i < fusenSvgWrapElems.length; i++) {
    fusenSvgWrapElems[i].style.transform = '';
  }

  const [fusenWrapWidth, fusenWrapHeight] = getSvgWrapSize(fusenSvgWrapElems[0]);
  const fusenRatio = fusenWrapWidth / fusenWrapHeight;

  const [canvasWidth, canvasHeight] = getSvgCanvasSize(); /* 付箋表示エリアサイズ */

  const fusenMarginX = canvasWidth * 0.01; /* 付箋間の横の余白 */
  const fusenMarginY = fusenMarginX; /* 付箋間の縦の余白 */

  const colNum = fusenNum === 1 ? 1 : fusenNum <= 4 ? 2 : 3; /* 列数 */
  const rowNum = Math.ceil(fusenNum / colNum);

  //  付箋サイズを計算
  const fusenWidth = calcFusenWidth(colNum, canvasWidth, fusenMarginX); /* 付箋幅 */
  const fusenHeight = fusenWidth / fusenRatio; /* 付箋高さ */
  const magnification = fusenWidth / fusenWrapWidth; /* 拡大率 */

  // 余白を計算
  const leftMargin = (canvasWidth * 0.1) / 2;
  const topMargin = calcTopMargin(canvasHeight, rowNum, fusenHeight, fusenMarginY); /* 上側の余白 */
  // console.log(topMargin);

  for (let i = 0; i < fusenNum; i++) {
    const [x, y] = calcFusenPosition(
      colNum,
      i,
      fusenWidth,
      fusenHeight,
      fusenMarginX,
      fusenMarginY,
      leftMargin,
      topMargin
    );
    // console.log(x, y);
    changeSvgWrapElemMatrix(fusenSvgWrapElems[i], magnification, 0, 0, magnification, x, y);
  }
};

//canvasとtransformの比率を計算する処理
const canvasViewboxMagnificationCalc = (target) => {
  if (target) {
    // //ターゲット要素の親要素.svg_wrapを取得
    const targetParentElement = target.closest('.svg_wrap');
    const res = targetParentElement.style.transform;
    const matrixArray = res.split(',');
    return Number(matrixArray[3]);
  }
};

// フォント指定を取得
export const getFontName = (elem) => {
  if (typeof elem.dataset.font === 'undefined') {
    return 'default';
  } else {
    return elem.dataset.font;
  }
};

// フォントサイズ指定を取得
export const getFontSize = (elem, elemType) => {
  if (typeof elem.dataset.fontsize === 'undefined') {
    if (elemType == 'text') {
      return 12;
    } else if (elemType === 'textarea') {
      return 18;
    } else if (elemType === 'date') {
      return 12;
    }
  } else {
    return elem.dataset.fontsize;
  }
};

// 位置指定を取得
export const getAlign = (elem) => {
  if (typeof elem.dataset.align === 'undefined') {
    return 'left';
  } else {
    return elem.dataset.align;
  }
};

// 行間指定を取得
export const getLineSpace = (elem) => {
  if (typeof elem.dataset.linespace === 'undefined') {
    return 6;
  } else {
    return elem.dataset.linespace;
  }
};

// 日付フォーマット指定を取得
export const getDateFormat = (elem) => {
  if (typeof elem.dataset.format === 'undefined') {
    return 'YY.MM.DD';
  } else {
    return elem.dataset.format;
  }
};

// 日付を2022年11月30日（水曜日）のような形式にフォーマット
export const formatDate = (date, format) => {
  //表示テキストを作成
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  //曜日表示の準備
  const JAee = ['日', '月', '火', '水', '木', '金', '土'];
  const JAEE = ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'];
  const ENee = ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'];

  let dateText = format.replace('YY', year); //年を挿入
  dateText = dateText.replace('MM', month); //月を挿入
  dateText = dateText.replace('DD', day); //日を挿入
  dateText = dateText.replace('JAee', JAee[date.getDay()]); //曜日を挿入
  dateText = dateText.replace('JAEE', JAEE[date.getDay()]); //曜日を挿入
  dateText = dateText.replace('ENee', ENee[date.getDay()]); //曜日を挿入

  return dateText;
};

// 指定のフォントデータを返す
// SVG内に埋め込むフォント指定はフォント名のみに変更予定
export const getFontData = (fontData, fontName) => {
  // console.log(fontName)
  // console.log(fontData, fontName)
  switch (fontName) {
    case './fonts/Bahagia.ttf':
      return fontData.find((font) => font.name === 'Bahagia').data;
    // return fontData.find(font => font.name === fontName)
    default:
      return fontData.find((font) => font.name === 'HanaMin').data;
  }
};

//input[type:text]をSVGに変更して表示
export const changeText = (targetElem, value, font, fontsize, align, fontColor = '#000000') => {
  try {
    //テキスト情報をSVGに変換
    let path = font.getPath(value, 0, parseInt(fontsize), parseInt(fontsize));

    //テキストSVGの横幅を取得
    const pathWidth = path.getBoundingBox().x2 - path.getBoundingBox().x1;
    //基準になる横幅をrectから取得
    const rectElement = targetElem.querySelectorAll('rect');
    const widthMagnification =
      rectElement[0].getBoundingClientRect().width / pathWidth / canvasViewboxMagnificationCalc(targetElem);

    //alignがセンターの場合センター寄せにpathの位置を修正
    if (
      align === 'center' &&
      pathWidth < rectElement[0].getBoundingClientRect().width / canvasViewboxMagnificationCalc(targetElem)
    ) {
      const xposition =
        (rectElement[0].getBoundingClientRect().width / canvasViewboxMagnificationCalc(targetElem) - pathWidth) / 2;
      path = font.getPath(value, xposition, parseInt(fontsize), parseInt(fontsize));
    }

    //alignがrightの場合右寄せにpathの位置を修正
    if (
      align === 'right' &&
      pathWidth < rectElement[0].getBoundingClientRect().width / canvasViewboxMagnificationCalc(targetElem)
    ) {
      const xposition =
        rectElement[0].getBoundingClientRect().width / canvasViewboxMagnificationCalc(targetElem) - pathWidth;
      path = font.getPath(value, xposition, parseInt(fontsize), parseInt(fontsize));
    }

    // path色変更
    // path.fill = fontColor;

    //pathデータのx座標情報変更
    const commands = path.commands;
    // console.log(commands);
    if (widthMagnification < 1) {
      for (let j = 0; j < commands.length; j++) {
        // commands[j]['x'] = commands[j]['x'] * widthMagnification;
        // console.log(commands[j]);
        if (commands[j]['type'] === 'Z') continue;
        if (commands[j]['type'] === 'Q') {
          // 二次ベジェ曲線
          commands[j]['x'] *= widthMagnification;
          commands[j]['x1'] *= widthMagnification;
        } else if (commands[j]['type'] === 'C') {
          // 三次ベジェ曲線
          commands[j]['x'] *= widthMagnification;
          commands[j]['x1'] *= widthMagnification;
          commands[j]['x2'] *= widthMagnification;
        } else {
          commands[j]['x'] *= widthMagnification;
        }
      }
    }
    //SVGに変換
    const Element = path.toSVG();

    targetElem.innerHTML = Element;
    //pathエレメントの取得
    const pathElemnt = targetElem.querySelector('path');
    //クリックイベントを無効にするクラスの追加
    pathElemnt.classList.add('event_none');
    //rectエレメントの追加
    targetElem.prepend(rectElement[0]);
  } catch (error) {
    console.error(error, targetElem, value, font, fontsize, align);
    // window.alert("エラー")
  }
};

// 未使用
//【縦書き】input[type:text]をSVGに変更して表示
export const changeTextVertical = (value, name) => {
  //表示する対象の要素を取得
  const textTargets = document.getElementsByClassName(name);
  // console.log(textTargets)
  //表示する要素があった場合
  if (textTargets.length) {
    for (let i = 0; i < textTargets.length; i++) {
      //fontsizeを取得
      let fontsize = 12; //デフォルトのフォントサイズを指定
      let align = 'left'; //デフォルトのテキスト位置
      let font = './fonts/HanaMin.ttf'; //デフォルトのフォントスタイル
      let linespace = 4; //デフォルトの行の間隔

      if (textTargets[i].dataset.fontsize) {
        fontsize = textTargets[i].dataset.fontsize;
      }
      if (textTargets[i].dataset.align) {
        align = textTargets[i].dataset.align;
      }
      if (textTargets[i].dataset.font) {
        font = textTargets[i].dataset.font;
      }
      if (textTargets[i].dataset.linespace) {
        linespace = textTargets[i].dataset.linespace;
      }
      //テキストの描画
      opentype.load(HomeDir + font, function (err, font) {
        if (err) {
          alert('Font could not be loaded: ' + err);
        } else {
          //テキスト情報を取得
          const textValue = value;
          //テキスト情報を一文字ごとに配列に格納
          let textArray = textValue.split('');
          console.log(textArray);
          //textArrayの分だけ繰り返し、一文字ごとでy座標を修正
          let path = '';
          for (let j = 0; j < textArray.length; j++) {
            //テキスト情報をSVGに変換
            path = font.getPath(textArray[j], 0, parseInt(fontsize) * (j + 1) + linespace * j, parseInt(fontsize));
            //テキストSVGの横幅を取得
            const pathWidth = path.getBoundingBox().x2 - path.getBoundingBox().x1;
            //基準になる横幅をrectから取得
            const rectElement = textTargets[i].querySelectorAll('rect');
            const widthMagnification =
              rectElement[0].getBoundingClientRect().width / pathWidth / canvasViewboxMagnificationCalc(textTargets[i]);

            //pathデータのx座標情報変更
            const commands = path.commands;
            if (widthMagnification < 1) {
              for (let k = 0; k < commands.length; k++) {
                commands[k]['x'] = commands[k]['x'] * widthMagnification;
              }
            }
            //SVGに変換
            const Element = path.toSVG();
            if (j === 0) {
              textTargets[i].innerHTML = Element;
            } else {
              textTargets[i].insertAdjacentHTML('beforeend', Element);
            }
            //pathエレメントの取得
            const pathElemnt = textTargets[i].querySelector('path');
            //クリックイベントを無効にするクラスの追加
            pathElemnt.classList.add('event_none');
            //rectエレメントの追加
            textTargets[i].prepend(rectElement[0]);
          }
        }
      });
    }
  }
};

//textareaをSVGに変更して表示
export const changeTextArea = (targetElem, value, font, fontsize, align, linespace) => {
  try {
    //テキストの描画
    //テキスト情報を取得
    const textValue = value;
    //テキスト情報を改行で分けて配列に格納
    let textArray = [];
    if (textValue.indexOf('\n') === -1) {
      textArray.push(textValue);
    } else {
      textArray = textValue.split('\n');
    }
    //textArrayの分だけ繰り返し、改行毎でy座標を修正
    let path = '';
    for (let j = 0; j < textArray.length; j++) {
      //テキスト情報をSVGに変換
      path = font.getPath(textArray[j], 0, parseInt(fontsize) * (j + 1) + linespace * j, parseInt(fontsize));
      //テキストSVGの横幅を取得
      const pathWidth = path.getBoundingBox().x2 - path.getBoundingBox().x1;
      //基準になる横幅をrectから取得
      const rectElement = targetElem.querySelectorAll('rect');
      const widthMagnification =
        rectElement[0].getBoundingClientRect().width / pathWidth / canvasViewboxMagnificationCalc(targetElem);

      //alignがセンターの場合センター寄せにpathの位置を修正
      if (
        align === 'center' &&
        pathWidth < rectElement[0].getBoundingClientRect().width / canvasViewboxMagnificationCalc(targetElem)
      ) {
        const xposition =
          (rectElement[0].getBoundingClientRect().width / canvasViewboxMagnificationCalc(targetElem) - pathWidth) / 2;
        path = font.getPath(textArray[j], xposition, parseInt(fontsize) * (j + 1) + linespace * j, parseInt(fontsize));
      }
      //alignが右の場合右寄せにpathの位置を修正
      if (
        align === 'right' &&
        pathWidth < rectElement[0].getBoundingClientRect().width / canvasViewboxMagnificationCalc(targetElem)
      ) {
        const xposition =
          rectElement[0].getBoundingClientRect().width / canvasViewboxMagnificationCalc(targetElem) - pathWidth;
        path = font.getPath(textArray[j], xposition, parseInt(fontsize) * (j + 1) + linespace * j, parseInt(fontsize));
      }
      //pathデータのx座標情報変更
      const commands = path.commands;
      if (widthMagnification < 1) {
        for (let k = 0; k < commands.length; k++) {
          // commands[k]['x'] = commands[k]['x'] * widthMagnification;
          if (commands[k]['type'] === 'Z') continue;
          if (commands[k]['type'] === 'Q') {
            // 二次ベジェ曲線
            commands[k]['x'] *= widthMagnification;
            commands[k]['x1'] *= widthMagnification;
          } else if (commands[k]['type'] === 'C') {
            // 三次ベジェ曲線
            commands[k]['x'] *= widthMagnification;
            commands[k]['x1'] *= widthMagnification;
            commands[k]['x2'] *= widthMagnification;
          } else {
            commands[k]['x'] *= widthMagnification;
          }
        }
      }
      //SVGに変換
      const Element = path.toSVG();
      if (j === 0) {
        targetElem.innerHTML = Element;
      } else {
        targetElem.insertAdjacentHTML('beforeend', Element);
      }
      //pathエレメントの取得
      const pathElemnt = targetElem.querySelector('path');
      //クリックイベントを無効にするクラスの追加
      pathElemnt.classList.add('event_none');
      //rectエレメントの追加
      targetElem.prepend(rectElement[0]);
    }
  } catch (error) {
    console.error(error, targetElem, value, font, fontsize, align, linespace);
  }
};

// 未使用
//【縦書き】textareaをSVGに変更して表示
export const changeTextAreaVertical = (value, name) => {
  //表示する対象の要素を取得
  const textTargets = document.getElementsByClassName(name);
  //表示する要素があった場合
  if (textTargets.length) {
    for (let i = 0; i < textTargets.length; i++) {
      let fontsize = 18; //デフォルトのフォントサイズを指定
      let align = 'left'; //デフォルトのテキスト位置
      let font = './fonts/HanaMin.ttf'; //デフォルトのフォントスタイル
      let linespace = 6; //デフォルトの行の間隔

      if (textTargets[i].dataset.fontsize) {
        fontsize = textTargets[i].dataset.fontsize;
      }
      if (textTargets[i].dataset.align) {
        align = textTargets[i].dataset.align;
      }
      if (textTargets[i].dataset.font) {
        font = textTargets[i].dataset.font;
      }
      if (textTargets[i].dataset.linespace) {
        linespace = textTargets[i].dataset.linespace;
      }
      //テキストの描画
      opentype.load(HomeDir + font, function (err, font) {
        if (err) {
          alert('Font could not be loaded: ' + err);
        } else {
          //テキスト情報を取得
          const textValue = value;
          //テキスト情報を改行で分けて配列に格納
          let textArray = [];
          if (textValue.indexOf('\n') === -1) {
            textArray.push(textValue);
          } else {
            textArray = textValue.split('\n');
          }
          //textArrayの分だけ繰り返し、改行毎でy座標を修正
          let path = '';
          for (let j = 0; j < textArray.length; j++) {
            //テキストを一文字ずつ配列に格納
            let wordArray = textArray[j].split('');
            for (let l = 0; l < wordArray.length; l++) {
              //テキスト情報をSVGに変換
              path = font.getPath(wordArray[l], 0, parseInt(fontsize) * (l + 1) + linespace * l, parseInt(fontsize));
              //テキストSVGの横幅を取得
              const pathWidth = path.getBoundingBox().x2 - path.getBoundingBox().x1;
              //基準になる横幅をrectから取得
              const rectElement = textTargets[i].querySelectorAll('rect');
              const widthMagnification =
                rectElement[0].getBoundingClientRect().width /
                pathWidth /
                canvasViewboxMagnificationCalc(textTargets[i]);

              //pathデータのx座標情報変更
              const commands = path.commands;
              if (widthMagnification < 1) {
                for (let k = 0; k < commands.length; k++) {
                  commands[k]['x'] = commands[k]['x'] * widthMagnification;
                }
              }
              //SVGに変換
              const Element = path.toSVG();
              const xValue = 0 - (Number(fontsize) + 3) * j;
              console.log(fontsize);
              const yValue = 0 - (Number(fontsize) - 6) * l;
              const div = document.createElement('div');
              div.style.display = 'none';
              div.innerHTML = Element;
              const DomElement = div.getElementsByTagName('path');
              const innerElement = DomElement[0];
              innerElement.style.transform = `translate(${xValue}px,${yValue}px)`;
              innerElement.style.fill = '#000000';
              console.log(innerElement);
              if (j === 0 && l === 0) {
                textTargets[i].innerHTML = innerElement.outerHTML;
              } else {
                textTargets[i].insertAdjacentHTML('beforeend', innerElement.outerHTML);
              }
              //pathエレメントの取得
              const pathElemnt = textTargets[i].querySelector('path');
              //クリックイベントを無効にするクラスの追加
              pathElemnt.classList.add('event_none');
              //rectエレメントの追加
              textTargets[i].prepend(rectElement[0]);
            }
          }
        }
      });
    }
  }
};

//日付をSVGに変更して表示
export const changeDate = (value, name) => {
  //nameから表示する要素を取得
  const dateTargets = document.getElementsByClassName(name);
  if (dateTargets.length) {
    //表示する要素があったら
    for (let i = 0; i < dateTargets.length; i++) {
      let fontsize = 12; //デフォルトのフォントサイズ
      let align = 'left'; //デフォルトのテキスト位置
      let format = 'YY.MM.DD'; //デフォルトのフォーマット
      let font = './fonts/HanaMin.ttf'; //デフォルトのフォントスタイル
      //要素のdata属性からfontとalignとformatを取得して代入
      if (dateTargets[i].dataset.fontsize) {
        fontsize = dateTargets[i].dataset.fontsize;
      }
      if (dateTargets[i].dataset.align) {
        align = dateTargets[i].dataset.align;
      }
      if (dateTargets[i].dataset.format) {
        format = dateTargets[i].dataset.format;
      }
      if (dateTargets[i].dataset.font) {
        font = dateTargets[i].dataset.font;
      }
      //表示テキストを作成
      const dateValue = new Date(value);
      const yearValue = dateValue.getFullYear();
      const monthValue = dateValue.getMonth() + 1;
      const dayValue = dateValue.getDate();

      //曜日表示の準備
      const JAee = ['日', '月', '火', '水', '木', '金', '土'];
      const JAEE = ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'];
      const ENee = ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'];

      let dateText = format.replace('YY', yearValue); //年を挿入
      dateText = dateText.replace('MM', monthValue); //月を挿入
      dateText = dateText.replace('DD', dayValue); //日を挿入
      dateText = dateText.replace('JAee', JAee[dateValue.getDay()]); //曜日を挿入
      dateText = dateText.replace('JAEE', JAEE[dateValue.getDay()]); //曜日を挿入
      dateText = dateText.replace('ENee', ENee[dateValue.getDay()]); //曜日を挿入

      opentype.load(HomeDir + font, function (err, font) {
        if (err) {
          alert('Font could not be loaded: ' + err);
        } else {
          //テキスト情報をSVGに変換
          let path = font.getPath(dateText, 0, parseInt(fontsize), parseInt(fontsize));

          //テキストSVGの横幅を取得
          const pathWidth = path.getBoundingBox().x2 - path.getBoundingBox().x1;
          //基準になる横幅をrectから取得
          const rectElement = dateTargets[i].querySelectorAll('rect');
          const widthMagnification =
            rectElement[0].getBoundingClientRect().width / pathWidth / canvasViewboxMagnificationCalc(dateTargets[i]);

          //alignがセンターの場合センター寄せにpathの位置を修正
          if (
            align === 'center' &&
            pathWidth < rectElement[0].getBoundingClientRect().width / canvasViewboxMagnificationCalc(dateTargets[i])
          ) {
            const xposition =
              (rectElement[0].getBoundingClientRect().width / canvasViewboxMagnificationCalc(dateTargets[i]) -
                pathWidth) /
              2;
            path = font.getPath(dateText, xposition, parseInt(fontsize), parseInt(fontsize));
          }
          //alignが右の場合右寄せにpathの位置を修正
          if (
            align === 'right' &&
            pathWidth < rectElement[0].getBoundingClientRect().width / canvasViewboxMagnificationCalc(dateTargets[i])
          ) {
            const xposition =
              rectElement[0].getBoundingClientRect().width / canvasViewboxMagnificationCalc(dateTargets[i]) - pathWidth;
            path = font.getPath(dateText, xposition, parseInt(fontsize), parseInt(fontsize));
          }
          //pathデータのx座標情報変更
          const commands = path.commands;
          if (widthMagnification < 1) {
            for (let j = 0; j < commands.length; j++) {
              commands[j]['x'] = commands[j]['x'] * widthMagnification;
            }
          }
          //SVGに変換
          const Element = path.toSVG();

          dateTargets[i].innerHTML = Element;
          //pathエレメントの取得
          const pathElemnt = dateTargets[i].querySelector('path');
          //クリックイベントを無効にするクラスの追加
          pathElemnt.classList.add('event_none');
          //rectエレメントの追加
          dateTargets[i].prepend(rectElement[0]);
        }
      });
    }
  }
};

//非掲載のdisplay切り替え
export const changeDisableDisplay = (name, groupname) => {
  const checkboxElement = document.getElementsByClassName(name);
  let disabledNumber = []; //非掲載エレメントの配列
  let abledNumber = []; //掲載エレメントの配列
  if (checkboxElement) {
    for (let i = 0; i < checkboxElement.length; i++) {
      if (checkboxElement[i].checked) {
        disabledNumber.push(i + 1);
      } else {
        abledNumber.push(i + 1);
      }
    }
  }
  //非掲載要素をdisplay:noneにする
  for (let i = 0; i < disabledNumber.length; i++) {
    if (document.getElementById(groupname + '_' + disabledNumber[i] + '_group')) {
      document.getElementById(groupname + '_' + disabledNumber[i] + '_group').style.display = 'none';
    }
  }
  //掲載要素をdisplay:blockにする
  for (let i = 0; i < abledNumber.length; i++) {
    if (document.getElementById(groupname + '_' + abledNumber[i] + '_group')) {
      document.getElementById(groupname + '_' + abledNumber[i] + '_group').style.display = 'block';
    }
  }
};
//日時の非掲載時の要素の調整
export const moveDisable = (name, groupname, lineheight) => {
  const checkboxElement = document.getElementsByClassName(name);
  let disabledNumber = []; //非掲載エレメントの配列
  let abledNumber = []; //掲載エレメントの配列
  if (checkboxElement) {
    for (let i = 0; i < checkboxElement.length; i++) {
      if (checkboxElement[i].checked) {
        disabledNumber.push(i + 1);
      } else {
        abledNumber.push(i + 1);
      }
    }
  }
  //移動する要素を初期位置に戻す
  const timeGroupElement = document.getElementsByClassName(groupname + '_group');
  if (timeGroupElement) {
    for (let i = 0; i < timeGroupElement.length; i++) {
      timeGroupElement[i].setAttribute('transform', 'matrix(1,0,0,1,0,0)');
    }
  }

  for (let i = 0; i < abledNumber.length; i++) {
    for (let j = 0; j < disabledNumber.length; j++) {
      if (disabledNumber[j] < abledNumber[i]) {
        const groupNumber = String(abledNumber[i]);
        const moveElement = document.getElementById(groupname + '_' + groupNumber + '_group');
        const matrixArray = moveElement.getAttribute('transform').split(',');
        let yLine = matrixArray[5].replace(')', '');
        moveElement.setAttribute('transform', 'matrix(1,0,0,1,0,' + (Number(yLine) - lineheight) + ')');
      }
    }
  }
};

export const resizeElements = () => {
  let selectedElement = null;
  let currentX = 0;
  let currentY = 0;

  const getCurrent = (e) => {
    currentX = e.clientX;
    currentY = e.clientY;
    selectedElement = e.target.closest('.image_click_element').querySelector('image');
  };

  const setCurrent = (e) => {
    if (selectedElement) {
      const res = selectedElement.getAttribute('transform');
      let matrixArray = res.split(',');
      let matrixX = matrixArray[0].replace('matrix(', '');
      let matrixY = matrixArray[3];
      console.log(matrixX);
      console.log(matrixX + 0.08 * (currentX - e.clientX));
      console.log(matrixY);
      console.log(matrixY + 0.08 * (currentY - e.clientY));
      const ratio = Math.sqrt(
        Math.pow(Number(currentX) - Number(e.clientX), 2) + Math.pow(Number(currentY) - Number(e.clientY), 2)
      );
      const targetX = Number(matrixX) * ratio;
      const targetY = Number(matrixY) * ratio;
      console.log(ratio);
      selectedElement.setAttribute(
        'transform',
        `matrix(${targetX},${matrixArray[1]},${matrixArray[2]},${targetY},-${ratio},-${ratio})`
      );
      // console.log(`matrix(${newX},${matrixArray[1]},${matrixArray[2]},${newY},${matrixArray[4]},${matrixArray[5]}`)
    }
  };
  //canvasの取得
  const canvas = document.getElementById('canvas');

  //クラスresize_btnの要素を取得
  const resizeElements = canvas.getElementsByClassName('resize_btn');
  for (let i = 0; i < resizeElements.length; i++) {
    //マウスダウンした時の処理
    resizeElements[i].onmousedown = (event) => {
      console.log('クリックしたよ');
      getCurrent(event);
    };

    //マウスを動かした時の処理
    resizeElements[i].onmousemove = (event) => {
      console.log('動かしたよ');
      setCurrent(event);
    };

    //マウスアップした時の処理
    resizeElements[i].onmouseup = (event) => {
      console.log('離したよ');
    };
  }
};

// テキストが最大行数以内かどうかを判定
export const validateTextLineNum = (text, maxLineNum) => {
  const lines = text.split('\n');
  return lines.length <= maxLineNum;
};

// テキストの最大行数を超えた部分を切り捨てる
export const discardTextToMaxLineNum = (text, maxLineNum) => {
  const lines = text.split('\n');
  console.log(lines);
  let result = '';
  if (lines.length > maxLineNum) {
    for (let i = 0; i < maxLineNum; i++) {
      result += lines[i];
      if (i < maxLineNum - 1) result += '\n';
    }
  } else {
    result = text;
  }
  return result;
};

// Selectorに合致する要素の表示切替
export const changeElemVisibilityBySelector = (selector, isVisible) => {
  // SVG内の要素の表示切替
  const changeElemVisibility = (elem, isVisible) => {
    if (isVisible) {
      elem.removeAttribute('display');
    } else {
      elem.setAttribute('display', 'none');
    }
  };

  const elems = document.querySelectorAll(selector);
  for (let i = 0; i < elems.length; i++) {
    changeElemVisibility(elems[i], isVisible);
  }
};

// 各用途にあわせてSVG内の要素表示切替
export const changeSvgElemVisibilitiesByPurpose = (purpose, designType) => {
  // 枠線表示切替
  const changeBorderLineVisibility = (isVisible) => {
    changeElemVisibilityBySelector('.border_line', isVisible);
  };

  // 折り目表示切替
  const changeFoldLineVisibility = (isVisible) => {
    changeElemVisibilityBySelector('.fold_line', isVisible);
  };

  // 「SAMPLE」表示切替
  const changeSampleWatermarkVisibility = (isVisible) => {
    changeElemVisibilityBySelector('.sample_watermark', isVisible);
  };

  // 返信はがき「新郎側」「新婦側」表示切替
  const changePostcardTypeTextVisibility = (isVisible) => {
    changeElemVisibilityBySelector('.postcard_type_text', isVisible);
  };

  // 席次表：席枠線表示切替
  const changeSeatingSeatBorderLineVisibility = (isVisible) => {
    const tableAddArea = document.querySelector('#add_table_area');
    const seatElems = tableAddArea.querySelectorAll('.guest');
    if (!seatElems) {
      return;
    }

    for (let i = 0; i < seatElems.length; i++) {
      const seatElem = seatElems[i]; /* 席要素 */

      // 点線幅判定
      let strokeWidth;
      if (isVisible) {
        // 配席済みの席の場合、点線非表示
        if (isGuestDeployedSeat(seatElem)) {
          strokeWidth = 0;
        } else {
          strokeWidth = 0.5;
        }
      } else {
        strokeWidth = 0;
      }

      // 点線幅設定
      const rectElem = seatElem.querySelector('rect');
      rectElem.setAttribute('stroke-width', strokeWidth);
    }
  };

  let isBorderLineVisible = false;
  let isFoldLineVisible = false;
  let isSampleWatermarkVisible = false;
  let isPostcardTypeTextVisible = false;
  let isSeatingSeatBorderLineVisible = false;

  // 用途から表示状態を判定
  switch (purpose) {
    case 'design':
      // デザイン画面
      isBorderLineVisible = true;
      isFoldLineVisible = true;
      isSampleWatermarkVisible = false;
      isPostcardTypeTextVisible = true;
      isSeatingSeatBorderLineVisible = true;
      break;
    case 'design_save':
      // デザイン画面から保存
      isBorderLineVisible = true;
      isFoldLineVisible = true;
      isSampleWatermarkVisible = true;
      isPostcardTypeTextVisible = true;
      isSeatingSeatBorderLineVisible = true;
      break;
    case 'check_pdf':
      // 確認用PDF
      isBorderLineVisible = true;
      isFoldLineVisible = true;
      isSampleWatermarkVisible = true;
      isPostcardTypeTextVisible = true;
      isSeatingSeatBorderLineVisible = false;
      break;
    case 'print_pdf':
      // 印刷用PDF
      isBorderLineVisible = false;
      isFoldLineVisible = false;
      isSampleWatermarkVisible = false;
      isPostcardTypeTextVisible = false;
      isSeatingSeatBorderLineVisible = false;
      break;
    default:
      return;
  }

  // 表示切替
  changeBorderLineVisibility(isBorderLineVisible); /* 枠線非表示 */
  changeFoldLineVisibility(isFoldLineVisible); /* 折り目表示 */
  changeSampleWatermarkVisibility(isSampleWatermarkVisible); /* 「SAMPLE」表示 */
  changePostcardTypeTextVisibility(isPostcardTypeTextVisible); /* 返信はがき「新郎側」「新婦側」表示 */

  if (designType === 'seating') {
    changeSeatingSeatBorderLineVisibility(isSeatingSeatBorderLineVisible); /* 席次表：席枠線非表示 */
  }
};

// テキストエリアの入力内容が最大行数以下かどうか判定
export const validateMaxLineNum = (event, maxLineNum) => {
  const text = event.target.value;
  const lines = text.split('\n');
  console.log(lines, lines.length <= maxLineNum);
  return lines.length <= maxLineNum;
};

// 郵便番号入力検証
export const validataPostalCode = (event) => {
  const postalCode = event.target.value;
  console.log(postalCode, /^[0-9]{0,7}$/.test(postalCode));
  return /^[0-9]{0,7}$/.test(postalCode);
};
