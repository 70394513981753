import React from 'react';
import { EditorTextInput } from '../UIkit';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);

const EditorBasicInfomation = (props) => {
  return (
    <>
      <h2>基本情報を入力</h2>
      <div className="edit_box">
        <h3>新郎のお名前</h3>
        <div className="col_2">
          <EditorTextInput
            label="新郎姓"
            name="groom_family_name"
            maxLength="10"
            placeholder="○○"
            rows="1"
            value={props.groomFamilyName}
            onChange={props.onChangeGroomFamilyName}
          />
          <EditorTextInput
            label="新郎名"
            name="groom_first_name"
            maxLength="10"
            placeholder="太郎"
            rows="1"
            value={props.groomFirstName}
            onChange={props.onChangeGroomFirstName}
          />
        </div>
        {props.viewValue === 'seating' && (
          <div className="col_2">
            {/* <EditorTextInput
            label="新郎姓(ローマ字)"
            name="groom_family_name_en"
            placeholder="○○"
            rows="1"
            value={props.groomFamilyNameEn}
            onChange={props.onChangeGroomFamilyNameEn}
          /> */}
            <EditorTextInput
              label="新郎名(ローマ字)"
              name="groom_first_name_en"
              maxLength="15"
              placeholder="taro"
              rows="1"
              value={props.groomFirstNameEn}
              onChange={props.onChangeGroomFirstNameEn}
            />
          </div>
        )}
      </div>
      <div className="edit_box">
        <h3>新婦のお名前</h3>
        <div className="col_2">
          <EditorTextInput
            label="新婦姓"
            name="bride_family_name"
            maxLength="10"
            placeholder="○○"
            rows="1"
            value={props.brideFamilyName}
            onChange={props.onChangeBrideFamilyName}
          />
          <EditorTextInput
            label="新婦名"
            name="bride_first_name"
            maxLength="10"
            placeholder="花子"
            rows="1"
            value={props.brideFirstName}
            onChange={props.onChangeBrideFirstName}
          />
        </div>
        {props.viewValue === 'seating' && (
          <div className="col_2">
            {/* <EditorTextInput
            label="新婦姓(ローマ字)"
            name="bride_family_name_en"
            placeholder="○○"
            rows="1"
            value={props.brideFamilyNameEn}
            onChange={props.onChangeBrideFamilyNameEn}
          /> */}
            <EditorTextInput
              label="新婦名(ローマ字)"
              name="bride_first_name_en"
              maxLength="15"
              placeholder="Hanako"
              rows="1"
              value={props.brideFirstNameEn}
              onChange={props.onChangeBrideFirstNameEn}
            />
          </div>
        )}
      </div>
      <div className="edit_box">
        <h3>挙式日</h3>
        <div className="col_1">
          <div className="editor_text_input_item">
            <label htmlFor="">挙式日</label>
            <DatePicker
              name={'wedding_day'}
              selected={props.startDate}
              onFocus={props.onFocusWeddingDay}
              onChange={props.onChangeWeddingDay}
              dateFormat="yyyy-MM-dd"
              locale="ja"
            />
          </div>
        </div>
      </div>
      {props.viewValue === 'invitation' && (
        <>
          <div className="edit_box">
            <h3>新郎 親御様のお名前</h3>
            <div className="col_2">
              <EditorTextInput
                label="新郎親姓"
                name="groom_parent_family_name"
                maxLength="10"
                placeholder="○○"
                rows="1"
                value={props.groomParentFamilyName}
                onChange={props.onChangeGroomParentFamilyName}
              />
              <EditorTextInput
                label="新郎親名"
                name="groom_parent_first_name"
                maxLength="10"
                placeholder="○○"
                rows="1"
                value={props.groomParentFirstName}
                onChange={props.onChangeGroomParentFirstName}
              />
            </div>
          </div>
          <div className="edit_box">
            <h3>新婦 親御様のお名前</h3>
            <div className="col_2">
              <EditorTextInput
                label="新婦親姓"
                name="bride_parent_family_name"
                maxLength="10"
                placeholder="○○"
                rows="1"
                value={props.brideParentFamilyName}
                onChange={props.onChangeBrideParentFamilyName}
              />
              <EditorTextInput
                label="新婦親名"
                name="bride_parent_first_name"
                maxLength="10"
                placeholder="○○"
                rows="1"
                value={props.brideParentFirstName}
                onChange={props.onChangeBrideParentFirstName}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditorBasicInfomation;
