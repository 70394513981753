import React from 'react';
import { validateMaxLineNum } from '../../templates/Public/EditorFunction';
import { EditorTextArea } from '../UIkit';

const EditorGreetings = (props) => {
  return (
    <>
      <h2>投函月・本状文章を入力</h2>
      <div className="edit_box">
        <h3>投函月</h3>
        <div className="col_1 post_date">
          <button className="pre" onClick={props.ChangePostDatepre}>
            <span></span>
          </button>
          <span>{props.postdateValue}</span>
          <button className="next" onClick={props.ChangePostDatenext}>
            <span></span>
          </button>
        </div>
      </div>
      <div className="edit_box">
        <h3>本状文章</h3>
        <div className="col_1">
          <EditorTextArea
            name="greetings"
            rows="10"
            wrap="off"
            value={props.greetings}
            onChange={(event) => {
              if (validateMaxLineNum(event, 9)) {
                props.onChange(event);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default EditorGreetings;
