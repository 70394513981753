import axios from 'axios';
import React from 'react';
import { ApiDir, PROGRAM_ERROR_ALERT_MSG, RouteDir } from '../../common';

// 注文詳細：招待状・席次表デザインPDF出力ボタン
const OrderDetailGuestListExcelDownloadBtn = (props) => {
  const btnStyle = {
    width: '150px',
    backgroundColor: 'green',
    color: '#ffffff',
    padding: '5px 12px',
    border: 'none',
    margin: '0 20px',
    borderRadius: '2px',
    cursor: 'pointer',
  };

  // ダウンロードボタンクリック時の処理
  // const handleBtnClick = () => {
  //   // form情報の取得
  //   // const formElem = document.querySelector('#guestListDownloadForm');

  //   const formData = new FormData();

  //   // 注文番号追加
  //   formData.append('order_number', props.orderNumber);

  //   // 会員ID追加
  //   formData.append('member_id', props.memberId);

  //   // alert(...formData.entries());
  //   // return;

  //   // ダウンロード処理
  //   axios
  //     .post(ApiDir + '/downloadGuestList.php', formData)
  //     .then(function (response) {
  //       console.log(response.data);
  //       if (response.data === 'error') {
  //         window.alert(PROGRAM_ERROR_ALERT_MSG);
  //         return;
  //       }

  //       // const blob = new Blob();
  //       // console.log(blob);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       window.alert(PROGRAM_ERROR_ALERT_MSG);
  //       return;
  //     });
  // };

  // return (
  //   // <form
  //   //   id="guestListDownloadForm"
  //   //   encType="multipart/form-data"
  //   //   method="post"
  //   //   onSubmit={(e) => e.preventDefault()}
  //   //   action=""
  //   // >
  //   // {/* <input name="order_number" value={props.orderNumber} hidden />
  //   // <input name="member_id" value={props.memberId} hidden /> */}
  //   <button
  //     style={btnStyle}
  //     onClick={() => {
  //       handleBtnClick();
  //     }}
  //   >
  //     宛名ダウンロード
  //   </button>
  //   // {/* <input type="submit" value={'宛名ダウンロード'} style={btnStyle} /> */}
  //   // </form>
  // );

  return (
    <form
      id="guestListDownloadForm"
      encType="multipart/form-data"
      method="post"
      action={`${ApiDir}/downloadGuestList.php`}
    >
      <input name="order_number" value={props.orderNumber} hidden />
      <input name="member_id" value={props.memberId} hidden />
      <input type="submit" value={'宛名ダウンロード'} style={btnStyle} />
    </form>
  );
};

export default OrderDetailGuestListExcelDownloadBtn;
