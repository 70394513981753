import * as Actions from './actions'
import initialState from '../store/initialState'

export const PopupsReducer = (state = initialState.popups, action) => {
  switch (action.type) {
    case Actions.LOGINPOPUP_CHANGE:
      return {
        ...state,
        ...action.payload
      }
    case Actions.LOADING_CHANGE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state

  }
}