import React, { useEffect, useCallback } from 'react';
import { EditorTextInput, EditorTextArea } from '../UIkit';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
import { validateMaxLineNum } from '../../templates/Public/EditorFunction';
registerLocale('ja', ja);

const EditorEnvelope = (props) => {
  // console.log(props)

  //住所、氏名のグループを全て非表示に変更
  const infoContentHidden = () => {
    //親住所
    const parentInfo = document.getElementById('parent_info_content');
    if (parentInfo) {
      parentInfo.style.display = 'none';
    }
    //新郎新婦同一住所
    const conbineOrganizerInfo = document.getElementById('combine_organizer_info_content');
    if (conbineOrganizerInfo) {
      conbineOrganizerInfo.style.display = 'none';
    }
    //親住所
    const separateOrganizerInfo = document.getElementById('separate_organizer_info_content');
    if (separateOrganizerInfo) {
      separateOrganizerInfo.style.display = 'none';
    }
  };

  //該当の住所、氏名グループを表示に変更
  const infoContentShow = (target) => {
    infoContentHidden();
    const targetInfo = document.getElementById(target);
    // console.log(targetInfo)
    if (targetInfo) {
      targetInfo.style.display = 'block';
    }
  };

  //差出人新郎新婦名を選択した時の処理
  const clickSendToOrganizer = () => {
    //記載方法の値が1の時
    if (props.descriptionMethodType === '1') {
      infoContentShow('combine_organizer_info_content');
    } else {
      infoContentShow('separate_organizer_info_content');
    }
  };

  //旧姓を全て非表示に変更
  const maidenNameHidden = () => {
    const groomMaidenNameElement = document.getElementById('groom_maiden_name');
    if (groomMaidenNameElement) {
      groomMaidenNameElement.style.display = 'none';
    }
    const brideMaidenNameElement = document.getElementById('bride_maiden_name');
    if (brideMaidenNameElement) {
      brideMaidenNameElement.style.display = 'none';
    }
  };

  //該当の旧姓を表示に変更
  const maidenNameShow = (target) => {
    maidenNameHidden();
    const targetMaidenName = document.getElementById(target);
    if (targetMaidenName) {
      targetMaidenName.style.display = 'block';
    }
  };

  useEffect(() => {
    // console.log(
    //   document.getElementById('parent_info_content') !== null,
    //   document.getElementById('combine_organizer_info_content') !== null,
    //   document.getElementById('separate_organizer_info_content') !== null,
    //   document.getElementById('groom_maiden_name') !== null,
    //   document.getElementById('bride_maiden_name') !== null,
    //   props.deliveryAddressType,
    //   props.descriptionMethodType,
    //   props.showMaidenNameType
    // )
    if (
      document.getElementById('parent_info_content') &&
      document.getElementById('combine_organizer_info_content') &&
      document.getElementById('separate_organizer_info_content') &&
      document.getElementById('groom_maiden_name') &&
      document.getElementById('bride_maiden_name')
    ) {
      if (props.deliveryAddressType === '1') {
        if (props.descriptionMethodType === '1' || props.descriptionMethodType === '0') {
          infoContentShow('combine_organizer_info_content');

          // 旧姓表示切替
          if (props.showMaidenNameType === '2') {
            maidenNameShow('bride_maiden_name');
          } else if (props.showMaidenNameType === '3') {
            maidenNameShow('groom_maiden_name');
          } else {
            maidenNameHidden();
          }
        } else {
          infoContentShow('separate_organizer_info_content');
        }
      } else {
        infoContentShow('parent_info_content');
      }
    }
  }, [
    document.getElementById('parent_info_content') !== null,
    document.getElementById('combine_organizer_info_content') !== null,
    document.getElementById('separate_organizer_info_content') !== null,
    document.getElementById('groom_maiden_name'),
    document.getElementById('bride_maiden_name'),
    props.deliveryAddressType,
    props.descriptionMethodType,
    props.showMaidenNameType,
  ]);

  return (
    <>
      <h2>封筒の差出人を入力</h2>
      <div className="edit_box">
        <h3>差出人</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="send_to_organizer">
              <input
                type="radio"
                name="envelope_delivery_address_type"
                value="1"
                id="send_to_organizer"
                checked={props.deliveryAddressType === '1'}
                onChange={props.onChangeDeliveryAddressType}
                // onClick={() => { clickSendToOrganizer() }}
              />
              新郎新婦名
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="send_to_parents">
              <input
                type="radio"
                name="envelope_delivery_address_type"
                value="2"
                id="send_to_parents"
                checked={props.deliveryAddressType === '2'}
                onChange={props.onChangeDeliveryAddressType}
                // onClick={() => { infoContentShow('parent_info_content') }}
              />
              親名
            </label>
          </div>
        </div>
      </div>

      <div className="discription_method_content" style={{ display: props.deliveryAddressType === '1' ? '' : 'none' }}>
        <div className="edit_box">
          <h3>記載方法</h3>
          <div className="col_1">
            <div className="editor_radio_input_item">
              <label htmlFor="combine">
                <input
                  type="radio"
                  name="envelope_description_method_type"
                  value="1"
                  id="combine"
                  checked={props.descriptionMethodType === '1' || props.descriptionMethodType === '0'}
                  onChange={props.onChangeDescriptionMethodType}
                  // onClick={() => { infoContentShow('combine_organizer_info_content') }}
                />
                1つの住所で連名
              </label>
            </div>
          </div>
          <div className="col_1">
            <div className="editor_radio_input_item">
              <label htmlFor="separate">
                <input
                  type="radio"
                  name="envelope_description_method_type"
                  value="2"
                  id="separate"
                  checked={props.descriptionMethodType === '2'}
                  onChange={props.onChangeDescriptionMethodType}
                  // onClick={() => { infoContentShow('separate_organizer_info_content') }}
                />
                新郎新婦別々に記載
              </label>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: props.deliveryAddressType === '1' ? '' : 'none',
        }}
      >
        <div
          id="organizer_address_content"
          style={{
            display: props.descriptionMethodType === '1' || props.descriptionMethodType === '0' ? '' : 'none',
          }}
        >
          <div className="edit_box">
            <h3>新郎新婦住所</h3>
            {/* <div className="col_2">
                  <EditorTextInput
                    label="郵便番号"
                    name="envelope_postal_code"
                    placeholder="0000000"
                    rows="1"
                    value={props.postalCode}
                    onChange={props.onChangePostalCode}
                  />
                </div> */}
            <div className="col_1">
              <div className="address_note">住所（2行まで）</div>
              <EditorTextArea
                name="envelope_address"
                rows="3"
                wrap="off"
                value={props.address}
                onChange={(event) => {
                  if (validateMaxLineNum(event, 2)) {
                    props.onChangeAddress(event);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div
          id="groom_bride_address_content"
          style={{
            display: props.descriptionMethodType === '2' ? '' : 'none',
          }}
        >
          <div className="edit_box">
            <h3>新郎住所</h3>
            {/* <div className="col_2">
                  <EditorTextInput
                    label="郵便番号"
                    name="envelope_groom_postal_code"
                    placeholder="0000000"
                    rows="1"
                    value={props.groomPostalCode}
                    onChange={props.onChangeGroomPostalCode}
                  />
                </div> */}
            <div className="col_1">
              <div className="address_note">住所（2行まで）</div>
              <EditorTextArea
                name="envelope_groom_address"
                rows="3"
                wrap="off"
                value={props.groomAddress}
                onChange={(event) => {
                  if (validateMaxLineNum(event, 2)) {
                    props.onChangeGroomAddress(event);
                  }
                }}
              />
            </div>
          </div>
          <div className="edit_box">
            <h3>新婦住所</h3>
            {/* <div className="col_2">
                  <EditorTextInput
                    label="郵便番号"
                    name="envelope_bride_postal_code"
                    placeholder="0000000"
                    rows="1"
                    value={props.bridePostalCode}
                    onChange={props.onChangeBridePostalCode}
                  />
                </div> */}
            <div className="col_1">
              <div className="address_note">住所（2行まで）</div>
              <EditorTextArea
                name="envelope_bride_address"
                rows="3"
                wrap="off"
                value={props.brideAddress}
                onChange={(event) => {
                  if (validateMaxLineNum(event, 2)) {
                    props.onChangeBrideAddress(event);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        id="parent_address_content"
        style={{
          display: props.deliveryAddressType === '2' ? '' : 'none',
        }}
      >
        <div className="edit_box">
          <h3>新郎親住所</h3>
          {/* <div className="col_2">
              <EditorTextInput
                label="郵便番号"
                name="envelope_groom_parent_postal_code"
                placeholder="0000000"
                rows="1"
                value={props.groomParentPostalCode}
                onChange={props.onChangeGroomParentPostalCode}
              />
            </div> */}
          <div className="col_1">
            <div className="address_note">住所（2行まで）</div>
            <EditorTextArea
              name="envelope_groom_parent_address"
              rows="3"
              wrap="off"
              value={props.groomParentAddress}
              onChange={(event) => {
                if (validateMaxLineNum(event, 2)) {
                  props.onChangeGroomParentAddress(event);
                }
              }}
            />
          </div>
        </div>
        <div className="edit_box">
          <h3>新婦親住所</h3>
          {/* <div className="col_2">
              <EditorTextInput
                label="郵便番号"
                name="envelope_bride_parent_postal_code"
                placeholder="0000000"
                rows="1"
                value={props.brideParentPostalCode}
                onChange={props.onChangeBrideParentPostalCode}
              />
            </div> */}
          <div className="col_1">
            <div className="address_note">住所（2行まで）</div>
            <EditorTextArea
              name="envelope_bride_parent_address"
              rows="3"
              wrap="off"
              value={props.brideParentAddress}
              onChange={(event) => {
                if (validateMaxLineNum(event, 2)) {
                  props.onChangeBrideParentAddress(event);
                }
              }}
            />
          </div>
        </div>
      </div>

      {/* {props.deliveryAddressType === '1' && (
        <>
          {(props.descriptionMethodType === '1' || props.descriptionMethodType === '0') && (
            <div className="edit_box">
              <h3>旧姓表示</h3>
              <div className="col_1">
                <div className="editor_radio_input_item">
                  <label htmlFor="noshow_maiden_name">
                    <input
                      type="radio"
                      name="envelope_show_maiden_name_type"
                      value="1"
                      id="noshow_maiden_name"
                      checked={props.showMaidenNameType === '1' || props.showMaidenNameType === '0'}
                      onChange={props.onChangeShowMaidenNameType}
                      // onClick={() => { maidenNameHidden() }}
                    />
                    旧姓を表示しない
                  </label>
                  <span className="show_maiden_name_span">※入籍済みの場合</span>
                  <label htmlFor="show_bride_maiden_name">
                    <input
                      type="radio"
                      name="envelope_show_maiden_name_type"
                      value="2"
                      id="show_bride_maiden_name"
                      checked={props.showMaidenNameType === '2'}
                      onChange={props.onChangeShowMaidenNameType}
                      // onClick={() => { maidenNameShow('bride_maiden_name') }}
                    />
                    新婦側旧姓を表示する
                  </label>
                  <label htmlFor="show_groom_maiden_name">
                    <input
                      type="radio"
                      name="envelope_show_maiden_name_type"
                      value="3"
                      id="show_groom_maiden_name"
                      checked={props.showMaidenNameType === '3'}
                      onChange={props.onChangeShowMaidenNameType}
                      // onClick={() => { maidenNameShow('groom_maiden_name') }}
                    />
                    新郎側旧姓を表示する
                  </label>
                </div>
              </div>
            </div>
          )}
        </>
      )} */}
    </>
  );
};

export default EditorEnvelope;
