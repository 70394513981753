import React, { useCallback, useEffect, useState } from 'react';
import { changeCategory, changeKeyword } from '../../reducks/items/operations';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RouteDir } from '../../common';
import { config } from '../../config';

const Sidebar = () => {
  const dispatch = useDispatch();

  const keyword = useSelector((state) => state.items.selectKeyword);
  const loginStatus = useSelector((state) => state.members.loginStatus);

  //検索商品名の入力
  const [searchNameValue, setSearchName] = useState(keyword);
  const inputSearchName = useCallback(
    (event) => {
      setSearchName(event.target.value);
    },
    [setSearchName]
  );

  useEffect(() => {
    setSearchName(keyword);
  }, [keyword]);

  const menuListFruitStyle = {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_fruit.png')`,
  };

  const menuListMeatStyle = {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_meat.png')`,
  };

  const menuListVegetableStyle = {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_vegetable.png')`,
  };

  const menuListProcessedGoodsStyle = {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_Processed_goods.png')`,
  };

  const menuListFlowerArrangementStyle = {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_flower_arrangement.png')`,
    backgroundSize: '25px',
    backgroundPosition: 'right 37px center',
  };

  const menuListOtherStyle = {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_other.png')`,
  };

  const searchInputStyle = {
    // backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_search.svg')`
  };

  const categoryarrowStyle = {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/sidebar_category_arrow.svg')`,
    backgroundSize: '7px',
    backgroundPosition: 'right 10px center',
    backgroundRepeat: 'no-repeat',
  };

  const arrowStyle = {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/sidebar_contents_arrow.svg')`,
    backgroundSize: '7px',
    backgroundPosition: 'left 0px center',
    backgroundRepeat: 'no-repeat',
  };

  const selectItemListByCategory = (id) => {
    setSearchName('');
    // dispatch(changeCategory(id))
    dispatch(push(RouteDir + '/item/list?category=' + id));
  };

  const selectItemListByKeyword = (searchNameValue) => {
    // dispatch(changeKeyword(searchNameValue))
    dispatch(push(RouteDir + '/item/list?keyword=' + searchNameValue));
  };

  return (
    <aside className={'sidebar'}>
      <div className="search_area">
        <h2>商品検索</h2>
        <input
          type="text"
          name="search_name"
          style={searchInputStyle}
          value={searchNameValue}
          onChange={inputSearchName}
          placeholder={'キーワード検索'}
        />
        <button onClick={() => selectItemListByKeyword(searchNameValue)}>検索</button>
      </div>
      <div className="menu_list">
        <h2>
          カテゴリ<span>category</span>
        </h2>
        <ul>
          <li style={categoryarrowStyle} onClick={() => selectItemListByCategory(17)}>
            <p>招待状</p>
          </li>
          <li style={categoryarrowStyle} onClick={() => selectItemListByCategory(18)}>
            <p>席次表</p>
          </li>
        </ul>
      </div>
      <div className="menu_list2">
        <h2>
          コンテンツ<span>contents</span>
        </h2>
        <ul>
          <li style={arrowStyle} onClick={() => dispatch(push(RouteDir + '/mypage/favorite_list'))}>
            <p>お気に入り商品</p>
          </li>
          <li style={arrowStyle} onClick={() => dispatch(push(RouteDir + '/mypage/cart_list'))}>
            <p>買い物カゴ</p>
          </li>
          <li style={arrowStyle} onClick={() => dispatch(push(RouteDir + '/contact'))}>
            <p>お問い合わせ</p>
          </li>
          {!loginStatus && (
            <>
              <li style={arrowStyle} onClick={() => dispatch(push(RouteDir + '/signup_application'))}>
                <p>新規会員登録</p>
              </li>
              <li style={arrowStyle} onClick={() => dispatch(push(RouteDir + '/login'))}>
                <p>ログイン</p>
              </li>
            </>
          )}
        </ul>
      </div>
      {/* <div className="web-banner-wrap">
        <div className="menu_list3">
          <a href={config.SIDEBAR_WEB_BANNER_URL_1} target="_blank">
            <img src={process.env.PUBLIC_URL + '/images/web_banner_1.png'} alt="" />
          </a>
        </div>
        <div className="menu_list3">
          <a href={config.SIDEBAR_WEB_BANNER_URL_2} target="_blank">
            <img src={process.env.PUBLIC_URL + '/images/web_banner_2.png'} alt="" />
          </a>
        </div>
        <div className="menu_list3">
          <a href={config.SIDEBAR_WEB_BANNER_URL_3} target="_blank">
            <img src={process.env.PUBLIC_URL + '/images/web_banner_3.png'} alt="" />
          </a>
        </div>
        <div className="menu_list3">
          <a href={config.SIDEBAR_WEB_BANNER_URL_4} target="_blank">
            <img src={process.env.PUBLIC_URL + '/images/web_banner_4.png'} alt="" />
          </a>
        </div>
      </div> */}
    </aside>
  );
};

export default Sidebar;
