import * as Actions from './actions'
import initialState from '../store/initialState'

export const AddressesReducer = (state = initialState.addresses, action) =>
{
  switch(action.type)
  {
    case Actions.SELECT_ADDRESS:
      return {
        ...state,
        ...action.payload
      }
      default:
        return state

  }
}