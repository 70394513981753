import { addSubItemInfoAction, selectCartListAction, updateCartOptionsAction, updateCartTypeAction } from './actions';
import axios from 'axios';
import { push } from 'connected-react-router';
import { ApiDir, PROGRAM_ERROR_ALERT_MSG, RouteDir } from '../../common';

//買い物カゴ情報登録
export const insertCart = (itemId, quantity, designSerial = '', addType = '') => {
  return async (dispatch) => {
    let params = new URLSearchParams();
    params.append('item_id', itemId);
    params.append('quantity', quantity);
    params.append('design_serial', designSerial);
    params.append('formkey', 'insertkey');
    axios
      .post(ApiDir + '/insertCart.php', params)
      .then(function (response) {
        if (response.data === 'nologin') {
          //未ログイン時はアラートを出してログイン画面に遷移
          window.alert('買い物カゴに追加するには、ログインを行ってください。');
          dispatch(push(RouteDir + '/login'));
        } else {
          if (response.data.result) {
            //store情報の変更
            dispatch(selectCartListAction(response.data.cart_list));

            // 保存したデザイン画面から追加された場合は、買い物カゴ画面に自動遷移
            if (addType === 'saved-design') {
              // カート画面へ遷移
              dispatch(push(`${RouteDir}/mypage/cart_list`));
              // setTimeout(() => {
              //   window.alert('必要項目を入力・指定してください。');
              // }, 100);
            } else {
              window.alert('買い物カゴに追加しました。');
            }
          } else {
            if (response.data.message === 'already_added') {
              window.alert(
                '買い物カゴに追加済みの商品です。\n数量を変更する場合は、お手数ですが商品を削除後にもう一度追加してください。'
              );
            } else if (response.data.message === 'item_type_error') {
              window.alert(
                '招待状・席次表は個別注文のみ受け付けております。\n買い物カゴの商品を先にご注文いただくか、買い物カゴを空にしてください。'
              );
            } else {
              throw new Error('failed to add a item to cart');
            }
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        window.alert(PROGRAM_ERROR_ALERT_MSG);
        return;
      })
      .finally(function () {
        return;
      });
  };
};

//買い物カゴ情報削除
export const deleteCart = (cartId, cartType) => {
  return async (dispatch) => {
    let params = new URLSearchParams();
    params.append('cart_id', cartId);
    params.append('formkey', 'deletekey');
    axios
      .post(ApiDir + '/deleteCart.php', params)
      .then(function (response) {
        if (response.data === 'nologin') {
          //未ログイン時はアラートを出してログイン画面に遷移
          window.alert('買い物カゴ情報を変更するには、ログインを行ってください。');
          dispatch(push(RouteDir + '/login'));
        } else {
          // window.alert('買い物カゴから削除しました。');
          //store情報の変更
          dispatch(selectCartListAction(response.data));

          // 招待状・席次表の場合はサブアイテムデータも削除
          if (cartType !== 'normal') {
            // カート種別初期化
            dispatch(updateCartType(''));

            // サブアイテム情報初期化
            dispatch(addSubItemInfo('', []));

            // カートオプション初期化
            dispatch(updateCartOptions({ isExpressDelivery: false, expressDeliveryFee: 0 }));
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        window.alert(PROGRAM_ERROR_ALERT_MSG);
        return;
      })
      .finally(function () {
        return;
      });
  };
};

// 最新の買い物カゴ情報を取得
export const fetchLatestCartInfo = () => {
  return async (dispatch) => {
    let params = new URLSearchParams();
    params.append('formkey', 'selectkey');
    axios
      .post(ApiDir + '/selectCart.php', params)
      .then(function (response) {
        if (response.data === 'nologin') {
          //未ログイン時はアラートを出してログイン画面に遷移
          window.alert('ログインしてください。');
          dispatch(push(RouteDir + '/login'));
        } else {
          if (response.data.cart_list) {
            //store情報の変更
            dispatch(selectCartListAction(response.data.cart_list));
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        window.alert(PROGRAM_ERROR_ALERT_MSG);
        return;
      })
      .finally(function () {
        return;
      });
  };
};

//お届け先情報に商品情報を追加したオーダー情報を挿入
export const selectCartList = (value) => {
  return async (dispatch) => {
    dispatch(selectCartListAction(value));
  };
};

// カート種別を更新
export const updateCartType = (cartType) => {
  return async (dispatch) => {
    dispatch(updateCartTypeAction(cartType));
  };
};

// サブアイテム情報をストアに登録
export const addSubItemInfo = (itemType, subItemData) => {
  return async (dispatch) => {
    dispatch(addSubItemInfoAction({ itemType: itemType, subItemData: subItemData }));
  };
};

// カートオプションを更新
export const updateCartOptions = (options) => {
  return async (dispatch) => {
    dispatch(updateCartOptionsAction(options));
  };
};
