import React from 'react';

// 商品登録・編集：サブアイテム価格設定フォーム
const SubItemPriceInputForm = (props) => {
  const tableStyle = {
    width: '400px',
    // border: '1px solid gray',
    lineHeight: '10px',
  };

  const tdTitleStyle = {
    width: '100px',
    height: '20px',
    padding: '0',
    padding: '10px 10px 10px 30px',
  };
  const tdQuantityStyle = {
    width: '150px',
    textAlign: 'right',
    padding: '10px',
  };

  // 付箋
  const fusenTableStyle = {
    display: 'inline-block',
    width: '200px',
    // border: '1px solid gray',
    lineHeight: '10px',
    borderCollapse: 'collapse',
  };
  const fusenTdTitleStyle = {
    width: '100px',
    height: '20px',
    padding: '0',
    padding: '10px 10px 10px 10px',
    textAlign: 'left',
    borderBottom: '1px solid gray',
  };
  const fusenTdQuantityStyle = {
    width: '100px',
    textAlign: 'right',
    padding: '10px',
    borderBottom: '1px solid gray',
  };

  return (
    <table style={tableStyle}>
      <thead></thead>
      <tbody>
        {/* 招待状 */}
        {props.itemType === 'invitation' && (
          <>
            <tr>
              <td style={tdTitleStyle}>招待状</td>
              <td style={tdQuantityStyle}>
                ￥
                <input
                  type="number"
                  name={'invitation_price'}
                  value={props.subItemPrices.invitation}
                  onChange={(e) => props.inputSubItemPrices('invitation', e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td style={tdTitleStyle}>返信はがき</td>
              <td style={tdQuantityStyle}>
                ￥
                <input
                  type="number"
                  name={'postcard_price'}
                  value={props.subItemPrices.postcard}
                  onChange={(e) => props.inputSubItemPrices('postcard', e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td style={tdTitleStyle}>封筒</td>
              <td style={tdQuantityStyle}>
                ￥
                <input
                  type="number"
                  name={'envelope_price'}
                  value={props.subItemPrices.envelope}
                  onChange={(e) => props.inputSubItemPrices('envelope', e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td style={tdTitleStyle}>封筒予備</td>
              <td style={tdQuantityStyle}>
                ￥
                <input
                  type="number"
                  name={'envelope_spare_price'}
                  value={props.subItemPrices.envelopeSpare}
                  onChange={(e) => props.inputSubItemPrices('envelopeSpare', e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td style={tdTitleStyle}>付箋</td>
              <td style={tdQuantityStyle}>
                ￥
                <input
                  type="number"
                  name={'fusen_price'}
                  value={props.subItemPrices.fusen}
                  onChange={(e) => props.inputSubItemPrices('fusen', e.target.value)}
                />
              </td>
            </tr>
          </>
        )}

        {/* 席次表 */}
        {props.itemType === 'seating' && (
          <>
            <tr>
              <td style={tdTitleStyle}>席次表</td>
              <td style={tdQuantityStyle}>
                ￥
                <input
                  type="number"
                  name={'seating_price'}
                  value={props.subItemPrices.seating}
                  onChange={(e) => props.inputSubItemPrices('seating', e.target.value)}
                />
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default SubItemPriceInputForm;
