import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGroup } from '../../reducks/groups/operations';
import axios from 'axios';
import { ApiDir, GUEST_ADD_EXCEL_TEMPLATE_URL, HomeUrl, JsDir, PROGRAM_ERROR_ALERT_MSG, RouteDir } from '../../common';
import DocumentMeta from 'react-document-meta';
import { SiteTitle } from './common';
import importScript from '../../components/hooks/ImportScript';
import { BreadCrumb } from '../../components';
import ExcelJS from 'exceljs';
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import { push } from 'connected-react-router';

const GuestList = () => {
  const dispatch = useDispatch();
  const memberId = useSelector((state) => state.members.member_id); /* ログイン中の会員ID */
  const [guestList, setGuestList] = useState([]);

  const Floors = [
    // {
    //   name: 'マイページトップ',
    //   href: '/mypage',
    // },
    {
      name: '宛名リスト',
      href: '/guest_add_excel',
    },
  ];

  const meta = {
    title: '宛名リスト｜' + SiteTitle,
  };

  // Grid用カラム情報
  const tableCols = [
    { field: 'excel_row', headerName: 'Excel行番号', width: 100, headerAlign: 'center', align: 'center' },
    // {
    //   field: 'selectBtn',
    //   headerName: '選択',
    //   width: 50,
    //   headerAlign: 'center',
    //   align: 'right',
    //   renderCell: () => <input type="checkbox" defaultChecked={true} />,
    // },
    { field: 'family_name', headerName: '苗字', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'first_name', headerName: '名前', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'honorific', headerName: '敬称', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'wife_honorific', headerName: '令夫人', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint1_family_name', headerName: '苗字Ａ', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint1_first_name', headerName: '名前連名A', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint1_honorific', headerName: '敬称A', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'to_family', headerName: 'ご家族様', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint2_first_name', headerName: '名前連名B', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint2_honorific', headerName: '敬称B', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint3_first_name', headerName: '名前連名C', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint3_honorific', headerName: '敬称C', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'postal_code', headerName: '郵便番号', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'address_1', headerName: '住所１', width: 300, headerAlign: 'center', align: 'left' },
    { field: 'address_2', headerName: '住所２', width: 300, headerAlign: 'center', align: 'left' },
    { field: 'title', headerName: ' 会社名・役職', width: 300, headerAlign: 'center', align: 'center' },
  ];

  useEffect(async () => {
    // DataGrid用に各データにIDを付与
    const addIdToEachDataForDataGrid = (jsonDataList) => {
      let jsonDataListWithId = [];
      for (let i = 0; i < jsonDataList.length; i++) {
        const jsonData = jsonDataList[i];
        jsonData.id = i;
        jsonDataListWithId.push(jsonData);
      }
      return jsonDataListWithId;
    };

    // DBから登録済みの宛名リストを取得
    const getMemberGuestList = () => {
      return new Promise((resolve, reject) => {
        axios
          .post(ApiDir + '/selectMemberGuests.php')
          .then((response) => {
            console.log(response.data);
            if (response.data === 'nologin') {
              window.alert('ログインしてください。');
              dispatch(push(`${RouteDir}/login`));
            } else if (Array.isArray(response.data)) {
              resolve(response.data);
            } else {
              window.alert('宛名リストの取得に失敗しました。');
              resolve([]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    };

    const memberGuestList = await getMemberGuestList();
    setGuestList(addIdToEachDataForDataGrid(memberGuestList));
  }, []);

  return (
    <DocumentMeta {...meta}>
      <div id="guest_list_page">
        <BreadCrumb floors={Floors} />
        <h1>宛名リスト</h1>
        <p>
          システムに登録された宛名を確認できます。
          <br />
          宛名登録は
          <a
            id="guest_add_excel_link"
            onClick={() => {
              dispatch(push(RouteDir + '/mypage/guest_add_excel'));
            }}
          >
            宛名登録画面
          </a>
          から行えます。
        </p>
        <p>
          ※招待状を購入される場合、こちらの宛名リストが使用されます。
          <br />
          ※システムに登録された宛名は、席次表にゲストを配席する際に使用できます。
        </p>

        <div id="loaded_guest_list_table_area">
          {guestList.length === 0 ? (
            <div className="loaded_result_title">登録されていません</div>
          ) : (
            <>
              <div className="loaded_result_title">宛名リスト</div>
              <DataGrid
                autoHeight // グリッドの行数時応じて変化
                // style={{ maxHeight: tableHeight, height: tableHeight }} // 高さ指定
                pageSize={10}
                // autoPageSize // pageSizeは最大行事応じて計算される
                // checkboxSelection // チェックボックス
                // checkboxSelectionVisibleOnly={(e)=>(console.log(e))} //チェックボックス選択時
                // columnBuffer={10}
                // rowBuffer={10}
                density="compact" // 行の高さ compact standard comfortable
                // disableExtendRowFullWidth // コンテナいっぱいに表示させない
                disableColumnFilter // フィルター非表示
                // editMode="cell"
                // logger={console}
                // onCellClick={(e) => console.log("click", e.row)}
                // rowSpacingType="border"
                // disableMultipleColumnsFiltering // 複数フィルタリング
                disableMultipleColumnsSorting={true} // 複数ソート
                disableSelectionOnClick // データの選択を無効にする
                showCellRightBorder
                showColumnRightBorder
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                // components={
                //   // props.data.Toolbar && {
                //     Toolbar: GridToolbar
                //   // }
                // }
                // components={{
                //   Toolbar: GridToolbar, // ツールバーを指定する
                // }}
                columns={tableCols}
                rows={guestList}
              />
            </>
          )}
        </div>
      </div>
    </DocumentMeta>
  );
};

export default GuestList;
