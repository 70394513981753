import React, { useCallback, useEffect, useState } from 'react';
import { insertFloor, insertH1 } from '../../reducks/pageInfos/operations';
import { insertFavorite, deleteFavorite } from '../../reducks/favorites/operations';
import { insertCart } from '../../reducks/carts/operations';
import { Paging } from '../../components/UIkit';
import DocumentMeta from 'react-document-meta';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { SiteTitle } from './common';
import { ApiDir, ImagesDir, RouteDir, ItemImageDir, PROGRAM_ERROR_ALERT_MSG } from '../../common';
import { calcItemTaxIncludedPrice } from '../../myLib';

const ItemList = (props) => {
  //商品IDをURLパラメータから取得
  const pageItemId = props.match.params.id;

  const dispatch = useDispatch();

  const [itemInfo, setItemInfo] = useState([]); //商品情報
  const [itemImages, setItemImages] = useState([]); //商品画像情報

  const favoriteList = useSelector((state) => state.favorites.list);
  const [favoriteState, setFavoriteState] = useState(false); //お気に入り登録フラグ

  const [quantity, setQuantity] = useState(1); //商品個数
  const inputQuantity = useCallback(
    (event) => {
      // console.log(Number(event.target.value))
      setQuantity(event.target.value);
      // if(Number(event.target.value) > 0) {
      //   setQuantity(event.target.value)
      // } else {
      //   alert("1以上の数量を入力してください。")
      //   setQuantity(1)
      // }
    },
    [setQuantity]
  );

  useEffect(() => {
    //商品情報を取得
    let params = new URLSearchParams();
    params.append('item_id', pageItemId);
    params.append('formkey', 'selectkey');
    axios
      .post(ApiDir + '/selectPublicItem.php', params)
      .then(function (response) {
        // console.log(response.data)
        setItemInfo(response.data[0]);
      })
      .catch(function (error) {
        console.log(error);
        window.alert(PROGRAM_ERROR_ALERT_MSG);
        return;
      });

    //商品画像情報を取得
    params = new URLSearchParams();
    params.append('item_id', pageItemId);
    params.append('kinds', 2);
    params.append('formkey', 'selectkey');
    axios
      .post(ApiDir + '/selectPublicItemImage.php', params)
      .then(function (response) {
        // console.log(response.data)
        setItemImages(response.data);
      })
      .catch(function (error) {
        console.log(error);
        window.alert(PROGRAM_ERROR_ALERT_MSG);
        return;
      });

    //お気に入り一覧の中にあるか調べる
    const favoriteLength = favoriteList.length;
    let favoriteFlag = false;
    for (let i = 0; i < favoriteLength; i++) {
      if (favoriteList[i]['item_id'] === pageItemId) {
        favoriteFlag = true;
      }
    }
    if (favoriteFlag) {
      setFavoriteState(true);
    } else {
      setFavoriteState(false);
    }
  }, [pageItemId, favoriteList]);
  // }, [dispatch, itemInfo.id, itemInfo.name, pageItemId, favoriteList])

  useEffect(() => {
    if (!itemInfo) {
      return;
    }

    //パンくずリストをセット
    const Floors = [
      {
        name: itemInfo.name,
        href: '/item/edit' + itemInfo.id,
      },
    ];
    dispatch(insertFloor(Floors));

    //h1をセット
    dispatch(insertH1(itemInfo.name));
  }, [itemInfo]);

  // useEffect(() => {
  //   // 商品数量の手動入力を禁止する
  //   const itemQuantityInputElem = document.getElementById("item_quantity_input");
  //   const onItemQuantityInputKeypress = (e) => {
  //     alert("右側の▲▼で数量を調整してください。")
  //     e.preventDefault();
  //   }
  //   itemQuantityInputElem.addEventListener("keypress", onItemQuantityInputKeypress)

  //   return () => {
  //     itemQuantityInputElem.removeEventListener("keypress", onItemQuantityInputKeypress)
  //   }
  // }, [])

  const insertCartStyle = {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_cart_w.svg')`,
  };

  const insertFavoriteStyle = {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/favorite_star_icon.svg')`,
  };

  const meta = {
    title: SiteTitle,
  };

  //お気に入り追加ボタン押下時
  const insertFavoriteBtn = (itemId) => {
    dispatch(insertFavorite(itemId));
  };

  //お気に入り削除ボタン押下時
  const deleteFavoriteBtn = (itemId) => {
    dispatch(deleteFavorite(itemId));
  };

  //買い物カゴに入れるボタン押下時
  const insertCartBtn = (itemId) => {
    // console.log(quantity);
    if (Number.isInteger(Number(quantity)) && Number(quantity) > 0 && String(quantity).indexOf('.') === -1) {
      if (Number(quantity) > Number(itemInfo.stock_quantity)) {
        window.alert('数量は在庫数以下で入力してください。');
      } else {
        dispatch(insertCart(itemId, quantity));
      }
    } else {
      window.alert('数量を正しく入力してください。');
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div id="item_detail_page" className="flex_1">
        <main className="item_detail_content">
          {itemInfo && (
            <>
              <div
                className="main_image_area"
                style={
                  itemInfo.path !== null
                    ? {
                        backgroundImage: `url('${ItemImageDir}${itemInfo.path}')`,
                      }
                    : {
                        backgroundImage: `url('${process.env.PUBLIC_URL}/images/noimage.jpg')`,
                      }
                }
              ></div>
              <section className="item_info_area">
                <div className="left_content">
                  <p className="item_serial">商品番号：{itemInfo.item_serial}</p>
                  <p className="standard">規格：{itemInfo.standard}</p>
                  <p className="name">
                    {itemInfo.name}
                    {itemInfo.stock_quantity === '0' && <span className="sold_out">売り切れ中</span>}
                  </p>
                  {itemInfo.item_type_id === '1' && (
                    <>
                      <p className="price">
                        ￥{itemInfo && calcItemTaxIncludedPrice(itemInfo.price, itemInfo.tax_value).toLocaleString()}
                        （税込）
                      </p>

                      <p className="postage">
                        <span>
                          送料：￥
                          {itemInfo && Number(itemInfo.postage).toLocaleString()}
                          (税込)
                        </span>
                      </p>
                    </>
                  )}
                  <div className="item_description">
                    <p></p>
                    <p>
                      【商品説明】
                      <br />
                      {String(itemInfo.description)
                        .split(/\r\n|\r|\n/)
                        .map((value, index) => {
                          return (
                            <>
                              {value}
                              <br />
                            </>
                          );
                        })}
                    </p>
                  </div>
                </div>
                <div className="right_content">
                  <div className="wrap">
                    <table>
                      <thead></thead>
                      <tbody>
                        <tr>
                          <th>価格</th>
                          {(itemInfo.item_type_id === '2' || itemInfo.item_type_id === '3') && (
                            <td
                              style={
                                {
                                  /* color: 'blue' */
                                }
                              }
                            >
                              ※注文時に計算されます
                            </td>
                          )}
                          {itemInfo.item_type_id === '1' && (
                            <td className="price">
                              ￥
                              {itemInfo &&
                                calcItemTaxIncludedPrice(itemInfo.price, itemInfo.tax_value).toLocaleString()}
                              （税込）
                            </td>
                          )}
                        </tr>
                        <tr>
                          <th>送料</th>
                          <td>
                            ￥{itemInfo && Number(itemInfo.postage).toLocaleString()}
                            （税込）
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {Number(itemInfo.stock_quantity) > 0 && (
                      <>
                        {itemInfo.item_type_id === '1' && (
                          <>
                            <div className="number_select">
                              数量
                              <input
                                id="item_quantity_input"
                                type="number"
                                name="quantity"
                                min={1}
                                max={Number(itemInfo.stock_quantity)}
                                value={quantity}
                                onChange={inputQuantity}
                              />
                            </div>
                            在庫数：
                            {Number(itemInfo.stock_quantity).toLocaleString()}
                          </>
                        )}
                      </>
                    )}

                    <div className="btn_area">
                      {itemInfo.item_type_id === '2' && (
                        <button
                          className="create_design_btn"
                          onClick={() => {
                            dispatch(push(RouteDir + '/editor?view=invitation'));
                          }}
                        >
                          {/* 招待状を作成する */}
                          デザインを作成する
                        </button>
                      )}
                      {itemInfo.item_type_id === '3' && (
                        <button
                          className="create_design_btn"
                          onClick={() => {
                            dispatch(push(RouteDir + '/editor?view=seating'));
                          }}
                        >
                          {/* 席次表を作成する */}
                          デザインを作成する
                        </button>
                      )}

                      {/* 「買い物カゴに入れる」ボタン：通常商品のみ */}
                      {itemInfo.item_type_id === '1' &&
                        itemInfo.stock_quantity &&
                        (Number(itemInfo.stock_quantity) > 0 ? (
                          <button
                            className="insert_cart_btn"
                            style={insertCartStyle}
                            onClick={() => insertCartBtn(itemInfo.id)}
                          >
                            買い物カゴに入れる
                          </button>
                        ) : (
                          <button className="soldout_btn">売り切れ中</button>
                        ))}
                      {favoriteState === true ? (
                        <button
                          className="insert_favorite_btn"
                          style={insertFavoriteStyle}
                          onClick={() => deleteFavoriteBtn(itemInfo.id)}
                        >
                          お気に入りから削除
                        </button>
                      ) : (
                        <button
                          className="insert_favorite_btn"
                          style={insertFavoriteStyle}
                          onClick={() => insertFavoriteBtn(itemInfo.id)}
                        >
                          お気に入りに追加
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </section>
              <section className="image_area">
                {Array.isArray(itemImages) &&
                  itemImages.map((image, i) => (
                    <div
                      key={i}
                      className="item_image_area"
                      style={
                        image.path !== null
                          ? {
                              backgroundImage: `url('${ItemImageDir}${image.path}')`,
                            }
                          : {
                              backgroundImage: `url('${process.env.PUBLIC_URL}/images/noimage.jpg')`,
                            }
                      }
                    ></div>
                  ))}
              </section>
            </>
          )}
          {!itemInfo && <p>この商品は現在販売しておりません。</p>}
        </main>
      </div>
    </DocumentMeta>
  );
};

export default ItemList;
