import React from 'react';
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import { addIdToEachDataForDataGrid } from '../../MyDataGridLib';

// 買い物カゴ画面：サブアイテム用テーブル
const CartSubItemTable = (props) => {
  // console.log(props);

  // 招待状用DataGrid設定
  const subItemTableCols = [
    {
      field: 'subItemName',
      headerName: 'アイテム',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'unitPrice',
      headerName: '単価（税込）',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (param) => {
        const unitPrice = param.row.unitPrice;
        return unitPrice === 0 ? '無料' : `￥${unitPrice.toLocaleString()}`;
      },
    },
    {
      field: 'selectBtn',
      headerName: '数量',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (param) => {
        if (param.row.quantityType === 'static') {
          // setInvitationSubItemQuantities({
          //   ...invitationSubItemQuantities,
          //   [param.row.subItemType]: param.row.quantity,
          // });

          // 編集不可の数値
          return (
            <p id={`quantity_${param.row.subItemType}`} className={'sub_item_quantity'}>
              {param.row.quantity}
            </p>
          );
        } else if (param.row.quantityType === 'editable') {
          // 編集可能な数値
          return (
            <input
              type="number"
              id={`quantity_${param.row.subItemType}`}
              className={'sub_item_quantity no_spin'}
              value={props.subItemData.find((data) => data.subItemType === param.row.subItemType).quantity}
              // defaultValue={param.row.quantity}
              onChange={(event) => {
                // サブアイテム個数変更時の処理
                props.updateSubItemQuantity(props.cartType, event.target.value, param.row.subItemType);
              }}
            />
          );
        } else if (param.row.quantityType === 'selectBox') {
          // セレクトボックス
          return (
            <select
              id={`quantity_${param.row.subItemType}`}
              className={'sub_item_quantity'}
              value={param.row.quantity}
              onChange={(event) => {
                const inputValue = event.target.value; /* 選択値 */
                const subItemType = param.row.subItemType; /* サブアイテム種別 */

                // サブアイテム個数変更時の処理
                props.updateSubItemQuantity(props.cartType, inputValue, subItemType);
              }}
            >
              {param.row.quantitySelectOptions.map((value, index) => (
                <option value={value} key={`${param.row.subItemType}_select_option_${index}`}>
                  {value}
                </option>
              ))}
            </select>
          );
        }
      },
    },
    {
      field: 'totalSubItemPrice',
      headerName: '小計',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (param) => {
        const totalSubItemPrice = param.row.totalSubItemPrice;
        return totalSubItemPrice === 0 ? '-' : `￥${totalSubItemPrice.toLocaleString()}`;
      },
    },
  ];

  return (
    <div className="cart_sub_item_table_wrapper">
      <DataGrid
        autoHeight // グリッドの行数時応じて変化
        // style={{ maxHeight: tableHeight, height: tableHeight }} // 高さ指定
        pageSize={10}
        // autoPageSize // pageSizeは最大行事応じて計算される
        // checkboxSelection // チェックボックス
        // checkboxSelectionVisibleOnly={(e)=>(console.log(e))} //チェックボックス選択時
        // columnBuffer={10}
        // rowBuffer={10}
        density="standard" // 行の高さ compact standard comfortable
        // disableExtendRowFullWidth // コンテナいっぱいに表示させない
        disableColumnFilter // フィルター非表示
        // editMode="cell"
        // logger={console}
        // onCellClick={(e) => console.log("click", e.row)}
        // rowSpacingType="border"
        // disableMultipleColumnsFiltering // 複数フィルタリング
        disableMultipleColumnsSorting={true} // 複数ソート
        disableSelectionOnClick // データの選択を無効にする
        showCellRightBorder
        showColumnRightBorder
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        // components={
        //   // props.data.Toolbar && {
        //     Toolbar: GridToolbar
        //   // }
        // }
        // components={{
        //   Toolbar: GridToolbar, // ツールバーを指定する
        // }}
        columns={subItemTableCols}
        rows={addIdToEachDataForDataGrid(props.subItemData)}
      />
      <p style={{ margin: '0', textAlign: 'center', fontSize: '0.9em', color: 'gray' }}>
        見切れている場合は横スクロールしてご覧ください。
      </p>
    </div>
  );
};
export default CartSubItemTable;
