import React from 'react';
import { useSelector } from 'react-redux';

const Loading = () => {
  const loading = useSelector((state) => state.popups.loading);

  return (
    <div id="loading_area" className={loading === true ? '' : 'hidden'}>
      <div className="loader">Loading...</div>
    </div>
  );
};

export default Loading;
