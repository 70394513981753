import React, { useCallback, useState, useEffect, useRef, createElement } from 'react';
import DocumentMeta from 'react-document-meta';
import { useDispatch, useSelector } from 'react-redux';
import { SiteTitle } from './common';
import { RouteDir, ApiDir, JsDir, FontsDir, PROGRAM_ERROR_ALERT_MSG, SAVED_SVG_DIR } from '../../common';
import importScript from '../../components/hooks/ImportScript';
import axios from 'axios';
import {
  changeText,
  changeTextVertical,
  changeTextArea,
  changeTextAreaVertical,
  changeDate,
  moveDisable,
  changeDisableDisplay,
  SVGWrapElementMatrixChange,
  resizeElements,
  loadFont,
  getTextFontSize,
  getAlign,
  loadAllFontData,
  getFontName,
  getFontSize,
  getLineSpace,
  formatDate,
  getDateFormat,
  getFontData,
  validateLineNum,
  discardTextToMaxLineNum,
  validateTextLineNum,
  adjustPostcardSeparatePosition,
  getSvgWrapWidth,
  getSvgCanvasSize,
  getSvgWrapSize,
  changeSvgWrapElemXPosition,
  changeSvgWrapElemYPosition,
  adjustFusenPositions,
  changeFusenVisibility,
  isAllFusenSvgLoaded,
  changeSvgElemVisibilitiesByPurpose,
} from './EditorFunction';
import EditorGreetings from '../../components/public/EditorGreetings';
import EditorDatePlace from '../../components/public/EditorDatePlace';
import EditorBasicInfomation from '../../components/public/EditorBasicInfomation';
import EditorEnvelope from '../../components/public/EditorEnvelope';
import EditorPostcardFrontside from '../../components/public/EditorPostcardFrontside';
import EditorPostcardBackside from '../../components/public/EditorPostcardBackside';
import EditorTag from '../../components/public/EditorTag';
import EditorProfilePhoto from '../../components/public/EditorProfilePhoto';
import EditorProfileInterview from '../../components/public/EditorProfileInterview';
import EditorSeatingGreeting from '../../components/public/EditorSeatingGreeting';
import EditorMenu from '../../components/public/EditorMenu';
import EditorSeatingLayout from '../../components/public/EditorSeatingLayout';
import EditorSeatingSeat from '../../components/public/EditorSeatingSeat';
import queryString from 'query-string';
import { saveNewSvg, saveSvg, EditerFuncToUseForlogin } from '../../reducks/members/operations';
import { loadingChangeAction, loginPopupChangeAction } from '../../reducks/popups/actions';
// import PopupLogin from '../../components/public/PopupLogin';
import moveElementFunc from '../../replaceElement';
import Loading from '../../components/public/Loading';
// import { loadFontDataForSeat } from "../../../../js/EditorSeatFunction"

import '../../pdf-export.css';
import {
  toggleTableDeleteMode,
  toggleTableMoveMode,
  toggleTableNameEditMode,
} from '../../components/public/EditorAddTableFunction';
import fi from 'date-fns/esm/locale/fi/index.js';
import { push } from 'connected-react-router';
import EditorSeatingAdditionalComment from '../../components/public/EditorSeatingAdditionalComment';
import { element } from 'prop-types';

const Editor = (props) => {
  const [isFoldLineVisible, setIsFoldLineVisible] = useState(true);

  // テスト用関数
  const myTest = () => {};

  const dispatch = useDispatch();
  const popups = useSelector((state) => state.popups);

  const [isLogin, setIsLogin] = useState(false);

  //ログイン状態などの会員情報
  const memberInfo = useSelector((state) => state.members);

  // ログインチェック
  const checkLogin = () => {
    console.log(memberInfo.member_id);
  };

  //svg操作のjsの読み込み
  importScript(JsDir + '/dist/opentype.js');
  // importScript(JsDir + '/loadFonts.js');
  importScript(JsDir + '/svg_operation.js');
  importScript(JsDir + '/EditorSeatFunction.js');

  //タブ切り替えのState
  const [showEditContent, setShowEditContent] = useState('basic_infomation');
  //Datepicker用
  const initialDate = new Date();

  const [startDate, setStartDate] = useState(initialDate);
  const [datePickerName, setDatePickerName] = useState('');

  const handleChange = (dateValue) => {
    const date = new Date(dateValue);
    setStartDate(date);
    // changeDate(date, datePickerName)
    drawInputValueOnSvg('date', date, datePickerName);
  };

  //クエリパラメータのState
  const [viewValue, setViewValue] = useState(''); //クエリパラメータ：view
  const [isTemplate, setIsTemplate] = useState(true); /* テンプレートかどうか */
  const [designType, setDesignType] = useState(''); /* 招待状 or 席次表 */
  const [designSerial, setDesignSerial] = useState(''); /* 保存デザインシリアル */

  //画像操作用clip-pathのState
  const [clipPath, setClipPath] = useState('');

  // フォントデータ
  const { MY_FONTS } = window;
  const [fontData, setFontData] = useState(MY_FONTS);

  //使用してない？消していいかも。

  //クエリパラメータのState
  // const [templateValue, setTemplateValue] = useState('')  //テンプレート名
  // const [savedataValue, setSavedataValue] = useState('')  //保存SVG名

  // const [envelopeTemplateValue, setEnvelopeTemplateValue] = useState('')  //封筒テンプレート名
  // const [envelopesavedataValue, setEnvelopeSavedataValue] = useState('')  //封筒保存SVG名

  //ここまで

  const [deliverydate, setDeliverydate] = useState(''); //出荷日時

  //入力内容フォームのState
  const [groomFamilyName, setGroomFamilyName] = useState(''); //新郎姓
  const [groomFirstName, setGroomFirstName] = useState(''); //新郎名
  const [groomFamilyNameEn, setGroomFamilyNameEn] = useState(''); //新郎姓(ローマ字)
  const [groomFirstNameEn, setGroomFirstNameEn] = useState(''); //新郎名(ローマ字)
  const [brideFamilyName, setBrideFamilyName] = useState(''); //新婦姓
  const [brideFirstName, setBrideFirstName] = useState(''); //新婦名
  const [brideFamilyNameEn, setBrideFamilyNameEn] = useState(''); //新婦姓(ローマ字)
  const [brideFirstNameEn, setBrideFirstNameEn] = useState(''); //新婦名(ローマ字)
  const [groomParentFamilyName, setGroomParentFamilyName] = useState(''); //新郎親姓
  const [groomParentFirstName, setGroomParentFirstName] = useState(''); //新郎親名
  const [brideParentFamilyName, setBrideParentFamilyName] = useState(''); //新郎親姓
  const [brideParentFirstName, setBrideParentFirstName] = useState(''); //新郎親名

  const [postdate, setPostdate] = useState(''); //投函月date
  const [postdateValue, setPostdateValue] = useState(''); //投函月表示テキスト
  const [greetings, setGreetings] = useState(''); //本状文章

  const [time1Name, setTime1Name] = useState(''); //日時名1
  const [time1Time, setTime1Time] = useState(''); //日時1
  const [time1Disabled, setTime1Disabled] = useState(false); //日時1の非掲載
  const [time1DisabledValue, setTime1DisabledValue] = useState(0); //日時1の非掲載Value
  const [time2Name, setTime2Name] = useState(''); //日時名2
  const [time2Time, setTime2Time] = useState(''); //日時2
  const [time2Disabled, setTime2Disabled] = useState(false); //日時2の非掲載
  const [time2DisabledValue, setTime2DisabledValue] = useState(0); //日時2の非掲載Value
  const [time3Name, setTime3Name] = useState(''); //日時名3
  const [time3Time, setTime3Time] = useState(''); //日時3
  const [time3Disabled, setTime3Disabled] = useState(false); //日時3の非掲載
  const [time3DisabledValue, setTime3DisabledValue] = useState(0); //日時3の非掲載Value

  const [venueName, setVenueName] = useState(''); //会場名
  const [venueInfo, setVenueInfo] = useState(''); //会場住所・TEL
  const [membershipFee, setMembershipFee] = useState(''); //会費
  const [membershipFeeDisabled, setMembershipFeeDisabled] = useState(false); //会費の非掲載
  const [membershipFeeDisabledValue, setMembershipFeeDisabledValue] = useState(0); //会費の非掲載value

  const [message1, setMessage1] = useState(''); //追記文1
  const [message1Disabled, setMessage1Disabled] = useState(false); //追記文1の非掲載
  const [message1DisabledValue, setMessage1DisabledValue] = useState(0); //追記文1の非掲載value
  const [message2, setMessage2] = useState(''); //追記文2
  const [message2Disabled, setMessage2Disabled] = useState(false); //追記文2の非掲載
  const [message2DisabledValue, setMessage2DisabledValue] = useState(0); //追記文2の非掲載value
  const [message3, setMessage3] = useState(''); //追記文3
  const [message3Disabled, setMessage3Disabled] = useState(false); //追記文3の非掲載
  const [message3DisabledValue, setMessage3DisabledValue] = useState(0); //追記文3の非掲載value

  //使用してない？消していいかも
  // const [invViewBox, setInvViewBox] = useState('') //招待状のViewBox値
  // const [envelopeViewBox, setEnvelopeViewBox] = useState('') //封筒のViewBox値
  // const [postcardFrontsideCombineViewBox, setPostcardFrontsideCombineViewBox] = useState('') //はがき表同一住所のViewBox値
  // const [postcardFrontsideSeparateViewBox, setPostcardFrontsideSeparateViewBox] = useState('') //はがき表別住所のViewBox値

  //ここまで

  const [envelopeDeliveryAddressType, setEnvelopeDeliveryAddressType] = useState('1'); //差出人
  const [envelopeDescriptionMethodType, setEnvelopeDescriptionMethodType] = useState('1'); //記載方法
  const [envelopePostalCode, setEnvelopePostalCode] = useState(''); //郵便番号
  const [envelopeAddress, setEnvelopeAddress] = useState(''); //住所
  const [envelopeGroomPostalCode, setEnvelopeGroomPostalCode] = useState(''); //新郎郵便番号
  const [envelopeGroomAddress, setEnvelopeGroomAddress] = useState(''); //新郎住所
  const [envelopeBridePostalCode, setEnvelopeBridePostalCode] = useState(''); //新婦郵便番号
  const [envelopeBrideAddress, setEnvelopeBrideAddress] = useState(''); //新婦住所
  const [envelopeGroomParentPostalCode, setEnvelopeGroomParentPostalCode] = useState(''); //新郎親郵便番号
  const [envelopeGroomParentAddress, setEnvelopeGroomParentAddress] = useState(''); //新郎親住所
  const [envelopeBrideParentPostalCode, setEnvelopeBrideParentPostalCode] = useState(''); //新婦親郵便番号
  const [envelopeBrideParentAddress, setEnvelopeBrideParentAddress] = useState(''); //新婦親住所
  const [envelopeShowMaidenNameType, setEnvelopeShowMaidenNameType] = useState('1'); //旧姓表示

  //はがき情報
  const [postcardDeliveryAddressType, setPostcardDeliveryAddressType] = useState('1'); //差出人
  const [postcardDescriptionMethodType, setPostcardDescriptionMethodType] = useState('1'); //記載方法
  const [postcardPostalCode, setPostcardPostalCode] = useState(''); //郵便番号
  const [postcardAddress, setPostcardAddress] = useState(''); //住所
  const [postcardShowMaidenNameType, setPostcardShowMaidenNameType] = useState('1'); //旧姓表示
  const [postcardGroomPostalCode, setPostcardGroomPostalCode] = useState(''); //新郎郵便番号
  const [postcardGroomAddress, setPostcardGroomAddress] = useState(''); //新郎住所
  const [postcardBridePostalCode, setPostcardBridePostalCode] = useState(''); //新婦郵便番号
  const [postcardBrideAddress, setPostcardBrideAddress] = useState(''); //新婦住所
  const [postcardGroomParentPostalCode, setPostcardGroomParentPostalCode] = useState(''); //新郎親郵便番号
  const [postcardGroomParentAddress, setPostcardGroomParentAddress] = useState(''); //新郎親住所
  const [postcardBrideParentPostalCode, setPostcardBrideParentPostalCode] = useState(''); //新婦親郵便番号
  const [postcardBrideParentAddress, setPostcardBrideParentAddress] = useState(''); //新婦親住所

  //はがき裏面情報
  const [englishNotation, setEnglishNotation] = useState('1'); //英語表記
  const [NOANotation, setNOANotation] = useState('1'); //出席者人数表記
  const [messageNotation, setMessageNotation] = useState('1'); //メッセージ表記
  const [allergyNotation, setAllergyNotation] = useState('1'); //アレルギー表記
  const [courtesyBusNotation, setCourtesyBusNotation] = useState('1'); //送迎バス表記

  //付箋情報
  const [ceremonyTag, setCeremonyTag] = useState('1'); //挙式参列表記
  const [ceremonyTagNumber, setCeremonyTagNumber] = useState(0); //挙式参列必要枚数
  const [ceremonyTagHour, setCeremonyTagHour] = useState(''); //挙式参列時
  const [ceremonyTagMinutes, setCeremonyTagMinutes] = useState(''); //挙式参列分
  const [courtesyBusTag, setCourtesyBusTag] = useState('1'); //送迎バス表記
  const [courtesyBusTagNumber, setCourtesyBusTagNumber] = useState(0); //送迎バス必要枚数
  const [courtesyBusTagHour, setCourtesyBusTagHour] = useState(''); //送迎バス時
  const [courtesyBusTagMinutes, setCourtesyBusTagMinutes] = useState(''); //送迎バス分
  const [courtesyBusTagPlace, setCourtesyBusTagPlace] = useState(''); //送迎バス場所
  const [entertainmentTag, setEntertainmentTag] = useState('1'); //余興表記
  const [entertainmentTagNumber, setEntertainmentTagNumber] = useState(0); //余興必要枚数
  const [groupPhotoTag, setGroupPhotoTag] = useState('1'); //集合写真表記
  const [groupPhotoTagNumber, setGroupPhotoTagNumber] = useState(0); //集合写真必要枚数
  const [groupPhotoTagHour, setGroupPhotoTagHour] = useState(''); //集合写真時
  const [groupPhotoTagMinutes, setGroupPhotoTagMinutes] = useState(''); //集合写真分
  const [toastTag, setToastTag] = useState('1'); //乾杯表記
  const [toastTagNumber, setToastTagNumber] = useState(0); //乾杯必要枚数
  const [receptionTag, setReceptionTag] = useState('1'); //受付表記
  const [receptionTagNumber, setReceptionTagNumber] = useState(0); //受付必要枚数
  const [receptionTagHour, setReceptionTagHour] = useState(''); //受付時
  const [receptionTagMinutes, setReceptionTagMinutes] = useState(''); //受付分
  const [speechTag, setSpeechTag] = useState('1'); //スピーチ表記
  const [speechTagNumber, setSpeechTagNumber] = useState(0); //スピーチ必要枚数
  const [congratulationsTag, setCongratulationsTag] = useState('1'); //祝辞表記
  const [congratulationsTagNumber, setCongratulationsTagNumber] = useState(0); //祝辞必要枚数
  const [banzaiTag, setBanzaiTag] = useState('1'); //万歳三唱表記
  const [banzaiTagNumber, setBanzaiTagNumber] = useState(0); //万歳三唱必要枚数

  //プロフィール&インタビュー
  const [prof1Q, setProf1Q] = useState(''); //プロフィール１質問
  const [prof1Agroom, setProf1Agroom] = useState(''); //プロフィール１新郎回答
  const [prof1Abride, setProf1Abride] = useState(''); //プロフィール１新婦回答
  const [prof2Q, setProf2Q] = useState(''); //プロフィール２質問
  const [prof2Agroom, setProf2Agroom] = useState(''); //プロフィール２新郎回答
  const [prof2Abride, setProf2Abride] = useState(''); //プロフィール２新婦回答
  const [prof3Q, setProf3Q] = useState(''); //プロフィール３質問
  const [prof3Agroom, setProf3Agroom] = useState(''); //プロフィール３新郎回答
  const [prof3Abride, setProf3Abride] = useState(''); //プロフィール３新婦回答
  const [prof4Q, setProf4Q] = useState(''); //プロフィール４質問
  const [prof4Agroom, setProf4Agroom] = useState(''); //プロフィール４新郎回答
  const [prof4Abride, setProf4Abride] = useState(''); //プロフィール４新婦回答

  const [interview1Q, setInterview1Q] = useState(''); //インタビュー１質問
  const [interview1Agroom, setInterview1Agroom] = useState(''); //インタビュー１新郎回答
  const [interview1Abride, setInterview1Abride] = useState(''); //インタビュー１新婦回答
  const [interview2Q, setInterview2Q] = useState(''); //インタビュー２質問
  const [interview2Agroom, setInterview2Agroom] = useState(''); //インタビュー２新郎回答
  const [interview2Abride, setInterview2Abride] = useState(''); //インタビュー２新婦回答
  const [interview3Q, setInterview3Q] = useState(''); //インタビュー３質問
  const [interview3Agroom, setInterview3Agroom] = useState(''); //インタビュー３新郎回答
  const [interview3Abride, setInterview3Abride] = useState(''); //インタビュー３新婦回答
  const [interview4Q, setInterview4Q] = useState(''); //インタビュー４質問
  const [interview4Agroom, setInterview4Agroom] = useState(''); //インタビュー４新郎回答
  const [interview4Abride, setInterview4Abride] = useState(''); //インタビュー４新婦回答

  const [seatingGreeting, setSeatingGreeting] = useState(''); //席次表ご挨拶

  const [menuName1, setMenuName1] = useState('○○○○○○○○'); //メニュー名1
  const [menuName2, setMenuName2] = useState('○○○○○○○○'); //メニュー名2
  const [menuName3, setMenuName3] = useState('○○○○○○○○'); //メニュー名3
  const [menuName4, setMenuName4] = useState('○○○○○○○○'); //メニュー名4
  const [menuName5, setMenuName5] = useState('○○○○○○○○'); //メニュー名5
  const [menuName6, setMenuName6] = useState('○○○○○○○○'); //メニュー名6
  const [menuName7, setMenuName7] = useState('○○○○○○○○'); //メニュー名7
  const [menuName8, setMenuName8] = useState('○○○○○○○○'); //メニュー名8
  const [menuName9, setMenuName9] = useState('○○○○○○○○'); //メニュー名9
  const [menuName10, setMenuName10] = useState('○○○○○○○○'); //メニュー名10
  const [profileAddressGuide, setProfileAddressGuide] = useState('〇〇県'); //住所案内
  const [seatingAdditionalComment, setSeatingAdditionalComment] = useState('席次表追記分'); //席次表追記分

  const [seatingLayout, setSeatingLayout] = useState('3-3-3'); //テーブルレイアウト
  const [seatingGuestList, setSeatingGuestList] = useState([]); //テーブルレイアウト

  // const [moveSeatFlag, setMoveSeatFlag] = useState(0); //席配置調整フラグ
  const [isSvgLoaded, setIsSvgLoaded] = useState(false); /* SVG読み込み完了フラグ */
  const [isTableMoveMode, setIsTableMoveMode] = useState(false); /* テーブル移動モードフラグ */
  const [isTableDeleteMode, setIsTableDeleteMode] = useState(false); /* テーブル削除モードフラグ */
  const [isTableNameEditMode, setIsTableNameEditMode] = useState(false); /* テーブル名編集モードフラグ */

  //入力後の次の文字入力までを待つ処理
  const inputTimer = useRef(null);
  const timerTime = 100; //入力までの時間
  // const timerTime = 700  //入力までの時間

  // 指定のフォントデータを返す
  // SVG内に埋め込むフォント指定はフォント名のみに変更予定
  // const getFontData = (fontData, fontName) => {
  //   // console.log(fontName)
  //   // console.log(fontData, fontName)
  //   switch (fontName) {
  //     case "./fonts/Bahagia.ttf":
  //       return fontData.find(font => font.name === "Bahagia").data
  //     // return fontData.find(font => font.name === fontName)
  //     default:
  //       return fontData.find(font => font.name === "HanaMin").data
  //   }
  // }

  // const getDrawingStyleForElem = (targetElem, drawType) => {
  //   // 描画スタイル指定を取得
  //   const fontName = getFontName(targetElem)
  //   const fontSize = getFontSize(targetElem, drawType)
  //   const align = getAlign(targetElem)

  //   // 該当フォントデータ準備
  //   const fontData = getFontData(fontData, fontName)

  //   // 描画関数用引数の一部
  //   let param = [font, fontSize, align]

  //   if (drawType === "textarea") {
  //     // 行間指定取得
  //     const lineSpace = getLineSpace(targetElem)
  //     param.push(lineSpace)
  //   }

  //   return param
  // }

  // SVG内の対象要素に入力内容を描画
  const drawInputValueOnSvg = (drawType, value, className) => {
    const targetElems = document.getElementsByClassName(className);

    for (let i = 0; i < targetElems.length; i++) {
      const targetElem = targetElems[i];

      // 描画スタイル指定を取得
      const fontName = getFontName(targetElem);
      const fontSize = getFontSize(targetElem, drawType);
      const align = getAlign(targetElem);
      let fontColor = '#000000';

      // 該当フォントデータ準備
      const font = getFontData(fontData, fontName);

      // SVG内の対象要素を書き換え
      switch (drawType) {
        case 'text':
          changeText(targetElem, value, font, fontSize, align, fontColor);
          break;

        case 'textarea':
          // 行間指定取得
          const lineSpace = getLineSpace(targetElem);
          changeTextArea(targetElem, value, font, fontSize, align, lineSpace);
          break;

        case 'date':
          const format = getDateFormat(targetElem);
          const dateValue = formatDate(value, format);
          // console.log(targetElem, dateValue, font, fontSize, align)
          changeText(targetElem, dateValue, font, fontSize, align);
          break;

        default:
          break;
      }
    }
  };

  // 未使用
  // const drowTypeObject = {
  //   text: drawInputValueOnSvg,
  //   textVertical: changeTextVertical,
  //   textarea: drawInputValueOnSvg,
  //   textareaVertical: changeTextAreaVertical,
  // };

  const setInputObject = {
    //新郎姓
    inputGroomFamilyName: useCallback(
      (event) => {
        setGroomFamilyName(event.target.value);
      },
      [setGroomFamilyName]
    ),
    //新郎名
    inputGroomFirstName: useCallback(
      (event) => {
        setGroomFirstName(event.target.value);
      },
      [setGroomFirstName]
    ),
    //新郎姓(ローマ字)

    inputGroomFamilyNameEn: useCallback(
      (event) => {
        setGroomFamilyNameEn(event.target.value);
      },
      [setGroomFamilyNameEn]
    ),
    //新郎名(ローマ字)
    inputGroomFirstNameEn: useCallback(
      (event) => {
        setGroomFirstNameEn(event.target.value);
      },
      [setGroomFirstNameEn]
    ),
    //新婦姓
    inputBrideFamilyName: useCallback(
      (event) => {
        setBrideFamilyName(event.target.value);
      },
      [setBrideFamilyName]
    ),
    //新婦名
    inputBrideFirstName: useCallback(
      (event) => {
        setBrideFirstName(event.target.value);
      },
      [setBrideFirstName]
    ),
    //新婦姓(ローマ字)
    inputBrideFamilyNameEn: useCallback(
      (event) => {
        setBrideFamilyNameEn(event.target.value);
      },
      [setBrideFamilyNameEn]
    ),
    //新婦名(ローマ字)
    inputBrideFirstNameEn: useCallback(
      (event) => {
        setBrideFirstNameEn(event.target.value);
      },
      [setBrideFirstNameEn]
    ),
    //新郎親姓
    inputGroomParentFamilyName: useCallback(
      (event) => {
        setGroomParentFamilyName(event.target.value);
      },
      [setGroomParentFamilyName]
    ),
    //新郎親名
    inputGroomParentFirstName: useCallback(
      (event) => {
        setGroomParentFirstName(event.target.value);
      },
      [setGroomParentFirstName]
    ),
    //新婦親姓
    inputBrideParentFamilyName: useCallback(
      (event) => {
        setBrideParentFamilyName(event.target.value);
      },
      [setBrideParentFamilyName]
    ),
    //新婦親名
    inputBrideParentFirstName: useCallback(
      (event) => {
        setBrideParentFirstName(event.target.value);
      },
      [setBrideParentFirstName]
    ),
    //本状文章
    inputGreetings: useCallback(
      (event) => {
        setGreetings(event.target.value);
      },
      [setGreetings]
    ),
    //日時名1
    inputTime1Name: useCallback(
      (event) => {
        setTime1Name(event.target.value);
      },
      [setTime1Name]
    ),
    //日時1
    inputTime1Time: useCallback(
      (event) => {
        setTime1Time(event.target.value);
      },
      [setTime1Time]
    ),
    //日時名2
    inputTime2Name: useCallback(
      (event) => {
        setTime2Name(event.target.value);
      },
      [setTime2Name]
    ),
    //日時2
    inputTime2Time: useCallback(
      (event) => {
        setTime2Time(event.target.value);
      },
      [setTime2Time]
    ),
    //日時名3
    inputTime3Name: useCallback(
      (event) => {
        setTime3Name(event.target.value);
      },
      [setTime3Name]
    ),
    //日時3
    inputTime3Time: useCallback(
      (event) => {
        setTime3Time(event.target.value);
      },
      [setTime3Time]
    ),
    //会場名
    inputVenueName: useCallback(
      (event) => {
        setVenueName(event.target.value);
      },
      [setVenueName]
    ),
    //会場住所・TEL
    inputVenueInfo: useCallback(
      (event) => {
        setVenueInfo(event.target.value);
      },
      [setVenueInfo]
    ),
    //会費
    inputMembershipFee: useCallback(
      (event) => {
        setMembershipFee(event.target.value);
      },
      [setMembershipFee]
    ),
    //追記文1
    inputMessage1: useCallback(
      (event) => {
        setMessage1(event.target.value);
      },
      [setMessage1]
    ),
    //追記文2
    inputMessage2: useCallback(
      (event) => {
        setMessage2(event.target.value);
      },
      [setMessage2]
    ),
    //追記文3
    inputMessage3: useCallback(
      (event) => {
        setMessage3(event.target.value);
      },
      [setMessage3]
    ),

    // 封筒　ここから
    //郵便番号
    inputEnvelopePostalCode: useCallback(
      (event) => {
        setEnvelopePostalCode(event.target.value);
      },
      [setEnvelopePostalCode]
    ),
    //住所
    inputEnvelopeAddress: useCallback(
      (event) => {
        setEnvelopeAddress(event.target.value);
      },
      [setEnvelopeAddress]
    ),
    //新郎郵便番号
    inputEnvelopeGroomPostalCode: useCallback(
      (event) => {
        setEnvelopeGroomPostalCode(event.target.value);
      },
      [setEnvelopeGroomPostalCode]
    ),
    //新郎住所
    inputEnvelopeGroomAddress: useCallback(
      (event) => {
        setEnvelopeGroomAddress(event.target.value);
      },
      [setEnvelopeGroomAddress]
    ),
    //新婦郵便番号
    inputEnvelopeBridePostalCode: useCallback(
      (event) => {
        setEnvelopeBridePostalCode(event.target.value);
      },
      [setEnvelopeBridePostalCode]
    ),
    //新婦住所
    inputEnvelopeBrideAddress: useCallback(
      (event) => {
        setEnvelopeBrideAddress(event.target.value);
      },
      [setEnvelopeBrideAddress]
    ),
    //新郎親郵便番号
    inputEnvelopeGroomParentPostalCode: useCallback(
      (event) => {
        setEnvelopeGroomParentPostalCode(event.target.value);
      },
      [setEnvelopeGroomParentPostalCode]
    ),
    //新郎親住所
    inputEnvelopeGroomParentAddress: useCallback(
      (event) => {
        setEnvelopeGroomParentAddress(event.target.value);
      },
      [setEnvelopeGroomParentAddress]
    ),
    //新婦親郵便番号
    inputEnvelopeBrideParentPostalCode: useCallback(
      (event) => {
        setEnvelopeBrideParentPostalCode(event.target.value);
      },
      [setEnvelopeBrideParentPostalCode]
    ),
    //新婦親住所
    inputEnvelopeBrideParentAddress: useCallback(
      (event) => {
        setEnvelopeBrideParentAddress(event.target.value);
      },
      [setEnvelopeBrideParentAddress]
    ),

    // 封筒　ここまで

    // 返信はがき（表面）　ここから

    //はがき郵便番号
    inputPostcardPostalCode: useCallback(
      (event) => {
        setPostcardPostalCode(event.target.value);
      },
      [setPostcardPostalCode]
    ),
    //はがき住所
    inputPostcardAddress: useCallback(
      (event) => {
        setPostcardAddress(event.target.value);
      },
      [setPostcardAddress]
    ),
    //新郎郵便番号
    inputPostcardGroomPostalCode: useCallback(
      (event) => {
        setPostcardGroomPostalCode(event.target.value);
      },
      [setPostcardGroomPostalCode]
    ),
    //新郎住所
    inputPostcardGroomAddress: useCallback(
      (event) => {
        setPostcardGroomAddress(event.target.value);
      },
      [setPostcardGroomAddress]
    ),
    //新婦郵便番号
    inputPostcardBridePostalCode: useCallback(
      (event) => {
        setPostcardBridePostalCode(event.target.value);
      },
      [setPostcardBridePostalCode]
    ),
    //新婦住所
    inputPostcardBrideAddress: useCallback(
      (event) => {
        setPostcardBrideAddress(event.target.value);
      },
      [setPostcardBrideAddress]
    ),
    //新郎親郵便番号
    inputPostcardGroomParentPostalCode: useCallback(
      (event) => {
        setPostcardGroomParentPostalCode(event.target.value);
      },
      [setPostcardGroomParentPostalCode]
    ),
    //新郎親住所
    inputPostcardGroomParentAddress: useCallback(
      (event) => {
        setPostcardGroomParentAddress(event.target.value);
      },
      [setPostcardGroomParentAddress]
    ),
    //新婦親郵便番号
    inputPostcardBrideParentPostalCode: useCallback(
      (event) => {
        setPostcardBrideParentPostalCode(event.target.value);
      },
      [setPostcardBrideParentPostalCode]
    ),
    //新婦親住所
    inputPostcardBrideParentAddress: useCallback(
      (event) => {
        setPostcardBrideParentAddress(event.target.value);
      },
      [setPostcardBrideParentAddress]
    ),

    // 返信はがき（表面）　ここまで

    // 付箋　ここから

    //付箋：挙式参列時
    inputCeremonyTagHour: useCallback(
      (event) => {
        setCeremonyTagHour(event.target.value);
      },
      [setCeremonyTagHour]
    ),
    //付箋：挙式参列分
    inputCeremonyTagMinutes: useCallback(
      (event) => {
        setCeremonyTagMinutes(event.target.value);
      },
      [setCeremonyTagMinutes]
    ),
    //付箋：送迎バス時
    inputCourtesyBusTagHour: useCallback(
      (event) => {
        setCourtesyBusTagHour(event.target.value);
      },
      [setCourtesyBusTagHour]
    ),
    //付箋：送迎バス分
    inputCourtesyBusTagMinutes: useCallback(
      (event) => {
        setCourtesyBusTagMinutes(event.target.value);
      },
      [setCourtesyBusTagMinutes]
    ),
    //付箋：送迎バス場所
    inputCourtesyBusTagPlace: useCallback(
      (event) => {
        setCourtesyBusTagPlace(event.target.value);
      },
      [setCourtesyBusTagPlace]
    ),
    //付箋：集合写真時
    inputGroupPhotoTagHour: useCallback(
      (event) => {
        setGroupPhotoTagHour(event.target.value);
      },
      [setGroupPhotoTagHour]
    ),
    //付箋：集合写真分
    inputGroupPhotoTagMinutes: useCallback(
      (event) => {
        setGroupPhotoTagMinutes(event.target.value);
      },
      [setGroupPhotoTagMinutes]
    ),
    //付箋：受付時
    inputReceptionTagHour: useCallback(
      (event) => {
        setReceptionTagHour(event.target.value);
      },
      [setReceptionTagHour]
    ),
    //付箋：受付分
    inputReceptionTagMinutes: useCallback(
      (event) => {
        setReceptionTagMinutes(event.target.value);
      },
      [setReceptionTagMinutes]
    ),

    // 付箋　ここまで

    //プロフィール&インタビュー
    //プロフィール1質問
    inputProf1Q: useCallback(
      (event) => {
        setProf1Q(event.target.value);
      },
      [setProf1Q]
    ),
    //プロフィール1新郎回答
    inputProf1Agroom: useCallback(
      (event) => {
        setProf1Agroom(event.target.value);
      },
      [setProf1Agroom]
    ),
    //プロフィール1新婦回答
    inputProf1Abride: useCallback(
      (event) => {
        setProf1Abride(event.target.value);
      },
      [setProf1Abride]
    ),
    //プロフィール2質問
    inputProf2Q: useCallback(
      (event) => {
        setProf2Q(event.target.value);
      },
      [setProf2Q]
    ),
    //プロフィール2新郎回答
    inputProf2Agroom: useCallback(
      (event) => {
        setProf2Agroom(event.target.value);
      },
      [setProf2Agroom]
    ),
    //プロフィール2新婦回答
    inputProf2Abride: useCallback(
      (event) => {
        setProf2Abride(event.target.value);
      },
      [setProf2Abride]
    ),
    //プロフィール3質問
    inputProf3Q: useCallback(
      (event) => {
        setProf3Q(event.target.value);
      },
      [setProf3Q]
    ),
    //プロフィール3新郎回答
    inputProf3Agroom: useCallback(
      (event) => {
        setProf3Agroom(event.target.value);
      },
      [setProf3Agroom]
    ),
    //プロフィール3新婦回答
    inputProf3Abride: useCallback(
      (event) => {
        setProf3Abride(event.target.value);
      },
      [setProf3Abride]
    ),
    //プロフィール4質問
    inputProf4Q: useCallback(
      (event) => {
        setProf4Q(event.target.value);
      },
      [setProf4Q]
    ),
    //プロフィール4新郎回答
    inputProf4Agroom: useCallback(
      (event) => {
        setProf4Agroom(event.target.value);
      },
      [setProf4Agroom]
    ),
    //プロフィール4新婦回答
    inputProf4Abride: useCallback(
      (event) => {
        setProf4Abride(event.target.value);
      },
      [setProf4Abride]
    ),

    //インタビュー1質問
    inputInterview1Q: useCallback(
      (event) => {
        setInterview1Q(event.target.value);
      },
      [setInterview1Q]
    ),
    //インタビュー1新郎回答
    inputInterview1Agroom: useCallback(
      (event) => {
        setInterview1Agroom(event.target.value);
      },
      [setInterview1Agroom]
    ),
    //インタビュー1新婦回答
    inputInterview1Abride: useCallback(
      (event) => {
        setInterview1Abride(event.target.value);
      },
      [setInterview1Abride]
    ),
    //インタビュー2質問
    inputInterview2Q: useCallback(
      (event) => {
        setInterview2Q(event.target.value);
      },
      [setInterview2Q]
    ),
    //インタビュー2新郎回答
    inputInterview2Agroom: useCallback(
      (event) => {
        setInterview2Agroom(event.target.value);
      },
      [setInterview2Agroom]
    ),
    //インタビュー2新婦回答
    inputInterview2Abride: useCallback(
      (event) => {
        setInterview2Abride(event.target.value);
      },
      [setInterview2Abride]
    ),
    //インタビュー3質問
    inputInterview3Q: useCallback(
      (event) => {
        setInterview3Q(event.target.value);
      },
      [setInterview3Q]
    ),
    //インタビュー3新郎回答
    inputInterview3Agroom: useCallback(
      (event) => {
        setInterview3Agroom(event.target.value);
      },
      [setInterview3Agroom]
    ),
    //インタビュー3新婦回答
    inputInterview3Abride: useCallback(
      (event) => {
        setInterview3Abride(event.target.value);
      },
      [setInterview3Abride]
    ),
    //インタビュー4質問
    inputInterview4Q: useCallback(
      (event) => {
        setInterview4Q(event.target.value);
      },
      [setInterview4Q]
    ),
    //インタビュー4新郎回答
    inputInterview4Agroom: useCallback(
      (event) => {
        setInterview4Agroom(event.target.value);
      },
      [setInterview4Agroom]
    ),
    //インタビュー4新婦回答
    inputInterview4Abride: useCallback(
      (event) => {
        setInterview4Abride(event.target.value);
      },
      [setInterview4Abride]
    ),

    //席次表ご挨拶
    inputSeatingGreeting: useCallback(
      (event) => {
        setSeatingGreeting(event.target.value);
      },
      [setSeatingGreeting]
    ),

    //メニュー名1
    inputMenuName1: useCallback(
      (event) => {
        setMenuName1(event.target.value);
      },
      [setMenuName1]
    ),
    //メニュー名2
    inputMenuName2: useCallback(
      (event) => {
        setMenuName2(event.target.value);
      },
      [setMenuName2]
    ),
    //メニュー名3
    inputMenuName3: useCallback(
      (event) => {
        setMenuName3(event.target.value);
      },
      [setMenuName3]
    ),
    //メニュー名4
    inputMenuName4: useCallback(
      (event) => {
        setMenuName4(event.target.value);
      },
      [setMenuName4]
    ),
    //メニュー名5
    inputMenuName5: useCallback(
      (event) => {
        setMenuName5(event.target.value);
      },
      [setMenuName5]
    ),
    //メニュー名6
    inputMenuName6: useCallback(
      (event) => {
        setMenuName6(event.target.value);
      },
      [setMenuName6]
    ),
    //メニュー名7
    inputMenuName7: useCallback(
      (event) => {
        setMenuName7(event.target.value);
      },
      [setMenuName7]
    ),
    //メニュー名8
    inputMenuName8: useCallback(
      (event) => {
        setMenuName8(event.target.value);
      },
      [setMenuName8]
    ),
    //メニュー名9
    inputMenuName9: useCallback(
      (event) => {
        setMenuName9(event.target.value);
      },
      [setMenuName9]
    ),
    //メニュー名10
    inputMenuName10: useCallback(
      (event) => {
        setMenuName10(event.target.value);
      },
      [setMenuName10]
    ),

    // 住所案内欄
    inputProfileAddressGuide: useCallback(
      (event) => {
        setProfileAddressGuide(event.target.value);
      },
      [setProfileAddressGuide]
    ),

    //席次表追記文
    inputSeatingAdditionalComment: useCallback(
      (event) => {
        setSeatingAdditionalComment(event.target.value);
      },
      [setSeatingAdditionalComment]
    ),
  };

  //差出人
  const inputEnvelopeDeliveryAddressType = useCallback(
    (event) => {
      setEnvelopeDeliveryAddressType(event.target.value);
    },
    [setEnvelopeDeliveryAddressType]
  );

  //記載方法
  const inputEnvelopeDescriptionMethodType = useCallback(
    (event) => {
      setEnvelopeDescriptionMethodType(event.target.value);
    },
    [setEnvelopeDescriptionMethodType]
  );

  //旧姓表示
  const inputEnvelopeShowMaidenNameType = useCallback(
    (event) => {
      setEnvelopeShowMaidenNameType(event.target.value);
    },
    [setEnvelopeShowMaidenNameType]
  );

  //はがき差出人
  const inputPostcardDeliveryAddressType = useCallback(
    (event) => {
      setPostcardDeliveryAddressType(event.target.value);
    },
    [setPostcardDeliveryAddressType]
  );

  //はがき記載方法
  const inputPostcardDescriptionMethodType = useCallback(
    (event) => {
      setPostcardDescriptionMethodType(event.target.value);
    },
    [setPostcardDescriptionMethodType]
  );

  //はがき旧姓表示
  const inputPostcardShowMaidenNameType = useCallback(
    (event) => {
      setPostcardShowMaidenNameType(event.target.value);
    },
    [setPostcardShowMaidenNameType]
  );

  //はがき英語表記表示
  const inputEnglishNotation = useCallback(
    (event) => {
      setEnglishNotation(event.target.value);
    },
    [setEnglishNotation]
  );

  //はがき出席者人数表記表示
  const inputNOANotation = useCallback(
    (event) => {
      setNOANotation(event.target.value);
    },
    [setNOANotation]
  );

  //はがきメッセージ表記表示
  const inputMessageNotation = useCallback(
    (event) => {
      setMessageNotation(event.target.value);
    },
    [setMessageNotation]
  );

  //はがきアレルギー表記表示
  const inputAllergyNotation = useCallback(
    (event) => {
      setAllergyNotation(event.target.value);
    },
    [setAllergyNotation]
  );

  //はがき送迎バス表記表示
  const inputCourtesyBusNotation = useCallback(
    (event) => {
      setCourtesyBusNotation(event.target.value);
    },
    [setCourtesyBusNotation]
  );

  //付箋：挙式参列の表示
  const inputCeremonyTag = useCallback(
    (event) => {
      setCeremonyTag(event.target.value);
    },
    [setCeremonyTag]
  );

  //付箋：挙式参列必要枚数
  const inputCeremonyTagNumber = useCallback(
    (event) => {
      setCeremonyTagNumber(event.target.value);
    },
    [setCeremonyTagNumber]
  );

  //付箋：挙式参列の表示
  const inputCourtesyBusTag = useCallback(
    (event) => {
      setCourtesyBusTag(event.target.value);
    },
    [setCourtesyBusTag]
  );

  //付箋：挙式参列必要枚数
  const inputCourtesyBusTagNumber = useCallback(
    (event) => {
      setCourtesyBusTagNumber(event.target.value);
    },
    [setCourtesyBusTagNumber]
  );

  //付箋：挙式参列の表示
  const inputEntertainmentTag = useCallback(
    (event) => {
      setEntertainmentTag(event.target.value);
    },
    [setEntertainmentTag]
  );

  //付箋：挙式参列必要枚数
  const inputEntertainmentTagNumber = useCallback(
    (event) => {
      setEntertainmentTagNumber(event.target.value);
    },
    [setEntertainmentTagNumber]
  );

  //付箋：挙式参列の表示
  const inputGroupPhotoTag = useCallback(
    (event) => {
      setGroupPhotoTag(event.target.value);
    },
    [setGroupPhotoTag]
  );

  //付箋：挙式参列必要枚数
  const inputGroupPhotoTagNumber = useCallback(
    (event) => {
      setGroupPhotoTagNumber(event.target.value);
    },
    [setGroupPhotoTagNumber]
  );

  //付箋：挙式参列の表示
  const inputToastTag = useCallback(
    (event) => {
      setToastTag(event.target.value);
    },
    [setToastTag]
  );

  //付箋：挙式参列必要枚数
  const inputToastTagNumber = useCallback(
    (event) => {
      setToastTagNumber(event.target.value);
    },
    [setToastTagNumber]
  );

  //付箋：挙式参列の表示
  const inputReceptionTag = useCallback(
    (event) => {
      setReceptionTag(event.target.value);
    },
    [setReceptionTag]
  );

  //付箋：挙式参列必要枚数
  const inputReceptionTagNumber = useCallback(
    (event) => {
      setReceptionTagNumber(event.target.value);
    },
    [setReceptionTagNumber]
  );

  //付箋：挙式参列の表示
  const inputSpeechTag = useCallback(
    (event) => {
      setSpeechTag(event.target.value);
    },
    [setSpeechTag]
  );

  //付箋：挙式参列必要枚数
  const inputSpeechTagNumber = useCallback(
    (event) => {
      setSpeechTagNumber(event.target.value);
    },
    [setSpeechTagNumber]
  );

  //付箋：挙式参列の表示
  const inputCongratulationsTag = useCallback(
    (event) => {
      setCongratulationsTag(event.target.value);
    },
    [setCongratulationsTag]
  );

  //付箋：挙式参列必要枚数
  const inputCongratulationsTagNumber = useCallback(
    (event) => {
      setCongratulationsTagNumber(event.target.value);
    },
    [setCongratulationsTagNumber]
  );

  //付箋：挙式参列の表示
  const inputBanzaiTag = useCallback(
    (event) => {
      setBanzaiTag(event.target.value);
    },
    [setBanzaiTag]
  );

  //付箋：挙式参列必要枚数
  const inputBanzaiTagNumber = useCallback(
    (event) => {
      setBanzaiTagNumber(event.target.value);
    },
    [setBanzaiTagNumber]
  );

  //テーブルレイアウト
  const inputSeatingLayout = useCallback(
    (event) => {
      setSeatingLayout(event.target.value);
    },
    [setSeatingLayout]
  );

  //入力後のチェック
  const inputCheck = (event, funcname, drowTypename) => {
    // console.log(funcname, drowTypename);
    // console.log(event.target.name, event.target.value);
    setInputObject[funcname](event);
    // clearTimeout(inputTimer.current);
    // inputTimer.current = setTimeout(() => {
    // console.log(drowTypename, event.target.value, event.target.name);
    drawInputValueOnSvg(drowTypename, event.target.value, event.target.name);
    // drowTypeObject[drowTypename](event.target.value, event.target.name)
    // }, timerTime);
  };

  //順番を入れ替えれるコンテンツにテキストを描画するときの処理
  const moveContentInputCheck = (event, funcname, drowTypename, targetGroupName, svgName) => {
    setInputObject[funcname](event); //入力値の反映

    //入力されたコンテンツが上から何番目かを調べる
    //該当要素グループを取得
    const targetGroup = document.querySelector(`#${targetGroupName}`);
    const targetLi = targetGroup.querySelectorAll('li');
    const liArray = [].slice.call(targetLi);

    //入力された要素の親li要素を取得
    const targetEle = event.target;
    //何番目か取得
    const eleIndexNum = liArray.indexOf(targetEle.closest('li')) + 1;

    //描画
    clearTimeout(inputTimer.current);
    inputTimer.current = setTimeout(() => {
      drawInputValueOnSvg(drowTypename, event.target.value, svgName + eleIndexNum);
      // drowTypeObject[drowTypename](event.target.value, svgName + eleIndexNum)
    }, timerTime);
  };

  //質問の順番を入れ替えた時に再描画する処理
  const moveContentReDrow = (event, name) => {
    const target = event.target;
    let downFlag = true;
    let activeFlag = false;
    target.addEventListener('mousemove', () => {
      if (downFlag) {
        downFlag = false;
        activeFlag = true;
      }
    });
    target.addEventListener('mouseup', () => {
      downFlag = false;
      if (!downFlag && activeFlag) {
        activeFlag = false;
        // 該当要素のliグループを取得
        clearTimeout(inputTimer.current);
        inputTimer.current = setTimeout(() => {
          const targetUl = event.target.closest('ul');
          if (targetUl.id === 'profile_group' || targetUl.id === 'interview_group') {
            const lis = targetUl.querySelectorAll('li');
            for (let i = 0; i < lis.length; i++) {
              const eleIndexNum = i + 1;
              if (lis[i].querySelector(`[name="${name}_q"]`) !== null) {
                //質問文取得
                const qText = lis[i].querySelector(`[name="${name}_q"]`).value;
                //描画
                // drowTypeObject['text'](qText, name + '_q_' + eleIndexNum)
                drawInputValueOnSvg('text', qText, name + '_q_' + eleIndexNum);
              }
              if (lis[i].querySelector(`[name="${name}_a_groom"]`) !== null) {
                //新郎回答文取得
                const groomText = lis[i].querySelector(`[name="${name}_a_groom"]`).value;
                //描画
                // drowTypeObject['text'](groomText, name + '_groom_a_' + eleIndexNum)
                drawInputValueOnSvg('text', groomText, name + '_groom_a_' + eleIndexNum);
              }
              if (lis[i].querySelector(`[name="${name}_a_bride"]`) !== null) {
                //新婦回答文取得
                const brideText = lis[i].querySelector(`[name="${name}_a_bride"]`).value;
                //描画
                // drowTypeObject['text'](brideText, name + '_bride_a_' + eleIndexNum)
                drawInputValueOnSvg('text', brideText, name + '_bride_a_' + eleIndexNum);
              }
            }
          } else if (targetUl.id === 'menu_group') {
            const lis = targetUl.querySelectorAll('li');
            for (let i = 0; i < lis.length; i++) {
              const eleIndexNum = i + 1;
              if (lis[i].querySelector('[name="menu_name"]') !== null) {
                //メニュー名取得
                const menuName = lis[i].querySelector('[name="menu_name"]').value;
                //描画
                // drowTypeObject['textarea'](menuName, 'menu_' + eleIndexNum)
                drawInputValueOnSvg('textarea', menuName, 'menu_' + eleIndexNum);
              }
            }
          }
        }, 200);
      }
    });
  };

  //郵便番号入力時の処理
  const changePostcardPostalCodeFunc = (event, funcname, drowTypename, className) => {
    console.log('change');
    setInputObject[funcname](event);
    clearTimeout(inputTimer.current);
    inputTimer.current = setTimeout(() => {
      //入力された郵便番号を配列に格納
      const inputPostalCodeArray = [...event.target.value];
      let finalyPostalCodeArray = [];
      for (let i = 0; i < 8; i++) {
        if (inputPostalCodeArray[i]) {
          finalyPostalCodeArray.push(inputPostalCodeArray[i]);
        } else {
          finalyPostalCodeArray.push('');
        }
      }
      for (let i = 0; i < finalyPostalCodeArray.length; i++) {
        // drowTypeObject[drowTypename](finalyPostalCodeArray[i], className + (i + 1));
        drawInputValueOnSvg(drowTypename, finalyPostalCodeArray[i], className + (i + 1));
      }
    }, timerTime);
  };

  //非掲載チェックボックス処理群
  const DisabledObject = {
    changeTime1Disabled: useCallback(
      (event) => {
        setTime1Disabled(event.target.checked);
        if (event.target.checked === true) {
          setTime1DisabledValue(1);
        } else {
          setTime1DisabledValue(0);
        }
      },
      [setTime1Disabled]
    ),

    changeTime2Disabled: useCallback(
      (event) => {
        setTime2Disabled(event.target.checked);
        if (event.target.checked === true) {
          setTime2DisabledValue(1);
        } else {
          setTime2DisabledValue(0);
        }
      },
      [setTime2Disabled]
    ),

    changeTime3Disabled: useCallback(
      (event) => {
        setTime3Disabled(event.target.checked);
        if (event.target.checked === true) {
          setTime3DisabledValue(1);
        } else {
          setTime3DisabledValue(0);
        }
      },
      [setTime3Disabled]
    ),

    changeMembershipFeeDisabled: useCallback(
      (event) => {
        setMembershipFeeDisabled(event.target.checked);
        if (event.target.checked === true) {
          setMembershipFeeDisabledValue(1);
        } else {
          setMembershipFeeDisabledValue(0);
        }
      },
      [setMembershipFeeDisabled]
    ),

    changeMessage1Disabled: useCallback(
      (event) => {
        setMessage1Disabled(event.target.checked);
        if (event.target.checked === true) {
          setMessage1DisabledValue(1);
        } else {
          setMessage1DisabledValue(0);
        }
      },
      [setMessage1Disabled]
    ),

    changeMessage2Disabled: useCallback(
      (event) => {
        setMessage2Disabled(event.target.checked);
        if (event.target.checked === true) {
          setMessage2DisabledValue(1);
        } else {
          setMessage2DisabledValue(0);
        }
      },
      [setMessage2Disabled]
    ),

    changeMessage3Disabled: useCallback(
      (event) => {
        setMessage3Disabled(event.target.checked);
        if (event.target.checked === true) {
          setMessage3DisabledValue(1);
        } else {
          setMessage3DisabledValue(0);
        }
      },
      [setMessage3Disabled]
    ),
  };

  //非掲載時に位置調整も一緒にする処理
  const DisabledMoveChange = (event, funcname, groupname, lineheight) => {
    DisabledObject[funcname](event);
    moveDisable(event.target.className, groupname, lineheight);
    changeDisableDisplay(event.target.className, groupname);
  };
  //非掲載時に表示非表示だけの処理
  const DisabledChange = (event, funcname, groupname) => {
    DisabledObject[funcname](event);
    changeDisableDisplay(event.target.className, groupname);
  };

  //SVG表示に切り替える処理
  const changeSvgImage = (targetSvgId) => {
    // すべてのSVGを非表示にする
    const hideAllSvgs = () => {
      //canvas内の全SVG要素を取得
      const canvasElement = document.getElementById('canvas');
      const svgElement = canvasElement.getElementsByClassName('svg_wrap');

      //SVG要素にclass="none"を追加
      for (let i = 0; i < svgElement.length; i++) {
        svgElement[i].classList.add('none');
      }
    };

    // 対象SVGを表示する
    const displayTargetSvg = (svgId) => {
      const svgElem = document.getElementById(svgId);
      const svgParentElem = svgElem.closest('.svg_wrap');
      svgParentElem.classList.remove('none');
    };

    // はがきSVG表示切替
    const switchPostcardSvg = (sender, addressType) => {
      // console.log(sender, addressType);
      if (sender === 'groomAndBride') {
        if (addressType === 'combine') {
          // 連名用はがきに切替
          displayTargetSvg('postcard_combine_svg');
        } else if (addressType === 'separate') {
          // 個別用はがきに切替
          displayTargetSvg('postcard_separate_groom_svg');
          displayTargetSvg('postcard_separate_bride_svg');
        }
      } else if (sender === 'parent') {
        displayTargetSvg('postcard_separate_groom_svg');
        displayTargetSvg('postcard_separate_bride_svg');
      }
    };

    // 付箋の表示をフォームの値によって切り替える
    const changeFusenVisibilityByForm = () => {
      changeFusenVisibility('fusen_01_ceremony_svg', ceremonyTag === '1');
      changeFusenVisibility('fusen_02_courtesy_bus_svg', courtesyBusTag === '1');
      changeFusenVisibility('fusen_03_entertainment_svg', entertainmentTag === '1');
      changeFusenVisibility('fusen_04_group_photo_svg', groupPhotoTag === '1');
      changeFusenVisibility('fusen_05_toast_svg', toastTag === '1');
      changeFusenVisibility('fusen_06_reception_svg', receptionTag === '1');
      changeFusenVisibility('fusen_07_speech_svg', speechTag === '1');
      changeFusenVisibility('fusen_08_congratulations_svg', congratulationsTag === '1');
      changeFusenVisibility('fusen_09_banzai_svg', banzaiTag === '1');
      adjustFusenPositions();
    };

    // すべてのSVGを非表示にする
    hideAllSvgs();

    // 該当SVGのみを表示
    if (targetSvgId === 'postcard_frontside_svg') {
      // はがきSVG表示切替
      const sender = postcardDeliveryAddressType === '1' ? 'groomAndBride' : 'parent'; /* 差出人 */
      const addressType = postcardDescriptionMethodType === '1' ? 'combine' : 'separate'; /* 記載方法 */
      switchPostcardSvg(sender, addressType);
    } else if (targetSvgId === 'fusen_svg') {
      // 付箋SVG表示
      changeFusenVisibilityByForm();
    } else {
      displayTargetSvg(targetSvgId);
    }
  };

  // リロード時にアラートを表示
  const showReloadAlert = useCallback((e) => {
    console.log('showReloadAlert');
    e.preventDefault();
    e.returnValue = ''; // アラートに任意のメッセージを表示することはできない（ブラウザ仕様）
  });

  useEffect(async () => {
    // ローディング開始
    dispatch(loadingChangeAction(true));

    // 画面離脱時アラート
    window.addEventListener('beforeunload', showReloadAlert);

    // 必要なフォントリスト
    // common.js等に移動予定
    const fontList = [
      { name: 'Bahagia', fileName: 'Bahagia.ttf' },
      { name: 'COTILLIO', fileName: 'COTILLIO.ttf' },
      { name: 'HanaMin', fileName: 'ApliMincho.otf' },
      // { name: 'HanaMin', fileName: 'HanaMin.ttf' },
      { name: 'LovelyBarthy-Regular', fileName: 'LovelyBarthy-Regular.ttf' },
    ];

    // フォントデータ読み込み
    const tmpFontData = await loadAllFontData(fontList);
    if (tmpFontData === null) {
      window.alert('フォントデータの読み込みに失敗しました。');
      // エラーページ表示予定
    } else {
      setFontData(tmpFontData);
      // loadFontDataForSeat(tmpFontData)
    }

    // 印刷機能終了時の処理
    // window.addEventListener('afterprint', cleanUpPrintProcess);
    window.onafterprint = cleanUpPrintProcess;

    // クリーンナップ処理
    // return window.removeEventListener('afterprint', cleanUpPrintProcess);

    // クリーンアップ処理
    return () => {
      window.removeEventListener('beforeunload', showReloadAlert);
    };
  }, []);

  // useEffect(() => {
  //   // console.log(fontData);
  //   if (fontData === null) {
  //     return;
  //   }
  //   setTimeout(() => {
  //     drawInputValueOnSvg('text', 'JavaScript', 'message_1');
  //     // console.log(font)
  //     // window.alert("ok")
  //   }, 5000);
  // }, [fontData]);

  useEffect(() => {
    // console.log(fontData);
    if (!fontData) {
      return;
    }

    // 移動機能はひとまず無効化
    // moveElementFunc('profile_group', 'edit_area');
    // moveElementFunc('interview_group', 'edit_area');
    // moveElementFunc('menu_group', 'edit_area');

    //現在の月を取得
    const toDay = new Date();

    //配達完了日時の更新
    toDay.setDate(toDay.getDate() + 3);
    const WeekChars = ['日', '月', '火', '水', '木', '金', '土'];
    setDeliverydate(toDay.getMonth() + 1 + '月' + toDay.getDate() + '日(' + WeekChars[toDay.getDay()] + ')');

    const moveMonthValue = toDay.getMonth() + 2;
    const newPostDate = new Date(toDay.setMonth(moveMonthValue));
    setPostdate(newPostDate);
    const newPostDateValue = newPostDate.getFullYear() + '年' + (newPostDate.getMonth() + 1) + '月吉日';
    //投函月の挿入
    setPostdateValue(newPostDateValue);

    //クエリパラメータの取得
    const queryParam = queryString.parse(props.location.search);
    setViewValue(queryParam.view);
    setIsTemplate(typeof queryParam.savedata === 'undefined'); /* テンプレート or 保存データ判定 */

    // queryから保存データシリアルを取得
    const getSerialFromQuery = (queryParam) => {
      return queryParam.savedata;
    };

    // queryからテンプレートか保存データかを取得
    const getDataTypeFromQuery = (queryParam) => {
      if (queryParam.savedata) {
        return 'savedata';
      } else {
        return 'template';
      }
    };

    // queryから招待状か席次表かを取得
    const getDesignTypeFromQuery = (queryParam) => {
      if (queryParam.view === 'seating') {
        return 'seating';
      } else if (queryParam.view === 'invitation') {
        return 'invitation';
      } else {
        return 'unknown';
      }
    };

    const dataType = getDataTypeFromQuery(queryParam);
    const designType = getDesignTypeFromQuery(queryParam);
    setDesignType(designType);
    let serial = '';
    if (dataType === 'savedata') {
      serial = getSerialFromQuery(queryParam);
      setDesignSerial(serial);
    }

    const data = {
      // memberId: memberInfo.member_id,
      dataType: dataType,
      designType: designType,
      serial: serial,
    };

    // console.log(data);

    let params = new URLSearchParams();
    params.append('data', JSON.stringify(data));

    //SVGの取得・書き換え
    axios
      .post(ApiDir + '/inport_svg.php', params)
      .then(function (response) {
        // console.log(response.data);
        if (response.data === 'nologin') {
          window.alert('ログインしてください。');
          dispatch(push(`${RouteDir}/login`));
          return;
        } else if (response.data === 'error') {
          throw 'svg import error';
        }

        //取得したSVG情報をHTMLに追加
        const canvasElement = document.getElementById('canvas');
        canvasElement.innerHTML = response.data;
        // const targetElement = canvasElement.getElementsByClassName('svg_wrap');

        // SVG内の要素表示切替：デザイン画面用
        changeSvgElemVisibilitiesByPurpose('design', designType);

        //画像にクリックイベントを追加
        const imageClickElements = canvasElement.getElementsByClassName('image_click_element');

        //画像操作要素を取得
        const getActiveEdit = () => {
          const canvasElement = document.getElementById('canvas');
          return canvasElement.getElementsByClassName('active_edit');
        };

        //画像操作部品を取得
        const getImageEditGroup = () => {
          const canvasElement = document.getElementById('canvas');
          return canvasElement.getElementsByClassName('image_edit_group');
        };

        document.addEventListener('mousedown', (event) => {
          if (!event.target.classList.contains('base_shape') && !event.target.classList.contains('image_edit_btn')) {
            const targetElement = getActiveEdit();
            const imageEditGroup = getImageEditGroup();
            if (targetElement.length !== 0) {
              targetElement[0].classList.remove('active_edit');
            }
            if (imageEditGroup.length !== 0) {
              imageEditGroup[0].remove();
            }
          }
        });

        // 画像クリック時の動作
        for (let i = 0; i < imageClickElements.length; i++) {
          imageClickElements[i].onclick = (event) => {
            if (event.target.classList.contains('image_edit_btn')) {
              console.log('resize');
            } else {
              const parent = event.target.closest('.image_group_wrap');

              //親要素のidを取得
              const parentId = parent.id;
              const targetInputElement = document.getElementsByName(parentId);
              if (parent.querySelector('image').href.baseVal !== '') {
                //clip-pathを取得、削除
                // const wrapElement = event.target.closest('.image_click_element');
                // if (!wrapElement.classList.contains('active_edit')) {
                //   wrapElement.classList.add('active_edit');
                //   //操作するための四隅のボタン、回転ボタンを追加
                //   //写真編集エリアの表示
                //   const imageEditorAreaElement = document.getElementById('image_editor_area');
                //   //編集モード開始
                //   imageEditorAreaElement.style.display = 'block';
                //   //画像の非表示
                //   const targetImage = wrapElement.getElementsByTagName('image');
                //   targetImage[0].style.opacity = '0';
                //   //画像のパスを取得
                //   const targetImagePath = targetImage[0].href.baseVal;
                //   //画像の図形情報を取得
                //   const targetImageInfo = targetImage[0].getBoundingClientRect();
                //   //画像の幅を取得
                //   const targetImageWidth = targetImageInfo.width;
                //   //image_groupのX座標調整値を取得
                //   const imageGroup = wrapElement.getElementsByClassName('image_group');
                //   const imageGroupMatrix = imageGroup[0].getAttribute('transform');
                //   const imageGroupMatrixArray = imageGroupMatrix.split(',');
                //   const imageGroupX = Number(imageGroupMatrixArray[4]);
                //   //編集SVGに画像をセット
                //   const editImageElement = document.getElementById('iamge_editor_element');
                //   //画像パスをセット
                //   editImageElement.setAttribute('src', targetImagePath);
                //   //挿入画像の大きさを取得
                //   const imageElement = new Image();
                //   imageElement.src = targetImagePath;
                //   const imageElementWidth = imageElement.naturalWidth;
                //   //挿入画像と元の画像の比率を計算
                //   const imageRatio = targetImageWidth / imageElementWidth;
                //   //描画SVGのx座標、y座標の取得
                //   const canvasSVG = document.getElementById('seating_profile_svg_wrap');
                //   const canvasSVGMatrix = canvasSVG.style.transform;
                //   const matrixArray = canvasSVGMatrix.split(',');
                //   const canvasRatio = matrixArray[0].replace('matrix(', '');
                //   const canvasSVGX = Number(matrixArray[4]);
                //   const canvasSVGY = Number(matrixArray[5].replace(')', ''));
                //   //対象画像の親要素のtransform値X座標Y座標を取得
                //   const imageParentElement = parent.closest('.image_click_element');
                //   const imageParentElementMatrix = imageParentElement.getAttribute('transform');
                //   const imageParentElementMatrixArray = imageParentElementMatrix.split(',');
                //   const imageParentElementX = Number(imageParentElementMatrixArray[4]);
                //   const imageParentElementY = Number(imageParentElementMatrixArray[5].replace(')', ''));
                //   //対象画像のグループ要素のtransform値X座標Y座標を取得
                //   const getImageGroupPosition = (ele) => {
                //     if (ele.hasAttribute('transform')) {
                //       const imageGroupElementMatrix = ele.getAttribute('transform');
                //       const imageGroupElementMatrixArray = imageGroupElementMatrix.split(',');
                //       return {
                //         x: Number(imageGroupElementMatrixArray[4]),
                //         y: Number(imageGroupElementMatrixArray[5].replace(')', '')),
                //       };
                //     } else {
                //       return {
                //         x: 0,
                //         y: 0,
                //       };
                //     }
                //   };
                //   const imageGroupElement = imageParentElement.getElementsByClassName('image_group_wrap');
                //   const imageGroupPosition = getImageGroupPosition(imageGroupElement[0]);
                //   //画像の位置・大きさを調整
                //   editImageElement.style.transform = `matrix(${imageRatio},0,0,${imageRatio},${
                //     canvasSVGX + imageParentElementX * canvasRatio + imageGroupX * canvasRatio + imageGroupPosition['x']
                //   },${canvasSVGY + imageParentElementY * canvasRatio + imageGroupPosition['y']})`;
                //   //画像のアウトラインを描画
                //   const editorSVG = document.getElementById('image_editor_area');
                //   const editorOutlineGroup = editorSVG.getElementsByClassName('outline_group');
                //   editorOutlineGroup[0].setAttribute(
                //     'transform',
                //     `matrix(${canvasRatio},0,0,${canvasRatio},${
                //       canvasSVGX + imageParentElementX * canvasRatio + imageGroupPosition['x']
                //     },${canvasSVGY + imageParentElementY * canvasRatio + imageGroupPosition['y']})`
                //   );
                //   //base-shapeを取得
                //   const outlineBaseShape = parent.getElementsByClassName('base_shape');
                //   const cloneOutlineBaseShape = outlineBaseShape[0].cloneNode(false);
                //   cloneOutlineBaseShape.style = '';
                //   editorOutlineGroup[0].appendChild(cloneOutlineBaseShape);
                //   //画像調整用ボタンの配置
                //   const editImageInfo = editImageElement.getBoundingClientRect();
                //   const editSVGInfo = editorSVG.getBoundingClientRect();
                //   //各ボタンの取得
                //   const tlElement = document.getElementById('image_size_button_TL');
                //   const trElement = document.getElementById('image_size_button_TR');
                //   const brElement = document.getElementById('image_size_button_BR');
                //   const blElement = document.getElementById('image_size_button_BL');
                //   //各ボタンの配置
                //   tlElement.style.transform = `matrix(1,0,0,1,${editImageInfo['x'] - editSVGInfo['x']},${
                //     editImageInfo['y'] - editSVGInfo['y']
                //   })`;
                //   trElement.style.transform = `matrix(1,0,0,1,${
                //     editImageInfo['x'] - editSVGInfo['x'] + editImageInfo['width']
                //   },${editImageInfo['y'] - editSVGInfo['y']})`;
                //   brElement.style.transform = `matrix(1,0,0,1,${
                //     editImageInfo['x'] - editSVGInfo['x'] + editImageInfo['width']
                //   },${editImageInfo['y'] - editSVGInfo['y'] + editImageInfo['height']})`;
                //   blElement.style.transform = `matrix(1,0,0,1,${editImageInfo['x'] - editSVGInfo['x']},${
                //     editImageInfo['y'] - editSVGInfo['y'] + editImageInfo['height']
                //   })`;
                //   //編集モードを終了
                //   imageEditorAreaElement.onclick = (event) => {
                //     // imageEditorAreaElement.style.display = 'none';
                //     // targetImage[0].style.opacity = '1';
                //     // while(editorOutlineGroup[0].firstChild)
                //     // {
                //     //   editorOutlineGroup[0].removeChild(editorOutlineGroup[0].firstChild);
                //     // }
                //   };
                // }
              } else {
                //input要素を選択する処理
                if (targetInputElement.length) {
                  targetInputElement[0].click();
                }
              }
            }
          };
        }

        // すべてのSVGの大きさ・位置を、画面サイズに合わせて調整
        const adjustAllSvgSizesAndPositions = (designType) => {
          const canvasElem = document.getElementById('canvas');
          const svgWrapElems = canvasElem.querySelectorAll('.svg_wrap');
          for (let i = 0; i < svgWrapElems.length; i++) {
            // Svgs[i].setAttribute('viewBox', firstSvgViewbox)
            SVGWrapElementMatrixChange(svgWrapElems[i]);
          }

          if (designType === 'invitation') {
            // はがき表示位置を調整
            adjustPostcardSeparatePosition();

            // 付箋表示位置を調整
            adjustFusenPositions();
          }
        };

        // すべてのSVGの大きさ・位置を、画面サイズに合わせて調整
        adjustAllSvgSizesAndPositions(designType);

        //リサイズ時の処理
        window.addEventListener('resize', () => {
          adjustAllSvgSizesAndPositions(designType);
        });

        // changeText(newPostDateValue, 'posting_month');
        // drawInputValueOnSvg('text', newPostDateValue, 'posting_month');

        //preview_areaの幅に合わせてcanvasの大きさを変更する処理 ※初期値
        // canvasTransform('invitation_svg')

        //SVG内要素をクリックした時に該当フォームが選択される処理
        const clickElement = canvasElement.querySelectorAll('[data-tab]');
        for (let i = 0; i < clickElement.length; i++) {
          clickElement[i].addEventListener('click', () => {
            if (clickElement[i].dataset.tab) {
              setShowEditContent(clickElement[i].dataset.tab);
              const targetInputElement = document.getElementsByName(clickElement[i].classList[0]);
              if (targetInputElement.length) {
                targetInputElement[0].focus();
              }
            }
          });
        }

        // フォームデータを取得（テンプレート or 保存データ）
        const importFormData = () => {
          //入力フォームのValueの取得・書き換え
          let targetValueId = 1;
          let targetValueName = 'template';
          if (queryParam.default) {
            targetValueId = queryParam.default;
          } else if (queryParam.savedata) {
            targetValueId = queryParam.savedata;
            targetValueName = 'saved';
          }
          const formValueData = {
            value_id: targetValueId,
            value_name: targetValueName,
            memberId: memberInfo.member_id,
            dataType: dataType,
            designType: designType,
            serial: serial,
          };
          let formValueParams = new URLSearchParams();
          formValueParams.append('data', JSON.stringify(formValueData));

          if (queryParam.view === 'invitation') {
            axios
              .post(ApiDir + '/inport_formvalue.php', formValueParams)
              .then(function (response) {
                if (response.data === 'error') {
                  throw 'formdata import error';
                }

                const res = response.data[0];

                // 招待状フォームデータを各Stateにセット
                setInvitationFormDataToState(res, dataType);

                // テンプレートの場合は、SVGにテンプレートテキストを描画
                if (targetValueName === 'template') {
                  drawInvitationFormDataOnSvg(res, dataType);
                }

                //最初に表示するSVGを表示
                changeSvgImage('invitation_svg');
                // changeInvSvgImage(queryParam.view);

                // 読み込み完了フラグ
                setIsSvgLoaded(true);
              })
              .catch(function (error) {
                console.log(error);
                window.alert(PROGRAM_ERROR_ALERT_MSG);
                dispatch(push(`${RouteDir}`));
                return;
              });
            //非掲載のチェックボックスが変更された時にvalue値を変更する
            // const boolCheckboxes = document.getElementsByClassName('boolvalue')
            // const length = boolCheckboxes.length
            // for(let i=0;i<length;i++)
            // {
            //   boolCheckboxes[i].addEventListener('onchange',()=>)
            // }
          } else if (queryParam.view === 'seating') {
            axios
              .post(ApiDir + '/importSeatingFormData.php', formValueParams)
              .then((response) => {
                if (response.data === 'error') {
                  throw 'formdata import error';
                }

                const res = response.data[0];

                // 席次表フォームデータを各Stateにセット
                setSeatingFormDataToState(res, dataType);

                // テンプレートの場合は、SVGにテンプレートテキストを描画
                if (targetValueName === 'template') {
                  drawSeatingFormDataOnSvg(res, dataType);
                }

                // 席次表保存データの場合は配席リストデータをStateにセット
                if (targetValueName === 'saved') {
                  setSeatingGuestList(res.seating_guests);
                }

                //最初に表示するSVGを表示
                changeSvgImage('seating_profile_svg');
                // changeInvSvgImage(queryParam.view);

                // 席次表SVG読み込み完了フラグ
                setIsSvgLoaded(true);
              })
              .catch((error) => {
                console.error(error);
                window.alert(PROGRAM_ERROR_ALERT_MSG);
                dispatch(push(`${RouteDir}`));
                return;
              });
          }
        };

        importFormData();
      })
      .catch(function (error) {
        console.error(error);
        window.alert(PROGRAM_ERROR_ALERT_MSG);
        dispatch(push(`${RouteDir}`));
        return;
      });

    //html,body,#rootのcssにheight:100%を追加
    const htmlElement = document.getElementsByTagName('html');
    htmlElement[0].style.height = '100%';

    const bodyElement = document.getElementsByTagName('body');
    bodyElement[0].style.height = '100%';

    const rootElement = document.getElementById('root');
    rootElement.style.height = '100%';
  }, [
    props.location.search,
    fontData,
    setGroomFamilyName,
    setGroomFirstName,
    setGroomFamilyNameEn,
    setGroomFirstNameEn,
    setBrideFamilyName,
    setBrideFirstName,
    setBrideFamilyNameEn,
    setBrideFirstNameEn,
    setStartDate,
    setGroomParentFamilyName,
    setGroomParentFirstName,
    setBrideParentFamilyName,
    setBrideParentFirstName,
    setPostdate,
    setPostdateValue,
    setGreetings,
    setTime1Name,
    setTime1Time,
    setTime1Disabled,
    setTime2Name,
    setTime2Time,
    setTime2Disabled,
    setTime3Name,
    setTime3Time,
    setTime3Disabled,
    setVenueName,
    setVenueInfo,
    setMembershipFee,
    setMembershipFeeDisabled,
    setMessage1,
    setMessage1Disabled,
    setMessage2,
    setMessage2Disabled,
    setMessage3,
    setMessage3Disabled,
  ]);

  useEffect(() => {
    setFontData(MY_FONTS);
  }, [MY_FONTS]);

  // 初回データ読み込み完了時の処理
  useEffect(() => {
    if (!isSvgLoaded) {
      return;
    }

    // ローディング解除
    dispatch(loadingChangeAction(false));

    // console.log(viewValue);
  }, [isSvgLoaded]);

  // 新規デザイン作成画面から保存デザイン画面への切替時：初回保存用
  useEffect(() => {
    // SVG読み込みが完了していない場合は何もしない
    if (!isSvgLoaded) return;

    /**
     * サイドバーのトップタブ（基本情報）をクリック
     */
    const selectSideBarTopTab = () => {
      const topTabElem = document.querySelector('#sideBarTopTab');
      topTabElem.click();
      // console.log('click');
    };

    // サイドバーのトップタブ（基本情報）をクリック
    selectSideBarTopTab();
  }, [isTemplate, isSvgLoaded]);

  //
  const testHTML2PDF = () => {
    // html2pdf検証
    const element = document.querySelector('#seating_profile_svg'); // 対象要素を指定
    const option = {
      margin: 1, // 余白
      filename: 'fileName.pdf', // ファイル名
      image: { type: 'svg', quality: 1 }, // PDFの生成に使用される画像のタイプとクオリティ
      html2canvas: { scale: 2, useCORS: true }, // html2canvasで使用される設定を記述。useCORS: trueを設定すると別ドメインの画像を表示できる（サイトによってはできないこともある）
      jsPDF: { format: 'a2', orientation: 'portrait' }, // jsPDFで使用される設定を記述
    };

    html2pdf()
      .from(element)
      .set(option)
      .save()
      .then(() => {
        // 成功
      })
      .catch((e) => {
        // 失敗
      });
  };

  // 招待状フォームデータを各Stateにセット
  const setInvitationFormDataToState = (res, dataType) => {
    setGroomFamilyName(res.groom_family_name);
    setGroomFirstName(res.groom_first_name);
    setGroomFamilyNameEn(res.groom_family_name_en);
    setGroomFirstNameEn(res.groom_first_name_en);
    setBrideFamilyName(res.bride_family_name);
    setBrideFirstName(res.bride_first_name);
    setBrideFamilyNameEn(res.bride_family_name_en);
    setBrideFirstNameEn(res.bride_first_name_en);

    setGroomParentFamilyName(res.groom_parent_family_name);
    setGroomParentFirstName(res.groom_parent_first_name);
    setBrideParentFamilyName(res.bride_parent_family_name);
    setBrideParentFirstName(res.bride_parent_first_name);
    if (res.wedding_day) {
      const initialDate = dataType === 'template' ? new Date() : new Date(res.wedding_day);
      // const initialDate = new Date(res.wedding_day);
      setStartDate(initialDate);
    }
    if (res.posting_month) {
      const newPostDate = dataType === 'template' ? new Date() : new Date(res.posting_month);
      setPostdate(newPostDate);
      const newPostDateValue = newPostDate.getFullYear() + '年' + (newPostDate.getMonth() + 1) + '月吉日';
      setPostdateValue(newPostDateValue);
    }

    setGreetings(res.greetings);

    setTime1Name(res.time_1_name);
    setTime1Time(res.time_1_time);
    setTime1DisabledValue(res.time_1_disabled);
    if (res.time_1_disabled !== '0') {
      setTime1Disabled(true);
    }
    setTime2Name(res.time_2_name);
    setTime2Time(res.time_2_time);
    setTime2DisabledValue(res.time_2_disabled);
    if (res.time_2_disabled !== '0') {
      setTime2Disabled(true);
    }
    setTime3Name(res.time_3_name);
    setTime3Time(res.time_3_time);
    setTime3DisabledValue(res.time_3_disabled);
    if (res.time_3_disabled !== '0') {
      setTime3Disabled(true);
    }
    setVenueName(res.venue_name);
    setVenueInfo(res.venue_info);
    setMembershipFee(res.membership_fee);
    setMembershipFeeDisabledValue(res.membership_fee_disabled);
    if (res.membership_fee_disabled !== '0') {
      setMembershipFeeDisabled(true);
    }
    setMessage1(res.message_1);
    setMessage1DisabledValue(res.message_1_disabled);
    if (res.message_1_disabled !== '0') {
      setMessage1Disabled(true);
    }
    setMessage2(res.message_2);
    setMessage2DisabledValue(res.message_2_disabled);
    if (res.message_2_disabled !== '0') {
      setMessage2Disabled(true);
    }
    setMessage3(res.message_3);
    setMessage3DisabledValue(res.message_3_disabled);
    if (res.message_3_disabled !== '0') {
      setMessage3Disabled(true);
    }
    setEnvelopePostalCode(res.envelope_postal_code);
    setEnvelopeDeliveryAddressType(res.envelope_delivery_address_type);
    setEnvelopeDescriptionMethodType(res.envelope_description_method_type);
    setEnvelopeAddress(res.envelope_address);
    setEnvelopeShowMaidenNameType(res.envelope_show_maiden_name_type);
    setEnvelopeGroomPostalCode(res.envelope_groom_postal_code);
    setEnvelopeGroomAddress(res.envelope_groom_address);
    setEnvelopeBridePostalCode(res.envelope_bride_postal_code);
    setEnvelopeBrideAddress(res.envelope_bride_address);
    setEnvelopeGroomParentPostalCode(res.envelope_groom_parent_postal_code);
    setEnvelopeGroomParentAddress(res.envelope_groom_parent_address);
    setEnvelopeBrideParentPostalCode(res.envelope_bride_parent_postal_code);
    setEnvelopeBrideParentAddress(res.envelope_bride_parent_address);
    setPostcardPostalCode(res.postcard_postal_code);
    setPostcardDeliveryAddressType(res.postcard_delivery_address_type);
    setPostcardDescriptionMethodType(res.postcard_description_method_type);
    setPostcardAddress(res.postcard_address);
    setPostcardShowMaidenNameType(res.postcard_show_maiden_name_type);
    setPostcardGroomPostalCode(res.postcard_groom_postal_code);
    setPostcardGroomAddress(res.postcard_groom_address);
    setPostcardBridePostalCode(res.postcard_bride_postal_code);
    setPostcardBrideAddress(res.postcard_bride_address);
    setPostcardGroomParentPostalCode(res.postcard_groom_parent_postal_code);
    setPostcardGroomParentAddress(res.postcard_groom_parent_address);
    setPostcardBrideParentPostalCode(res.postcard_bride_parent_postal_code);
    setPostcardBrideParentAddress(res.postcard_bride_parent_address);
    setEnglishNotation(res.postcard_backside_english_notation);
    setNOANotation(res.postcard_backside_noa_notation);
    setMessageNotation(res.postcard_backside_message_notation);
    setAllergyNotation(res.postcard_backside_allergy_notation);
    setCourtesyBusNotation(res.postcard_backside_courtesy_bus_notation);
    setCeremonyTag(res.ceremony_tag);
    setCeremonyTagNumber(res.ceremony_tag_number);
    setCeremonyTagHour(res.ceremony_tag_hour);
    setCeremonyTagMinutes(res.ceremony_tag_minutes);
    setCourtesyBusTag(res.courtesy_bus_tag);
    setCourtesyBusTagNumber(res.courtesy_bus_tag_number);
    setCourtesyBusTagHour(res.courtesy_bus_tag_hour);
    setCourtesyBusTagMinutes(res.courtesy_bus_tag_minutes);
    setCourtesyBusTagPlace(res.courtesy_bus_tag_place);
    setEntertainmentTag(res.entertainment_tag);
    setEntertainmentTagNumber(res.entertainment_tag_number);
    setGroupPhotoTag(res.group_photo_tag);
    setGroupPhotoTagNumber(res.group_photo_tag_number);
    setGroupPhotoTagHour(res.group_photo_tag_hour);
    setGroupPhotoTagMinutes(res.group_photo_tag_minutes);
    setToastTag(res.toast_tag);
    setToastTagNumber(res.toast_tag_number);
    setReceptionTag(res.reception_tag);
    setReceptionTagNumber(res.reception_tag_number);
    setReceptionTagHour(res.reception_tag_hour);
    setReceptionTagMinutes(res.reception_tag_minutes);
    // スピーチのお願い
    setSpeechTag(res.speech_tag);
    setSpeechTagNumber(res.speech_tag_number);

    // 祝辞のお願い
    setCongratulationsTag(res.congratulations_tag);
    setCongratulationsTagNumber(res.congratulations_tag_number);

    // 万歳三唱のお願い
    setBanzaiTag(res.banzai_tag);
    setBanzaiTagNumber(res.banzai_tag_number);
  };

  // 招待状フォームデータをSVGに描画
  const drawInvitationFormDataOnSvg = (res, dataType) => {
    // 郵便番号をSVGに反映する
    const drawPostalCodeOnSvg = (postalCode, partialClassName) => {
      const postalCodeArray = [...postalCode];
      for (let i = 0; i < postalCodeArray.length; i++) {
        const className = partialClassName + (i + 1);
        drawInputValueOnSvg('text', postalCodeArray[i], className);
      }
    };

    //SVG内のwedding_dayの変更
    if (res.wedding_day) {
      const initialDate = dataType === 'template' ? new Date() : new Date(res.wedding_day);
      // changeDate(res.wedding_day, 'wedding_day')
      drawInputValueOnSvg('date', initialDate, 'wedding_day');
    } else {
      const makeDateValue = (startDate) => {
        const DateValue = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
        return DateValue;
      };
      // changeDate(makeDateValue(startDate), 'wedding_day')
      drawInputValueOnSvg('date', startDate, 'wedding_day');
    }
    if (res.posting_month) {
      const newPostDate = dataType === 'template' ? new Date() : new Date(res.posting_month);
      const newPostDateValue = newPostDate.getFullYear() + '年' + (newPostDate.getMonth() + 1) + '月吉日';
      //投函月の挿入
      // changeText(newPostDateValue, 'posting_month')
      drawInputValueOnSvg('text', newPostDateValue, 'posting_month');
    }
    drawInputValueOnSvg('text', res.groom_first_name, 'groom_first_name');
    drawInputValueOnSvg('text', res.groom_family_name, 'groom_family_name');
    drawInputValueOnSvg('text', res.groom_family_name_en, 'groom_family_name_en');
    drawInputValueOnSvg('text', res.groom_first_name_en, 'groom_first_name_en');
    drawInputValueOnSvg('text', res.bride_family_name, 'bride_family_name');
    drawInputValueOnSvg('text', res.bride_first_name, 'bride_first_name');
    drawInputValueOnSvg('text', res.bride_family_name_en, 'bride_family_name_en');
    drawInputValueOnSvg('text', res.bride_first_name_en, 'bride_first_name_en');
    drawInputValueOnSvg('text', res.groom_parent_family_name, 'groom_parent_family_name');
    drawInputValueOnSvg('text', res.groom_parent_first_name, 'groom_parent_first_name');
    drawInputValueOnSvg('text', res.bride_parent_family_name, 'bride_parent_family_name');
    drawInputValueOnSvg('text', res.bride_parent_first_name, 'bride_parent_first_name');

    const changeEnterCord = (str) => {
      return str.replace(/\r\n/g, '\n');
    };
    drawInputValueOnSvg('textarea', changeEnterCord(res.greetings), 'greetings');
    drawInputValueOnSvg('text', res.time_1_time, 'time_1_time');
    drawInputValueOnSvg('text', res.time_1_name, 'time_1_name');

    drawInputValueOnSvg('text', res.time_2_name, 'time_2_name');
    drawInputValueOnSvg('text', res.time_2_time, 'time_2_time');

    drawInputValueOnSvg('text', res.time_3_name, 'time_3_name');
    drawInputValueOnSvg('text', res.time_3_time, 'time_3_time');
    drawInputValueOnSvg('textarea', changeEnterCord(res.venue_name), 'venue_name');
    drawInputValueOnSvg('textarea', changeEnterCord(res.venue_info), 'venue_info');
    drawInputValueOnSvg('text', res.membership_fee, 'membership_fee');
    drawInputValueOnSvg('textarea', changeEnterCord(res.message_1), 'message_1');
    drawInputValueOnSvg('textarea', changeEnterCord(res.message_2), 'message_2');
    drawInputValueOnSvg('textarea', changeEnterCord(res.message_3), 'message_3');

    // 封筒　ここから

    // SVGに郵便番号表示領域が存在しない
    // drawInputValueOnSvg('text',res.envelope_postal_code, 'postal_code')
    // drawPostalCodeOnSvg(res.envelope_postal_code, "postal_code_")

    drawInputValueOnSvg('textarea', changeEnterCord(res.envelope_address), 'envelope_address');
    drawInputValueOnSvg('text', res.envelope_groom_postal_code, 'groom_postal_code');
    drawInputValueOnSvg('textarea', changeEnterCord(res.envelope_groom_address), 'envelope_groom_address');
    drawInputValueOnSvg('text', res.envelope_bride_postal_code, 'bride_postal_code');
    drawInputValueOnSvg('textarea', changeEnterCord(res.envelope_bride_address), 'envelope_bride_address');
    drawInputValueOnSvg('text', res.envelope_groom_parent_postal_code, 'groom_parent_postal_code');
    drawInputValueOnSvg(
      'textarea',
      changeEnterCord(res.envelope_groom_parent_address),
      'envelope_groom_parent_address'
    );
    drawInputValueOnSvg('text', res.envelope_bride_parent_postal_code, 'bride_parent_postal_code');
    drawInputValueOnSvg(
      'textarea',
      changeEnterCord(res.envelope_bride_parent_address),
      'envelope_bride_parent_address'
    );

    // 封筒　ここまで

    // 返信はがき（表面）　ここから

    // 返信はがき（表面）郵便番号
    drawPostalCodeOnSvg(res.postcard_postal_code, 'postal_code_');
    drawPostalCodeOnSvg(res.postcard_groom_postal_code, 'groom_postal_code_');
    drawPostalCodeOnSvg(res.postcard_bride_postal_code, 'bride_postal_code_');
    drawPostalCodeOnSvg(res.postcard_groom_parent_postal_code, 'groom_parent_postal_code_');
    drawPostalCodeOnSvg(res.postcard_bride_parent_postal_code, 'bride_parent_postal_code_');

    drawInputValueOnSvg('textarea', changeEnterCord(res.postcard_address), 'postcard_address');
    drawInputValueOnSvg('text', res.postcard_groom_postal_code, 'groom_postal_code');
    drawInputValueOnSvg('textarea', changeEnterCord(res.postcard_groom_address), 'postcard_groom_address');
    drawInputValueOnSvg('text', res.postcard_bride_postal_code, 'bride_postal_code');
    drawInputValueOnSvg('textarea', changeEnterCord(res.postcard_bride_address), 'postcard_bride_address');
    drawInputValueOnSvg('text', res.postcard_groom_parent_postal_code, 'groom_parent_postal_code');
    drawInputValueOnSvg(
      'textarea',
      changeEnterCord(res.postcard_groom_parent_address),
      'postcard_groom_parent_address'
    );
    drawInputValueOnSvg('text', res.postcard_bride_parent_postal_code, 'bride_parent_postal_code');
    drawInputValueOnSvg(
      'textarea',
      changeEnterCord(res.postcard_bride_parent_address),
      'postcard_bride_parent_address'
    );

    // 返信はがき（表面）　ここまで

    // 付箋　ここから

    // 挙式参列のお願い
    drawInputValueOnSvg('text', res.ceremony_tag_hour, 'ceremony_tag_hour');
    drawInputValueOnSvg('text', res.ceremony_tag_minutes, 'ceremony_tag_minutes');

    // 送迎バスのご案内
    drawInputValueOnSvg('text', res.courtesy_bus_tag_hour, 'courtesy_bus_tag_hour');
    drawInputValueOnSvg('text', res.courtesy_bus_tag_minutes, 'courtesy_bus_tag_minutes');
    drawInputValueOnSvg('text', res.courtesy_bus_tag_place, 'courtesy_bus_tag_place');

    // 集合写真のお願い
    drawInputValueOnSvg('text', res.group_photo_tag_hour, 'group_photo_tag_hour');
    drawInputValueOnSvg('text', res.group_photo_tag_minutes, 'group_photo_tag_minutes');

    // 受付のお願い
    drawInputValueOnSvg('text', res.reception_tag_hour, 'reception_tag_hour');
    drawInputValueOnSvg('text', res.reception_tag_minutes, 'reception_tag_minutes');

    // 付箋　ここまで
  };

  // 席次表フォームデータを各Stateにセット
  const setSeatingFormDataToState = (res, dataType) => {
    setGroomFamilyName(res.groom_family_name);
    setGroomFirstName(res.groom_first_name);
    setGroomFirstNameEn(res.groom_first_name_en);
    setBrideFamilyName(res.bride_family_name);
    setBrideFirstName(res.bride_first_name);
    setBrideFirstNameEn(res.bride_first_name_en);

    if (res.wedding_day) {
      const initialDate = dataType === 'template' ? new Date() : new Date(res.wedding_day);
      // const initialDate = new Date(res.wedding_day);
      setStartDate(initialDate);
    }

    // プロフィール&インタビュー
    setProf1Q(res.profile_1_question);
    setProf1Agroom(res.profile_1_groom_answer);
    setProf1Abride(res.profile_1_bride_answer);

    setProf2Q(res.profile_2_question);
    setProf2Agroom(res.profile_2_groom_answer);
    setProf2Abride(res.profile_2_bride_answer);

    setProf3Q(res.profile_3_question);
    setProf3Agroom(res.profile_3_groom_answer);
    setProf3Abride(res.profile_3_bride_answer);

    setProf4Q(res.profile_4_question);
    setProf4Agroom(res.profile_4_groom_answer);
    setProf4Abride(res.profile_4_bride_answer);

    setInterview1Q(res.interview_1_question);
    setInterview1Agroom(res.interview_1_groom_answer);
    setInterview1Abride(res.interview_1_bride_answer);

    setInterview2Q(res.interview_2_question);
    setInterview2Agroom(res.interview_2_groom_answer);
    setInterview2Abride(res.interview_2_bride_answer);

    setInterview3Q(res.interview_3_question);
    setInterview3Agroom(res.interview_3_groom_answer);
    setInterview3Abride(res.interview_3_bride_answer);

    setInterview4Q(res.interview_4_question);
    setInterview4Agroom(res.interview_4_groom_answer);
    setInterview4Abride(res.interview_4_bride_answer);

    // メニュー
    setMenuName1(res.menu_1);
    setMenuName2(res.menu_2);
    setMenuName3(res.menu_3);
    setMenuName4(res.menu_4);
    setMenuName5(res.menu_5);

    setSeatingGreeting(res.greetings);
    setProfileAddressGuide(res.address_guide);
    setVenueName(res.venue_name);
    setSeatingLayout(res.table_layout);

    // 追記文
    setSeatingAdditionalComment(res.additional_comment);
  };

  // 席次表フォームデータをSVGに描画
  const drawSeatingFormDataOnSvg = (res, dataType) => {
    // 基本情報
    drawInputValueOnSvg('text', res.groom_family_name, 'groom_family_name');
    drawInputValueOnSvg('text', res.groom_first_name, 'groom_first_name');
    drawInputValueOnSvg('text', res.groom_first_name_en, 'groom_first_name_en');
    drawInputValueOnSvg('text', res.bride_family_name, 'bride_family_name');
    drawInputValueOnSvg('text', res.bride_first_name, 'bride_first_name');
    drawInputValueOnSvg('text', res.bride_first_name_en, 'bride_first_name_en');

    // プロフィール
    drawInputValueOnSvg('text', res.profile_1_question, 'prof_q_1');
    drawInputValueOnSvg('text', res.profile_1_groom_answer, 'prof_groom_a_1');
    drawInputValueOnSvg('text', res.profile_1_bride_answer, 'prof_bride_a_1');
    drawInputValueOnSvg('text', res.profile_2_question, 'prof_q_2');
    drawInputValueOnSvg('text', res.profile_2_groom_answer, 'prof_groom_a_2');
    drawInputValueOnSvg('text', res.profile_2_bride_answer, 'prof_bride_a_2');
    drawInputValueOnSvg('text', res.profile_3_question, 'prof_q_3');
    drawInputValueOnSvg('text', res.profile_3_groom_answer, 'prof_groom_a_3');
    drawInputValueOnSvg('text', res.profile_3_bride_answer, 'prof_bride_a_3');
    drawInputValueOnSvg('text', res.profile_4_question, 'prof_q_4');
    drawInputValueOnSvg('text', res.profile_4_groom_answer, 'prof_groom_a_4');
    drawInputValueOnSvg('text', res.profile_4_bride_answer, 'prof_bride_a_4');

    // インタビュー
    drawInputValueOnSvg('text', res.interview_1_question, 'interview_q_1');
    drawInputValueOnSvg('text', res.interview_1_groom_answer, 'interview_groom_a_1');
    drawInputValueOnSvg('text', res.interview_1_bride_answer, 'interview_bride_a_1');
    drawInputValueOnSvg('text', res.interview_2_question, 'interview_q_2');
    drawInputValueOnSvg('text', res.interview_2_groom_answer, 'interview_groom_a_2');
    drawInputValueOnSvg('text', res.interview_2_bride_answer, 'interview_bride_a_2');
    drawInputValueOnSvg('text', res.interview_3_question, 'interview_q_3');
    drawInputValueOnSvg('text', res.interview_3_groom_answer, 'interview_groom_a_3');
    drawInputValueOnSvg('text', res.interview_3_bride_answer, 'interview_bride_a_3');
    drawInputValueOnSvg('text', res.interview_4_question, 'interview_q_4');
    drawInputValueOnSvg('text', res.interview_4_groom_answer, 'interview_groom_a_4');
    drawInputValueOnSvg('text', res.interview_4_bride_answer, 'interview_bride_a_4');

    // メニュー
    drawInputValueOnSvg('text', res.menu_1, 'menu_1 menu_ele');
    drawInputValueOnSvg('text', res.menu_2, 'menu_2 menu_ele');
    drawInputValueOnSvg('text', res.menu_3, 'menu_3 menu_ele');
    drawInputValueOnSvg('text', res.menu_4, 'menu_4 menu_ele');
    drawInputValueOnSvg('text', res.menu_5, 'menu_5 menu_ele');

    drawInputValueOnSvg('textarea', res.greetings, 'seating_greeting');
    drawInputValueOnSvg('textarea', res.address_guide, 'profile_address_guide');

    drawInputValueOnSvg('text', res.venue_name, 'venue_name');

    // 追記文
    drawInputValueOnSvg('text', res.additional_comment, 'seating_additional_comment');

    const initialDate = dataType === 'template' ? new Date() : new Date(res.wedding_day);
    // const initialDate = new Date(res.wedding_day);
    // changeDate(res.wedding_day, 'wedding_day')
    drawInputValueOnSvg('date', initialDate, 'wedding_day');
  };

  //SVGの保存
  const svgSubmit = () => {
    // 保存用にSVG要素リストを作成
    const createSvgElemList = () => {
      const canvasElem = document.querySelector('#canvas');
      const svgWrapElems = canvasElem.querySelectorAll('.svg_wrap');
      let svgElemList = [];
      for (let i = 0; i < svgWrapElems.length; i++) {
        const svgElem = svgWrapElems[i].querySelector('svg');
        const svgName = svgElem.dataset.name; /* SVGテンプレート作成時にdata-nameとして設定 */
        const data = {
          svgType: svgName,
          svgData: svgElem.outerHTML,
        };
        svgElemList.push(data);
      }
      return svgElemList;
    };

    if (designType === 'seating') {
      endTableLayoutModes();
    }

    dispatch(loadingChangeAction(true));

    const formElement = document.getElementById('edit_form');
    const formData = new FormData(formElement);

    // SVG内の要素表示切替：デザイン保存用
    changeSvgElemVisibilitiesByPurpose('design_save', designType);

    if (designType === 'invitation') {
      // 保存用にSVG要素リストを作成
      const svgElemList = createSvgElemList();
      // console.log(svgElemList);

      //投函月の作成
      const sendPostDate = postdate.getFullYear() + '-' + (postdate.getMonth() + 1) + '-01';
      formData.append('posting_month', sendPostDate);

      //templateの表示か、保存データの表示か
      //クエリパラメータの取得
      if (isTemplate) {
        //ログインしているかを確認してSVGデータ、formDataを保存
        dispatch(saveNewSvg('invitation', svgElemList, formData));
        // dispatch(saveNewSvg(svgElement, formData))
      } else {
        const svgData = {
          serial: designSerial,
          svgData: svgElemList,
        };

        //ログインしているかを確認してSVGデータ、formDataを保存
        dispatch(saveSvg('invitation', svgData, formData));

        // SVG内の要素表示切替：デザイン画面用
        changeSvgElemVisibilitiesByPurpose('design', designType);
      }
    } else if (designType === 'seating') {
      // 保存用にSVG要素リストを作成
      const svgElemList = createSvgElemList();
      console.log(svgElemList);

      // 配席リストデータを取得
      const seatingGuests = getGuestListToSave();
      formData.append('seating_guests', JSON.stringify(seatingGuests));

      if (isTemplate) {
        //ログインしているかを確認してSVGデータ、formDataを保存
        dispatch(saveNewSvg('seating', svgElemList, formData));
        // changeGuestRectVisibility(true);
      } else {
        const svgData = {
          serial: designSerial,
          svgData: svgElemList,
        };

        //ログインしているかを確認してSVGデータ、formDataを保存
        dispatch(saveSvg('seating', svgData, formData));

        // SVG内の要素表示切替：デザイン画面用
        changeSvgElemVisibilitiesByPurpose('design', designType);
      }
    }
  };

  // 保存したデザイン一覧画面に遷移
  const ancerMypage = () => {
    if (window.confirm('購入に進みますか？\nデザインを保存するには「保存する」ボタンを押してください。')) {
      dispatch(push(`${RouteDir}/mypage/saved_designs`));
    }
  };

  //タブの切り替え
  const changeEditContent = (value) => {
    setShowEditContent(value);
  };

  //head情報の変更
  const meta = {
    title: SiteTitle,
    description: 'ブライダルグッズポケットのポケット',
  };

  const close = () => {
    //ログインポップアップstateの更新
    dispatch(
      loginPopupChangeAction({
        login: false,
      })
    );
  };

  //投函月の操作
  const ChangePostDate = (value) => {
    const moveMonthValue = postdate.getMonth() + value;
    const newPostDate = new Date(postdate.setMonth(moveMonthValue));
    setPostdate(newPostDate);
    setPostdateValue(postdate.getFullYear() + '年' + (postdate.getMonth() + 1) + '月吉日');
    //投函月の描画
    // changeText(postdate.getFullYear() + '年' + (postdate.getMonth() + 1) + '月吉日', 'posting_month')
    drawInputValueOnSvg('text', postdate.getFullYear() + '年' + (postdate.getMonth() + 1) + '月吉日', 'posting_month');
  };

  const saveStyle = {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_save_w.svg')`,
  };
  const mypageStyle = {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_cart_w.svg')`,
  };

  // テーブルレイアウトタブの各モード終了
  const endTableLayoutModes = () => {
    // 席次表デザインでない場合は何もしない
    if (designType !== 'seating') return;

    // テーブル移動モード終了
    toggleTableMoveMode('end');
    setIsTableMoveMode(false);

    // テーブル削除モード終了
    toggleTableDeleteMode('end');
    setIsTableDeleteMode(false);

    // テーブル名編集モード終了
    toggleTableNameEditMode('end');
    setIsTableNameEditMode(false);
  };

  // PDF出力（ブラウザ機能）
  const exportPdf = () => {
    // SVG要素を取得
    const svgWrapElems = document.getElementsByClassName('svg_wrap');
    let svgElems = [];
    for (let i = 0; i < svgWrapElems.length; i++) {
      const svgElem = svgWrapElems[i].querySelector('svg');
      const svgElemCopy = svgElem.cloneNode(true); /* 複製せずに操作すると復元できなくなったので、複製して利用 */

      // SVGサイズ変更検証（画像ごとに調整予定）
      svgElemCopy.setAttribute('width', 1233);
      svgElemCopy.setAttribute('height', 729);

      svgElems.push(svgElemCopy);
    }

    // 印刷用要素を生成
    const printElem = document.createElement('div');
    printElem.id = 'print_area';

    // 検証221205　ここから

    // ページ要素を生成
    const pageElem = document.createElement('div');
    pageElem.className = 'print_page'; /* 改ページ用クラス */

    // imgタグを生成
    const imgElem = document.createElement('img');
    imgElem.src = SAVED_SVG_DIR + 'O60Rh2ENz3aDPMwv7Mjn_seating_profile.svg';
    imgElem.width = 1233;
    // imgElem.height = 200;

    printElem.appendChild(imgElem);

    // ページ要素にSVG画像を追加
    pageElem.appendChild(imgElem);

    // 印刷用要素にページ要素を追加
    printElem.appendChild(pageElem);

    // 検証221205　ここまで

    for (let i = 0; i < svgElems.length; i++) {
      // ページ要素を生成
      const pageElem = document.createElement('div');
      pageElem.className = 'print_page'; /* 改ページ用クラス */

      // ページ要素にSVG画像を追加
      pageElem.appendChild(svgElems[i]);

      // 印刷用要素にページ要素を追加
      printElem.appendChild(pageElem);
    }

    // 不要要素を非表示にする
    const headerElem = document.querySelector('header');
    const mainElem = document.querySelector('main');
    headerElem.classList.add('print_off');
    mainElem.classList.add('print_off');

    // 印刷用要素をページに追加
    const editorPageElem = document.getElementById('editor_page');
    editorPageElem.appendChild(printElem);

    imgElem.addEventListener('load', () => {
      // ブラウザ印刷機能呼び出し
      window.print();
    });
  };

  // PDF出力（React-PDF）
  const openDesignExportPage = (designType, designSerial) => {
    if (!window.confirm('最後に保存された時の状態でPDF出力されます。')) {
      return;
    }
    // window.alert(
    //   '別タブでダウンロードページが開きます。\nPDFファイルがダウンロードされるまでそのままお待ちください。\n※10秒ほどかかる場合がございます。'
    // );
    window.open(`${RouteDir}/saved_design_export_user/${designType}/${designSerial}`);
  };

  // 印刷機能呼び出し前の状態に復元
  const cleanUpPrintProcess = () => {
    console.log('cleanUpPrintProcess');
    // window.alert('test');
    // 印刷用要素を削除
    const printAreaElem = document.getElementById('print_area');
    console.log(printAreaElem);
    printAreaElem.remove();

    // 印刷時に非表示にした要素を再度表示
    const headerElem = document.querySelector('header');
    const mainElem = document.querySelector('main');
    headerElem.classList.remove('print_off');
    mainElem.classList.remove('print_off');
  };

  return (
    <DocumentMeta {...meta}>
      <div id="editor_page">
        {/* <PopupLogin className={popups.login ? 'show' : ''} onClick={() => close()} /> */}
        <header style={{ minWidth: '1300px' }}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (
                window.confirm('トップページに戻りますか？\nデザインを保存するには「保存する」ボタンを押してください。')
              ) {
                window.removeEventListener('beforeunload', showReloadAlert);
                dispatch(push(RouteDir));
              }
            }}
          >
            <img className="headerlogo" src={`${process.env.PUBLIC_URL}/images/logo_w.svg`} alt="" />
          </div>
          <h1>デザイン画面</h1>
          {/* <div className="delivery_date">
            <p>本日ご注文の場合　出荷予定日</p>
            <p>
              通常納期　<span>{deliverydate}</span>　出荷予定
            </p>
          </div> */}
          <button
            id="canvas_submit"
            style={{ backgroundColor: '#6698cb', margin: '0 10px' }}
            onClick={() => {
              // window.alert('ご利用ガイドを別画面で開きます。');
              window.open(`${RouteDir}/howto`);
            }}
          >
            ご利用ガイド
          </button>
          <div>
            この画面はPCでの動作を想定しています。{/* 入力内容が画面右側の商品画像に反映されます。 */}
            <br />
            画面サイズの調整には、ブラウザの拡大・縮小機能をご利用ください。
          </div>

          <button id="canvas_submit" onClick={() => svgSubmit()}>
            <span style={saveStyle}>保存する</span>
          </button>
          {!isTemplate && (
            <>
              <button className="mypage_ancer" onClick={() => ancerMypage()}>
                <span style={mypageStyle}>購入に進む</span>
              </button>
              {/* <div className="ancer_link">
            <a href={`${RouteDir}/guide`}>ご利用ガイド</a>
          </div> */}
              <button
                id="pdf_export_btn"
                onClick={() => openDesignExportPage(designType, designSerial) /* exportPdf() */}
              >
                <span>PDF出力</span>
              </button>
            </>
          )}
        </header>
        <main>
          <aside>
            <div id="tab_area">
              <ul>
                <li
                  id="sideBarTopTab"
                  className={showEditContent === 'basic_infomation' ? 'active' : ''}
                  onClick={() => {
                    changeEditContent('basic_infomation');
                    if (viewValue === 'invitation') {
                      changeSvgImage('invitation_svg');
                    } else if (viewValue === 'seating') {
                      changeSvgImage('seating_profile_svg');
                    }
                    endTableLayoutModes();
                  }}
                >
                  基本情報
                </li>
                {/**招待状の時のサイドバータブ群 */}
                {viewValue === 'invitation' && (
                  <>
                    <li
                      className={showEditContent === 'greetings' ? 'active' : ''}
                      onClick={() => {
                        changeEditContent('greetings');
                        changeSvgImage('invitation_svg');
                      }}
                    >
                      本状文章
                    </li>
                    <li
                      className={showEditContent === 'date_place' ? 'active' : ''}
                      onClick={() => {
                        changeEditContent('date_place');
                        changeSvgImage('invitation_svg');
                      }}
                    >
                      日時・場所
                    </li>
                    <li
                      className={showEditContent === 'envelope' ? 'active' : ''}
                      onClick={() => {
                        changeEditContent('envelope');
                        changeSvgImage('envelope_svg');
                      }}
                    >
                      封筒
                    </li>
                    <li
                      className={showEditContent === 'postcard_frontside' ? 'active' : ''}
                      onClick={() => {
                        changeEditContent('postcard_frontside');
                        changeSvgImage('postcard_frontside_svg');
                      }}
                    >
                      返信はがき
                      <br />
                      （表面）
                    </li>
                    <li
                      className={showEditContent === 'postcard_backside' ? 'active' : ''}
                      onClick={() => {
                        changeEditContent('postcard_backside');
                        changeSvgImage('postcard_backside_svg');
                      }}
                    >
                      返信はがき
                      <br />
                      （裏面）
                    </li>
                    <li
                      className={showEditContent === 'tag' ? 'active' : ''}
                      onClick={() => {
                        changeEditContent('tag');
                        changeSvgImage('fusen_svg');
                      }}
                    >
                      付箋
                    </li>
                  </>
                )}
                {/**席次表の時のサイドバータブ群 */}
                {viewValue === 'seating' && (
                  <>
                    <li
                      className={showEditContent === 'profile_photo' ? 'active' : ''}
                      onClick={() => {
                        changeEditContent('profile_photo');
                        changeSvgImage('seating_profile_svg');
                        endTableLayoutModes();
                      }}
                    >
                      写真を登録
                    </li>
                    <li
                      className={showEditContent === 'profile_interview' ? 'active' : ''}
                      onClick={() => {
                        changeEditContent('profile_interview');
                        changeSvgImage('seating_profile_svg');
                        endTableLayoutModes();
                      }}
                    >
                      プロフィール＆インタビュー
                    </li>
                    <li
                      className={showEditContent === 'seating_greeting' ? 'active' : ''}
                      onClick={() => {
                        changeEditContent('seating_greeting');
                        changeSvgImage('seating_profile_svg');
                        endTableLayoutModes();
                      }}
                    >
                      ご挨拶文
                    </li>
                    <li
                      className={showEditContent === 'menu' ? 'active' : ''}
                      onClick={() => {
                        changeEditContent('menu');
                        changeSvgImage('seating_profile_svg');
                        endTableLayoutModes();
                      }}
                    >
                      メニュー
                    </li>
                    <li
                      className={showEditContent === 'seating_layout' ? 'active' : ''}
                      onClick={() => {
                        changeEditContent('seating_layout');
                        changeSvgImage('seating_seat_svg');
                      }}
                    >
                      ＜席次表＞
                      <br />
                      テーブル
                      <br />
                      レイアウト
                    </li>
                    <li
                      className={showEditContent === 'seating_seat' ? 'active' : ''}
                      onClick={() => {
                        changeEditContent('seating_seat');
                        changeSvgImage('seating_seat_svg');
                        endTableLayoutModes();
                      }}
                    >
                      ＜席次表＞
                      <br />
                      ゲストを配席
                    </li>
                    <li
                      className={showEditContent === 'seating_additional_comment' ? 'active' : ''}
                      onClick={() => {
                        changeEditContent('seating_additional_comment');
                        changeSvgImage('seating_seat_svg');
                        endTableLayoutModes();
                      }}
                    >
                      ＜席次表＞
                      <br />
                      追記文
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div id="edit_area">
              <form id="edit_form" onSubmit={(e) => e.preventDefault()}>
                <div
                  className={
                    'basic_infomation_edit_area edit_content_box' +
                    (showEditContent === 'basic_infomation' ? ' show' : '')
                  }
                >
                  <EditorBasicInfomation
                    viewValue={viewValue}
                    groomFamilyName={groomFamilyName}
                    onChangeGroomFamilyName={(event) => inputCheck(event, 'inputGroomFamilyName', 'text')}
                    groomFirstName={groomFirstName}
                    onChangeGroomFirstName={(event) => inputCheck(event, 'inputGroomFirstName', 'text')}
                    groomFamilyNameEn={groomFamilyNameEn}
                    onChangeGroomFamilyNameEn={(event) => inputCheck(event, 'inputGroomFamilyNameEn', 'text')}
                    groomFirstNameEn={groomFirstNameEn}
                    onChangeGroomFirstNameEn={(event) => inputCheck(event, 'inputGroomFirstNameEn', 'text')}
                    brideFamilyName={brideFamilyName}
                    onChangeBrideFamilyName={(event) => inputCheck(event, 'inputBrideFamilyName', 'text')}
                    brideFirstName={brideFirstName}
                    onChangeBrideFirstName={(event) => inputCheck(event, 'inputBrideFirstName', 'text')}
                    brideFamilyNameEn={brideFamilyNameEn}
                    onChangeBrideFamilyNameEn={(event) => inputCheck(event, 'inputBrideFamilyNameEn', 'text')}
                    brideFirstNameEn={brideFirstNameEn}
                    onChangeBrideFirstNameEn={(event) => inputCheck(event, 'inputBrideFirstNameEn', 'text')}
                    startDate={startDate}
                    onFocusWeddingDay={(event) => {
                      setDatePickerName(event.target.name);
                    }}
                    onChangeWeddingDay={(date) => handleChange(date)}
                    groomParentFamilyName={groomParentFamilyName}
                    onChangeGroomParentFamilyName={(event) => inputCheck(event, 'inputGroomParentFamilyName', 'text')}
                    groomParentFirstName={groomParentFirstName}
                    onChangeGroomParentFirstName={(event) => inputCheck(event, 'inputGroomParentFirstName', 'text')}
                    brideParentFamilyName={brideParentFamilyName}
                    onChangeBrideParentFamilyName={(event) => inputCheck(event, 'inputBrideParentFamilyName', 'text')}
                    brideParentFirstName={brideParentFirstName}
                    onChangeBrideParentFirstName={(event) => inputCheck(event, 'inputBrideParentFirstName', 'text')}
                  />
                </div>
                {/**招待状の時のサイドバータブ群 */}
                {viewValue === 'invitation' && (
                  <>
                    <div
                      className={
                        'greetings_edit_area edit_content_box' + (showEditContent === 'greetings' ? ' show' : '')
                      }
                    >
                      <EditorGreetings
                        ChangePostDatepre={() => {
                          ChangePostDate(-1);
                        }}
                        ChangePostDatenext={() => {
                          ChangePostDate(1);
                        }}
                        postdateValue={postdateValue}
                        greetings={greetings}
                        onChange={(event) => inputCheck(event, 'inputGreetings', 'textarea')}
                      />
                    </div>
                    <div
                      className={
                        'date_place_edit_area edit_content_box' + (showEditContent === 'date_place' ? ' show' : '')
                      }
                    >
                      <EditorDatePlace
                        time1Name={time1Name}
                        onChangeName1={(event) => inputCheck(event, 'inputTime1Name', 'text')}
                        disabledTime1={time1Disabled}
                        time1Time={time1Time}
                        onChangeTime1={(event) => inputCheck(event, 'inputTime1Time', 'text')}
                        disabledTime1Value={time1DisabledValue}
                        onChangeDisable1={(event) => {
                          DisabledMoveChange(event, 'changeTime1Disabled', 'time', 17);
                        }}
                        time2Name={time2Name}
                        onChangeName2={(event) => inputCheck(event, 'inputTime2Name', 'text')}
                        disabledTime2={time2Disabled}
                        time2Time={time2Time}
                        onChangeTime2={(event) => inputCheck(event, 'inputTime2Time', 'text')}
                        disabledTime2Value={time2DisabledValue}
                        onChangeDisable2={(event) => {
                          DisabledMoveChange(event, 'changeTime2Disabled', 'time', 17);
                        }}
                        time3Name={time3Name}
                        onChangeName3={(event) => inputCheck(event, 'inputTime3Name', 'text')}
                        disabledTime3={time3Disabled}
                        time3Time={time3Time}
                        onChangeTime3={(event) => inputCheck(event, 'inputTime3Time', 'text')}
                        disabledTime3Value={time3DisabledValue}
                        onChangeDisable3={(event) => {
                          DisabledMoveChange(event, 'changeTime3Disabled', 'time', 17);
                        }}
                        venueName={venueName}
                        onChangeVenueName={(event) => inputCheck(event, 'inputVenueName', 'textarea')}
                        venueInfo={venueInfo}
                        onChangeVenueInfo={(event) => inputCheck(event, 'inputVenueInfo', 'textarea')}
                        membershipFee={membershipFee}
                        onChangeMembershipFee={(event) => inputCheck(event, 'inputMembershipFee', 'text')}
                        disabledmembershipFee={membershipFeeDisabled}
                        disabledmembershipFeeValue={membershipFeeDisabledValue}
                        onChangeDisableMembershipFee={(event) => {
                          DisabledChange(event, 'changeMembershipFeeDisabled', 'membership_fee');
                        }}
                        message1={message1}
                        onChangeMessage1={(event) => inputCheck(event, 'inputMessage1', 'textarea')}
                        disabledMessage1={message1Disabled}
                        disabledMessage1Value={message1DisabledValue}
                        onChangeDisableMessage1={(event) => {
                          DisabledChange(event, 'changeMessage1Disabled', 'message_1');
                        }}
                        message2={message2}
                        onChangeMessage2={(event) => inputCheck(event, 'inputMessage2', 'textarea')}
                        disabledMessage2={message2Disabled}
                        disabledMessage2Value={message2DisabledValue}
                        onChangeDisableMessage2={(event) => {
                          DisabledChange(event, 'changeMessage2Disabled', 'message_2');
                        }}
                        message3={message3}
                        onChangeMessage3={(event) => inputCheck(event, 'inputMessage3', 'textarea')}
                        disabledMessage3={message3Disabled}
                        disabledMessage3Value={message3DisabledValue}
                        onChangeDisableMessage3={(event) => {
                          DisabledChange(event, 'changeMessage3Disabled', 'message_3');
                        }}
                      />
                    </div>
                    {/* 封筒 */}
                    <div
                      className={
                        'envelope_edit_area edit_content_box' + (showEditContent === 'envelope' ? ' show' : '')
                      }
                    >
                      <EditorEnvelope
                        descriptionMethodType={envelopeDescriptionMethodType}
                        onChangeDescriptionMethodType={inputEnvelopeDescriptionMethodType}
                        deliveryAddressType={envelopeDeliveryAddressType}
                        onChangeDeliveryAddressType={inputEnvelopeDeliveryAddressType}
                        // 差出人：新郎新婦名, 記載方法：1つの住所で連名
                        postalCode={envelopePostalCode}
                        onChangePostalCode={(event) => inputCheck(event, 'inputEnvelopePostalCode', 'text')}
                        address={envelopeAddress}
                        onChangeAddress={(event) => inputCheck(event, 'inputEnvelopeAddress', 'textarea')}
                        showMaidenNameType={envelopeShowMaidenNameType}
                        onChangeShowMaidenNameType={inputEnvelopeShowMaidenNameType}
                        // 差出人：新郎新婦名, 記載方法：新郎新婦別々に記載
                        groomPostalCode={envelopeGroomPostalCode}
                        onChangeGroomPostalCode={(event) => inputCheck(event, 'inputEnvelopeGroomPostalCode', 'text')}
                        groomAddress={envelopeGroomAddress}
                        onChangeGroomAddress={(event) => inputCheck(event, 'inputEnvelopeGroomAddress', 'textarea')}
                        bridePostalCode={envelopeBridePostalCode}
                        onChangeBridePostalCode={(event) => inputCheck(event, 'inputEnvelopeBridePostalCode', 'text')}
                        brideAddress={envelopeBrideAddress}
                        onChangeBrideAddress={(event) => inputCheck(event, 'inputEnvelopeBrideAddress', 'textarea')}
                        groomParentPostalCode={envelopeGroomParentPostalCode}
                        onChangeGroomParentPostalCode={(event) =>
                          inputCheck(event, 'inputEnvelopeGroomParentPostalCode', 'text')
                        }
                        groomParentAddress={envelopeGroomParentAddress}
                        onChangeGroomParentAddress={(event) =>
                          inputCheck(event, 'inputEnvelopeGroomParentAddress', 'textarea')
                        }
                        brideParentPostalCode={envelopeBrideParentPostalCode}
                        onChangeBrideParentPostalCode={(event) =>
                          inputCheck(event, 'inputEnvelopeBrideParentPostalCode', 'text')
                        }
                        brideParentAddress={envelopeBrideParentAddress}
                        onChangeBrideParentAddress={(event) =>
                          inputCheck(event, 'inputEnvelopeBrideParentAddress', 'textarea')
                        }
                      />
                    </div>
                    <div
                      className={
                        'postcard_frontside_edit_area edit_content_box' +
                        (showEditContent === 'postcard_frontside' ? ' show' : '')
                      }
                    >
                      <EditorPostcardFrontside
                        deliveryAddressType={postcardDeliveryAddressType}
                        onChangeDeliveryAddressType={inputPostcardDeliveryAddressType}
                        descriptionMethodType={postcardDescriptionMethodType}
                        onChangeDescriptionMethodType={inputPostcardDescriptionMethodType}
                        postalCode={postcardPostalCode}
                        onChangePostalCode={(event) =>
                          changePostcardPostalCodeFunc(event, 'inputPostcardPostalCode', 'text', 'postal_code_')
                        }
                        address={postcardAddress}
                        onChangeAddress={(event) => inputCheck(event, 'inputPostcardAddress', 'textarea')}
                        groomPostalCode={postcardGroomPostalCode}
                        onChangeGroomPostalCode={(event) =>
                          changePostcardPostalCodeFunc(
                            event,
                            'inputPostcardGroomPostalCode',
                            'text',
                            'groom_postal_code_'
                          )
                        }
                        groomAddress={postcardGroomAddress}
                        onChangeGroomAddress={(event) => inputCheck(event, 'inputPostcardGroomAddress', 'textarea')}
                        bridePostalCode={postcardBridePostalCode}
                        onChangeBridePostalCode={(event) =>
                          changePostcardPostalCodeFunc(
                            event,
                            'inputPostcardBridePostalCode',
                            'text',
                            'bride_postal_code_'
                          )
                        }
                        brideAddress={postcardBrideAddress}
                        onChangeBrideAddress={(event) => inputCheck(event, 'inputPostcardBrideAddress', 'textarea')}
                        groomParentPostalCode={postcardGroomParentPostalCode}
                        onChangeGroomParentPostalCode={(event) =>
                          changePostcardPostalCodeFunc(
                            event,
                            'inputPostcardGroomParentPostalCode',
                            'text',
                            'groom_parent_postal_code_'
                          )
                        }
                        groomParentAddress={postcardGroomParentAddress}
                        onChangeGroomParentAddress={(event) =>
                          inputCheck(event, 'inputPostcardGroomParentAddress', 'textarea')
                        }
                        brideParentPostalCode={postcardBrideParentPostalCode}
                        onChangeBrideParentPostalCode={(event) =>
                          changePostcardPostalCodeFunc(
                            event,
                            'inputPostcardBrideParentPostalCode',
                            'text',
                            'bride_parent_postal_code_'
                          )
                        }
                        brideParentAddress={postcardBrideParentAddress}
                        onChangeBrideParentAddress={(event) =>
                          inputCheck(event, 'inputPostcardBrideParentAddress', 'textarea')
                        }
                        showMaidenNameType={postcardShowMaidenNameType}
                        onChangeShowMaidenNameType={inputPostcardShowMaidenNameType}
                      />
                    </div>
                    <div
                      className={
                        'postcard_backside_edit_area edit_content_box' +
                        (showEditContent === 'postcard_backside' ? ' show' : '')
                      }
                    >
                      <EditorPostcardBackside
                        englishNotation={englishNotation}
                        onChangeEnglishNotation={inputEnglishNotation}
                        NOANotation={NOANotation}
                        onChangeNOANotation={inputNOANotation}
                        messageNotation={messageNotation}
                        onChangeMessageNotation={inputMessageNotation}
                        allergyNotation={allergyNotation}
                        onChangeAllergyNotation={inputAllergyNotation}
                        courtesyBusNotation={courtesyBusNotation}
                        onChangeCourtesyBusNotation={inputCourtesyBusNotation}
                      />
                    </div>
                    <div className={'tag_edit_area edit_content_box' + (showEditContent === 'tag' ? ' show' : '')}>
                      <EditorTag
                        ceremonyTag={ceremonyTag}
                        onChangeCeremonyTag={inputCeremonyTag}
                        ceremonyTagNumber={ceremonyTagNumber}
                        onChangeCeremonyTagNumber={inputCeremonyTagNumber}
                        ceremonyTagHour={ceremonyTagHour}
                        onChangeCeremonyTagHour={(event) => {
                          inputCheck(event, 'inputCeremonyTagHour', 'text');
                        }}
                        ceremonyTagMinutes={ceremonyTagMinutes}
                        onChangeCeremonyTagMinutes={(event) => {
                          inputCheck(event, 'inputCeremonyTagMinutes', 'text');
                        }}
                        courtesyBusTag={courtesyBusTag}
                        onChangeCourtesyBusTag={inputCourtesyBusTag}
                        courtesyBusTagNumber={courtesyBusTagNumber}
                        onChangeCourtesyBusTagNumber={inputCourtesyBusTagNumber}
                        courtesyBusTagHour={courtesyBusTagHour}
                        onChangeCourtesyBusTagHour={(event) => {
                          inputCheck(event, 'inputCourtesyBusTagHour', 'text');
                        }}
                        courtesyBusTagMinutes={courtesyBusTagMinutes}
                        onChangeCourtesyBusTagMinutes={(event) => {
                          inputCheck(event, 'inputCourtesyBusTagMinutes', 'text');
                        }}
                        courtesyBusTagPlace={courtesyBusTagPlace}
                        onChangeCourtesyBusTagPlace={(event) => {
                          inputCheck(event, 'inputCourtesyBusTagPlace', 'text');
                        }}
                        entertainmentTag={entertainmentTag}
                        onChangeEntertainmentTag={inputEntertainmentTag}
                        entertainmentTagNumber={entertainmentTagNumber}
                        onChangeEntertainmentTagNumber={inputEntertainmentTagNumber}
                        groupPhotoTag={groupPhotoTag}
                        onChangeGroupPhotoTag={inputGroupPhotoTag}
                        groupPhotoTagNumber={groupPhotoTagNumber}
                        onChangeGroupPhotoTagNumber={inputGroupPhotoTagNumber}
                        groupPhotoTagHour={groupPhotoTagHour}
                        onChangeGroupPhotoTagHour={(event) => {
                          inputCheck(event, 'inputGroupPhotoTagHour', 'text');
                        }}
                        groupPhotoTagMinutes={groupPhotoTagMinutes}
                        onChangeGroupPhotoTagMinutes={(event) => {
                          inputCheck(event, 'inputGroupPhotoTagMinutes', 'text');
                        }}
                        toastTag={toastTag}
                        onChangeToastTag={inputToastTag}
                        toastTagNumber={toastTagNumber}
                        onChangeToastTagNumber={inputToastTagNumber}
                        receptionTag={receptionTag}
                        onChangeReceptionTag={inputReceptionTag}
                        receptionTagNumber={receptionTagNumber}
                        onChangeReceptionTagNumber={inputReceptionTagNumber}
                        receptionTagHour={receptionTagHour}
                        onChangeReceptionTagHour={(event) => {
                          inputCheck(event, 'inputReceptionTagHour', 'text');
                        }}
                        receptionTagMinutes={receptionTagMinutes}
                        onChangeReceptionTagMinutes={(event) => {
                          inputCheck(event, 'inputReceptionTagMinutes', 'text');
                        }}
                        speechTag={speechTag}
                        onChangeSpeechTag={inputSpeechTag}
                        speechTagNumber={speechTagNumber}
                        onChangeSpeechTagNumber={inputSpeechTagNumber}
                        congratulationsTag={congratulationsTag}
                        onChangecongratulationsTag={inputCongratulationsTag}
                        congratulationsTagNumber={congratulationsTagNumber}
                        onChangecongratulationsTagNumber={inputCongratulationsTagNumber}
                        banzaiTag={banzaiTag}
                        onChangebanzaiTag={inputBanzaiTag}
                        banzaiTagNumber={banzaiTagNumber}
                        onChangebanzaiTagNumber={inputBanzaiTagNumber}
                      />
                    </div>
                  </>
                )}
                {viewValue === 'seating' && (
                  <>
                    <div
                      className={
                        'profile_photo_edit_area edit_content_box' +
                        (showEditContent === 'profile_photo' ? ' show' : '')
                      }
                    >
                      <EditorProfilePhoto />
                    </div>
                    <div
                      id="profile_interview_edit_area"
                      className={
                        'profile_interview_edit_area edit_content_box' +
                        (showEditContent === 'profile_interview' ? ' show' : '')
                      }
                    >
                      <EditorProfileInterview
                        mouseupFunc={moveContentReDrow}
                        prof1Q={prof1Q}
                        onChangeprof1Q={(event) =>
                          moveContentInputCheck(event, 'inputProf1Q', 'text', 'profile_group', 'prof_q_')
                        }
                        prof1Agroom={prof1Agroom}
                        onChangeprof1Agroom={(event) =>
                          moveContentInputCheck(event, 'inputProf1Agroom', 'text', 'profile_group', 'prof_groom_a_')
                        }
                        prof1Abride={prof1Abride}
                        onChangeprof1Abride={(event) =>
                          moveContentInputCheck(event, 'inputProf1Abride', 'text', 'profile_group', 'prof_bride_a_')
                        }
                        prof2Q={prof2Q}
                        onChangeprof2Q={(event) =>
                          moveContentInputCheck(event, 'inputProf2Q', 'text', 'profile_group', 'prof_q_')
                        }
                        prof2Agroom={prof2Agroom}
                        onChangeprof2Agroom={(event) =>
                          moveContentInputCheck(event, 'inputProf2Agroom', 'text', 'profile_group', 'prof_groom_a_')
                        }
                        prof2Abride={prof2Abride}
                        onChangeprof2Abride={(event) =>
                          moveContentInputCheck(event, 'inputProf2Abride', 'text', 'profile_group', 'prof_bride_a_')
                        }
                        prof3Q={prof3Q}
                        onChangeprof3Q={(event) =>
                          moveContentInputCheck(event, 'inputProf3Q', 'text', 'profile_group', 'prof_q_')
                        }
                        prof3Agroom={prof3Agroom}
                        onChangeprof3Agroom={(event) =>
                          moveContentInputCheck(event, 'inputProf3Agroom', 'text', 'profile_group', 'prof_groom_a_')
                        }
                        prof3Abride={prof3Abride}
                        onChangeprof3Abride={(event) =>
                          moveContentInputCheck(event, 'inputProf3Abride', 'text', 'profile_group', 'prof_bride_a_')
                        }
                        prof4Q={prof4Q}
                        onChangeprof4Q={(event) =>
                          moveContentInputCheck(event, 'inputProf4Q', 'text', 'profile_group', 'prof_q_')
                        }
                        prof4Agroom={prof4Agroom}
                        onChangeprof4Agroom={(event) =>
                          moveContentInputCheck(event, 'inputProf4Agroom', 'text', 'profile_group', 'prof_groom_a_')
                        }
                        prof4Abride={prof4Abride}
                        onChangeprof4Abride={(event) =>
                          moveContentInputCheck(event, 'inputProf4Abride', 'text', 'profile_group', 'prof_bride_a_')
                        }
                        interview1Q={interview1Q}
                        onChangeinterview1Q={(event) =>
                          moveContentInputCheck(event, 'inputInterview1Q', 'text', 'interview_group', 'interview_q_')
                        }
                        interview1Agroom={interview1Agroom}
                        onChangeinterview1Agroom={(event) =>
                          moveContentInputCheck(
                            event,
                            'inputInterview1Agroom',
                            'text',
                            'interview_group',
                            'interview_groom_a_'
                          )
                        }
                        interview1Abride={interview1Abride}
                        onChangeinterview1Abride={(event) =>
                          moveContentInputCheck(
                            event,
                            'inputInterview1Abride',
                            'text',
                            'interview_group',
                            'interview_bride_a_'
                          )
                        }
                        interview2Q={interview2Q}
                        onChangeinterview2Q={(event) =>
                          moveContentInputCheck(event, 'inputInterview2Q', 'text', 'interview_group', 'interview_q_')
                        }
                        interview2Agroom={interview2Agroom}
                        onChangeinterview2Agroom={(event) =>
                          moveContentInputCheck(
                            event,
                            'inputInterview2Agroom',
                            'text',
                            'interview_group',
                            'interview_groom_a_'
                          )
                        }
                        interview2Abride={interview2Abride}
                        onChangeinterview2Abride={(event) =>
                          moveContentInputCheck(
                            event,
                            'inputInterview2Abride',
                            'text',
                            'interview_group',
                            'interview_bride_a_'
                          )
                        }
                        interview3Q={interview3Q}
                        onChangeinterview3Q={(event) =>
                          moveContentInputCheck(event, 'inputInterview3Q', 'text', 'interview_group', 'interview_q_')
                        }
                        interview3Agroom={interview3Agroom}
                        onChangeinterview3Agroom={(event) =>
                          moveContentInputCheck(
                            event,
                            'inputInterview3Agroom',
                            'text',
                            'interview_group',
                            'interview_groom_a_'
                          )
                        }
                        interview3Abride={interview3Abride}
                        onChangeinterview3Abride={(event) =>
                          moveContentInputCheck(
                            event,
                            'inputInterview3Abride',
                            'text',
                            'interview_group',
                            'interview_bride_a_'
                          )
                        }
                        interview4Q={interview4Q}
                        onChangeinterview4Q={(event) =>
                          moveContentInputCheck(event, 'inputInterview4Q', 'text', 'interview_group', 'interview_q_')
                        }
                        interview4Agroom={interview4Agroom}
                        onChangeinterview4Agroom={(event) =>
                          moveContentInputCheck(
                            event,
                            'inputInterview4Agroom',
                            'text',
                            'interview_group',
                            'interview_groom_a_'
                          )
                        }
                        interview4Abride={interview4Abride}
                        onChangeinterview4Abride={(event) =>
                          moveContentInputCheck(
                            event,
                            'inputInterview4Abride',
                            'text',
                            'interview_group',
                            'interview_bride_a_'
                          )
                        }
                      />
                    </div>
                    <div
                      id="seating_greeting_edit_area"
                      className={
                        'seating_greeting_edit_area edit_content_box' +
                        (showEditContent === 'seating_greeting' ? ' show' : '')
                      }
                    >
                      <EditorSeatingGreeting
                        seatingGreeting={seatingGreeting}
                        onChangeSeatingGreeting={(event) => {
                          const maxLineNum = 12;
                          if (validateTextLineNum(event.target.value, maxLineNum)) {
                            inputCheck(event, 'inputSeatingGreeting', 'textarea');
                          }
                        }}
                        venueName={venueName}
                        onChangeVenueName={(event) => inputCheck(event, 'inputVenueName', 'text')}
                        profileAddressGuide={profileAddressGuide}
                        onChangeProfileAddressGuide={(event) => {
                          // 最大行数チェック
                          const maxLineNum = 4;
                          if (validateTextLineNum(event.target.value, maxLineNum)) {
                            inputCheck(event, 'inputProfileAddressGuide', 'textarea');
                          }
                        }}
                      />
                    </div>
                    <div
                      id="menu_edit_area"
                      className={'menu_edit_area edit_content_box' + (showEditContent === 'menu' ? ' show' : '')}
                    >
                      <EditorMenu
                        isFormVisible={showEditContent === 'menu'}
                        moveElementFunc={(targetId, wrapId) => {
                          moveElementFunc(targetId, wrapId);
                        }}
                        mouseupFunc={moveContentReDrow}
                        menuName1={menuName1}
                        onChangeMenuName1={(event) =>
                          moveContentInputCheck(event, 'inputMenuName1', 'textarea', 'menu_group', 'menu_')
                        }
                        menuName2={menuName2}
                        onChangeMenuName2={(event) =>
                          moveContentInputCheck(event, 'inputMenuName2', 'textarea', 'menu_group', 'menu_')
                        }
                        menuName3={menuName3}
                        onChangeMenuName3={(event) =>
                          moveContentInputCheck(event, 'inputMenuName3', 'textarea', 'menu_group', 'menu_')
                        }
                        menuName4={menuName4}
                        onChangeMenuName4={(event) =>
                          moveContentInputCheck(event, 'inputMenuName4', 'textarea', 'menu_group', 'menu_')
                        }
                        menuName5={menuName5}
                        onChangeMenuName5={(event) =>
                          moveContentInputCheck(event, 'inputMenuName5', 'textarea', 'menu_group', 'menu_')
                        }
                        menuName6={menuName6}
                        onChangeMenuName6={(event) =>
                          moveContentInputCheck(event, 'inputMenuName6', 'textarea', 'menu_group', 'menu_')
                        }
                        menuName7={menuName7}
                        onChangeMenuName7={(event) =>
                          moveContentInputCheck(event, 'inputMenuName7', 'textarea', 'menu_group', 'menu_')
                        }
                        menuName8={menuName8}
                        onChangeMenuName8={(event) =>
                          moveContentInputCheck(event, 'inputMenuName8', 'textarea', 'menu_group', 'menu_')
                        }
                        menuName9={menuName9}
                        onChangeMenuName9={(event) =>
                          moveContentInputCheck(event, 'inputMenuName9', 'textarea', 'menu_group', 'menu_')
                        }
                        menuName10={menuName10}
                        onChangeMenuName10={(event) =>
                          moveContentInputCheck(event, 'inputMenuName10', 'textarea', 'menu_group', 'menu_')
                        }
                      />
                    </div>
                    <div
                      id="seating_layout_edit_area"
                      className={
                        'seating_layout_edit_area edit_content_box' +
                        (showEditContent === 'seating_layout' ? ' show' : '')
                      }
                    >
                      <EditorSeatingLayout
                        isTemplate={isTemplate}
                        isSvgLoaded={isSvgLoaded}
                        seatingLayout={seatingLayout}
                        setSeatingLayout={setSeatingLayout}
                        onChangeSeatingLayout={inputSeatingLayout}
                        isTableMoveMode={isTableMoveMode}
                        setIsTableMoveMode={setIsTableMoveMode}
                        isTableDeleteMode={isTableDeleteMode}
                        setIsTableDeleteMode={setIsTableDeleteMode}
                        isTableNameEditMode={isTableNameEditMode}
                        setIsTableNameEditMode={setIsTableNameEditMode}
                        fontData={fontData}
                      />
                    </div>
                    <div
                      id="seating_seat_edit_area"
                      className={
                        'seating_seat_edit_area edit_content_box' + (showEditContent === 'seating_seat' ? ' show' : '')
                      }
                    >
                      <EditorSeatingSeat
                        isFormVisible={showEditContent === 'seating_seat'}
                        seatingLayout={seatingLayout}
                        onChangeSeatingLayout={inputSeatingLayout}
                        EditerFuncToUseForlogin={EditerFuncToUseForlogin}
                        memberInfo={memberInfo}
                        fontData={fontData}
                        seatingGuestList={seatingGuestList}
                      />
                    </div>
                    <div
                      id="seating_additional_comment_edit_area"
                      className={
                        'seating_seat_edit_area edit_content_box' +
                        (showEditContent === 'seating_additional_comment' ? ' show' : '')
                      }
                    >
                      <EditorSeatingAdditionalComment
                        seatingAdditionalComment={seatingAdditionalComment}
                        onChangeSeatingAdditionalComment={(event) =>
                          inputCheck(event, 'inputSeatingAdditionalComment', 'text')
                        }
                      />
                    </div>
                  </>
                )}
              </form>
            </div>
          </aside>
          <div id="preview_area">
            <div id="canvas"></div>
            <div id="image_editor_area">
              <img id="iamge_editor_element" className="image_move_element" src="" alt="" />
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={'100%'} height={'100%'}>
                <g className="outline_group"></g>
              </svg>
              <div id="image_size_button_TL" className="image_size_button">
                <div className="button_wrap image_size_change_btn">
                  <img src={`${process.env.PUBLIC_URL}/images/top_left.svg`} alt="" />
                </div>
              </div>
              <div id="image_size_button_TR" className="image_size_button">
                <div className="button_wrap image_size_change_btn">
                  <img src={`${process.env.PUBLIC_URL}/images/top_right.svg`} alt="" />
                </div>
              </div>
              <div id="image_size_button_BR" className="image_size_button">
                <div className="button_wrap image_size_change_btn">
                  <img src={`${process.env.PUBLIC_URL}/images/bottom_right.svg`} alt="" />
                </div>
              </div>
              <div id="image_size_button_BL" className="image_size_button">
                <div className="button_wrap image_size_change_btn">
                  <img src={`${process.env.PUBLIC_URL}/images/bottom_left.svg`} alt="" />
                </div>
              </div>
            </div>
          </div>
        </main>
        <Loading />
      </div>
    </DocumentMeta>
  );
};

export default Editor;
