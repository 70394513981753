import React from 'react';
import { RouteDir } from '../../common';

// 注文詳細：招待状・席次表デザインPDF出力ボタン
const OrderDetailDesignPdfExportBtn = (props) => {
  return (
    <button
      // id="back_btn"
      onClick={() =>
        window.open(
          `${RouteDir}/admin/orders/saved_design_export/${props.orderNumber}/${props.designType}/${props.designSerial}`
        )
      }
    >
      {props.btnTitle}
    </button>
  );
};

export default OrderDetailDesignPdfExportBtn;
