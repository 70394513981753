import React, { useEffect, useState, useCallback } from 'react';
import { EditorTextInput, EditorTextArea } from '../UIkit';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);

const EditorMenu = (props) => {
  const [lisArray, setLisArray] = useState([1, 2, 3, 4, 5]);
  const [menuInterval, setMenuInterval] = useState(45);

  //メニュー間隔スライダーの値変更
  const inputMenuInterval = useCallback(
    (event) => {
      setMenuInterval(event.target.value);
    },
    [setMenuInterval]
  );

  //メニュー間隔スライダー変更時の処理
  const changeMenuIntervalFunc = (event) => {
    //間隔の値を変える処理
    inputMenuInterval(event);
    //SVG画像からmenu要素群を取得
    const menuSVGs = getMenuSVGs();

    //menuSVGsの数を取得
    const menuSVGsLength = menuSVGs.length;

    //それぞれの要素のmatrixYの値を変更する。
    for (let i = 1; i < menuSVGsLength; i++) {
      const MenuEleMatrix = menuSVGs[i].getAttribute('transform');
      const MenuEleMatrixArray = MenuEleMatrix.split(',');
      const newY = menuInterval * i;
      const newMatrix =
        MenuEleMatrixArray[0] +
        ',' +
        MenuEleMatrixArray[1] +
        ',' +
        MenuEleMatrixArray[2] +
        ',' +
        MenuEleMatrixArray[3] +
        ',' +
        MenuEleMatrixArray[4] +
        ',' +
        newY +
        ')';
      menuSVGs[i].setAttribute('transform', newMatrix);
    }
  };

  //小さい要素から編集用要素に切り替える処理
  const toggleEle = (event) => {
    const target = event.target.closest('.show');
    //クリックされた要素のshowクラスを削除
    target.classList.toggle('show');

    //非表示になっている兄弟要素を表示
    if (target.className === 'edit_move_box') {
      const showTarget = target.nextElementSibling;
      showTarget.classList.toggle('show');
    } else if (target.className === 'edit_box') {
      const showTarget = target.previousElementSibling;
      showTarget.classList.toggle('show');
    }
  };

  //lisの数を変更する処理
  const addInputMenuBox = () => {
    //liLengthの数を取得
    const lisLength = lisArray.length;
    const newLis = () => {
      const res = [];
      for (let i = 0; i < lisLength + 1; i++) {
        res.push(i + 1);
      }
      return res;
    };
    setLisArray(newLis());
    setTimeout(() => {
      // props.moveElementFunc('menu_group', 'edit_area');  /* 順番入れ替え用関数 */
    }, 500);
  };
  //SVG内のメニュー群を取得する処理
  const getMenuSVGs = () => {
    //SVG画像の取得
    const seatingSVG = document.getElementById('seating_profile_svg');
    //SVG画像の中からメニュー描画要素群を取得
    const menuSVGs = seatingSVG.querySelectorAll('.menu_ele');
    return menuSVGs;
  };

  const getMenuGroup = () => {
    //SVG画像の取得
    const seatingSVG = document.getElementById('seating_profile_svg');
    const SVGmenuGroup = seatingSVG.getElementById('menu_group');
    return SVGmenuGroup;
  };

  //SVG内にメニュー要素を追加する処理
  const addSVGMenuEle = () => {
    const menuSVGs = getMenuSVGs();
    //メニュー要素群から一番最後の要素と最後から二番目の要素を取得
    const menuSVGsLength = menuSVGs.length; //menuSVGsの数
    const lastMenuEle = menuSVGs[menuSVGsLength - 1]; //最後の要素
    const secondLastMenuEle = menuSVGs[menuSVGsLength - 2]; //最後から二番目の要素

    //最後の要素を複製
    const newEle = menuSVGs[menuSVGsLength - 1].cloneNode(true);

    //要素の間隔を計算
    const lastMenuEleMatrix = lastMenuEle.getAttribute('transform');
    const lastMenuEleMatrixArray = lastMenuEleMatrix.split(',');
    const lastMenuEleY = Number(lastMenuEleMatrixArray[5].replace(')', ''));

    const secondLastMenuEleMatrix = secondLastMenuEle.getAttribute('transform');
    const secondLastMenuEleMatrixArray = secondLastMenuEleMatrix.split(',');
    const secondLastMenuEleY = Number(secondLastMenuEleMatrixArray[5].replace(')', ''));

    const intervalY = lastMenuEleY - secondLastMenuEleY; //要素同士の間隔
    const newY = lastMenuEleY + intervalY;

    //複製した要素のMatrixの値とクラス名を変更
    const newMatrix =
      lastMenuEleMatrixArray[0] +
      ',' +
      lastMenuEleMatrixArray[1] +
      ',' +
      lastMenuEleMatrixArray[2] +
      ',' +
      lastMenuEleMatrixArray[3] +
      ',' +
      lastMenuEleMatrixArray[4] +
      ',' +
      newY +
      ')';
    newEle.setAttribute('transform', newMatrix);
    newEle.setAttribute('class', 'menu_ele menu_' + (menuSVGsLength + 1));

    //SVGメニュグループを取得
    const SVGmenuGroup = getMenuGroup();
    //メニューグループの最後に複製要素を追加
    SVGmenuGroup.appendChild(newEle);
  };
  //メニューを追加するするボタンを押した時の処理
  const pressAddMenuBtn = () => {
    addInputMenuBox();
    addSVGMenuEle();
    console.log('menu_add');
  };

  //メニューの数の上限を１０とします。
  const menuNameList = {
    1: props.menuName1,
    2: props.menuName2,
    3: props.menuName3,
    4: props.menuName4,
    5: props.menuName5,
    6: props.menuName6,
    7: props.menuName7,
    8: props.menuName8,
    9: props.menuName9,
    10: props.menuName10,
  };

  const onChangeMenuNameList = {
    1: props.onChangeMenuName1,
    2: props.onChangeMenuName2,
    3: props.onChangeMenuName3,
    4: props.onChangeMenuName4,
    5: props.onChangeMenuName5,
    6: props.onChangeMenuName6,
    7: props.onChangeMenuName7,
    8: props.onChangeMenuName8,
    9: props.onChangeMenuName9,
    10: props.onChangeMenuName10,
  };

  return (
    <>
      <h2>メニュー</h2>
      <div className="edit_box">
        <h3>メニューを入力</h3>
        <div className="add_btn_area">
          <button
            onClick={() => {
              pressAddMenuBtn();
            }}
            disabled={!props.isFormVisible}
          >
            メニューを追加する
          </button>
        </div>
        {/* <p className='small_text'>ドラッグアンドドロップで順序変更できます。</p> */}
        <ul id="menu_group" className="dd-box">
          {lisArray.map((number) => (
            <li
              key={number}
              className={'li_' + number}
              onMouseDown={(event) => {
                props.mouseupFunc(event, 'menu');
              }}
            >
              <div className="edit_box_group">
                <div className="edit_move_box show" onClick={(event) => toggleEle(event)}>
                  <p className="textarea_pre">{menuNameList[number]}</p>
                </div>
                <div className="edit_box">
                  <p className="close_btn" onClick={(event) => toggleEle(event)}>
                    閉じる
                  </p>
                  <div className="col_1">
                    <EditorTextArea
                      name={'menu_' + number}
                      // name="menu_name"
                      rows="2"
                      value={menuNameList[number]}
                      onChange={onChangeMenuNameList[number]}
                    />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="edit_box">
        <h3>間隔を調整</h3>
        <input
          type="range"
          name="menu_interval"
          min="10"
          max="80"
          step="5"
          value={menuInterval}
          onChange={(event) => changeMenuIntervalFunc(event)}
        />
      </div>
    </>
  );
};

export default EditorMenu;
