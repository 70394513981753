import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGroup } from '../../reducks/groups/operations';
import axios from 'axios';
import { ApiDir, GUEST_ADD_EXCEL_TEMPLATE_URL, HomeUrl, JsDir, PROGRAM_ERROR_ALERT_MSG, RouteDir } from '../../common';
import DocumentMeta from 'react-document-meta';
import { SiteTitle } from './common';
import importScript from '../../components/hooks/ImportScript';
import { BreadCrumb } from '../../components';
import ExcelJS from 'exceljs';
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import { push } from 'connected-react-router';
import { changeLoading } from '../../reducks/pageInfos/operations';

const GuestAddExcel = () => {
  const dispatch = useDispatch();
  const memberId = useSelector((state) => state.members.member_id); /* ログイン中の会員ID */

  //ローディング
  const loading = useSelector((state) => state.pageInfos.loading);

  const [excelFileData, setExcelFileData] = useState('');
  const [guestList, setGuestList] = useState([]);

  const Floors = [
    // {
    //   name: 'マイページトップ',
    //   href: '/mypage',
    // },
    {
      name: '宛名登録',
      href: '/guest_add_excel',
    },
  ];

  const meta = {
    title: '宛名登録｜' + SiteTitle,
  };

  // 画面遷移用リンクスタイル
  const linkStyle = {
    textDecoration: 'underline',
    color: 'blue',
    cursor: 'pointer',
  };

  // Grid用カラム情報
  const tableCols = [
    { field: 'id', headerName: 'Excel行番号', width: 100, headerAlign: 'center', align: 'center' },
    // {
    //   field: 'selectBtn',
    //   headerName: '選択',
    //   width: 50,
    //   headerAlign: 'center',
    //   align: 'right',
    //   renderCell: () => <input type="checkbox" defaultChecked={true} />,
    // },
    { field: 'familyName', headerName: '苗字', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'firstName', headerName: '名前', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'honorific', headerName: '敬称', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'wifeHonorific', headerName: '令夫人', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint1FamilyName', headerName: '苗字Ａ', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint1FirstName', headerName: '名前連名A', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint1Honorific', headerName: '敬称A', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'toFamily', headerName: 'ご家族様', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint2FirstName', headerName: '名前連名B', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint2Honorific', headerName: '敬称B', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint3FirstName', headerName: '名前連名C', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint3Honorific', headerName: '敬称C', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'postalCode', headerName: '郵便番号', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'address1', headerName: '住所１', width: 300, headerAlign: 'center', align: 'left' },
    { field: 'address2', headerName: '住所２', width: 300, headerAlign: 'center', align: 'left' },
    { field: 'title', headerName: ' 会社名・役職', width: 300, headerAlign: 'center', align: 'center' },
  ];

  // Excelファイル読み込み時の処理
  const onExcelLoaded = async (event) => {
    // ObjectURLをUint8Arrayデータに変換
    const convertObjectURLToUint8Array = (objectURL) => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
          const result = xhr.response;
          const Uint8ArrayData = new Uint8Array(result);
          resolve(Uint8ArrayData);
        };

        xhr.onerror = (error) => {
          throw error;
        };

        xhr.responseType = 'arraybuffer';
        xhr.open('get', objectURL);
        xhr.send();
      });
    };

    // 招待客リストExcelを読み込んでJSON化する
    const importExcelData = async (excelUint8Array) => {
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(excelUint8Array);
      const worksheet = workbook.getWorksheet('住所録');
      worksheet.pageSetup = { orientation: 'portrait' };
      const startRow = 10;
      const guests = [];
      const rowCount = worksheet.rowCount;
      for (let i = startRow; i < rowCount; i++) {
        const row = worksheet.getRow(i);
        if (row.getCell(1).value !== null) {
          // console.log(row.getCell(1).value);
          let guest = {
            id: i /* Grid用 */,
            excelRow: i,
            familyName: row.getCell(1).value,
            firstName: row.getCell(2).value,
            honorific: row.getCell(3).value,
            wifeHonorific: row.getCell(4).value,
            joint1FamilyName: row.getCell(5).value,
            joint1FirstName: row.getCell(6).value,
            joint1Honorific: row.getCell(7).value,
            toFamily: row.getCell(8).value,
            joint2FirstName: row.getCell(9).value,
            joint2Honorific: row.getCell(10).value,
            joint3FirstName: row.getCell(11).value,
            joint3Honorific: row.getCell(12).value,
            postalCode: row.getCell(13).value,
            address1: row.getCell(14).value,
            address2: row.getCell(15).value,
            title: row.getCell(16).value,
          };
          guests.push(guest);
        }
      }
      return guests;
    };

    try {
      // 既存読み込み結果を削除
      setGuestList([]);

      // Excelファイルを取得
      const fileObject = event.target.files[0];

      // ExcelファイルをStateにセット
      setExcelFileData(fileObject);

      // ExcelファイルをObjectURL形式で読み込み
      const excelObjectURL = window.URL.createObjectURL(fileObject);
      // console.log(excelObjectURL);

      // ExcelファイルのObjectURLをUint8Array型に変換
      const excelUint8Array = await convertObjectURLToUint8Array(excelObjectURL);
      // console.log(excelUint8Array);

      // Excelファイルから招待客リストをJSON形式でインポート
      const guestList = await importExcelData(excelUint8Array);
      console.log(guestList.length);
      if (guestList.length === 0) {
        window.alert('Excelファイルに宛名が入力されていません。\n10行目から宛名を入力してください。');
        deleteLoadedExcelData();
        return;
      }

      setGuestList(guestList);

      // setTimeout(() => {
      //   window.alert(
      //     '下の表で正しく宛名が読み込まれていることをご確認の上、\n「宛名を一括登録する」ボタンを押してください。'
      //   );
      // }, 100);
    } catch (error) {
      console.log(error);
      deleteLoadedExcelData();
      window.alert(
        '宛名リストの読み込みに失敗しました。\n既定の形式で宛名リストが作成されていることをご確認ください。'
      );
    }
  };

  // Excelファイル読み込み結果を削除
  const deleteLoadedExcelData = () => {
    setGuestList([]); /* 読み込み結果を空に */

    // inputのファイルを削除
    const excelInput = document.querySelector(`input[name='guest_list_excel'`);
    excelInput.value = '';

    // 画面最上部にスクロール
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  // 読み込んだ宛名リストを一括登録
  const registerGuestList = (memberId, guestList) => {
    if (guestList.length === 0) {
      window.alert('Excelファイルを読み込みんでください。');
      return;
    }
    if (!window.confirm('表示されている宛名を一括登録しますか？')) {
      return;
    }

    const formElem = document.querySelector('#excel_form');
    const formData = new FormData(formElem);
    formData.append('guest_list', JSON.stringify(guestList));

    // let params = new URLSearchParams();
    // // params.append('memberId', memberId);
    // params.append('data', JSON.stringify(guestList));
    // params.append('excel_file', excelFileData);

    //ローディング開始
    dispatch(changeLoading(true));

    // DBに宛名リストを登録
    axios
      .post(ApiDir + '/registerGuestList.php', formData)
      .then((response) => {
        console.log(response.data);
        if (response.data === true) {
          window.alert('宛名を登録しました。');
          deleteLoadedExcelData(); /* 現在のExcel読み込み結果を削除 */
          dispatch(push(`${RouteDir}/mypage/guest_list`));
        } else if (response.data === 'nologin') {
          window.alert('ログインしてください。');
          dispatch(push(`${RouteDir}/login`));
        } else {
          window.alert('宛名の登録に失敗しました。');
        }

        //ローディング終了
        dispatch(changeLoading(false));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <DocumentMeta {...meta}>
      <div id="guest_add_excel_page">
        <BreadCrumb floors={Floors} />
        <h1>宛名登録</h1>
        <p>
          招待状の送付先となる宛名を、システムに登録します。
          <br />
        </p>
        <p>
          ※招待状を購入される場合は、宛名登録が<span style={{ textDecoration: 'underline' }}>必須</span>となります。
          <br />
          ※システムに登録された宛名は、
          <span
            style={linkStyle}
            onClick={() => {
              dispatch(push(`${RouteDir}/mypage/guest_list`));
            }}
          >
            宛名リスト画面
          </span>
          よりご確認いただけます。
          <br />
          ※システムに登録された宛名は、席次表にゲストを配席する際に使用できます。
        </p>
        <div id="operation_area">
          {/* {guestList.length === 0 && ( */}
          <>
            <div className="operation_guide">
              <div className="guide_text">1. 既定のテンプレートExcelファイルを使用して、宛名リストを作成</div>
              <div className="operation_area">
                ※必ず以下のテンプレートファイルを使用してください。
                <br />
                <a
                  id="template_excel_download_link"
                  href={GUEST_ADD_EXCEL_TEMPLATE_URL}
                  download="ブライダルポケット_宛名一括登録用テンプレート.xlsx"
                >
                  テンプレートExcelファイルをダウンロード
                </a>
                {/* <a download="../../../guest_list/template/ブライダルポケット_宛名一括登録用テンプレート.xlsx">ダウンロード</a> */}
              </div>
            </div>
            <div className="operation_guide">
              <div className="guide_text">2. Excelファイルを読み込み</div>
              <div className="operation_area">
                {/* <form encType="multipart/form-data" id="excel_form" method="post" onSubmit={(e) => e.preventDefault()}> */}
                <form id="excel_form" method="post">
                  <input
                    type="file"
                    accept=".xlsx"
                    name="guest_list_excel"
                    id="guest_list_excel"
                    onChange={(event) => {
                      onExcelLoaded(event);
                    }}
                  />
                </form>
              </div>
            </div>
          </>
          {/* )} */}
          <div id="loaded_guest_list_table_area">
            <>
              <div className="operation_guide">
                <div className="guide_text">3. 取り込み内容を確認</div>
                {/* <div className="loaded_result_title">読み込み結果</div> */}
                <div className="operation_area">
                  {guestList.length === 0 ? (
                    <>Excelファイルを読み込んでください。</>
                  ) : (
                    <DataGrid
                      autoHeight // グリッドの行数時応じて変化
                      // style={{ maxHeight: tableHeight, height: tableHeight }} // 高さ指定
                      pageSize={10}
                      // autoPageSize // pageSizeは最大行事応じて計算される
                      // checkboxSelection // チェックボックス
                      // checkboxSelectionVisibleOnly={(e)=>(console.log(e))} //チェックボックス選択時
                      // columnBuffer={10}
                      // rowBuffer={10}
                      density="compact" // 行の高さ compact standard comfortable
                      // disableExtendRowFullWidth // コンテナいっぱいに表示させない
                      disableColumnFilter // フィルター非表示
                      // editMode="cell"
                      // logger={console}
                      // onCellClick={(e) => console.log("click", e.row)}
                      // rowSpacingType="border"
                      // disableMultipleColumnsFiltering // 複数フィルタリング
                      disableMultipleColumnsSorting={true} // 複数ソート
                      disableSelectionOnClick // データの選択を無効にする
                      showCellRightBorder
                      showColumnRightBorder
                      localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                      // components={
                      //   // props.data.Toolbar && {
                      //     Toolbar: GridToolbar
                      //   // }
                      // }
                      // components={{
                      //   Toolbar: GridToolbar, // ツールバーを指定する
                      // }}
                      columns={tableCols}
                      rows={guestList}
                    />
                  )}
                </div>
              </div>
              <div className="operation_guide">
                <div className="register_btn_area">
                  <div className="guide_text">4. 宛名をシステムに登録</div>
                  <div className="operation_area">
                    登録済みの宛名リストは削除され、
                    <span style={{ textDecoration: 'wavy gray underline' }}>新しい宛名リストに置き換わります。</span>
                    <button
                      className="register_guest_list_btn"
                      onClick={() => {
                        registerGuestList(memberId, guestList);
                      }}
                    >
                      宛名を登録
                    </button>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      <div id="loading_area" className={loading === true ? '' : 'hidden'}>
        <div className="loader">Loading...</div>
      </div>
    </DocumentMeta>
  );
};

export default GuestAddExcel;
