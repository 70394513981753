import React from 'react';

// カート特急配送指定
const CartExpressDeliveryFeeBox = (props) => {
  return (
    <div className="express_delivery_fee_box">
      <table className="express_delivery_fee">
        <tbody>
          <tr>
            {props.canSelect && (
              <>
                <th>特急配送指定</th>
                <td className={'is_express_delivery_select ' + props.canSelect ? 'can_select' : ''}>
                  <select
                    value={props.isExpressDelivery ? '1' : '0'}
                    onChange={(event) => {
                      // alert(event.target.checked);
                      props.setIsExpressDelivery(event.target.value === '1' ? true : false);
                    }}
                  >
                    <option value="0">しない</option>
                    <option value="1">する</option>
                  </select>
                </td>
                <td className={props.canSelect ? 'can_select' : ''}>
                  {props.isExpressDelivery ? <>￥{Number(props.expressDeliveryFee).toLocaleString()}</> : <>￥0</>}
                </td>
              </>
            )}
            {!props.canSelect && (
              <>
                <th>特急配送料金</th>
                <td>
                  {props.isExpressDelivery ? <>￥{Number(props.expressDeliveryFee).toLocaleString()}</> : <>￥0</>}
                </td>
              </>
            )}
          </tr>
        </tbody>
      </table>
      {props.canSelect && (
        <p style={{ fontSize: '0.9em', margin: 'auto', width: '50%', minWidth: '350px' }}>
          ※通常よりも早く発送いたします。
        </p>
      )}
    </div>
  );
};

export default CartExpressDeliveryFeeBox;
