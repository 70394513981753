import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditorTextInput, EditorTextArea } from '../UIkit';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { onDragStart, onDragEnd } from './EditorAddTableFunction';
import { selectAddress } from '../../reducks/addresses/operations';
import ja from 'date-fns/locale/ja';
import { Checkbox } from '@material-ui/core';
import axios from 'axios';
import { ApiDir } from '../../common';
import { validateTextLineNum } from '../../templates/Public/EditorFunction';
registerLocale('ja', ja);

const EditorSeatingSeat = (props) => {
  const dispatch = useDispatch();

  const [seatingFont, setSeatingFont] = useState(props.fontData.find((font) => font.name === 'HanaMin').data);

  //宛名リスト
  const addresses = useSelector((state) => state.addresses.list);
  const [addressesContentShowFlag, setAddressesContentShowFlag] = useState(0); //宛名リスト表示フラグ

  // 配席用ゲストリスト
  const [seatingGuestElemList, setSeatingGuestElemList] = useState([]);

  // 開発用にゲスト情報を初期設定
  const [guestTitle, setGuestTitle] = useState('肩書１行目\n肩書２行目\n肩書３行目'); //新規ゲスト登録用肩書き
  const [guestFamilyName, setGuestFamilyName] = useState('驫驫驫'); //新規ゲスト登録用姓
  const [guestFirstName, setGuestFirstName] = useState('驫驫驫'); //新規ゲスト登録用名
  const [guestHonorific, setGuestHonorific] = useState('驫驫驫'); //新規ゲスト登録用敬称
  // const [guestFamilyName, setGuestFamilyName] = useState('テスト姓'); //新規ゲスト登録用姓
  // const [guestFirstName, setGuestFirstName] = useState('テスト名'); //新規ゲスト登録用名
  // const [guestHonorific, setGuestHonorific] = useState('敬称'); //新規ゲスト登録用敬称
  // const [guestTitle, setGuestTitle] = useState(''); //新規ゲスト登録用肩書き
  // const [guestFamilyName, setGuestFamilyName] = useState(''); //新規ゲスト登録用姓
  // const [guestFirstName, setGuestFirstName] = useState(''); //新規ゲスト登録用名
  // const [guestHonorific, setGuestHonorific] = useState(''); //新規ゲスト登録用敬称

  //新規登録用肩書き変更時処理
  const inpufGuestTitle = useCallback(
    (event) => {
      const maxLineNum = 3;
      if (validateTextLineNum(event.target.value, maxLineNum)) {
        setGuestTitle(event.target.value);
      }
    },
    [setGuestTitle]
  );

  //新規登録用姓変更時処理
  const inpufGuestFamilyName = useCallback(
    (event) => {
      setGuestFamilyName(event.target.value);
    },
    [setGuestFamilyName]
  );

  //新規登録用名変更時処理
  const inpufGuestFirstName = useCallback(
    (event) => {
      setGuestFirstName(event.target.value);
    },
    [setGuestFirstName]
  );

  //新規登録用敬称変更時処理
  const inpufGuestHonorific = useCallback(
    (event) => {
      setGuestHonorific(event.target.value);
    },
    [setGuestHonorific]
  );

  useEffect(() => {
    //宛名一覧のチェックボックスを全てチェックに変更 addressesの変化を監視
    const addGuestForAddressesListElement = document.getElementById('add_guest_for_addresses_list_content');
    if (
      addGuestForAddressesListElement !== null &&
      addGuestForAddressesListElement.querySelectorAll('input[type="checkbox"]').length !== 0
    ) {
      const checkboxElement = addGuestForAddressesListElement.querySelectorAll('input[type="checkbox"]');
      for (let i = 0; i < checkboxElement.length; i++) {
        checkboxElement[i].checked = true;
      }
      console.log(checkboxElement);
    }
  }, [addresses]);

  useEffect(() => {
    // console.log(props.seatingGuestList);
    if (!props.seatingGuestList || props.seatingGuestList.length === 0) return;

    // 既存の配席リストを空にする（初回保存の画面遷移時の対策）
    removeCurrentSeatingGuestList();

    // 保存した配席ゲスト情報から配席リストを復元する
    restoreSeatingGuestList(props.seatingGuestList);
  }, [props.seatingGuestList]);

  /**
   * ユニークIDを生成
   * https://shanabrian.com/web/javascript/create-unique-id.php
   * @param {number} [digits=1000] 末尾に付与する乱数の桁
   * @return {string} 生成したユニークIDを返す
   */
  const uniqueId = function (digits) {
    const strong = typeof digits !== 'undefined' ? digits : 1000;
    // console.log(Date.now().toString(16));
    return (Date.now().toString(16) + Math.floor(strong * Math.random()).toString(16)).toString();
  };

  // 配席用ゲスト要素作成
  const addGuestCard = (title, familyName, firstName, honorific) => {
    if (familyName === '' || firstName === '') {
      window.alert('姓・名を入力してください。');
      return;
    }

    //ゲスト配席カードの作成
    //挿入するカード
    const Gid = uniqueId(10000); //判別用ユニークid
    // const Gid = Date.now(); //判別用ユニークid/* 一括追加時にユニークにならない */
    console.log('配席用ゲストID:', Gid);
    const guestCardElement = document.createElement('li');
    guestCardElement.classList.add('guest_card', 'drag_item');
    guestCardElement.setAttribute('data-guest-id', Gid);
    guestCardElement.setAttribute('draggable', true);
    // guestCardElement.setAttribute('ondragstart', `guestSeatSetOnDragStartFromSidebar(event)`);
    guestCardElement.ondragstart = (event) => {
      guestSeatSetOnDragStartFromSidebar(event, seatingFont);
    };
    guestCardElement.setAttribute('ondragend', 'guestSeatSetOnDragEndFromSidebar(event)');

    //名前表示用要素の作成
    const showNameElement = document.createElement('div');
    showNameElement.classList.add('edit_move_box', 'show');
    showNameElement.setAttribute('onclick', 'toggleEle(event)');
    const guestNameElement = document.createElement('span');
    const guestName = familyName + '　' + firstName + '　' + honorific;
    guestNameElement.textContent = guestName;
    showNameElement.appendChild(guestNameElement);

    //編集部分要素の作成
    const editInfoElement = document.createElement('div');
    editInfoElement.classList.add('edit_box');
    //編集部分の閉じるボタンの作成
    const closeBtnElement = document.createElement('p');
    closeBtnElement.classList.add('close_btn');
    // closeBtnElement.setAttribute(
    //   'onclick',
    //   // `toggleEle(event);changeShowNameForCloseBtn(event);changeGuestText(${seatingFont},${Gid});`
    // );
    // 閉じるボタン押下時の処理
    closeBtnElement.onclick = (event) => {
      toggleEle(event);
      changeShowNameForCloseBtn(event);

      // 配席済みゲストの場合はSVGの該当席テキスト更新
      isGuestDeployed(Gid) && changeGuestText(seatingFont, Gid);
    };
    closeBtnElement.textContent = '閉じる';
    //肩書き編集部分の作成
    const titleEditElement = document.createElement('div');
    titleEditElement.classList.add('col_1');
    const labelElement = document.createElement('label');
    labelElement.textContent = '肩書き（3行まで）';
    const textareaWrapElement = document.createElement('div');
    textareaWrapElement.classList.add('editor_textarea_item');
    const textareaElement = document.createElement('textarea');
    textareaElement.setAttribute('name', `guest_title_${Gid}`);
    textareaElement.setAttribute('rows', '3');
    textareaElement.setAttribute('wrap', 'off');
    textareaElement.setAttribute('placeholder', '3行まで');
    textareaElement.setAttribute('data-prev-text', title); /* 直前の肩書の値 */
    // textareaElement.setAttribute('onchange', 'validateTitleLineNum(event)');
    textareaElement.addEventListener('input', (event) => {
      validateTitleLineNum(event); /* 肩書行数制限 */
    });
    textareaElement.textContent = title;
    textareaWrapElement.appendChild(textareaElement);
    titleEditElement.appendChild(labelElement);
    titleEditElement.appendChild(textareaWrapElement);

    //姓、名、敬称編集部分の作成
    const nameHonorificElement = document.createElement('div');
    nameHonorificElement.classList.add('col_3');
    //姓、名、敬称の共通要素作成関数
    const makeInputElement = (labelText, nameValue, placeholder, value) => {
      const editElement = document.createElement('div');
      editElement.classList.add('editor_text_input_item');
      const lavelElement = document.createElement('label');
      lavelElement.textContent = labelText;
      const inputTextElement = document.createElement('input');
      inputTextElement.setAttribute('type', 'text');
      inputTextElement.setAttribute('class', nameValue);
      inputTextElement.setAttribute('name', `guest_${nameValue}_${Gid}`);
      inputTextElement.setAttribute('maxLength', '10');
      inputTextElement.setAttribute('placeholder', placeholder);
      inputTextElement.setAttribute('value', value);
      editElement.appendChild(lavelElement);
      editElement.appendChild(inputTextElement);
      return editElement;
    };
    //姓編集部分の作成
    const familyNameEditElement = makeInputElement('姓', 'family_name', '大村', familyName);
    //名編集部分の作成
    const firstNameEditElement = makeInputElement('名', 'first_name', '太郎', firstName);
    //敬称編集部分の作成
    const honorificEditElement = makeInputElement('敬称', 'honorific', '様', honorific);

    //挿入
    nameHonorificElement.appendChild(familyNameEditElement);
    nameHonorificElement.appendChild(firstNameEditElement);
    nameHonorificElement.appendChild(honorificEditElement);

    editInfoElement.appendChild(closeBtnElement);
    editInfoElement.appendChild(titleEditElement);
    editInfoElement.appendChild(nameHonorificElement);

    //li要素に挿入
    const outerWrap = document.createElement('div');
    outerWrap.classList.add('edit_box_group');
    outerWrap.appendChild(showNameElement);
    outerWrap.appendChild(editInfoElement);

    guestCardElement.appendChild(outerWrap);

    //挿入するul要素を取得
    const guestListUl = document.getElementById('guest_card_list');

    //作成した配席カードの挿入
    if (familyName !== '' || firstName !== '') {
      guestListUl.appendChild(guestCardElement);
    }

    //各stateの初期化
    setGuestTitle('');
    setGuestFamilyName('');
    setGuestFirstName('');
    setGuestHonorific('');
  };

  // 既存の配席リストを空にする（初回保存の画面遷移時の対策）
  const removeCurrentSeatingGuestList = () => {
    // 親要素の全子要素を削除する
    const removeAllChildElems = (parentElem) => {
      while (parentElem.firstChild) {
        parentElem.removeChild(parentElem.firstChild);
      }
    };

    // 未配席リスト削除
    const undeployedGuestListUlElem = document.querySelector('#guest_card_list');
    removeAllChildElems(undeployedGuestListUlElem);

    // 配席済みリスト削除
    const deployedGuestListUlElem = document.querySelector('#already_deploy_guest_card_list');
    removeAllChildElems(deployedGuestListUlElem);
  };

  // 配席用ゲスト要素復元処理
  const restoreSeatingGuestList = (guestInfoList) => {
    const createGuestLiElem = (guestId, isDeployed, title, familyName, firstName, honorific) => {
      // li要素の閉じるボタン作成
      const createCloseBtn = (guestId) => {
        //編集部分の閉じるボタンの作成
        const closeBtnElement = document.createElement('p');
        closeBtnElement.classList.add('close_btn');

        // 閉じるボタン押下時の処理
        closeBtnElement.onclick = (event) => {
          toggleEle(event);
          changeShowNameForCloseBtn(event);

          // 配席済みゲストの場合はSVGの該当席テキスト更新
          isGuestDeployed(guestId) && changeGuestText(seatingFont, guestId);
        };
        closeBtnElement.textContent = '閉じる';
        return closeBtnElement;
      };

      // li要素を作成
      const createGuestLi = (guestId) => {
        const guestCardElement = document.createElement('li');
        guestCardElement.classList.add('guest_card', 'drag_item');
        guestCardElement.setAttribute('data-guest-id', guestId);
        guestCardElement.setAttribute('draggable', !isDeployed);
        guestCardElement.ondragstart = (event) => {
          guestSeatSetOnDragStartFromSidebar(event, seatingFont);
        };
        guestCardElement.setAttribute('ondragend', 'guestSeatSetOnDragEndFromSidebar(event)');
        return guestCardElement;
      };

      //名前表示用要素の作成
      const createShowName = (familyName, firstName, honorific) => {
        const showNameElement = document.createElement('div');
        showNameElement.classList.add('edit_move_box', 'show');
        showNameElement.setAttribute('onclick', 'toggleEle(event)');
        const guestNameElement = document.createElement('span');
        const guestName = familyName + '　' + firstName + '　' + honorific;
        guestNameElement.textContent = guestName;
        showNameElement.appendChild(guestNameElement);
        return showNameElement;
      };

      //編集部分要素の作成
      const createEditInfoElem = () => {
        const editInfoElement = document.createElement('div');
        editInfoElement.classList.add('edit_box');
        return editInfoElement;
      };

      //肩書き編集部分の作成
      const createTitleEditElem = (title, guestId) => {
        const titleEditElement = document.createElement('div');
        titleEditElement.classList.add('col_1');
        const labelElement = document.createElement('label');
        labelElement.textContent = '肩書き（3行まで）';
        const textareaWrapElement = document.createElement('div');
        textareaWrapElement.classList.add('editor_textarea_item');
        const textareaElement = document.createElement('textarea');
        textareaElement.setAttribute('name', `guest_title_${guestId}`);
        textareaElement.setAttribute('rows', '3');
        textareaElement.setAttribute('wrap', 'off');
        textareaElement.setAttribute('placeholder', '3行まで');
        textareaElement.setAttribute('data-prev-text', title); /* 直前の肩書の値 */
        textareaElement.addEventListener('input', (event) => {
          validateTitleLineNum(event); /* 肩書行数制限 */
        });
        textareaElement.textContent = title;
        textareaWrapElement.appendChild(textareaElement);
        titleEditElement.appendChild(labelElement);
        titleEditElement.appendChild(textareaWrapElement);
        return titleEditElement;
      };

      //姓、名、敬称編集部分の作成
      const createNameHonorificElem = () => {
        const nameHonorificElement = document.createElement('div');
        nameHonorificElement.classList.add('col_3');
        return nameHonorificElement;
      };

      //姓、名、敬称の共通要素作成関数
      const makeInputElement = (guestId, labelText, nameValue, placeholder, value) => {
        const editElement = document.createElement('div');
        editElement.classList.add('editor_text_input_item');
        const lavelElement = document.createElement('label');
        lavelElement.textContent = labelText;
        const inputTextElement = document.createElement('input');
        inputTextElement.setAttribute('type', 'text');
        inputTextElement.setAttribute('class', nameValue);
        inputTextElement.setAttribute('name', `guest_${nameValue}_${guestId}`);
        inputTextElement.setAttribute('placeholder', placeholder);
        inputTextElement.setAttribute('value', value);
        editElement.appendChild(lavelElement);
        editElement.appendChild(inputTextElement);
        return editElement;
      };

      const createOuterWrapElem = () => {
        const outerWrap = document.createElement('div');
        outerWrap.classList.add('edit_box_group');
        return outerWrap;
      };

      const guestCardElement = createGuestLi(guestId);
      const showNameElement = createShowName(familyName, firstName, honorific);
      const editInfoElement = createEditInfoElem();
      const closeBtnElement = createCloseBtn(guestId);
      const titleEditElement = createTitleEditElem(title, guestId);
      const nameHonorificElement = createNameHonorificElem();

      //姓編集部分の作成
      const familyNameEditElement = makeInputElement(guestId, '姓', 'family_name', '大村', familyName);
      //名編集部分の作成
      const firstNameEditElement = makeInputElement(guestId, '名', 'first_name', '太郎', firstName);
      //敬称編集部分の作成
      const honorificEditElement = makeInputElement(guestId, '敬称', 'honorific', '様', honorific);

      //挿入
      nameHonorificElement.appendChild(familyNameEditElement);
      nameHonorificElement.appendChild(firstNameEditElement);
      nameHonorificElement.appendChild(honorificEditElement);

      editInfoElement.appendChild(closeBtnElement);
      editInfoElement.appendChild(titleEditElement);
      editInfoElement.appendChild(nameHonorificElement);

      const outerWrap = createOuterWrapElem();
      outerWrap.appendChild(showNameElement);
      outerWrap.appendChild(editInfoElement);

      guestCardElement.appendChild(outerWrap);

      return guestCardElement;
    };

    // console.log(guestInfoList);
    for (let i = 0; i < guestInfoList.length; i++) {
      const guest = guestInfoList[i];
      const isDeployed = guest.is_deployed === '1' ? true : false; /* 配席済みフラグ */

      // 配席用ゲストli要素を作成
      const guestLiElem = createGuestLiElem(
        guest.guest_id,
        isDeployed,
        guest.title,
        guest.family_name,
        guest.first_name,
        guest.honorific
      );

      // 未配席 or 配席済みリストに追加
      const guestListUlSelector = isDeployed ? '#already_deploy_guest_card_list' : '#guest_card_list';
      const guestListUlElem = document.querySelector(guestListUlSelector);
      guestListUlElem.appendChild(guestLiElem);
      // console.log(guestLiElem, guestListUlSelector);
    }
  };

  //一括登録ボタンを押した時の処理
  const bulkAddGuest = () => {
    dispatch(props.EditerFuncToUseForlogin());
    //ログイン状態の時
    if (props.memberInfo.loginStatus) {
      //宛名リストを取得
      const param = {
        host_member_id: props.memberInfo.memberId,
        Limit: -1,
      };
      dispatch(selectAddress(param));

      //宛名一覧要素を表示する
      setAddressesContentShowFlag(1);
    }
  };

  //宛名リストからゲストを登録する時の処理
  const addAddressesGuest = (addresses) => {
    //チェックボックスがチェックになっているidを配列に取得
    const addGuestForAddressesListElement = document.getElementById('add_guest_for_addresses_list_content');
    const checkboxElement = addGuestForAddressesListElement.querySelectorAll('input[type="checkbox"]');
    let checkedId = [];
    for (let i = 0; i < checkboxElement.length; i++) {
      if (checkboxElement[i].checked) {
        checkedId.push(checkboxElement[i].value);
      }
    }
    //配列のidを持つゲストカードを作成
    for (let i = 0; i < checkedId.length; i++) {
      for (let k = 0; k < addresses.length; k++) {
        if (addresses[k].id === checkedId[i]) {
          const title = addresses[k].title;
          const familyName = addresses[k].familyname;
          const firstName = addresses[k].firstname;
          const honorific = addresses[k].honorific_title_name;

          //カードの作成
          addGuestCard(title, familyName, firstName, honorific);
        }
      }
    }
    //宛名一覧要素を非表示
    setAddressesContentShowFlag(0);
  };

  // 配席ゲストリスト作成用に会員の宛名リストを取得
  const getRegisteredGuestList = async () => {
    // 会員の宛名リストを取得
    const getMemberGuestList = (memberId) => {
      return new Promise((resolve, reject) => {
        // 宛名リストを取得
        const param = new URLSearchParams();
        param.append('memberId', memberId);
        axios
          .post(ApiDir + '/selectGuestsForSeating.php', param)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
            // window.alert('宛名リストの取得に失敗しました。');
            throw '宛名リストの取得に失敗しました。';
          });
      });
    };

    // 取得した宛名リストデータから配席用ゲストリスト用にデータ加工
    const prepareSeatingGuestList = (guestList) => {
      // 個別ゲスト情報を作成
      const createGuestInfo = (familyName, firstName, honorific) => {
        if (firstName === '') {
          return null;
        }

        return {
          familyName: familyName,
          firstName: firstName,
          honorific: honorific,
        };
      };

      // 1行のゲスト情報から、有効なゲストのみの配列を作成
      const processGuestRow = (guestRow) => {
        const returnArray = [];

        // 代表者情報を作成
        const representative = createGuestInfo(guestRow.family_name, guestRow.first_name, guestRow.honorific);
        if (representative === null) {
          return [];
        }
        returnArray.push(representative);

        // 連名１～３を処理
        for (let j = 0; j < 3; j++) {
          const jointNum = j + 1;

          let jointFamilyName = guestRow['joint' + jointNum + '_family_name']; /* 連名姓 */
          const jointFirstName = guestRow['joint' + jointNum + '_first_name']; /* 連名名 */
          const jointHonorific = guestRow['joint' + jointNum + '_honorific']; /* 連名敬称 */

          /* 連名姓が空の場合は、代表者姓を使用 */
          if (jointFamilyName === '') {
            jointFamilyName = representative.familyName;
          }

          // 連名nの情報を作成
          const joint = createGuestInfo(jointFamilyName, jointFirstName, jointHonorific);

          // 連名nが存在する場合のみ利用
          if (joint !== null) {
            returnArray.push(joint);
          }
        }

        return returnArray;
      };

      const seatingGuestList = [];

      for (let i = 0; i < guestList.length; i++) {
        // 宛名リストの1行を取得
        const guestRow = guestList[i];
        // console.log(guestRow);

        // 宛名リストの1行から、配席用ゲストととして利用するゲストの配列を作成
        const guestInfoArray = processGuestRow(guestRow);
        // console.log(...guestInfoArray);
        seatingGuestList.push(...guestInfoArray);
      }
      // console.log(seatingGuestList);
      return seatingGuestList;
    };

    try {
      // ログイン確認
      console.log(props.memberInfo);
      if (!props.memberInfo.loginStatus) {
        window.alert('ログインしてください。');
        return;
      }

      if (!window.confirm('宛名リストから配席用ゲストを一括追加しますか？')) {
        return;
      }

      const guestList = await getMemberGuestList(props.memberInfo.member_id);
      console.log(guestList);
      if (guestList.length === 0) {
        alert('宛名が登録されていません。\n宛名登録画面から宛名を登録してください。');
        return;
      }

      const seatingGuestList = prepareSeatingGuestList(guestList);

      // 未配席リストにゲスト情報を追加
      for (let i = 0; i < seatingGuestList.length; i++) {
        const guest = seatingGuestList[i];
        const title = ''; /* 肩書は空で登録 */
        addGuestCard(title, guest.familyName, guest.firstName, guest.honorific);
      }
      alert('宛名リストからゲストを一括追加しました。');
    } catch (error) {
      console.log(error);
      window.alert(error);
    }
  };

  return (
    <>
      <h2>
        ＜席次表＞ <br />
        ゲストを配席
      </h2>
      <div className="edit_box seating_form_box">
        <h3>
          1. テーブルに席を追加
          <br />
        </h3>
        <div className="add_seat_note">※席が足りない場合のみ</div>
        <div
          draggable="true"
          onDragStart={(event) => onDragStart(event)}
          onDragEnd={(event) => onDragEnd(event)}
          className="drag_item add_seat"
        >
          <span className="seat_name">空席</span>
          <br />
          <span className="guide_text">テーブルの赤枠にドラッグ＆ドロップ</span>
        </div>
      </div>
      <div id="add_guest_card_area" className="edit_box seating_form_box">
        <h3>2. 配席リストにゲストを追加</h3>
        <div className="section">
          <div className="section_title">個別追加</div>
          <div className="section_contents">
            <div className="input_area">
              <div className="col_1">
                <label htmlFor="guest_title">肩書き（3行まで）</label>
                <EditorTextArea
                  name="guest_title"
                  rows="3"
                  wrap="off"
                  label={'肩書き'}
                  value={guestTitle}
                  onChange={inpufGuestTitle}
                  placeholder={'3行まで'}
                />
              </div>
              <div className="col_3">
                <EditorTextInput
                  label="姓"
                  name="guest_family_name"
                  maxLength="10"
                  rows="1"
                  placeholder={'大村'}
                  value={guestFamilyName}
                  onChange={inpufGuestFamilyName}
                />
                <EditorTextInput
                  label="名"
                  name="guest_first_name"
                  maxLength="10"
                  rows="1"
                  placeholder={'太郎'}
                  value={guestFirstName}
                  onChange={inpufGuestFirstName}
                />
                <EditorTextInput
                  label="敬称"
                  name="guest_honorific"
                  maxLength="10"
                  placeholder="様"
                  rows="1"
                  value={guestHonorific}
                  onChange={inpufGuestHonorific}
                />
              </div>
            </div>
            <div className="col_1">
              <div className="add_btn_area">
                <button
                  onClick={() => {
                    addGuestCard(guestTitle, guestFamilyName, guestFirstName, guestHonorific);
                  }}
                  disabled={!props.isFormVisible}
                >
                  追加
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="section_title">宛名リストから一括追加</div>
          <div className="section_contents">
            <div className="add_btn_area">
              <button onClick={() => getRegisteredGuestList()} disabled={!props.isFormVisible}>
                一括追加
              </button>
              {/* <button onClick={() => bulkAddGuest()}>一括登録</button> */}
            </div>
          </div>
        </div>
      </div>
      {/* <button onClick={() => console.log(getGuestListToSave())}>テスト</button> */}
      <div className="edit_box"></div>
      <div className="edit_box seating_form_box">
        <h3>3. ゲストを配席</h3>
        <div className="seating_form_note">名前をクリックすると編集できます</div>
        <div id="guest_card_list_area">
          <div className="section">
            <div className="section_title deploy_list_title">
              未配席ゲスト
              <br />
              <span className="small_text">空席にドラッグ＆ドロップ</span>
            </div>
            <div className="section_contents">
              <ul id="guest_card_list"></ul>
            </div>
          </div>
          <div className="section">
            <div className="section_title deploy_list_title">配席済みゲスト</div>
            <div className="section_contents">
              <ul id="already_deploy_guest_card_list"></ul>
            </div>
          </div>
        </div>
      </div>
      {addressesContentShowFlag === 1 && (
        <div id="add_guest_for_addresses_list_content_wrap">
          <div id="add_guest_for_addresses_list_content">
            <table>
              <thead>
                <tr>
                  <th>選択</th>
                  <th>名前</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(addresses) &&
                  addresses.map((address) => (
                    <tr key={address.id}>
                      <td>
                        <input type="checkbox" name="add_check" value={address.id} />
                      </td>
                      <td>{address.familyname + ' ' + address.firstname + ' ' + address.honorific_title_name}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="add_btn">
              <button
                onClick={() => {
                  addAddressesGuest(addresses);
                }}
              >
                登　録
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditorSeatingSeat;
