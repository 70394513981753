export const LOGINPOPUP_CHANGE = 'LOGINPOPUP_CHANGE'
export const loginPopupChangeAction = (popupsState) => {
  return {
    type: 'LOGINPOPUP_CHANGE',
    payload: {
      login: popupsState.login
    }
  }
}

export const LOADING_CHANGE = 'LOADING_CHANGE'
export const loadingChangeAction = (loadingState) => {
  return {
    type: 'LOADING_CHANGE',
    payload: {
      loading: loadingState
    }
  }
}