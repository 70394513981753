import React, { useEffect, useCallback } from 'react';
import { EditorTextInput, EditorTextArea } from '../UIkit';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
import { changeElemVisibilityBySelector } from '../../templates/Public/EditorFunction';
registerLocale('ja', ja);

const EditorEnvelope = (props) => {
  const changeShowNotation = (target, value) => {};

  useEffect(() => {
    // 各表記初期表示切替
    changeElemVisibilityBySelector('.英語表記', props.englishNotation === '1');
    changeElemVisibilityBySelector('.出席者人数部分', props.NOANotation === '1');
    changeElemVisibilityBySelector('.メッセージ部分', props.messageNotation === '1');
    changeElemVisibilityBySelector('.アレルギー', props.allergyNotation === '1');
    changeElemVisibilityBySelector('.送迎バス', props.courtesyBusNotation === '1');
  }, [props]);

  return (
    <>
      <h2>返信はがき(裏面)を入力</h2>
      <div className="edit_box">
        <h3>英語表記</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="show_english">
              <input
                type="radio"
                name="postcard_backside_english_notation"
                value="1"
                id="show_english"
                checked={props.englishNotation === '1'}
                onChange={props.onChangeEnglishNotation}
                onClick={() => {
                  changeElemVisibilityBySelector('.英語表記', true);
                }}
              />
              あり
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="invisible_english">
              <input
                type="radio"
                name="postcard_backside_english_notation"
                value="0"
                id="invisible_english"
                checked={props.englishNotation === '0'}
                onChange={props.onChangeEnglishNotation}
                onClick={() => {
                  changeElemVisibilityBySelector('.英語表記', false);
                }}
              />
              なし
            </label>
          </div>
        </div>
      </div>
      <div className="edit_box">
        <h3>出席者人数表記</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="show_NOA">
              <input
                type="radio"
                name="postcard_backside_NOA_notation"
                value="1"
                id="show_NOA"
                checked={props.NOANotation === '1'}
                onChange={props.onChangeNOANotation}
                onClick={() => {
                  changeElemVisibilityBySelector('.出席者人数部分', true);
                }}
              />
              あり
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="invisible_NOA">
              <input
                type="radio"
                name="postcard_backside_NOA_notation"
                value="0"
                id="invisible_NOA"
                checked={props.NOANotation === '0'}
                onChange={props.onChangeNOANotation}
                onClick={() => {
                  changeElemVisibilityBySelector('.出席者人数部分', false);
                }}
              />
              なし
            </label>
          </div>
        </div>
      </div>
      <div className="edit_box">
        <h3>メッセージ</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="show_message">
              <input
                type="radio"
                name="postcard_backside_message_notation"
                value="1"
                id="show_message"
                checked={props.messageNotation === '1'}
                onChange={props.onChangeMessageNotation}
                onClick={() => {
                  changeElemVisibilityBySelector('.メッセージ部分', true);
                }}
              />
              あり
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="invisible_message">
              <input
                type="radio"
                name="postcard_backside_message_notation"
                value="0"
                id="invisible_message"
                checked={props.messageNotation === '0'}
                onChange={props.onChangeMessageNotation}
                onClick={() => {
                  changeElemVisibilityBySelector('.メッセージ部分', false);
                }}
              />
              なし
            </label>
          </div>
        </div>
      </div>
      <div className="edit_box">
        <h3>アレルギー表記</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="show_allergy">
              <input
                type="radio"
                name="postcard_backside_allergy_notation"
                value="1"
                id="show_allergy"
                checked={props.allergyNotation === '1'}
                onChange={props.onChangeAllergyNotation}
                onClick={() => {
                  changeElemVisibilityBySelector('.アレルギー', true);
                }}
              />
              あり
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="invisible_allergy">
              <input
                type="radio"
                name="postcard_backside_allergy_notation"
                value="0"
                id="invisible_allergy"
                checked={props.allergyNotation === '0'}
                onChange={props.onChangeAllergyNotation}
                onClick={() => {
                  changeElemVisibilityBySelector('.アレルギー', false);
                }}
              />
              なし
            </label>
          </div>
        </div>
      </div>
      <div className="edit_box">
        <h3>送迎バス表記</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="show_courtesy_bus">
              <input
                type="radio"
                name="postcard_backside_courtesy_bus_notation"
                value="1"
                id="show_courtesy_bus"
                checked={props.courtesyBusNotation === '1'}
                onChange={props.onChangeCourtesyBusNotation}
                onClick={() => {
                  changeElemVisibilityBySelector('.送迎バス', true);
                }}
              />
              あり
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="invisible_courtesy_bus">
              <input
                type="radio"
                name="postcard_backside_courtesy_bus_notation"
                value="0"
                id="invisible_courtesy_bus"
                checked={props.courtesyBusNotation === '0'}
                onChange={props.onChangeCourtesyBusNotation}
                onClick={() => {
                  changeElemVisibilityBySelector('.送迎バス', false);
                }}
              />
              なし
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditorEnvelope;
