import React from 'react';

// 離島配送料
const CartIsolatedIslandsShippingFeeBox = (props) => {
  return (
    <div className="isolated_islands_shipping_fee_box">
      <table className="isolated_islands_shipping_fee">
        <tbody>
          <tr>
            <th>離島配送料</th>
            <td>￥{Number(props.isolatedIslandsShippingFee).toLocaleString()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CartIsolatedIslandsShippingFeeBox;
