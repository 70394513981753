import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGroup } from '../../reducks/groups/operations';
import axios from 'axios';
import { ApiDir, HomeDir, ItemImageDir, JsDir, PROGRAM_ERROR_ALERT_MSG, RouteDir } from '../../common';
import DocumentMeta from 'react-document-meta';
import { SiteTitle } from './common';
import importScript from '../../components/hooks/ImportScript';
import { BreadCrumb } from '../../components';
import ExcelJS from 'exceljs';
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import { calcItemTaxIncludedPrice } from '../../myLib';
import { insertCart } from '../../reducks/carts/operations';
import { push } from 'connected-react-router';

const SavedDesigns = () => {
  const dispatch = useDispatch();

  const [savedInvitationDesigns, setSavedInvitationDesigns] = useState([]);
  const [savedSeatingDesigns, setSavedSeatingDesigns] = useState([]);

  // 保存したデザインを取得
  const getSavedDesigns = async () => {
    return new Promise((resolve, reject) => {
      //sessionの会員ID情報取得
      axios
        .post(ApiDir + '/selectSavedDesigns.php')
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          resolve();
        });
    });
  };

  const Floors = [
    // {
    //   name: 'マイページトップ',
    //   href: '/mypage',
    // },
    {
      name: '保存したデザイン',
      href: '/saved_designs',
    },
  ];

  const meta = {
    title: '保存したデザイン｜' + SiteTitle,
  };

  // DataGrid：値調整：税込価格
  const getLocaledItemPrice = (param) => {
    const taxIncludedPrice = calcItemTaxIncludedPrice(param.row.item_price, param.row.tax_value);
    return '￥' + taxIncludedPrice.toLocaleString();
  };

  // DataGrid：値調整：送料
  const getLocaledPostage = (param) => {
    return '￥' + Number(param.row.item_postage).toLocaleString();
  };

  // デザイン編集ページを開く
  const openDesignEditPage = (designType, designSerial) => {
    // デザイン編集リンク生成
    const createDesignEditUrl = (designType, designSerial) => {
      if (designType !== 'invitation' && designType !== 'seating') {
        return '';
      }

      switch (designType) {
        case 'invitation':
          return RouteDir + '/editor?view=invitation&savedata=' + designSerial;
        case 'seating':
          return RouteDir + '/editor?view=seating&savedata=' + designSerial;
      }
    };

    const url = createDesignEditUrl(designType, designSerial);
    window.open(url);
  };

  // Grid用カラム情報
  const tableCols = [
    // {
    //   field: 'selectBtn',
    //   headerName: '選択',
    //   width: 50,
    //   headerAlign: 'center',
    //   align: 'right',
    //   renderCell: () => <input type="checkbox" defaultChecked={true} />,
    // },
    // { field: 'design_serial', headerName: 'シリアル', width: 100, headerAlign: 'center', align: 'center' },
    {
      field: 'selectBtn',
      headerName: '商品イメージ',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (param) => <img src={ItemImageDir + param.row.item_main_image_path} height="50" />,
    },
    {
      field: 'item_name',
      headerName: '商品名',
      width: 300,
      headerAlign: 'center',
      align: 'center',
      renderCell: (param) => (
        <div>
          <a href={RouteDir + '/item/detail/' + param.row.item_id}>{param.row.item_name}</a>
        </div>
      ),
    },
    // {
    //   field: 'item_price',
    //   headerName: '税込価格',
    //   width: 100,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: getLocaledItemPrice,
    // },
    // {
    //   field: 'postage',
    //   headerName: '送料',
    //   width: 100,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: getLocaledPostage,
    // },
    { field: 'design_create_at', headerName: '作成日時', width: 160, headerAlign: 'center', align: 'center' },
    { field: 'design_update_at', headerName: '最終更新日時', width: 160, headerAlign: 'center', align: 'center' },
    {
      field: 'editBtn',
      headerName: '編集',
      width: 80,
      headerAlign: 'center',
      align: 'center',
      renderCell: (param) => (
        <button
          onClick={() => {
            openDesignEditPage(param.row.item_type, param.row.design_serial);
          }}
        >
          編集
        </button>
      ),
    },
    {
      field: 'cartAddBtn',
      headerName: '購入',
      width: 80,
      headerAlign: 'center',
      align: 'center',
      renderCell: (param) => (
        <button
          onClick={() => {
            // カートに追加する
            dispatch(insertCart(param.row.item_id, 1, param.row.design_serial, 'saved-design'));
          }}
          style={
            {
              // backgroundColor: '#f6e052',
              // color: 'gray',
              // fontWeight: 'bold',
              // border: '1px solid gray',
              // fontFamily: 'sans-serif',
              // fontSize: '16px',
              // borderRadius: '3px',
            }
          }
        >
          購入
        </button>
      ),
    },
  ];

  // DataGrid用IDをリストの各データに付与
  const addGridRowIdToListData = (listData) => {
    const tmpListData = listData;
    if (tmpListData) {
      for (let i = 0; i < tmpListData.length; i++) {
        tmpListData[i]['id'] = i;
      }
    }
    return tmpListData;
  };

  useEffect(async () => {
    // 保存されたデザインを取得
    const designData = await getSavedDesigns();
    // console.log(designData);

    // DataGrid用IDを付与
    const invitationData = addGridRowIdToListData(designData['invitation']);
    const seatingData = addGridRowIdToListData(designData['seating']);
    // console.log(invitationData);

    // Stateにセット
    setSavedInvitationDesigns(invitationData); /* 招待状 */
    setSavedSeatingDesigns(seatingData); /* 席次表 */
  }, []);

  return (
    <DocumentMeta {...meta}>
      <div id="saved_design_page">
        <BreadCrumb floors={Floors} />
        <h1>保存したデザイン</h1>
        <p>「購入」ボタンを押すと、作成したデザインが買い物カゴに追加されます。</p>
        <p>
          招待状・席次表は個別注文のみお受けしております。
          <br />
          他の一般商品との同時注文はできませんのでご注意ください。
        </p>
        <div>
          <div className="design_list" style={{ minHeight: '200px' }}>
            <div className="design_list_title">保存した招待状デザイン</div>
            {!savedInvitationDesigns ||
              (savedInvitationDesigns.length === 0 && <p>保存した招待状デザインはありません。</p>)}
            {savedInvitationDesigns && savedInvitationDesigns.length !== 0 && (
              <>
                <div className="data_grid_wrapper">
                  <DataGrid
                    autoHeight // グリッドの行数時応じて変化
                    // style={{ maxHeight: tableHeight, height: tableHeight }} // 高さ指定
                    pageSize={10}
                    // autoPageSize // pageSizeは最大行事応じて計算される
                    // checkboxSelection // チェックボックス
                    // checkboxSelectionVisibleOnly={(e)=>(console.log(e))} //チェックボックス選択時
                    // columnBuffer={10}
                    // rowBuffer={10}
                    density="comfortable" // 行の高さ compact standard comfortable
                    // disableExtendRowFullWidth // コンテナいっぱいに表示させない
                    disableColumnFilter // フィルター非表示
                    // editMode="cell"
                    // logger={console}
                    // onCellClick={(e) => console.log("click", e.row)}
                    // rowSpacingType="border"
                    // disableMultipleColumnsFiltering // 複数フィルタリング
                    disableMultipleColumnsSorting={true} // 複数ソート
                    disableSelectionOnClick // データの選択を無効にする
                    showCellRightBorder
                    showColumnRightBorder
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                    // components={
                    //   // props.data.Toolbar && {
                    //     Toolbar: GridToolbar
                    //   // }
                    // }
                    // components={{
                    //   Toolbar: GridToolbar, // ツールバーを指定する
                    // }}
                    columns={tableCols}
                    rows={savedInvitationDesigns}
                  />
                </div>
              </>
            )}
          </div>
          <div className="design_list" style={{ minHeight: '200px' }}>
            <div className="design_list_title">保存した席次表デザイン</div>
            {!savedSeatingDesigns || (savedSeatingDesigns.length === 0 && <p>保存した席次表デザインはありません。</p>)}
            {savedSeatingDesigns && savedSeatingDesigns.length !== 0 && (
              <>
                <div className="data_grid_wrapper">
                  <DataGrid
                    autoHeight // グリッドの行数時応じて変化
                    // style={{ maxHeight: tableHeight, height: tableHeight }} // 高さ指定
                    pageSize={10}
                    // autoPageSize // pageSizeは最大行事応じて計算される
                    // checkboxSelection // チェックボックス
                    // checkboxSelectionVisibleOnly={(e)=>(console.log(e))} //チェックボックス選択時
                    // columnBuffer={10}
                    // rowBuffer={10}
                    density="comfortable" // 行の高さ compact standard comfortable
                    // disableExtendRowFullWidth // コンテナいっぱいに表示させない
                    disableColumnFilter // フィルター非表示
                    // editMode="cell"
                    // logger={console}
                    // onCellClick={(e) => console.log("click", e.row)}
                    // rowSpacingType="border"
                    // disableMultipleColumnsFiltering // 複数フィルタリング
                    disableMultipleColumnsSorting={true} // 複数ソート
                    disableSelectionOnClick // データの選択を無効にする
                    showCellRightBorder
                    showColumnRightBorder
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                    // components={
                    //   // props.data.Toolbar && {
                    //     Toolbar: GridToolbar
                    //   // }
                    // }
                    // components={{
                    //   Toolbar: GridToolbar, // ツールバーを指定する
                    // }}
                    columns={tableCols}
                    rows={savedSeatingDesigns}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
};

export default SavedDesigns;
