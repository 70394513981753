import React, { useEffect, useState } from 'react';
import DocumentMeta from 'react-document-meta';
import {
  addSubItemInfo,
  deleteCart,
  fetchLatestCartInfo,
  updateCartOptions,
  updateCartType,
} from '../../reducks/carts/operations';
import { itemListLink } from '../../reducks/items/operations';
import { useDispatch, useSelector } from 'react-redux';
import { BreadCrumb, OrderFlow } from '../../components';
import { SiteTitle } from './common';
import { ImagesDir, RouteDir, ItemImageDir, ApiDir, PROGRAM_ERROR_ALERT_MSG } from '../../common';
import { push } from 'connected-react-router';
import { calcItemTaxIncludedPrice } from '../../myLib';
import axios from 'axios';
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import { changeLoading } from '../../reducks/pageInfos/operations';
import { arrayOf } from 'prop-types';
import { addSubItemInfoAction } from '../../reducks/carts/actions';
import { config } from '../../config';
import CartSubItemTable from '../../components/public/CartSubItemTable';
import MemberGuestListTable from '../../components/public/MemberGuestListTable';
import CartShippingFeeBox from '../../components/public/CartShippingFeeBox';
import CartExpressDeliveryFeeBox from '../../components/public/CartExpressDeliveryFeeBox';

const CartList = () => {
  const dispatch = useDispatch();

  // 招待状用DataGridデータ
  const [invitationSubItemData, setInvitationSubItemData] = useState([
    {
      subItemName: '招待状',
      subItemType: 'invitation',
      unitPrice: 0,
      quantityType: 'selectBox',
      quantitySelectOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200],
      quantity: 20,
      totalSubItemPrice: 0,
    },
    {
      subItemName: '返信はがき',
      subItemType: 'postcard',
      unitPrice: 0,
      quantityType: 'static',
      quantity: 0 /* 招待状枚数と同数 */,
      totalSubItemPrice: 0,
    },
    {
      subItemName: '封筒',
      subItemType: 'envelope',
      unitPrice: 0,
      quantityType: 'static' /* 宛名数 */,
      quantity: 0,
      totalSubItemPrice: 0,
    },
    {
      subItemName: '封筒予備',
      subItemType: 'envelope_spare',
      unitPrice: 0,
      quantityType: 'editable' /* 宛名数 */,
      quantity: 0,
      totalSubItemPrice: 0,
    },
    {
      subItemName: '付箋',
      subItemType: 'fusen',
      unitPrice: 0,
      quantityType: 'static' /* 保存データから自動計算 */,
      quantity: 0,
      totalSubItemPrice: 0,
    },
  ]); /* 招待状サブアイテムデータ */

  // 席次表用DataGridデータ
  const [seatingSubItemData, setSeatingSubItemData] = useState([
    {
      subItemName: '席次表',
      subItemType: 'seating',
      unitPrice: 0,
      quantityType: 'editable',
      quantity: '',
      totalSubItemPrice: 0,
    },
  ]); /* 席次表サブアイテムデータ */

  const [isSubItemDataPrepared, setIsSubItemDataPrepared] = useState(false); /* サブアイテムデータ準備完了フラグ */

  const [isExpressDelivery, setIsExpressDelivery] = useState(false); /* 特急配送フラグ */

  const [guestList, setGuestList] = useState();
  const cartType = useSelector((state) => state.carts.cartType);
  const items = useSelector((state) => state.carts.list);
  const subItemInfo = useSelector((state) => state.carts.subItemInfo);
  const cartOptions = useSelector((state) => state.carts.options);

  // サブアイテムの個数を更新
  const updateSubItemQuantity = (itemType, inputQuantityValue, subItemType) => {
    /**
     * 招待状枚数変更時に返信はがき・封筒枚数を同数に更新
     * @param {number} invitationQuantity 招待状枚数
     */
    const handleInvitationQuantityChange = (invitationQuantity) => {
      // console.log(invitationQuantity);

      /**
       * 返信はがき・封筒の枚数を招待状枚数と同数に設定
       *    stateの該当箇所のみ更新
       * @see https://amateur-engineer.com/react-usestate-object-update/#toc3
       */
      setInvitationSubItemData((prev) =>
        prev.map((data) => {
          if (data.subItemType === 'postcard' || data.subItemType === 'envelope') {
            return { ...data, quantity: invitationQuantity };
          } else {
            return data;
          }
        })
      );
    };

    if (subItemType === 'postcard' || subItemType === 'envelope') {
      return;
    }

    let newQuantity; /* 新しい個数 */
    let totalSubItemPrice; /* サブアイテム合計価格 */

    // 商品種別によって対象サブアイテムデータソースを切り替え
    let subItemDataList;
    if (itemType === 'invitation') {
      subItemDataList = invitationSubItemData;
    } else if (itemType === 'seating') {
      subItemDataList = seatingSubItemData;
    } else {
      console.error('itemType error');
      return;
    }

    // 該当サブアイテムデータを取得
    const targetSubItemData = subItemDataList.find((subItemData) => subItemData.subItemType === subItemType);
    // console.log(targetSubItemData);
    if (typeof targetSubItemData === 'undefined') {
      window.alert(PROGRAM_ERROR_ALERT_MSG);
      console.error('subItemData error');
      return;
    }

    // 正しい個数が入力されているか検証
    if (/^[0-9]{1,8}$/.test(inputQuantityValue)) {
      // 単価を取得
      const unitPrice = targetSubItemData.unitPrice;

      newQuantity = Number(inputQuantityValue); /* 個数 */
      totalSubItemPrice = unitPrice * newQuantity; /* 合計価格 */
    } else {
      console.error(`sub item[${subItemType}] quantity is not valid: ${inputQuantityValue}`);

      // 不正な個数
      newQuantity = inputQuantityValue;

      // 不正な個数なので税込価格には0をセット
      totalSubItemPrice = 0;
    }

    // 該当サブアイテムデータを更新
    if (itemType === 'invitation') {
      // 商品種別：招待状の場合
      setInvitationSubItemData((prev) =>
        prev.map((data) =>
          data.subItemType === subItemType
            ? { ...data, quantity: newQuantity, totalSubItemPrice: totalSubItemPrice }
            : data
        )
      );
    } else if (itemType === 'seating') {
      // 商品種別：席次表の場合
      setSeatingSubItemData((prev) =>
        prev.map((data) =>
          data.subItemType === subItemType
            ? { ...data, quantity: newQuantity, totalSubItemPrice: totalSubItemPrice }
            : data
        )
      );
    }

    // 招待状の場合は、封筒・返信はがきの枚数も同数に設定
    if (subItemType === 'invitation') {
      handleInvitationQuantityChange(newQuantity);
    }
  };

  // サブアイテム価格・小計を初期設定
  const initSubItemPrices = async () => {
    // DBからサブアイテム価格を取得
    const fetchSubItemPrices = () => {
      return new Promise((resolve, reject) => {
        let params = new URLSearchParams();
        params.append('item_id', items[0].item_id);
        params.append('item_type_id', items[0].item_type_id);

        axios
          .post(ApiDir + '/selectSubItemPrices.php', params)
          .then((response) => {
            // console.log(response.data);
            resolve(response.data[0]);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    };

    // サブアイテム税込単価を設定
    const setSubItemPrice = (itemType, subItemType, subItemPrice, taxRate) => {
      // 税込単価を計算
      const unitPrice = calcItemTaxIncludedPrice(subItemPrice, taxRate);

      // 該当サブアイテムの税込単価を設定
      if (itemType === 'invitation') {
        // 招待状の場合
        setInvitationSubItemData((prev) =>
          prev.map((data) => (data.subItemType === subItemType ? { ...data, unitPrice: unitPrice } : data))
        );
      } else if (itemType === 'seating') {
        // 席次表の場合
        setSeatingSubItemData((prev) =>
          prev.map((data) => (data.subItemType === subItemType ? { ...data, unitPrice: unitPrice } : data))
        );
      }
    };

    // DBからサブアイテム価格を取得
    const subItemPrices = await fetchSubItemPrices();
    // console.log(subItemPrices);

    if (!subItemPrices) {
      console.log('faild to get sub item info');
      return;
    }

    // 商品税率
    const taxRate = Number(items[0].tax_value);

    // 各サブアイテム税込単価を設定
    if (cartType === 'invitation') {
      setSubItemPrice('invitation', 'invitation', subItemPrices.invitation_price, taxRate);
      setSubItemPrice('invitation', 'postcard', subItemPrices.postcard_price, taxRate);
      setSubItemPrice('invitation', 'envelope', subItemPrices.envelope_price, taxRate);
      setSubItemPrice('invitation', 'envelope_spare', subItemPrices.envelope_spare_price, taxRate);
      setSubItemPrice('invitation', 'fusen', subItemPrices.fusen_price, taxRate);

      // サブアイテム小計を計算
      calcTotalSubItemPrice('invitation');
    } else if (cartType === 'seating') {
      setSubItemPrice('seating', 'seating', subItemPrices.seating_price, taxRate);

      // サブアイテム小計を計算
      calcTotalSubItemPrice('seating');
    }
  };

  /**
   * 宛名数をもとに招待状数量を初期設定
   * @param {number} guestNum 宛名数
   */
  const initInvitationQuantity = (guestNum) => {
    // 宛名数から10枚単位の招待状数量を割り出す
    const calcInvitationQuantity = (guestNum) => {
      // 招待状は20枚以上で10枚単位
      if (guestNum < 20) {
        return 20;
      } else {
        if (guestNum % 10 === 0) {
          // 10以上で割り切れる場合はそのまま
          return guestNum;
        } else {
          // 10で割り切れない場合は、10単位で最も近い数
          return 10 * (Math.floor(guestNum / 10) + 1);
        }
      }
    };

    // 招待状：初期数量
    const invitationQuantity = calcInvitationQuantity(guestNum);

    // 招待状数量を設定
    updateSubItemQuantity('invitation', invitationQuantity, 'invitation');
  };

  // すべてのサブアイテムの小計を計算
  const calcTotalSubItemPrice = (itemType) => {
    // 計算処理
    const calc = (subItemData) => {
      return subItemData.map((data) => {
        // console.log(data);
        const unitPrice = data.unitPrice; /* 税込単価 */
        const quantity = data.quantity; /* 数量 */

        // 小計を計算
        const totalSubItemPrice = unitPrice * quantity;

        // 小計のみ更新
        return { ...data, totalSubItemPrice: totalSubItemPrice };
      });
    };

    // stateを更新
    if (itemType === 'invitation') {
      // 招待状の場合
      setInvitationSubItemData((prev) => calc(prev));
    } else if (itemType === 'seating') {
      // 席次表の場合
      setSeatingSubItemData((prev) => calc(prev));
    }
  };

  // 招待状商品：付箋合計枚数を取得
  const initFusenNum = async () => {
    // DBから該当招待状デザインの付箋合計枚数を取得
    const fetchFusenInfo = (designSerial) => {
      return new Promise((resolve, reject) => {
        let params = new URLSearchParams();
        params.append('design_serial', designSerial);

        axios
          .post(ApiDir + '/selectTotalFusenNum.php', params)
          .then((response) => {
            // console.log(response.data);
            resolve(response.data[0]);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    };

    // 付箋合計枚数を計算（「必要」のみ）
    const calcTotalFusenNum = (fusenInfo) => {
      // 付箋名リストを作成
      const fusenNameList = Object.keys(fusenInfo).filter((data) => data.match(/_tag$/));

      // 合計付箋枚数
      let totalFusenNum = 0;

      // 各付箋の情報をチェック
      fusenNameList.map((fusenName) => {
        // 該当付箋が「必要」の場合
        if (fusenInfo[fusenName] === '1') {
          // 該当付箋枚数を取得
          const fusenNum = Number(fusenInfo[`${fusenName}_number`]);

          // 合計付箋枚数に加算
          totalFusenNum += fusenNum;
        }
      });

      return totalFusenNum;
    };

    // 付箋数量のみ更新
    const updateFusenQuantity = (fusenQuantity) => {
      setInvitationSubItemData((prev) =>
        prev.map((data) => (data.subItemType === 'fusen' ? { ...data, quantity: fusenQuantity } : data))
      );
    };

    // DBから該当招待状デザインの付箋合計枚数を取得
    const fusenInfo = await fetchFusenInfo(items[0].design_serial);
    // console.log(fusenInfo);

    if (!fusenInfo) {
      console.log('fusenInfo error');
      return;
    }

    // 付箋合計枚数を計算（「必要」のみ）
    const totalFusenNum = calcTotalFusenNum(fusenInfo);
    // console.log(totalFusenNum);

    // サブアイテムデータの付箋数量を更新
    updateFusenQuantity(totalFusenNum);
    // updateSubItemQuantity('invitation', totalFusenNum, 'fusen');
  };

  /**
   * 最新の宛名情報を取得してStateにセット
   * @returns {boolean} 宛名データ取得結果
   */
  const loadMemberGuestList = async () => {
    // DBから登録済みの宛名リストを取得
    const fetchMemberGuestList = () => {
      return new Promise((resolve, reject) => {
        axios
          .post(ApiDir + '/selectMemberGuests.php')
          .then((response) => {
            // console.log(response.data);
            if (response.data === 'nologin') {
              window.alert('ログインしてください。');
              dispatch(push(`${RouteDir}/login`));
            } else if (Array.isArray(response.data)) {
              return resolve(response.data);
            } else {
              window.alert('宛名リストの取得に失敗しました。');
              return resolve([]);
            }
          })
          .catch((error) => {
            console.log(error);
            return resolve([]);
          });
      });
    };

    // DBから登録済みの宛名リストを取得
    const memberGuestList = await fetchMemberGuestList();

    setGuestList(memberGuestList);

    if (memberGuestList.length === 0) {
      // 宛名データ未登録
      console.log('no guest data');
      return false;
    } else {
      // Stateにセット
      // 宛名データ取得成功
      return true;
    }
  };

  // 1. ページ読み込み時の処理
  useEffect(() => {
    // 最新のカート情報をサーバから取得（売り切れ確認）
    dispatch(fetchLatestCartInfo());
  }, []);

  // 2. 買い物カゴ内の商品データ読み込み後の処理
  useEffect(() => {
    // カート種別を判定（招待状・席次表が含まれているか）
    const judgeCartType = (itemsArray) => {
      // 招待状が商品に含まれるか検索
      const invitationItem = itemsArray.find((item) => item.item_type_id === '2');

      // 招待状が含まれる場合
      if (typeof invitationItem !== 'undefined') return 'invitation';

      // 席次表が商品に含まれるか検索
      const seatingItem = itemsArray.find((item) => item.item_type_id === '3');

      // 席次表が含まれる場合
      if (typeof seatingItem !== 'undefined') return 'seating';

      return 'normal';
    };

    // カート種別を判定（招待状・席次表が含まれているか）
    const judgedCartType = judgeCartType(items);

    // ストアのカート種別を更新
    dispatch(updateCartType(judgedCartType));
    return;
  }, [items]);

  // 3. カート種別判定後の処理
  useEffect(async () => {
    if (cartType === '' || cartType === 'normal') return;

    // 招待状の場合は、宛名リストデータを読み込む
    if (cartType === 'invitation') {
      // 宛名リストデータ読み込み処理
      loadMemberGuestList().then((result) => {
        if (!result) {
          // window.alert('宛名が登録されていません。');
          // return;
        }
      });
    }

    // ストアにサブアイテムの入力情報がすでに存在するか確認
    if (subItemInfo.subItemData.length === 0) {
      // ストアにサブアイテムの入力情報が存在しない場合は初期化

      // 招待状の場合
      if (cartType === 'invitation') {
        // 付箋枚数を初期設定（招待状デザインデータを参照）
        await initFusenNum();
      }

      // サブアイテム価格・小計を初期設定
      initSubItemPrices();

      // console.log(invitationSubItemData);
      // console.log(seatingSubItemData);
    } else {
      // 存在する場合はその情報をセット
      if (cartType === 'invitation') {
        // 招待状の場合
        setInvitationSubItemData(subItemInfo.subItemData);
      } else if (cartType === 'seating') {
        // 席次表の場合
        setSeatingSubItemData(subItemInfo.subItemData);
      }
    }
    // console.log(invitationSubItemData);

    // サブアイテムデータ準備完了
    setIsSubItemDataPrepared(true);
  }, [cartType]);

  // 4. 宛名リスト取得完了後の処理
  useEffect(() => {
    // console.log(guestList);
    // 宛名が読み込まれていない場合
    if (typeof guestList === 'undefined') return;

    // 宛名数を取得
    const guestNum = guestList.length;

    // 招待状枚数を初期設定
    // console.log(guestNum);
    initInvitationQuantity(guestNum);
  }, [guestList]);

  // useEffect(() => {
  //   // console.log(items);
  //   // 買い物カゴの商品が読み込まれていない場合は何もしない
  //   if (items.length === 0) return;

  //   // カート種別未判定の場合
  //   if (cartType === '') {
  //     // // カート種別を判定（招待状・席次表が含まれているか）
  //     // const judgeCartType = (itemsArray) => {
  //     //   // 招待状が商品に含まれるか検索
  //     //   const invitationItem = itemsArray.find((item) => item.item_type_id === '2');
  //     //   // 招待状が含まれる場合
  //     //   if (typeof invitationItem !== 'undefined') return 'invitation';
  //     //   // 席次表が商品に含まれるか検索
  //     //   const seatingItem = itemsArray.find((item) => item.item_type_id === '3');
  //     //   // 席次表が含まれる場合
  //     //   if (typeof seatingItem !== 'undefined') return 'seating';
  //     //   return 'normal';
  //     // };
  //     // // カート種別を判定（招待状・席次表が含まれているか）
  //     // const judgedCartType = judgeCartType(items);
  //     // // ストアのカート種別を更新
  //     // dispatch(updateCartType(judgedCartType));
  //     // return;
  //   } else {
  //     // カート種別判定済み

  //     // console.log(invitationSubItemData);
  //     // console.log('cartType: ', cartType);
  //     // console.log(subItemInfo);
  //     // console.log(items);

  //     // カート種別がnormal以外の場合
  //     if (cartType !== 'normal') {
  //       // // ストアにサブアイテムの入力情報がすでに存在するか確認
  //       // if (subItemInfo.subItemData.length === 0) {
  //       //   // 存在しない場合は初期化
  //       //   // initInvitationSubItemData(invitationSubItemData);
  //       //   // サブアイテム価格を初期設定
  //       //   initSubItemPrices();
  //       //   if (cartType === 'invitation') {
  //       //     // 付箋枚数を初期設定
  //       //     initFusenNum();
  //       //   }
  //       //   // console.log(invitationSubItemData);
  //       //   // console.log(seatingSubItemData);
  //       // } else {
  //       //   // 存在する場合はその情報をセット
  //       //   if (cartType === 'invitation') {
  //       //     // 招待状の場合
  //       //     setInvitationSubItemData(subItemInfo.subItemData);
  //       //   } else if (cartType === 'seating') {
  //       //     // 席次表の場合
  //       //     setSeatingSubItemData(subItemInfo.subItemData);
  //       //   }
  //       // }
  //       // // console.log(invitationSubItemData);
  //       // setIsSubItemDataPrepared(true);
  //     }
  //   }
  // }, [cartType, items]);

  // ストアのカートオプションデータをStateにセット
  useEffect(() => {
    // 特急配送指定
    setIsExpressDelivery(cartOptions.isExpressDelivery);
  }, [cartOptions]);

  const itemListLinkBtn = () => {
    dispatch(itemListLink());
  };

  const deleteConfirm = (cartId) => {
    const result = window.confirm('買い物カゴから削除してよろしいですか？');
    if (result) {
      dispatch(deleteCart(cartId, cartType));
    } else {
      return false;
    }
  };

  // 購入手続きへ
  const goToNextPage = () => {
    // 在庫チェック
    for (let i = 0; i < items.length; i++) {
      // console.log(items[i])
      // console.log(items[i].stock_quantity)
      if (Number(items[i].stock_quantity) === 0 || Number(items[i].quantity) > Number(items[i].stock_quantity)) {
        window.alert(
          '「売り切れ」または「在庫不足」の商品があります。\n売り切れ商品：買い物カゴから削除してください。\n在庫不足商品：数量を在庫数以内に調整してください。'
        );
        return;
      }
    }

    // 各サブアイテムの個数が正常に入力されているかどうか検証
    const validateSubItemQuantities = (cartType, subItemData) => {
      for (let i = 0; i < subItemData.length; i++) {
        const data = subItemData[i];
        // console.log(data.quantity);
        // 正しい数字が入力されているか
        if (!/^[0-9]{1,8}$/.test(data.quantity)) {
          return false;
        }

        // 席次表商品の場合、席次表数量が0でないか
        if (cartType === 'seating' && data.subItemType === 'seating') {
          if (data.quantity === 0) {
            return false;
          }
        }
      }
      return true;
    };

    // 宛名数以上の招待状数量が指定されているかどうかを確認
    const validateInvitationQuantity = (subItemData) => {
      const invitationData = subItemData.find((data) => data.subItemType === 'invitation');

      // 招待状データを取得できない場合
      if (!invitationData) {
        window.alert(PROGRAM_ERROR_ALERT_MSG);
        console.error('invitation quantity error');
        return false;
      }

      // 宛名数
      const guestNumber = guestList.length;

      // 招待状数量
      const invitationQuantity = invitationData.quantity;

      // console.log(guestNumber, invitationQuantity, invitationQuantity >= guestNumber);

      // 宛名数以上の招待状数量が指定されているかどうか
      return invitationQuantity >= guestNumber;
    };

    // 宛名リストが正しく登録されているかどうかを確認
    const validateGuestList = () => {
      return typeof guestList !== 'undefined' && guestList.length !== 0;
    };

    // 招待状または席次表の場合
    if (cartType === 'invitation' || cartType === 'seating') {
      if (cartType === 'invitation') {
        if (!validateGuestList()) {
          window.alert('宛名を登録してください。');
          return;
        }
      }

      // 対象のサブアイテムデータを取得
      let targetSubItemData;
      if (cartType === 'invitation') targetSubItemData = invitationSubItemData;
      if (cartType === 'seating') targetSubItemData = seatingSubItemData;

      // サブアイテムの数量チェック
      if (!validateSubItemQuantities(cartType, targetSubItemData)) {
        window.alert('数量を正しく入力してください。');
        return;
      }

      // 招待状注文の場合
      if (cartType === 'invitation') {
        // 宛名数以上の招待状数量が指定されているかどうかを確認
        if (!validateInvitationQuantity(targetSubItemData)) {
          window.alert('宛名数以上の招待状数量を指定してください。');
          return;
        }
      }

      // ストアにサブアイテムデータを登録
      const itemType = cartType;
      dispatch(addSubItemInfo(itemType, targetSubItemData));
    }

    // ストアに特急配送指定情報を登録
    // console.log({ ...cartOptions, isExpressDelivery: isExpressDelivery });
    dispatch(
      updateCartOptions({
        ...cartOptions,
        expressDeliveryFee: isExpressDelivery ? config.EXPRESS_DELIVERY_FEE : 0,
        isExpressDelivery: isExpressDelivery,
      })
    );

    // お届け先入力画面に遷移
    dispatch(push(RouteDir + '/mypage/add_order_post_address'));
  };

  const meta = {
    title: SiteTitle,
  };

  const Floors = [
    {
      name: '買い物カゴ',
      href: '/mypage/cart_list',
    },
  ];

  return (
    <DocumentMeta {...meta}>
      <div id="cart_list_page">
        <BreadCrumb floors={Floors} />
        <main className="cart_list_content">
          <h1>買い物カゴ</h1>
          <OrderFlow activeKey={0} />
          {Array.isArray(items) && items.length !== 0 ? (
            <>
              <p className="introduction">
                各商品の数量を確認して「購入手続き」へお進みください。
                <br />
                ※ここから先の画面では、画面の再読み込み（リロード）を行わないでください。
              </p>

              {/* 招待状の場合 */}
              {cartType === 'invitation' && (
                <>
                  {/* 各種アイテムの数量入力 */}
                  {isSubItemDataPrepared && (
                    <>
                      <CartSubItemTable
                        cartType={cartType}
                        subItemData={invitationSubItemData}
                        updateSubItemQuantity={updateSubItemQuantity}
                      />
                      <p style={{ fontSize: '0.9em' }}>
                        ※返信はがきと封筒の数量は、招待状の数量と同一になります。
                        <br />
                        ※封筒は10枚単位となっており、余った分の封筒には宛名は印字されません。
                        <br />
                        ※付箋の枚数を変更する場合は、デザイン画面の付箋タブから変更してください。
                      </p>
                    </>
                  )}
                </>
              )}

              {/* 席次表の場合 */}
              {cartType === 'seating' && (
                <>
                  {/* 各種アイテムの数量入力 */}
                  {isSubItemDataPrepared && (
                    <CartSubItemTable
                      cartType={cartType}
                      subItemData={seatingSubItemData}
                      updateSubItemQuantity={updateSubItemQuantity}
                    />
                  )}
                </>
              )}

              {/* 招待状・席次表共通 */}
              {(cartType === 'invitation' || cartType === 'seating') && (
                <>
                  {/* 送料 */}
                  <CartShippingFeeBox shippingFee={items[0].postage} />

                  {/* 特急指定 */}
                  <CartExpressDeliveryFeeBox
                    canSelect={true}
                    isExpressDelivery={isExpressDelivery}
                    setIsExpressDelivery={setIsExpressDelivery}
                    expressDeliveryFee={config.EXPRESS_DELIVERY_FEE}
                  />

                  {/* 「買い物カゴから削除」ボタン */}
                  <button className="delete_btn" onClick={() => deleteConfirm(items[0].cart_id)}>
                    買い物カゴから削除
                  </button>
                </>
              )}

              {/* 招待状の場合 */}
              {cartType === 'invitation' && (
                // 宛名リスト
                <div className="guest_list_wrapper">
                  {typeof guestList === 'undefined' ? (
                    <>読み込み中</>
                  ) : (
                    <>
                      {guestList.length === 0 ? (
                        <div className="loaded_result_title">
                          <p style={{ textAlign: 'center' }}>
                            {/* 宛名が登録されていません。
                            <br /> */}
                            <a
                              onClick={() => {
                                dispatch(push(RouteDir + '/mypage/guest_add_excel'));
                              }}
                              style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                            >
                              宛名登録画面
                            </a>
                            から宛名を登録してください。
                          </p>
                        </div>
                      ) : (
                        <>
                          <div className="loaded_result_title">
                            <p style={{ textAlign: 'center' }}>ご登録いただいた以下の宛名が使用されます。</p>
                          </div>
                          <MemberGuestListTable guestList={guestList} />
                          <p style={{ fontSize: '0.9em' }}>
                            ※宛名を更新する場合は、宛名登録画面から再度宛名を登録してください。
                          </p>
                        </>
                      )}
                    </>
                  )}
                </div>
              )}

              <section className="list_type_2">
                {/* 招待状・席次表以外の場合 */}
                {cartType === 'normal' && (
                  <>
                    <div className="scroll_wrap">
                      <table>
                        <thead>
                          <tr>
                            <th className="item_name_th">商品名</th>
                            <th>金額（税込）</th>
                            <th>数量</th>
                            <th className="delete_btn_th">&ensp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(items) &&
                            items.map((item, i) => (
                              <tr key={i}>
                                <td
                                  //  className={Number(item.stock_quantity) === '0'
                                  //     ? "item_image_name_box nostock"
                                  //     : "item_image_name_box"
                                  //   }
                                  className={(() => {
                                    if (Number(item.stock_quantity) <= 0) {
                                      return 'item_image_name_box nostock';
                                    } else if (Number(item.quantity) > Number(item.stock_quantity)) {
                                      return 'item_image_name_box shortage';
                                    } else {
                                      return 'item_image_name_box';
                                    }
                                  })()}
                                >
                                  <div className="thumbnail_area">
                                    <div>
                                      <img
                                        src={
                                          item.path === null
                                            ? process.env.PUBLIC_URL + '/images/noimage.jpg'
                                            : ItemImageDir + item.path
                                        }
                                        alt={item.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="item_info_wrap">
                                    <div className="item_info_content">
                                      <p className="name">{item.name}</p>
                                      <p className="standard">{item.standard}</p>
                                      <p className="standard">
                                        在庫数：
                                        {item.stock_quantity <= 0 ? 0 : Number(item.stock_quantity).toLocaleString()}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="price_box">
                                  ￥{calcItemTaxIncludedPrice(item.price, item.tax_value).toLocaleString()}
                                </td>
                                <td className="quantity_box">{item.quantity}個</td>
                                <td className="delete_btn_box">
                                  <button className="delete_btn" onClick={() => deleteConfirm(item.cart_id)}>
                                    削除
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <p className="sp_txt">横スクロールできます。</p>
                  </>
                )}
                <div className="btn_area">
                  <button className="item_list_link_btn" onClick={() => itemListLinkBtn()}>
                    買い物を続ける
                  </button>
                  <button className="order_post_info_link_btn" onClick={() => goToNextPage()}>
                    購入手続きへ
                  </button>
                  {/* <button className="order_post_info_link_btn" onClick={()=>dispatch(push(RouteDir+'/mypage/add_order_post_address'))}>購入手続きへ</button> */}
                </div>
              </section>
            </>
          ) : (
            <p>買い物カゴに何も入っていません。</p>
          )}
        </main>
      </div>
    </DocumentMeta>
  );
};

export default CartList;
