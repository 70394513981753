import { push } from 'connected-react-router';
import React from 'react';
import DocumentMeta from 'react-document-meta';
import { useDispatch } from 'react-redux';
import { RouteDir } from '../../common';
import { BreadCrumb } from '../../components';
import { SiteTitle } from './common';

const Howto = () => {
  const dispatch = useDispatch();

  const meta = {
    title: SiteTitle,
  };

  const Floors = [
    {
      name: 'ご利用ガイド',
      href: '/howto',
    },
  ];

  const textAreaStyle = {
    padding: '0 20px',
  };

  // 画面遷移用リンクスタイル
  const linkStyle = {
    textDecoration: 'underline',
    color: 'blue',
    cursor: 'pointer',
  };

  return (
    <DocumentMeta {...meta}>
      <div id="howto_page">
        <BreadCrumb floors={Floors} />
        <main className="howto_content">
          <h1>ご利用ガイド</h1>
          {/* <section>
            <h2>商品代金以外に必要な費用</h2>
            <div className="text_area">
              <p>商品代金以外に下記料金が別途かかります。</p>
              <ul>
                <li>消費税</li>
                <li>送料</li>
              </ul>
            </div>
          </section> */}
          <section>
            <h2 style={{ color: '#6698cb' }}>招待状・席次表デザイン作成方法</h2>
            <div
              className="text_area"
              style={{ ...textAreaStyle, border: '2px solid #6698cb', backgroundColor: '#6698cb33' }}
            >
              <p style={{ textDecoration: 'gray wavy underline' }}>
                ※デザイン画面はパソコンから操作することを想定しております。
              </p>
              <ul>
                <li>
                  招待状・席次表の商品画面から「デザインを作成する」ボタンをクリックして、デザイン画面を開きます。
                </li>
                <li>
                  画面左のタブを切り替えて、以下アイテムの必要項目をすべて入力します。
                  <br />
                  画面右側に表示されるアイテム画像を確認しながら、入力を進めてください。
                </li>
                <div style={{ margin: '20px' }}>
                  招待状商品
                  <ul style={{ listStyle: 'square' }}>
                    <li>招待状</li>
                    <li>封筒</li>
                    <li>返信はがき</li>
                    <li>付箋</li>
                  </ul>
                </div>
                <div style={{ margin: '20px' }}>
                  席次表商品
                  <ul style={{ listStyle: 'square' }}>
                    <li>席次表</li>
                  </ul>
                </div>
                <li>
                  画面上部の「保存する」ボタンをクリックして、デザインを保存します。
                  <br />
                  ※デザインが保存されると、画面上部に「PDF出力ボタン」が表示されます。
                </li>
                <li>
                  「PDF出力」ボタンをクリックして、確認用PDFファイルを出力します。
                  <br />
                  ※PCの性能によっては1分ほどかかる場合がございますが、画面を操作せずにそのままお待ちください。
                </li>
                <li>
                  確認用PDFファイルを以下の方法等で、
                  <span style={{ textDecoration: '#6698cb wavy underline' }}>
                    式場の担当者に確認を依頼してください。
                  </span>
                </li>
                <div style={{ margin: '20px' }}>
                  <ul style={{ listStyle: 'square' }}>
                    <li>確認用PDFを印刷して、式場に持参する。</li>
                    <li>確認用PDFを式場担当者宛にメールで送信する。</li>
                  </ul>
                </div>
                <li>
                  画面上部の「購入に進む」ボタンをクリックすると、購入手続きに進みます。
                  <br />
                  ※保存したデザイン画面に遷移します。
                </li>
              </ul>
            </div>
          </section>
          <section>
            <h2 style={{ color: '#c6a886' }}>宛名登録方法</h2>
            <div
              className="text_area"
              style={{ ...textAreaStyle, border: '2px solid #c6a886', backgroundColor: '#c6a88633' }}
            >
              <p>
                <span
                  style={linkStyle}
                  onClick={() => {
                    dispatch(push(`${RouteDir}/mypage/guest_add_excel`));
                  }}
                >
                  宛名登録画面
                </span>
                の1～4の手順に従って、宛名を登録してください。
              </p>
              <p>
                登録された宛名情報は、
                <span
                  style={linkStyle}
                  onClick={() => {
                    dispatch(push(`${RouteDir}/mypage/guest_list`));
                  }}
                >
                  宛名リスト画面
                </span>
                からご確認いただけます。
              </p>
              <p style={{ textDecoration: 'gray wavy underline' }}>※招待状の注文には、宛名登録が必須となります。</p>
            </div>
          </section>
          <section>
            <h2>注文方法</h2>
            <div className="text_area" style={{ padding: '0 20px', border: '1px solid gray' }}>
              <h3>一般商品（招待状・席次表以外）</h3>
              <ul>
                <li>商品詳細画面の「買い物カゴに入れる」ボタンをクリックします。</li>
                <li>買い物カゴ画面で数量を確認して、「購入手続きへ」をクリックします。</li>
                <li>お届け先を入力して「決定」をクリックします。</li>
                <li>ご注文内容を確認して「注文する」をクリックします。</li>
                <li>注文処理完了後、 ご注文確認メールが届きますのでご確認ください。</li>
              </ul>
              <h3>招待状・席次表</h3>
              <ul>
                <li>商品詳細画面のデザイン作成ボタンから、デザインを作成します。</li>
                <li>
                  <span
                    style={linkStyle}
                    onClick={() => {
                      dispatch(push(`${RouteDir}/mypage/saved_designs`));
                    }}
                  >
                    保存したデザイン画面
                  </span>
                  で、注文したいデザインの「購入」ボタンをクリックします。
                  <br />
                  ※買い物カゴに作成したデザインが追加されます。
                  <br />
                  ※買い物カゴに別の商品がある場合は、それらを先に注文するか削除してください。
                </li>
                <li>買い物カゴ画面で、各種サブアイテムの数量を入力して、「購入手続きへ」をクリックします。</li>
                <li>お届け先を入力して「決定」をクリックします。</li>
                <li>ご注文内容を確認して「注文する」をクリックします。</li>
                <li>注文処理完了後、 ご注文確認メールが届きますのでご確認ください。</li>
              </ul>
            </div>
            <p>
              <br />※<span style={{ textDecoration: 'gray wavy underline' }}>招待状・席次表は個別注文のみ</span>
              お受けしており、他の一般商品との同時注文はできませんのでご注意ください。
              <br />
              ※パソコン・スマホからのご注文のみ受け付けております。
              <br />
              ※お電話、FAX、E-mailでのご注文は承りかねます。
            </p>
          </section>

          <section>
            <h2>お支払い方法</h2>
            <div className="text_area">
              <p>
                『クレジットカード決済』は、Square株式会社の決済代行システムを利用しております。
                <br />
                対応ブランド：Visa, Mastercard, American Express, JCB, Diners Club, Discover
                <br />
                お支払回数は1回のみとさせていただきます。
              </p>
            </div>
          </section>
          {/* <section>
            <h2>支払期限</h2>
            <div className="text_area">
              <p>
                お申込み後、1週間以内にお支払ください。
              </p>
            </div>
          </section> */}
          <section>
            <h2>注文からお届けまでの日数</h2>
            <div className="text_area">
              <p>
                ご入金確認後から5営業日以内に発送いたします。
                <br />
                土・日・祝とその前日の午前0時以降のご注文は、休日明けから5営業日以内にお発送いたします。
                <br />
                ※商品欠品などにより発送に時間のかかる場合がございますので、あらかじめご了承ください。
                <br />
                ※北海道、沖縄、離島など一部地域については配送にお時間がかかる場合がありますので、
                <br />
                ご予定日に配達できない場合がございます。
                <br />
                ※年末年始・ゴールデンウィーク、悪天候、交通事故、繁忙期、ご注文内容の不備などの場合、お届けが遅れることがございます。
              </p>
            </div>
          </section>
          <section>
            <h2>返品・返金について</h2>
            <div className="text_area">
              <p>
                お客様の御都合による返品は不可とさせて頂きます。
                <br />
                不良商品の交換は電話かメールにて、商品到着後1週間以内にご連絡下さい。
              </p>
            </div>
          </section>
          <section>
            <h2>その他</h2>
            <div className="text_area">
              <p>
                本サイトでは招待状・席次表デザイン画面において「アプリ明朝」(
                <a
                  href="http://flopdesign.com/blog/font/5852/"
                  target="_blank"
                  style={{ color: 'black', textDecoration: 'underline' }}
                >
                  http://flopdesign.com/blog/font/5852/
                </a>
                )を使用しております。
                <br />
                Licensed under SIL Open Font License 1.1 (
                <a
                  href="http://scripts.sil.org/OFL"
                  target="_blank"
                  style={{ color: 'black', textDecoration: 'underline' }}
                >
                  http://scripts.sil.org/OFL
                </a>
                ) <br />© 2017 フロップデザイン, © 2017 Adobe Systems Incorporated
              </p>
            </div>
          </section>
        </main>
      </div>
    </DocumentMeta>
  );
};

export default Howto;
