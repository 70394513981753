import React, { useEffect, useCallback } from 'react';
import { EditorTextInput, EditorTextArea } from '../UIkit';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);

const EditorSeatingGreeting = (props) => {
  return (
    <>
      <h2>ご挨拶文・会場・住所案内</h2>
      <div className="edit_box">
        <h3>ご挨拶文（12行まで）</h3>
        <div className="col_1">
          <EditorTextArea
            name="seating_greeting"
            rows="15"
            wrap="off"
            value={props.seatingGreeting}
            onChange={props.onChangeSeatingGreeting}
          />
        </div>
      </div>
      <div className="edit_box">
        <h3>会場</h3>
        <div className="col_1">
          <EditorTextInput name="venue_name" value={props.venueName} onChange={props.onChangeVenueName} />
        </div>
      </div>
      <div className="edit_box">
        <h3>住所案内（4行まで）</h3>
        <div className="col_1">
          <EditorTextArea
            name="profile_address_guide"
            rows="5"
            wrap="off"
            placeholder={'〇〇県〇〇市〇〇町1-2-3\n〇〇〇〇101\n\nお近くにお立ち寄りの際はいつでも遊びに来てください！'}
            value={props.profileAddressGuide}
            onChange={props.onChangeProfileAddressGuide}
          />
        </div>
      </div>
    </>
  );
};

export default EditorSeatingGreeting;
