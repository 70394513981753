export const UPDATE_CART_TYPE = 'UPDATE_CART_TYPE';
export const updateCartTypeAction = (cartType) => {
  return {
    type: 'UPDATE_CART_TYPE',
    payload: {
      cartType: cartType,
    },
  };
};

export const SELECT_CART_LIST = 'SELECT_CART_LIST';
export const selectCartListAction = (cartsState) => {
  return {
    type: 'SELECT_CART_LIST',
    payload: {
      list: cartsState,
    },
  };
};

export const ADD_SUB_ITEM_INFO = 'ADD_SUB_ITEM_INFO';
export const addSubItemInfoAction = (subItemInfo) => {
  return {
    type: 'ADD_SUB_ITEM_INFO',
    payload: {
      subItemInfo: subItemInfo,
    },
  };
};

export const UPDATE_CART_OPTIONS = 'UPDATE_CART_OPTIONS';
export const updateCartOptionsAction = (options) => {
  return {
    type: 'UPDATE_CART_OPTIONS',
    payload: {
      options: options,
    },
  };
};
