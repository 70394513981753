import React, { useEffect, useCallback, useState } from 'react';
import DocumentMeta from 'react-document-meta';
import { changeLoading } from '../../reducks/pageInfos/operations';
import { useDispatch, useSelector } from 'react-redux';
import { BreadCrumb, OrderFlow } from '../../components';
import PaymentPage from '../../components/public/PaymentPage';
import { SiteTitle } from './common';
import { push } from 'connected-react-router';
import { RouteDir, ItemImageDir } from '../../common';
import { calcItemTaxIncludedPrice } from '../../myLib';
import { config } from '../../config';
import CartSubItemTable from '../../components/public/CartSubItemTable';
import CartShippingFeeBox from '../../components/public/CartShippingFeeBox';
import CartExpressDeliveryFeeBox from '../../components/public/CartExpressDeliveryFeeBox';
import CartIsolatedIslandsShippingFeeBox from '../../components/public/CartIsolatedIslandsShippingFeeBox';

const OrderConfirm = () => {
  const dispatch = useDispatch();

  //ローディング
  const loading = useSelector((state) => state.pageInfos.loading);

  //注文情報
  const orders = useSelector((state) => state.orders.list);
  const payType = useSelector((state) => state.orders.payType);
  const members = useSelector((state) => state.members);

  //注文商品情報
  const cartType = useSelector((state) => state.carts.cartType);
  const items = useSelector((state) => state.carts.list);
  const subItemInfo = useSelector((state) => state.carts.subItemInfo);
  const cartOptions = useSelector((state) => state.carts.options);

  //お届け先
  const postAddress = useSelector((state) => state.orders.postAddress);

  //商品合計
  const [totalItemPrice, setTotalItemPrice] = useState(0);
  const [isTotalItemPriceCalcEnd, setIsTotalItemPriceCalcEnd] = useState(false);

  //送料合計
  const [totalPostage, setTotalPostage] = useState(0);

  //手数料
  const [commission, setCommission] = useState(0);

  //決済ID
  const [paymentId, setPaymentId] = useState('');

  //ハッシュ値
  const [hash, setHash] = useState('');

  //決済IDの生成
  const getDateTime = () => {
    const toDoubleDigites = (num) => {
      let number = String(num);
      if (number.length === 1) {
        number = '0' + number;
      }
      return number;
    };
    const date = new Date();
    const datetime =
      String(date.getFullYear()) +
      toDoubleDigites(date.getMonth() + 1) +
      toDoubleDigites(date.getDate()) +
      toDoubleDigites(date.getHours()) +
      toDoubleDigites(date.getMinutes()) +
      toDoubleDigites(date.getSeconds());
    return datetime;
  };

  useEffect(() => {
    // リロードするとストアの値が消えるため、もう一度お届け先入力画面に戻す
    if (postAddress[0].family_name === '') {
      alert('画面が再読み込みされました。\n再度各種情報を入力してください。');
      dispatch(push(RouteDir + '/mypage/cart_list'));
    }

    //ローディング開始
    dispatch(changeLoading(true));

    // リロード時にアラートを表示
    const showReloadAlert = (e) => {
      e.preventDefault();
      e.returnValue = ''; // アラートに任意のメッセージを表示することはできない（ブラウザ仕様）
    };
    window.addEventListener('beforeunload', showReloadAlert);

    // クリーンアップ処理
    return () => {
      dispatch(changeLoading(false));
      window.removeEventListener('beforeunload', showReloadAlert);
    };
  }, []);

  useEffect(() => {
    console.log(items, subItemInfo, cartOptions);
    // カートの商品を読み込めない場合
    if (items.length === 0) return;

    // 招待状・席次表のサブアイテムデータが読み込めない場合
    if (cartType === 'invitation' || cartType === 'seating') {
      if (subItemInfo.itemType === '' || subItemInfo.subItemData.length === 0) {
        return;
      }
    }

    // const test =() => {
    //   let totalPrice = 0
    //   let debugText = ""
    //   for(let i=0; i<2000; i++){
    //     const itemPrice = Number(i)  /* 商品税抜き価格 */
    //     const taxRate = Number(8)  /* 消費税率 */
    //     const shippingFee = Number(1000)  /* 送料 */
    //     const additionalShippingFee = Number(2000)  /* 離島配送料 */
    //     const quantity = Number(3)  /* 注文個数 */
    //     const tax = itemPrice * (taxRate / 100)

    //     const taxIncludedItemPriceRaw = itemPrice * (1 + (taxRate / 100)) /* 商品税込価格（小数点第2位以降切り捨て前） */
    //     const taxIncludedItemPriceTruncated = truncateDecimal(itemPrice * (1 + (taxRate / 100))) /* 商品税込価格（小数点第2位以降切り捨て後） */
    //     const taxIncludedItemPriceFloor = Math.floor(taxIncludedItemPriceTruncated)
    //     const taxIncludedItemPriceCeil = Math.ceil(taxIncludedItemPriceTruncated)
    //     const taxIncludedItemPriceRound = Math.round(taxIncludedItemPriceTruncated)

    //     // 切り捨て・四捨五入・切り上げ選択
    //     const taxIncludedItemPrice = taxIncludedItemPriceFloor
    //     // const taxIncludedItemPrice = taxIncludedItemPriceRound
    //     // const taxIncludedItemPrice = taxIncludedItemPriceCeil

    //     const subTotal = (taxIncludedItemPrice * quantity) + shippingFee
    //     totalPrice =  totalPrice + subTotal

    //     // debugText += "・商品一つ当たりの税込価格計算処理\n"
    //     debugText += "商品税抜き価格：￥" + itemPrice + "\n"
    //     // debugText += "消費税率：" + taxRate + "%\n"
    //     debugText += "消費税：￥" + tax + "\n"
    //     debugText += "商品税込価格（小数点第2位以降切り捨て前）：￥" + taxIncludedItemPriceRaw + "\n"
    //     debugText += "商品税込価格（小数点第2位以降切り捨て後）：￥" + taxIncludedItemPriceTruncated + "\n"
    //     // debugText += "\n"
    //     debugText += "商品税込価格（切り捨て）：￥" + taxIncludedItemPriceFloor + "\n"
    //     debugText += "商品税込価格（四捨五入）：￥" + taxIncludedItemPriceRound + "\n"
    //     debugText += "商品税込価格（切り上げ）：￥" + taxIncludedItemPriceCeil + "\n"
    //     // debugText += "\n"
    //     // debugText += "・数量・送料加算後計算処理\n"
    //     // debugText += "数量：" + quantity + "\n"
    //     // debugText += "送料：￥" + shippingFee + "\n"
    //     debugText += "カート1項目あたりの金額：￥" + subTotal + " (商品税込価格 × 数量) ＋ 送料\n"
    //     debugText += "---------------------------\n"
    //   }
    //   document.getElementById("debug").innerText = debugText;
    // }

    // test();

    // let debugText = "";

    // 合計金額計算処理（招待状・席次表以外）
    const totalPriceClac = (items) => {
      const itemCount = items.length;
      let totalPrice = 0;
      for (let i = 0; i < itemCount; i++) {
        const shippingFee = Number(items[i].postage); /* 送料 */
        const quantity = Number(items[i].quantity); /* 注文個数 */
        const taxIncludedItemPrice = calcItemTaxIncludedPrice(items[i].price, items[i].tax_value);
        const subTotal = taxIncludedItemPrice * quantity + shippingFee;
        totalPrice = totalPrice + subTotal;

        // debugText += "・商品一つ当たりの税込価格計算処理\n"
        // debugText += "商品税抜き価格：￥" + itemPrice + "\n"
        // debugText += "消費税率：" + taxRate + "%\n"
        // debugText += "消費税：￥" + tax + "\n"
        // debugText += "商品税込価格（小数点第2位以降切り捨て前）：￥" + taxIncludedItemPriceRaw + "\n"
        // debugText += "商品税込価格（小数点第2位以降切り捨て後）：￥" + taxIncludedItemPriceTruncated + "\n"
        // debugText += "\n"
        // debugText += "商品税込価格：￥" + taxIncludedItemPrice + "\n"
        // debugText += "\n"
        // debugText += "・数量・送料加算後計算処理\n"
        // debugText += "数量：" + quantity + "\n"
        // debugText += "送料：￥" + shippingFee + "\n"
        // debugText += "カート1項目あたりの金額：￥" + subTotal + " (商品税込価格 × 数量) ＋ 送料\n"
        // debugText += "---------------------------\n"
      }
      // debugText += "合計金額：￥" + totalPrice.toLocaleString() + "\n"
      // document.getElementById("debug").innerText = debugText;

      const additionalShippingFee = Number(postAddress[0].additionalShippingFee);
      return totalPrice + additionalShippingFee; /* 商品合計価格＋離島配送料（離島のみ） */
    };
    // console.log(items)

    // 合計金額計算処理（招待状・席次表）
    const calcTotalPriceForInvitationOrSeating = (item, subItemInfo, cartOptions) => {
      /**
       * サブアイテム合計価格を計算
       * @param {Object[]} subItemData ストアのcarts.subItemInfo.subItemData
       * @returns {number} サブアイテム合計価格
       */
      const calcSubItemsTotalPrice = (subItemData) => {
        // サブアイテム合計価格
        let totalPrice = 0;
        subItemData.map((data) => {
          // サブアイテム税込価格（買い物カゴ画面で計算済み）
          const price = data.totalSubItemPrice;

          // サブアイテム税込価格を加算
          totalPrice += price;
        });

        return totalPrice;
      };

      // 合計価格
      let totalPrice = 0;

      // サブアイテム合計価格を計算（招待状・席次表本体も含む）
      const subItemTotalPrice = calcSubItemsTotalPrice(subItemInfo.subItemData);

      // サブアイテム合計価格を加算
      totalPrice += subItemTotalPrice;

      // 通常送料を取得
      const shippingFee = Number(item.postage);

      // 通常送料を加算
      totalPrice += shippingFee;

      // 特急配送指定フラグを取得
      const isExpressDelivery = cartOptions.isExpressDelivery;

      // 特急配送指定ありの場合
      if (isExpressDelivery) {
        // 特急配送料金を取得
        const expressDeliveryFee = config.EXPRESS_DELIVERY_FEE;

        // 特急配送料金を加算
        totalPrice += expressDeliveryFee;
      }

      // 離島配送料（離島のみ）を取得
      const additionalShippingFee = Number(postAddress[0].additionalShippingFee);

      // 離島配送料を加算
      totalPrice += additionalShippingFee;

      return totalPrice;
    };

    // 招待状・席次表とその他商品で合計価格計算処理を切り分け
    let totalPriceCalcResult = 0;
    if (cartType === 'invitation' || cartType === 'seating') {
      // 招待状・席次表の場合
      // 合計価格を計算
      totalPriceCalcResult = calcTotalPriceForInvitationOrSeating(items[0], subItemInfo, cartOptions);
    } else {
      // その他商品の場合
      // 合計価格を計算
      totalPriceCalcResult = totalPriceClac(items);
    }

    // 計算結果を反映
    setTotalItemPrice(totalPriceCalcResult);

    setIsTotalItemPriceCalcEnd(true);
  }, [items, subItemInfo, cartOptions]);

  useEffect(() => {
    if (isTotalItemPriceCalcEnd) {
      dispatch(changeLoading(false));
    } else {
      dispatch(changeLoading(true));
    }
  }, [isTotalItemPriceCalcEnd]);

  // const OrderDecideBtn = () => {
  //   console.log(paymentId)
  // }

  const meta = {
    title: SiteTitle,
  };

  const Floors = [
    {
      name: '買い物カゴ',
      href: '/mypage/cart_list',
    },
  ];

  return (
    <DocumentMeta {...meta}>
      <div id="order_confirm_page">
        <BreadCrumb floors={Floors} />
        <main className="order_confirm_content">
          {/* <p id="debug">test</p> */}
          <h1>ご注文内容確認</h1>
          <OrderFlow activeKey={2} />
          <section className="list_type_4">
            <div className="order_info_list">
              <div className="order_card_box">
                <h2>ご注文内容</h2>
                {(cartType === 'invitation' || cartType === 'seating') && (
                  <>
                    {/* サブアイテム情報 */}
                    <CartSubItemTable
                      // ストアのサブアイテムデータを編集不可にして渡す
                      cartType={cartType}
                      subItemData={subItemInfo.subItemData.map((data) => ({
                        ...data,
                        quantityType: 'static',
                      }))}
                    />

                    {/* 送料 */}
                    <CartShippingFeeBox shippingFee={items[0].postage} />

                    {/* 特急指定 */}
                    <CartExpressDeliveryFeeBox
                      canSelect={false}
                      isExpressDelivery={cartOptions.isExpressDelivery}
                      expressDeliveryFee={config.EXPRESS_DELIVERY_FEE}
                    />
                  </>
                )}

                {cartType === 'normal' && (
                  <div className="list_type_2">
                    <div className="scroll_wrap">
                      <table>
                        <thead>
                          <tr>
                            <th className="item_name_th">商品名</th>
                            <th>金額（税込）</th>
                            <th>送料</th>
                            <th>数量</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(items) &&
                            items.map((item, i) => (
                              <tr key={i}>
                                <td className="item_image_name_box">
                                  <div className="thumbnail_area">
                                    <div>
                                      <img
                                        src={
                                          item.path === null
                                            ? process.env.PUBLIC_URL + '/images/noimage.jpg'
                                            : ItemImageDir + item.path
                                        }
                                        alt={item.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="item_info_wrap">
                                    <div className="item_info_content">
                                      <p className="name">{item.name}</p>
                                      <p className="standard">{item.standard}</p>
                                    </div>
                                  </div>
                                </td>
                                <td className="price_box">
                                  ￥{calcItemTaxIncludedPrice(item.price, item.tax_value).toLocaleString()}
                                </td>
                                <td className="postage_box">￥{Number(item.postage).toLocaleString()}</td>
                                <td className="quantity_box">{item.quantity}個</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {/* 離島配送料 */}
                {Number(postAddress[0].additionalShippingFee) !== 0 && (
                  <CartIsolatedIslandsShippingFeeBox
                    isolatedIslandsShippingFee={postAddress[0].additionalShippingFee}
                  />
                )}

                {/* お届け先情報 */}
                <div className="order_card">
                  <dl>
                    <dt>お届け先</dt>
                    <dd>
                      <p>
                        {postAddress[0].family_name}　{postAddress[0].first_name} 様
                      </p>
                      <p>
                        〒{postAddress[0].postal_code.slice(0, 3)}-{postAddress[0].postal_code.slice(-4)}
                        <br />
                        {postAddress[0].address
                          ? postAddress[0].address
                          : postAddress[0].address_1 + postAddress[0].address_2}
                      </p>
                      <p>{postAddress[0].telnumber}</p>
                    </dd>
                  </dl>
                </div>
              </div>

              {/* 合計金額 */}
              <div className="total_price_box">
                <h2>お支払金額</h2>
                {/* <dl>
                  <dt>商品合計</dt>
                  <dd>¥{totalItemPrice.toLocaleString()}</dd>
                </dl> */}
                {/* <dl>
                  <dt>送料</dt>
                  <dd>¥{totalPostage.toLocaleString()}</dd>
                </dl> */}
                {/* <dl>
                  <dt>手数料</dt>
                  <dd>¥{commission.toLocaleString()}</dd>
                </dl> */}
                <dl>
                  <dt>合計</dt>
                  <dd className="total_price">¥{totalItemPrice.toLocaleString()}</dd>
                </dl>
              </div>
            </div>

            {/* Square決済フォーム */}
            <div className="payment_area">
              <h2>クレジットカード決済</h2>
              {isTotalItemPriceCalcEnd ? (
                <PaymentPage
                  procedure={''}
                  totalPrice={totalItemPrice}
                  memberId={members.member_id}
                  items={items}
                  cartType={cartType}
                  cartOptions={cartOptions}
                  subItemData={subItemInfo.subItemData}
                  deliveryFamilyName={postAddress[0].family_name}
                  deliveryFirstName={postAddress[0].first_name}
                  deliveryFamilyNameFurigana={postAddress[0].family_name_furigana}
                  deliveryFirstNameFurigana={postAddress[0].first_name_furigana}
                  deliveryPostalCode={postAddress[0].postal_code}
                  deliveryAddress={
                    postAddress[0].address
                      ? postAddress[0].address
                      : postAddress[0].address_1 + postAddress[0].address_2
                  }
                  deliveryTelnumber={postAddress[0].telnumber}
                />
              ) : (
                'お待ちください...'
              )}
            </div>

            {/* ボタン */}
            <div className="btn_area">
              <button
                className="order_info_link_btn"
                onClick={() => dispatch(push(RouteDir + '/mypage/add_order_post_address'))}
              >
                お届け先入力へ戻る
              </button>
              {/* <button className="order_decide_btn" onClick={()=>OrderDecideBtn()}>決済する</button> */}
            </div>
          </section>
        </main>
      </div>
      <div id="loading_area" className={loading === true ? '' : 'hidden'}>
        <div className="loader">Loading...</div>
      </div>
    </DocumentMeta>
  );
};

export default OrderConfirm;
