export const SELECT_GROUP = 'SELECT_GROUP'
export const selectGroupAction = (groupState) =>
{
  return {
    type: 'SELECT_GROUP',
    payload: {
      list: groupState
    }
  }
}
