export const config = {
  COMPANY_NAME: 'ブライダルグッズポケット',
  COMPANY_PERSON_IN_CHARGE_NAME: '帯屋充人',
  COMPANY_POSTAL_CODE: '856-0820',
  COMPANY_ADDRESS: '長崎県大村市協和町729-2',
  COMPANY_TEL_NUMBER: '0957-48-5822',
  COMPANY_MAIL_ADDRESS: '一括変更（メールアドレス）',
  COMPANY_EC_URL: 'https://www.bridalpocket.com/',
  COMPANY_BUSINESS_DAY: '10:00～18:00（定休日：水曜日）',

  // ヘッダー・フッター：SNSアイコンリンク
  COMPANY_SNS_URL_1: 'https://',
  COMPANY_SNS_URL_2: 'https://',
  COMPANY_SNS_URL_3: 'https://',
  COMPANY_SNS_URL_4: 'https://',

  // サイドバー：バナー画像リンク
  SIDEBAR_WEB_BANNER_URL_1: 'https://',
  SIDEBAR_WEB_BANNER_URL_2: 'https://',
  SIDEBAR_WEB_BANNER_URL_3: 'https://',
  SIDEBAR_WEB_BANNER_URL_4: 'https://',

  // 商品価格計算方法
  // ITEM_PRICE_CALCULATION_TYPE: 'FLOOR' /* 切り捨て */,
  // ITEM_PRICE_CALCULATION_TYPE: "ROUND" /* 四捨五入 */,
  ITEM_PRICE_CALCULATION_TYPE: 'CEIL' /* 切り上げ */,

  // 特急配送料金
  EXPRESS_DELIVERY_FEE: 2200,

  // PDF出力用設定
  // https://www.vanfu.co.jp/column/data/resolution.html#sec5
  IMG_SHORT_SIDE_MIN_PIXEL: 5000 /* SVGからPNG変換時の短い辺のピクセル数 */,
};
