// DataGrid用に各データにIDを付与
export const addIdToEachDataForDataGrid = (jsonDataList) => {
  let jsonDataListWithId = [];
  for (let i = 0; i < jsonDataList.length; i++) {
    const jsonData = jsonDataList[i];
    jsonData.id = i;
    jsonDataListWithId.push(jsonData);
  }
  return jsonDataListWithId;
};
