import React from 'react';

// カート送料
const CartShippingFeeBox = (props) => {
  return (
    <div className="shipping_fee_box">
      <table className="shipping_fee">
        <tbody>
          <tr>
            <th>通常送料</th>
            <td>￥{Number(props.shippingFee).toLocaleString()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CartShippingFeeBox;
