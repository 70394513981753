import React, { useState, lazy } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Svg,
  Ellipse,
} from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import { useEffect } from 'react';
import { ItemImageDir, SAVED_SVG_DIR } from '../../common';
import { height } from '@mui/system';
// import { sampleBase64Text } from './sampleBase64Text';
import { SampleSvg } from './sampleSvg';

const ReactPdfSample = () => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isAllSvgLoaded, setIsAllSvgLoaded] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const [pdfDoc, setPdfDoc] = useState();
  const sampleBase64Text = '';
  const PNG_MAGNIFICATION = 7; /* SVGからPNGに変換する際の倍率 */

  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#00ff00',
    },
    section: {
      margin: 0,
      padding: 0,
      flexGrow: 0,
    },
  });

  const savePdf = () => {
    ReactPDF.renderToStream(<MyDocument />);
  };

  // 画像ファイルをBase64に変換する
  const convertImgToBase64 = async (imgSrc) => {
    return new Promise((resolve, reject) => {
      // console.log(imgSrc);
      var xhr = new XMLHttpRequest();

      xhr.onload = function () {
        // Base64方式
        // 例：data:image/svg+xml;base64,PD94bWwgdmVyc...
        var reader = new FileReader();
        reader.onloadend = function () {
          console.log(reader.result);
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);

        // ObjectURL方式
        // 例：blob:http://localhost:3000/346e09bc-bac7-4030-adfb-0452511f6e7e

        // const objectUrl = window.URL.createObjectURL(xhr.response);

        // // const img = new window.Image();
        // // img.onload = () => {
        // //   setImgWidth(img.naturalWidth);
        // //   setImgHeight(img.naturalHeight);
        // // };
        // // img.src = objectUrl;

        // console.log(objectUrl);
        // resolve(objectUrl);
      };

      xhr.open('GET', imgSrc);
      xhr.responseType = 'blob';
      xhr.send();
    });
  };

  // 画像サイズを取得
  const getImgNaturalSize = (imgObjectUrl) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.onload = () => {
        const imgSize = {
          width: img.naturalWidth,
          height: img.naturalHeight,
        };
        console.log(imgSize);
        resolve(imgSize);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = imgObjectUrl;
    });
  };

  // SVGファイルをPNG形式に変換する
  const convertSvgToPng = (imgSrc, imgWidth, imgHeight, pngMagnification) => {
    return new Promise((resolve, reject) => {
      const canvasWidth = imgWidth * pngMagnification;
      const canvasHeight = imgHeight * pngMagnification;

      const canvas = document.createElement('canvas');
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      const ctx = canvas.getContext('2d');
      // const ctx = canvas.getContext('2d', { alpha: false });/* 透過させない */
      const img = new window.Image();
      img.onload = () => {
        ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
        canvas.toBlob((result) => {
          console.log(result);
          const objectUrl = window.URL.createObjectURL(result);
          console.log(objectUrl);
          resolve(objectUrl);
        });
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = imgSrc;
    });
  };

  // SVGファイルからObjectURLを生成
  const loadSvgAsObjectUrl = (imgSrc) => {
    return new Promise((resolve, reject) => {
      // console.log(imgSrc);
      var xhr = new XMLHttpRequest();

      xhr.onload = function () {
        // ObjectURL方式
        // 例：blob:http://localhost:3000/346e09bc-bac7-4030-adfb-0452511f6e7e

        const objectUrl = window.URL.createObjectURL(xhr.response);

        // const img = new window.Image();
        // img.onload = () => {
        //   setImgWidth(img.naturalWidth);
        //   setImgHeight(img.naturalHeight);
        // };
        // img.src = objectUrl;

        console.log(objectUrl);
        resolve(objectUrl);
      };

      xhr.open('GET', imgSrc);
      xhr.responseType = 'blob';
      xhr.send();
    });
  };

  // const SampleSvg = () => (
  //   <Svg viewBox="0 0 200 100">
  //     <Ellipse cx="100" cy="50" rx="80" ry="40" fill="tomato" stroke="gray" />
  //   </Svg>
  // );

  // Create Document Component
  const MyDocument = () => {
    return (
      <Document>
        <Page
          // size={[1500, 1500]}
          size={[imgWidth, imgHeight]}
          // size={[1500, 'auto']}
          // size={['auto', 500]}
          // size={[1500, 500]}
          // size="A4"
          // orientation="landscape"
          style={styles.page}
        >
          {/* <View style={styles.section}>
            <Text>Section #1</Text>
          </View> */}
          <View style={styles.section}>
            {/* <Text>Section #2</Text> */}
            <img src={SAVED_SVG_DIR + 'O60Rh2ENz3aDPMwv7Mjn_seating_profile.svg'} />
            {/* <Image
              src={(async () => {
                await convertImgToBase64(SAVED_SVG_DIR + 'O60Rh2ENz3aDPMwv7Mjn_seating_profile.svg');
              })()}
            /> */}
            {/* <SampleSvg /> */}
            {/* <Image src="/pocket_ec/svg/saved/fusen.png" width="200" height="200"></Image> */}
            
            {/* <Svg viewBox="0 0 200 100">
              <Ellipse cx="100" cy="50" rx="80" ry="40" fill="tomato" stroke="gray" />
            </Svg> */}
            {/* <img src={imgSrc} /> */}
            {/* <Image allowDangerousPaths src={imgSrc} /> */}
            {/* <Image src={imgSrc} /> */}

            {/* <Image src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOC4zNyAxNy40NyI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMzYWM5ZTE7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSI5LjE5IDAgMTIuMDMgNS43NSAxOC4zNyA2LjY3IDEzLjc4IDExLjE1IDE0Ljg2IDE3LjQ3IDkuMTkgMTQuNDkgMy41MSAxNy40NyA0LjU5IDExLjE1IDAgNi42NyA2LjM1IDUuNzUgOS4xOSAwIi8+PC9nPjwvZz48L3N2Zz4=" /> */}
            {/* <Image
              src={
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII='
              }
            /> */}
            {/* <Image src={sampleBase64Text} /> */}
            {/* <img src={imgSrc} />
            <Image src={imgSrc} />
            <Image source={imgSrc} /> */}
            {/* <img src={convertImgToBase64(SAVED_SVG_DIR + 'O60Rh2ENz3aDPMwv7Mjn_seating_profile.svg')} /> */}
            {/* <Image source={convertImgToBase64(SAVED_SVG_DIR + 'O60Rh2ENz3aDPMwv7Mjn_seating_profile.svg')} /> */}
            {/* <Image source={ItemImageDir + '/20221124115550.png'} /> */}
          </View>
        </Page>
        {/* <Page
          size={[500, 500]}
          // size="A4"
          // orientation="landscape"
          style={styles.page}
        >
          <View style={styles.section}>
            <Text>Section #2</Text> */}
        {/* <Image
              src={
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII='
              }
            /> */}
        {/* </View>
        </Page>
        <Page
          size={[200, 300]}
          // size="A4"
          // orientation="landscape"
          style={styles.page}
        >
          <View style={styles.section}>
            <Text>Section #3</Text>
            <Text>Section #3</Text>
            <Text>Section #3</Text>
          </View>
        </Page> */}
      </Document>
    );
    // console.log(myDocument);
  };

  // const importSvgs = lazy((svgFileNames) => import(ItemImageDir + '/20221124115550.png'));

  useEffect(async () => {
    setIsPageLoaded(true);
    // convertImgToBase64(SAVED_SVG_DIR + 'O60Rh2ENz3aDPMwv7Mjn_seating_profile.svg');
    // const imgObjectURL = await convertImgToBase64(SAVED_SVG_DIR + 'O60Rh2ENz3aDPMwv7Mjn_seating_profile.png');

    const imgObjectURL = await convertSvgToPng(
      SAVED_SVG_DIR + 'jUbsMOKo9LsL09lUDbyf_seating_profile.svg',
      1233,
      729,
      PNG_MAGNIFICATION
    );
    // const imgObjectURL = await convertSvgToPng(SAVED_SVG_DIR + 'O60Rh2ENz3aDPMwv7Mjn_seating_profile.svg', 1233, 729);

    // const imgObjectURL = await convertSvgToPng(SAVED_SVG_DIR + 'O60Rh2ENz3aDPMwv7Mjn_seating_profile.svg', 1233, 729);
    // const imgObjectURL = await convertImgToBase64(SAVED_SVG_DIR + 'O60Rh2ENz3aDPMwv7Mjn_seating_profile.svg');
    // const { width, height } = await getImgNaturalSize(imgObjectURL);
    setImgWidth(1233);
    setImgHeight(729);

    // const imgObjectURL = await loadSvgAsObjectUrl( SAVED_SVG_DIR + 'jUbsMOKo9LsL09lUDbyf_seating_profile.svg');
    // alert(imgObjectURL)
    setImgSrc(imgObjectURL);

    setIsAllSvgLoaded(true);

    // setPdfDoc(MyDocument());
  }, []);

  useEffect(() => {
    console.log(imgWidth, imgHeight);
    //   if (imgWidth !== 0 && imgHeight !== 0) {
    //     setIsAllSvgLoaded(true);
    //   }
  }, [imgWidth, imgHeight]);

  return (
    <>
      {/* <img src={SAVED_SVG_DIR + 'O60Rh2ENz3aDPMwv7Mjn_seating_profile.svg'} /> */}
      {/* <img src={sampleBase64Text} /> */}
      {/* <img
        width="100"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOC4zNyAxNy40NyI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMzYWM5ZTE7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSLjg6zjgqTjg6Tjg7xfMiIgZGF0YS1uYW1lPSLjg6zjgqTjg6Tjg7wgMiI+PGcgaWQ9IuODrOOCpOODpOODvF8xLTIiIGRhdGEtbmFtZT0i44Os44Kk44Ok44O8IDEiPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSI5LjE5IDAgMTIuMDMgNS43NSAxOC4zNyA2LjY3IDEzLjc4IDExLjE1IDE0Ljg2IDE3LjQ3IDkuMTkgMTQuNDkgMy41MSAxNy40NyA0LjU5IDExLjE1IDAgNi42NyA2LjM1IDUuNzUgOS4xOSAwIi8+PC9nPjwvZz48L3N2Zz4="
      /> */}

      {/* <img src={SAVED_SVG_DIR + 'O60Rh2ENz3aDPMwv7Mjn_seating_profile.svg'} /> */}
      {isAllSvgLoaded && (
        <>
          {/* PDFプレビュー */}
          {/* https://react-pdf.org/components#pdfviewer */}
          <PDFViewer width={1000} height={500}>
            {<MyDocument />}
            {/* {<sampleSvg />} */}
          </PDFViewer>

          {/* PDFダウンロードリンク */}
          {/* https://react-pdf.org/components#pdfdownloadlink */}
          <PDFDownloadLink document={<MyDocument />} fileName="sample.pdf" style={{ textDecoration: 'none' }}>
            <button>PDF出力</button>
            {/* <button onClick={savePdf}>PDF出力</button> */}
          </PDFDownloadLink>
        </>
      )}
      <div>{/* <img width={'500'} src={imgSrc} /> */}</div>
    </>
  );
};

export default ReactPdfSample;
