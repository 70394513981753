import React from 'react'

const EditorTextInput = (props) =>
{
  const inputStyle =
  {
    color: '#000'
  }
  return(
    <div className="editor_text_input_item">
      <label htmlFor={props.name}>{props.label}</label>
      <input
        style={inputStyle}
        // type="text"
        type={props.type ? props.type : "text"}
        maxLength={props.maxLength ? props.maxLength : ""}
        name = {props.name}
        placeholder = {props.placeholder}
        rows = {props.rows}
        value = {props.value}
        onChange = {props.onChange}
        readOnly = {props.readOnly}
        disabled = {props.disabled}
      />
    </div>
  )
}

export default EditorTextInput