import React, { useEffect, useCallback } from 'react';
import { EditorTextInput, EditorTextArea } from '../UIkit';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
import { useState } from 'react';
import { validataPostalCode, validateMaxLineNum } from '../../templates/Public/EditorFunction';
registerLocale('ja', ja);

const EditorPostcardFrontside = (props) => {
  // console.log(props)
  const [sender, setSender] = useState(''); /* 差出人 */
  const [addressType, setAddressType] = useState(''); /* 記載方法 */

  // 要素の表示切替
  const changeElemVisibility = (elem, isVisible) => {
    if (isVisible) {
      elem.classList.remove('none');
      elem.removeAttribute('display'); /* 保存SVG調整用 */
    } else {
      elem.classList.add('none');
      elem.setAttribute('display', 'none'); /* 保存SVG調整用 */
    }
  };

  // 差出人と記載方法によって、はがき・表示内容切替
  const changePostcardType = (sender, addressType) => {
    // はがきSVGの表示切替
    const switchPostcardSvg = (postcardType) => {
      // すべてのはがきSVGを非表示にする
      const hideAllPostcards = () => {
        const addClassNoneToSvg = (targetSvgIdList) => {
          for (let i = 0; i < targetSvgIdList.length; i++) {
            const targetSvg = document.querySelector(`#${targetSvgIdList[i]}`);
            const svgParentElem = targetSvg.closest('.svg_wrap');
            svgParentElem.classList.add('none');
          }
        };

        const postcardSvgIdList = [
          'postcard_combine_svg',
          'postcard_separate_groom_svg',
          'postcard_separate_bride_svg',
        ];
        addClassNoneToSvg(postcardSvgIdList);
      };

      // 対象のはがきSVGを表示
      const displayTargetSvg = (targetSvgId) => {
        const removeClassNoneFromSvg = (targetSvgId) => {
          const targetSVG = document.getElementById(targetSvgId);
          const targetParent = targetSVG.closest('.svg_wrap');
          targetParent.classList.remove('none');
        };

        removeClassNoneFromSvg(targetSvgId);
      };

      // すべてのはがきを非表示に
      hideAllPostcards();

      // 対象のはがきのみを表示
      if (postcardType === 'combine') {
        displayTargetSvg('postcard_combine_svg');
      } else if (postcardType === 'separate') {
        displayTargetSvg('postcard_separate_groom_svg');
        displayTargetSvg('postcard_separate_bride_svg');
      }
    };

    // 新郎新婦用要素の表示切替
    const changeGroomAndBrideInfoVisibility = (isVisible) => {
      const groomInfo = document.querySelector('#postcard_separate_groom_info');
      const brideInfo = document.querySelector('#postcard_separate_bride_info');
      changeElemVisibility(groomInfo, isVisible);
      changeElemVisibility(brideInfo, isVisible);
    };

    // 親用要素の表示切替
    const changeParentsInfoVisibility = (isVisible) => {
      const groomParentInfo = document.querySelector('#postcard_separate_groom_parent_info');
      const brideParentInfo = document.querySelector('#postcard_separate_bride_parent_info');
      changeElemVisibility(groomParentInfo, isVisible);
      changeElemVisibility(brideParentInfo, isVisible);
    };

    if (sender === 'groomAndBride') {
      if (addressType === 'combine') {
        // 連名用はがきに切替
        switchPostcardSvg('combine');
      } else if (addressType === 'separate') {
        // 個別用はがきに切替
        switchPostcardSvg('separate');

        // 親名要素を非表示
        changeParentsInfoVisibility(false);

        // 新郎新婦用要素を表示
        changeGroomAndBrideInfoVisibility(true);
      }
    } else if (sender === 'parent') {
      // 連名用はがきに切替
      switchPostcardSvg('separate');

      // 新郎新婦用要素を非表示
      changeGroomAndBrideInfoVisibility(false);

      // 親名要素を表示
      changeParentsInfoVisibility(true);
    }
  };

  // //同一の新郎新婦情報と親情報に.noneを追加する処理
  // const addClassNoneDeliveryAddressType = () => {
  //   // const groombrideInfo = document.getElementById('新郎新婦情報');
  //   // groombrideInfo.classList.add('none');
  //   // groombrideInfo.setAttribute('display', 'none'); /* 保存SVG調整用 */
  //   // const parentInfo = document.getElementById('親情報');
  //   // parentInfo.classList.add('none');
  //   // parentInfo.setAttribute('display', 'none'); /* 保存SVG調整用 */

  //   // SVG内の要素を非表示にする
  //   const hideSVGElems = (selector) => {
  //     const elems = document.querySelectorAll(selector);
  //     for (let i = 0; i < elems.length; i++) {
  //       elems[i].classList.add('none');
  //       elems[i].setAttribute('display', 'none'); /* 保存SVG調整用 */
  //     }
  //   };

  //   hideSVGElems('.groom_bride_info');
  //   hideSVGElems('.parent_info');
  // };

  //差出人を選択した時の処理
  // const changeAddressType = (target) => {
  //   // 一度すべての郵便番号・住所等を非表示に
  //   //同一の新郎新婦情報と親情報に.noneを追加
  //   addClassNoneDeliveryAddressType();

  //   // 必要な要素のみ表示
  //   //target値で表示する
  //   // const targetSVG = document.getElementById(target);
  //   // targetSVG.classList.remove('none');
  //   // targetSVG.removeAttribute('display'); /* 保存SVG調整用 */
  //   // SVG内の要素を表示する
  //   const displaySVGElems = (selector) => {
  //     const elems = document.querySelectorAll(selector);
  //     console.log(elems);
  //     for (let i = 0; i < elems.length; i++) {
  //       elems[i].classList.remove('none');
  //       elems[i].removeAttribute('display'); /* 保存SVG調整用 */
  //     }
  //   };

  //   // はがき画像切替
  //   if (target === 'groom_bride_info') {
  //     displaySVGElems('.groom_bride_info');
  //     if (props.descriptionMethodType === '1') {
  //       switchPostcardSvg('combine');
  //     } else if (props.descriptionMethodType === '2') {
  //       switchPostcardSvg('separate');
  //     }
  //   } else if (target === 'parent_info') {
  //     displaySVGElems('.groom_bride_info');
  //     switchPostcardSvg('separate');
  //   }
  // };

  // //旧姓を非表示に変更する処理
  // const addClassNoneMaidenNameType = () => {
  //   const groomMaidenName = document.getElementById('postcard_groom_maiden_name');
  //   groomMaidenName.classList.add('none');
  //   const brideMaidenName = document.getElementById('postcard_bride_maiden_name');
  //   brideMaidenName.classList.add('none');
  // };

  // //旧姓の表示に変更する処理
  // const changeMaidenNameType = (target) => {
  //   addClassNoneMaidenNameType();

  //   const targetMaidenName = document.getElementById(target);
  //   targetMaidenName.classList.remove('none');
  // };

  // 差出人をセット
  useEffect(() => {
    setSender(props.deliveryAddressType === '1' ? 'groomAndBride' : 'parent');
  }, [props.deliveryAddressType]);

  // 記載方法をセット
  useEffect(() => {
    setAddressType(props.descriptionMethodType === '1' ? 'combine' : 'separate');
  }, [props.descriptionMethodType]);

  useEffect(() => {
    // console.log(props.deliveryAddressType, props.descriptionMethodType);
    // console.log(sender, addressType);
    if (
      sender !== '' &&
      addressType !== '' &&
      document.getElementById('postcard_combine_svg') &&
      document.getElementById('postcard_separate_groom_svg') &&
      document.getElementById('postcard_separate_bride_svg') &&
      document.getElementById('postcard_groom_maiden_name') &&
      document.getElementById('postcard_bride_maiden_name')
    ) {
      changePostcardType(sender, addressType);
    }
  }, [
    sender,
    addressType,
    document.getElementById('postcard_combine_svg') !== null,
    document.getElementById('postcard_separate_groom_svg') !== null,
    document.getElementById('postcard_separate_bride_svg') !== null,
    document.getElementById('postcard_groom_maiden_name') !== null,
    document.getElementById('postcard_bride_maiden_name') !== null,
  ]);

  return (
    <>
      <h2>返信はがき(表面)を入力</h2>
      <div className="edit_box">
        <h3>差出人</h3>
        <div className="col_2">
          <div className="editor_radio_input_item">
            <label htmlFor="postcard_send_to_organizer">
              <input
                type="radio"
                name="postcard_delivery_address_type"
                value="1"
                id="postcard_send_to_organizer"
                checked={props.deliveryAddressType === '1'}
                onChange={props.onChangeDeliveryAddressType}
                // onClick={() => { changeAddressType('新郎新婦情報') }}
              />
              新郎新婦名
            </label>
          </div>
          <div className="editor_radio_input_item">
            <label htmlFor="postcard_send_to_parents">
              <input
                type="radio"
                name="postcard_delivery_address_type"
                value="2"
                id="postcard_send_to_parents"
                checked={props.deliveryAddressType === '2'}
                onChange={props.onChangeDeliveryAddressType}
                // onClick={() => { changeAddressType('親情報') }}
              />
              親名
            </label>
          </div>
        </div>
      </div>
      <div
        className="discription_method_content"
        style={{
          display: props.deliveryAddressType === '1' ? '' : 'none',
        }}
      >
        <div className="edit_box">
          <h3>記載方法</h3>
          <div className="col_1">
            <div className="editor_radio_input_item">
              <label htmlFor="postcard_combine">
                <input
                  type="radio"
                  name="postcard_description_method_type"
                  value="1"
                  id="postcard_combine"
                  checked={props.descriptionMethodType === '1' || props.descriptionMethodType === '0'}
                  onChange={props.onChangeDescriptionMethodType}
                  // onClick={() => { switchPostcardSvg('postcard_combine_svg') }}
                />
                1つの住所で連名
              </label>
            </div>
          </div>
          <div className="col_1">
            <div className="editor_radio_input_item">
              <label htmlFor="postcard_separate">
                <input
                  type="radio"
                  name="postcard_description_method_type"
                  value="2"
                  id="postcard_separate"
                  checked={props.descriptionMethodType === '2'}
                  onChange={props.onChangeDescriptionMethodType}
                  // onClick={() => { switchPostcardSvg('postcard_separate_svg') }}
                />
                新郎新婦別々に記載
              </label>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: props.deliveryAddressType === '1' ? '' : 'none',
        }}
      >
        <div
          id="organizer_address_content"
          style={{
            display: props.descriptionMethodType === '1' || props.descriptionMethodType === '0' ? '' : 'none',
          }}
        >
          <div className="edit_box">
            <h3>新郎新婦住所</h3>
            <div className="col_2">
              <EditorTextInput
                label="郵便番号 (半角数字)"
                name="postcard_postal_code"
                placeholder="0000000"
                rows="1"
                maxLength="7"
                value={props.postalCode}
                onChange={(event) => {
                  if (validataPostalCode(event)) {
                    props.onChangePostalCode(event);
                  }
                }}
              />
            </div>
            <div className="col_1">
              <div className="address_note">住所（3行まで）</div>
              <EditorTextArea
                name="postcard_address"
                rows="4"
                wrap="off"
                value={props.address}
                onChange={(event) => {
                  if (validateMaxLineNum(event, 3)) {
                    props.onChangeAddress(event);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div
          id="groom_bride_address_content"
          style={{
            display: props.descriptionMethodType === '2' ? '' : 'none',
          }}
        >
          <div className="edit_box">
            <h3>新郎住所</h3>
            <div className="col_2">
              <EditorTextInput
                label="郵便番号 (半角数字)"
                name="postcard_groom_postal_code"
                placeholder="0000000"
                rows="1"
                maxLength="7"
                value={props.groomPostalCode}
                onChange={(event) => {
                  if (validataPostalCode(event)) {
                    props.onChangeGroomPostalCode(event);
                  }
                }}
              />
            </div>
            <div className="col_1">
              <div className="address_note">住所（3行まで）</div>
              <EditorTextArea
                name="postcard_groom_address"
                rows="4"
                wrap="off"
                value={props.groomAddress}
                onChange={(event) => {
                  if (validateMaxLineNum(event, 3)) {
                    props.onChangeGroomAddress(event);
                  }
                }}
              />
            </div>
          </div>
          <div className="edit_box">
            <h3>新婦住所</h3>
            <div className="col_2">
              <EditorTextInput
                label="郵便番号 (半角数字)"
                name="postcard_bride_postal_code"
                placeholder="0000000"
                rows="1"
                maxLength="7"
                value={props.bridePostalCode}
                onChange={(event) => {
                  if (validataPostalCode(event)) {
                    props.onChangeBridePostalCode(event);
                  }
                }}
              />
            </div>
            <div className="col_1">
              <div className="address_note">住所（3行まで）</div>
              <EditorTextArea
                name="postcard_bride_address"
                rows="4"
                wrap="off"
                value={props.brideAddress}
                onChange={(event) => {
                  if (validateMaxLineNum(event, 3)) {
                    props.onChangeBrideAddress(event);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        id="parent_address_content"
        style={{
          display: props.deliveryAddressType === '2' ? '' : 'none',
        }}
      >
        <div className="edit_box">
          <h3>新郎親住所</h3>
          <div className="col_2">
            <EditorTextInput
              label="郵便番号 (半角数字)"
              name="postcard_groom_parent_postal_code"
              placeholder="0000000"
              rows="1"
              maxLength="7"
              value={props.groomParentPostalCode}
              onChange={(event) => {
                if (validataPostalCode(event)) {
                  props.onChangeGroomParentPostalCode(event);
                }
              }}
            />
          </div>
          <div className="col_1">
            <div className="address_note">住所（3行まで）</div>
            <EditorTextArea
              name="postcard_groom_parent_address"
              rows="4"
              wrap="off"
              value={props.groomParentAddress}
              onChange={(event) => {
                if (validateMaxLineNum(event, 3)) {
                  props.onChangeGroomParentAddress(event);
                }
              }}
            />
          </div>
        </div>
        <div className="edit_box">
          <h3>新婦親住所</h3>
          <div className="col_2">
            <EditorTextInput
              label="郵便番号 (半角数字)"
              name="postcard_bride_parent_postal_code"
              placeholder="0000000"
              rows="1"
              maxLength="7"
              value={props.brideParentPostalCode}
              onChange={(event) => {
                if (validataPostalCode(event)) {
                  props.onChangeBrideParentPostalCode(event);
                }
              }}
            />
          </div>
          <div className="col_1">
            <div className="address_note">住所（3行まで）</div>
            <EditorTextArea
              name="postcard_bride_parent_address"
              rows="4"
              wrap="off"
              value={props.brideParentAddress}
              onChange={(event) => {
                if (validateMaxLineNum(event, 3)) {
                  props.onChangeBrideParentAddress(event);
                }
              }}
            />
          </div>
        </div>
      </div>
      {/* {props.deliveryAddressType === '1' && (
        <>
          {(props.descriptionMethodType === '1' || props.descriptionMethodType === '0') && (
            <div className="edit_box">
              <h3>旧姓表示</h3>
              <div className="col_1">
                <div className="editor_radio_input_item">
                  <label htmlFor="postcard_noshow_maiden_name">
                    <input
                      type="radio"
                      name="postcard_show_maiden_name_type"
                      value="1"
                      id="postcard_noshow_maiden_name"
                      checked={props.showMaidenNameType === '1' || props.showMaidenNameType === '0'}
                      onChange={props.onChangeShowMaidenNameType}
                      // onClick={() => { addClassNoneMaidenNameType() }}
                    />
                    旧姓を表示しない
                  </label>
                  <span className="show_maiden_name_span">※入籍済みの場合</span>
                  <label htmlFor="postcard_show_bride_maiden_name">
                    <input
                      type="radio"
                      name="postcard_show_maiden_name_type"
                      value="2"
                      id="postcard_show_bride_maiden_name"
                      checked={props.showMaidenNameType === '2'}
                      onChange={props.onChangeShowMaidenNameType}
                      // onClick={() => { changeMaidenNameType('postcard_bride_maiden_name') }}
                    />
                    新婦側旧姓を表示する
                  </label>
                  <label htmlFor="postcard_show_groom_maiden_name">
                    <input
                      type="radio"
                      name="postcard_show_maiden_name_type"
                      value="3"
                      id="postcard_show_groom_maiden_name"
                      checked={props.showMaidenNameType === '3'}
                      onChange={props.onChangeShowMaidenNameType}
                      // onClick={() => { changeMaidenNameType('postcard_groom_maiden_name') }}
                    />
                    新郎側旧姓を表示する
                  </label>
                </div>
              </div>
            </div>
          )}
        </>
      )} */}
    </>
  );
};

export default EditorPostcardFrontside;
