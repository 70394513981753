import * as Actions from './actions';
import initialState from '../store/initialState';

export const CartsReducer = (state = initialState.carts, action) => {
  switch (action.type) {
    case Actions.UPDATE_CART_TYPE:
    case Actions.SELECT_CART_LIST:
    case Actions.ADD_SUB_ITEM_INFO:
    case Actions.UPDATE_CART_OPTIONS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
