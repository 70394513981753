import React from 'react';

// 注文詳細：サブアイテム情報テーブル
const OrderDetailSubItemInfoTable = (props) => {
  const tableStyle = {
    width: '400px',
    // border: '1px solid gray',
    lineHeight: '10px',
  };

  const tdTitleStyle = {
    width: '100px',
    height: '20px',
    padding: '0',
    padding: '10px 10px 10px 30px',
  };
  const tdQuantityStyle = {
    width: '150px',
    textAlign: 'right',
    padding: '10px',
  };

  // 付箋
  const fusenTableStyle = {
    display: 'inline-block',
    width: '200px',
    // border: '1px solid gray',
    lineHeight: '10px',
    borderCollapse: 'collapse',
  };
  const fusenTdTitleStyle = {
    width: '100px',
    height: '20px',
    padding: '0',
    padding: '10px 10px 10px 10px',
    textAlign: 'left',
    borderBottom: '1px solid gray',
  };
  const fusenTdQuantityStyle = {
    width: '100px',
    textAlign: 'right',
    padding: '10px',
    borderBottom: '1px solid gray',
  };

  return (
    <table style={tableStyle}>
      <thead></thead>
      <tbody>
        {/* 招待状 */}
        {props.order.order_type === 'invitation' && (
          <>
            <tr>
              <td style={tdTitleStyle}>招待状</td>
              <td style={tdQuantityStyle}>{props.order.invitation_quantity}</td>
            </tr>
            <tr>
              <td style={tdTitleStyle}>返信はがき</td>
              <td style={tdQuantityStyle}>{props.order.postcard_quantity}</td>
            </tr>
            <tr>
              <td style={tdTitleStyle}>封筒</td>
              <td style={tdQuantityStyle}>{props.order.envelope_quantity}</td>
            </tr>
            <tr>
              <td style={tdTitleStyle}>封筒予備</td>
              <td style={tdQuantityStyle}>{props.order.envelope_spare_quantity}</td>
            </tr>
            <tr>
              <td style={tdTitleStyle}>付箋</td>
              <td style={tdQuantityStyle}>
                <table style={fusenTableStyle}>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td style={fusenTdTitleStyle}>挙式参列</td>
                      <td style={fusenTdQuantityStyle}>
                        {props.order.ceremony_tag === '1' ? props.order.ceremony_tag_number : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td style={fusenTdTitleStyle}>送迎バス</td>
                      <td style={fusenTdQuantityStyle}>
                        {props.order.courtesy_bus_tag === '1' ? props.order.courtesy_bus_tag_number : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td style={fusenTdTitleStyle}>余興</td>
                      <td style={fusenTdQuantityStyle}>
                        {props.order.entertainment_tag === '1' ? props.order.entertainment_tag_number : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td style={fusenTdTitleStyle}>集合写真</td>
                      <td style={fusenTdQuantityStyle}>
                        {props.order.group_photo_tag === '1' ? props.order.group_photo_tag_number : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td style={fusenTdTitleStyle}>乾杯</td>
                      <td style={fusenTdQuantityStyle}>
                        {props.order.toast_tag === '1' ? props.order.toast_tag_number : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td style={fusenTdTitleStyle}>受付</td>
                      <td style={fusenTdQuantityStyle}>
                        {props.order.reception_tag === '1' ? props.order.reception_tag_number : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td style={fusenTdTitleStyle}>スピーチ</td>
                      <td style={fusenTdQuantityStyle}>
                        {props.order.speech_tag === '1' ? props.order.speech_tag_number : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td style={fusenTdTitleStyle}>祝辞</td>
                      <td style={fusenTdQuantityStyle}>
                        {props.order.congratulations_tag === '1' ? props.order.congratulations_tag_number : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td style={fusenTdTitleStyle}>万歳三唱</td>
                      <td style={fusenTdQuantityStyle}>
                        {props.order.banzai_tag === '1' ? props.order.banzai_tag_number : '-'}
                      </td>
                    </tr>
                    {/* <tr>
                      <td style={{ ...fusenTdTitleStyle, paddingTop: '30px' }}> 合計</td>
                      <td style={{ ...fusenTdQuantityStyle, paddingTop: '30px' }}>{props.order.fusen_quantity}</td>
                    </tr> */}
                  </tbody>
                </table>
              </td>
            </tr>
          </>
        )}

        {/* 席次表 */}
        {props.order.order_type === 'seating' && (
          <>
            <tr>
              <td style={{ ...tdTitleStyle, borderBottom: '1px solid gray' }}>席次表</td>
              <td style={{ ...tdQuantityStyle, borderBottom: '1px solid gray' }}>{props.order.seating_quantity}</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default OrderDetailSubItemInfoTable;
