import React from 'react';

const EditorTextArea = (props) => {
  return (
    <div className="editor_textarea_item">
      <textarea
        name={props.name}
        rows={props.rows}
        wrap={props.wrap}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        readOnly={props.readOnly}
      ></textarea>
    </div>
  );
};

export default EditorTextArea;
