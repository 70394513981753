import React, { useEffect, useCallback } from 'react';
import { EditorTextInput, EditorTextArea } from '../UIkit';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);

const EditorProfileInterview = (props) => {
  //小さい要素から編集用要素に切り替える処理
  const toggleEle = (event) => {
    const target = event.target.closest('.show');
    //クリックされた要素のshowクラスを削除
    target.classList.toggle('show');

    //非表示になっている兄弟要素を表示
    if (target.className === 'edit_move_box') {
      const showTarget = target.nextElementSibling;
      showTarget.classList.toggle('show');
    } else if (target.className === 'edit_box') {
      const showTarget = target.previousElementSibling;
      showTarget.classList.toggle('show');
    }
  };

  return (
    <>
      <h2>プロフィール＆インタビュー</h2>
      <div className="edit_box">
        <h3>プロフィール</h3>
        {/* <p className="small_text">ドラッグアンドドロップで順序変更できます。</p> */}
        <ul id="profile_group" className="dd-box">
          <li
            onMouseDown={(event) => {
              props.mouseupFunc(event, 'prof');
            }}
          >
            <div className="edit_box_group">
              <div className="edit_move_box show" onClick={(event) => toggleEle(event)}>
                <table>
                  <tbody>
                    <tr>
                      <th>質問１</th>
                      <td>{props.prof1Q}</td>
                    </tr>
                    <tr>
                      <th>新郎</th>
                      <td>{props.prof1Agroom}</td>
                    </tr>
                    <tr>
                      <th>新婦</th>
                      <td>{props.prof1Abride}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="edit_box">
                <p className="close_btn" onClick={(event) => toggleEle(event)}>
                  閉じる
                </p>
                <div className="col_1">
                  <EditorTextInput
                    label="質問１"
                    name="profile_1_question"
                    rows="1"
                    value={props.prof1Q}
                    onChange={props.onChangeprof1Q}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新郎"
                    name="profile_1_groom_answer"
                    rows="1"
                    value={props.prof1Agroom}
                    onChange={props.onChangeprof1Agroom}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新婦"
                    name="profile_1_bride_answer"
                    rows="1"
                    value={props.prof1Abride}
                    onChange={props.onChangeprof1Abride}
                  />
                </div>
              </div>
            </div>
          </li>
          <li
            onMouseUp={(event) => {
              props.mouseupFunc(event, 'prof');
            }}
          >
            <div className="edit_box_group">
              <div className="edit_move_box show" onClick={(event) => toggleEle(event)}>
                <table>
                  <tbody>
                    <tr>
                      <th>質問２</th>
                      <td>{props.prof2Q}</td>
                    </tr>
                    <tr>
                      <th>新郎</th>
                      <td>{props.prof2Agroom}</td>
                    </tr>
                    <tr>
                      <th>新婦</th>
                      <td>{props.prof2Abride}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="edit_box">
                <p className="close_btn" onClick={(event) => toggleEle(event)}>
                  閉じる
                </p>
                <div className="col_1">
                  <EditorTextInput
                    label="質問２"
                    name="profile_2_question"
                    rows="1"
                    value={props.prof2Q}
                    onChange={props.onChangeprof2Q}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新郎"
                    name="profile_2_groom_answer"
                    rows="1"
                    value={props.prof2Agroom}
                    onChange={props.onChangeprof2Agroom}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新婦"
                    name="profile_2_bride_answer"
                    rows="1"
                    value={props.prof2Abride}
                    onChange={props.onChangeprof2Abride}
                  />
                </div>
              </div>
            </div>
          </li>
          <li
            onMouseUp={(event) => {
              props.mouseupFunc(event, 'prof');
            }}
          >
            <div className="edit_box_group">
              <div className="edit_move_box show" onClick={(event) => toggleEle(event)}>
                <table>
                  <tbody>
                    <tr>
                      <th>質問３</th>
                      <td>{props.prof3Q}</td>
                    </tr>
                    <tr>
                      <th>新郎</th>
                      <td>{props.prof3Agroom}</td>
                    </tr>
                    <tr>
                      <th>新婦</th>
                      <td>{props.prof3Abride}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="edit_box">
                <p className="close_btn" onClick={(event) => toggleEle(event)}>
                  閉じる
                </p>
                <div className="col_1">
                  <EditorTextInput
                    label="質問３"
                    name="profile_3_question"
                    rows="1"
                    value={props.prof3Q}
                    onChange={props.onChangeprof3Q}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新郎"
                    name="profile_3_groom_answer"
                    rows="1"
                    value={props.prof3Agroom}
                    onChange={props.onChangeprof3Agroom}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新婦"
                    name="profile_3_bride_answer"
                    rows="1"
                    value={props.prof3Abride}
                    onChange={props.onChangeprof3Abride}
                  />
                </div>
              </div>
            </div>
          </li>
          <li
            onMouseUp={(event) => {
              props.mouseupFunc(event, 'prof');
            }}
          >
            <div className="edit_box_group">
              <div className="edit_move_box show" onClick={(event) => toggleEle(event)}>
                <table>
                  <tbody>
                    <tr>
                      <th>質問４</th>
                      <td>{props.prof4Q}</td>
                    </tr>
                    <tr>
                      <th>新郎</th>
                      <td>{props.prof4Agroom}</td>
                    </tr>
                    <tr>
                      <th>新婦</th>
                      <td>{props.prof4Abride}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="edit_box">
                <p className="close_btn" onClick={(event) => toggleEle(event)}>
                  閉じる
                </p>
                <div className="col_1">
                  <EditorTextInput
                    label="質問４"
                    name="profile_4_question"
                    rows="1"
                    value={props.prof4Q}
                    onChange={props.onChangeprof4Q}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新郎"
                    name="profile_4_groom_answer"
                    rows="1"
                    value={props.prof4Agroom}
                    onChange={props.onChangeprof4Agroom}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新婦"
                    name="profile_4_bride_answer"
                    rows="1"
                    value={props.prof4Abride}
                    onChange={props.onChangeprof4Abride}
                  />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="edit_box">
        <h3>インタビュー</h3>
        {/* <p className="small_text">ドラッグアンドドロップで順序変更できます。</p> */}
        <ul id="interview_group" className="dd-box">
          <li
            onMouseUp={(event) => {
              props.mouseupFunc(event, 'interview');
            }}
          >
            <div className="edit_box_group">
              <div className="edit_move_box show" onClick={(event) => toggleEle(event)}>
                <table>
                  <tbody>
                    <tr>
                      <th>質問１</th>
                      <td>{props.interview1Q}</td>
                    </tr>
                    <tr>
                      <th>新郎</th>
                      <td>{props.interview1Agroom}</td>
                    </tr>
                    <tr>
                      <th>新婦</th>
                      <td>{props.interview1Abride}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="edit_box">
                <p className="close_btn" onClick={(event) => toggleEle(event)}>
                  閉じる
                </p>
                <div className="col_1">
                  <EditorTextInput
                    label="質問１"
                    name="interview_1_question"
                    rows="1"
                    value={props.interview1Q}
                    onChange={props.onChangeinterview1Q}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新郎"
                    name="interview_1_groom_answer"
                    rows="1"
                    value={props.interview1Agroom}
                    onChange={props.onChangeinterview1Agroom}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新婦"
                    name="interview_1_bride_answer"
                    rows="1"
                    value={props.interview1Abride}
                    onChange={props.onChangeinterview1Abride}
                  />
                </div>
              </div>
            </div>
          </li>
          <li
            onMouseUp={(event) => {
              props.mouseupFunc(event, 'interview');
            }}
          >
            <div className="edit_box_group">
              <div className="edit_move_box show" onClick={(event) => toggleEle(event)}>
                <table>
                  <tbody>
                    <tr>
                      <th>質問２</th>
                      <td>{props.interview2Q}</td>
                    </tr>
                    <tr>
                      <th>新郎</th>
                      <td>{props.interview2Agroom}</td>
                    </tr>
                    <tr>
                      <th>新婦</th>
                      <td>{props.interview2Abride}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="edit_box">
                <p className="close_btn" onClick={(event) => toggleEle(event)}>
                  閉じる
                </p>
                <div className="col_1">
                  <EditorTextInput
                    label="質問２"
                    name="interview_2_question"
                    rows="1"
                    value={props.interview2Q}
                    onChange={props.onChangeinterview2Q}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新郎"
                    name="interview_2_groom_answer"
                    rows="1"
                    value={props.interview2Agroom}
                    onChange={props.onChangeinterview2Agroom}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新婦"
                    name="interview_2_bride_answer"
                    rows="1"
                    value={props.interview2Abride}
                    onChange={props.onChangeinterview2Abride}
                  />
                </div>
              </div>
            </div>
          </li>
          <li
            onMouseUp={(event) => {
              props.mouseupFunc(event, 'interview');
            }}
          >
            <div className="edit_box_group">
              <div className="edit_move_box show" onClick={(event) => toggleEle(event)}>
                <table>
                  <tbody>
                    <tr>
                      <th>質問３</th>
                      <td>{props.interview3Q}</td>
                    </tr>
                    <tr>
                      <th>新郎</th>
                      <td>{props.interview3Agroom}</td>
                    </tr>
                    <tr>
                      <th>新婦</th>
                      <td>{props.interview3Abride}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="edit_box">
                <p className="close_btn" onClick={(event) => toggleEle(event)}>
                  閉じる
                </p>
                <div className="col_1">
                  <EditorTextInput
                    label="質問３"
                    name="interview_3_question"
                    rows="1"
                    value={props.interview3Q}
                    onChange={props.onChangeinterview3Q}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新郎"
                    name="interview_3_groom_answer"
                    rows="1"
                    value={props.interview3Agroom}
                    onChange={props.onChangeinterview3Agroom}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新婦"
                    name="interview_3_bride_answer"
                    rows="1"
                    value={props.interview3Abride}
                    onChange={props.onChangeinterview3Abride}
                  />
                </div>
              </div>
            </div>
          </li>
          <li
            onMouseUp={(event) => {
              props.mouseupFunc(event, 'interview');
            }}
          >
            <div className="edit_box_group">
              <div className="edit_move_box show" onClick={(event) => toggleEle(event)}>
                <table>
                  <tbody>
                    <tr>
                      <th>質問４</th>
                      <td>{props.interview4Q}</td>
                    </tr>
                    <tr>
                      <th>新郎</th>
                      <td>{props.interview4Agroom}</td>
                    </tr>
                    <tr>
                      <th>新婦</th>
                      <td>{props.interview4Abride}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="edit_box">
                <p className="close_btn" onClick={(event) => toggleEle(event)}>
                  閉じる
                </p>
                <div className="col_1">
                  <EditorTextInput
                    label="質問４"
                    name="interview_4_question"
                    rows="1"
                    value={props.interview4Q}
                    onChange={props.onChangeinterview4Q}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新郎"
                    name="interview_4_groom_answer"
                    rows="1"
                    value={props.interview4Agroom}
                    onChange={props.onChangeinterview4Agroom}
                  />
                </div>
                <div className="col_1">
                  <EditorTextInput
                    label="新婦"
                    name="interview_4_bride_answer"
                    rows="1"
                    value={props.interview4Abride}
                    onChange={props.onChangeinterview4Abride}
                  />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default EditorProfileInterview;
