import React from 'react';
import { validateMaxLineNum } from '../../templates/Public/EditorFunction';
import { EditorTextInput, EditorTextArea } from '../UIkit';

const EditorDatePlace = (props) => {
  return (
    <>
      <h2>日時・場所を入力</h2>
      <div className="edit_box">
        <h3>時間</h3>
        <div className="date_place_item margin_bottom_20">
          <EditorTextInput
            name="time_1_name"
            placeholder=""
            rows="1"
            value={props.time1Name}
            onChange={props.onChangeName1}
            readOnly={props.disabledTime1}
          />
          <EditorTextInput
            name="time_1_time"
            placeholder=""
            rows="1"
            value={props.time1Time}
            onChange={props.onChangeTime1}
            readOnly={props.disabledTime1}
          />
          <label className="editor_item">
            <input
              type="checkbox"
              className="time_nopost_checkbox"
              name="time_1_disabled"
              value={props.disabledTime1Value}
              onChange={props.onChangeDisable1}
              checked={props.disabledTime1}
            />
            <span>非掲載</span>
          </label>
        </div>
        <div className="date_place_item margin_bottom_20">
          <EditorTextInput
            name="time_2_name"
            placeholder=""
            rows="1"
            value={props.time2Name}
            onChange={props.onChangeName2}
            readOnly={props.disabledTime2}
          />
          <EditorTextInput
            name="time_2_time"
            placeholder=""
            rows="1"
            value={props.time2Time}
            onChange={props.onChangeTime2}
            readOnly={props.disabledTime2}
          />
          <label className="editor_item">
            <input
              type="checkbox"
              className="time_nopost_checkbox"
              name="time_2_disabled"
              value={props.disabledTime2Value}
              onChange={props.onChangeDisable2}
              checked={props.disabledTime2}
            />
            <span>非掲載</span>
          </label>
        </div>
        <div className="date_place_item margin_bottom_20">
          <EditorTextInput
            name="time_3_name"
            placeholder=""
            rows="1"
            value={props.time3Name}
            onChange={props.onChangeName3}
            readOnly={props.disabledTime3}
          />
          <EditorTextInput
            name="time_3_time"
            placeholder=""
            rows="1"
            value={props.time3Time}
            onChange={props.onChangeTime3}
            readOnly={props.disabledTime3}
          />
          <label className="editor_item">
            <input
              type="checkbox"
              className="time_nopost_checkbox"
              name="time_3_disabled"
              value={props.disabledTime3Value}
              onChange={props.onChangeDisable3}
              checked={props.disabledTime3}
            />
            <span>非掲載</span>
          </label>
        </div>
      </div>
      <div className="edit_box">
        <h3>会場</h3>
        <div className="col_1">
          <label>会場名（1行）</label>
          <EditorTextArea
            name="venue_name"
            rows="4"
            placeholder="〇〇〇〇ホテル"
            value={props.venueName}
            onChange={(event) => {
              if (validateMaxLineNum(event, 1)) {
                props.onChangeVenueName(event);
              }
            }}
          />
        </div>
        <div className="col_1">
          <label>会場住所・TEL（4行まで）</label>
          <EditorTextArea
            name="venue_info"
            rows="5"
            placeholder="〇〇〇〇&#13;TEL:"
            value={props.venueInfo}
            onChange={(event) => {
              if (validateMaxLineNum(event, 4)) {
                props.onChangeVenueInfo(event);
              }
            }}
          />
        </div>
      </div>
      <div className="edit_box">
        <h3>会費</h3>
        <div className="col_1">
          <label className="editor_item">
            <input
              type="checkbox"
              className="membership_fee_nopost_checkbox"
              name="membership_fee_disabled"
              value={props.disabledmembershipFeeValue}
              onChange={props.onChangeDisableMembershipFee}
              checked={props.disabledmembershipFee}
            />
            <span>非掲載</span>
          </label>
          <EditorTextInput
            name="membership_fee"
            placeholder="15,000円"
            rows="1"
            value={props.membershipFee}
            onChange={props.onChangeMembershipFee}
            readOnly={props.disabledmembershipFee}
          />
        </div>
      </div>
      <div className="edit_box">
        <h3>追記文</h3>
        <div className="col_1">
          <label className="editor_item">
            <label>追記文1（1行）</label>
            <input
              type="checkbox"
              className="message_1_nopost_checkbox"
              name="message_1_disabled"
              value={props.disabledMessage1Value}
              onChange={props.onChangeDisableMessage1}
              checked={props.disabledMessage1}
            />
            <span>非掲載</span>
          </label>
          <EditorTextArea
            name="message_1"
            rows="4"
            placeholder=""
            value={props.message1}
            onChange={(event) => {
              if (validateMaxLineNum(event, 1)) {
                props.onChangeMessage1(event);
              }
            }}
            readOnly={props.disabledMessage1}
          />
          <label>追記文2（1行）</label>
          <label className="editor_item">
            <input
              type="checkbox"
              className="message_2_nopost_checkbox"
              name="message_2_disabled"
              value={props.disabledMessage2Value}
              onChange={props.onChangeDisableMessage2}
              checked={props.disabledMessage2}
            />
            <span>非掲載</span>
          </label>
          <EditorTextArea
            name="message_2"
            rows="4"
            placeholder=""
            value={props.message2}
            onChange={(event) => {
              if (validateMaxLineNum(event, 1)) {
                props.onChangeMessage2(event);
              }
            }}
            readOnly={props.disabledMessage2}
          />

          <label>追記文3（1行）</label>
          <label className="editor_item">
            <input
              type="checkbox"
              className="message_3_nopost_checkbox"
              name="message_3_disabled"
              value={props.disabledMessage3Value}
              onChange={props.onChangeDisableMessage3}
              checked={props.disabledMessage3}
            />
            <span>非掲載</span>
          </label>
          <EditorTextArea
            name="message_3"
            rows="4"
            placeholder=""
            value={props.message3}
            onChange={(event) => {
              if (validateMaxLineNum(event, 1)) {
                props.onChangeMessage3(event);
              }
            }}
            readOnly={props.disabledMessage3}
          />
        </div>
      </div>
    </>
  );
};

export default EditorDatePlace;
