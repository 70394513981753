import React, { useCallback, useEffect, useState } from 'react';
import { adminLogout } from '../../reducks/admins/operations';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RouteDir } from '../../common';
import { config } from '../../config';

const Header = () => {
  const dispatch = useDispatch();

  const telnumberStyle = {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_tel.png')`,
  };

  const mailformStyle = {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/footer_contact.png')`,
  };

  return (
    <>
      <footer>
        <div className="subline_1000">
          <aside className="footer_menu">
            {/* <ul>
            <li onClick={()=>dispatch(push(RouteDir))}>トップページ</li>
            <li onClick={()=>dispatch(push(RouteDir+'/howto'))}>ご利用について</li>
            <li onClick={()=>dispatch(push(RouteDir+'/commercial_transaction'))}>特定商取引法に基づく表記</li>
            <li onClick={()=>dispatch(push(RouteDir+'/privacy_policy'))}>プライバシーポリシー</li>
          </ul> */}
            <div className="media_area">
              {/* <span>公式メディア</span>
              <a href="https://www.instagram.com/ja_nagasaki_seihi_agriplus/" target="_blank">
                <img src={process.env.PUBLIC_URL + '/images/icon_instagram.svg'} alt="instagram" />
              </a>
              <a href="https://www.youtube.com/channel/UCgA2-pf8uF_jw3yY2vYLFWg" target="_blank">
                <img src={process.env.PUBLIC_URL + '/images/icon_youtube.svg'} alt="YouTube" />
              </a> */}
            </div>
          </aside>
          <div className="contact_infomation_area">
            <div className="telnumber_area">
              <p>お電話によるお問い合わせ</p>
              <p className="telnumber" style={telnumberStyle}>
                {config.COMPANY_TEL_NUMBER}
              </p>{' '}
              <p>{config.COMPANY_BUSINESS_DAY}</p>
            </div>
            <div className="logo_image_area">
              <img src={process.env.PUBLIC_URL + '/images/logo_public_footer.png'} alt="" />{' '}
              {/* 一般画面：フッター：ロゴ画像 */}
            </div>
            <div className="mailform_area">
              <p>メールによるお問い合わせ</p>
              <div className="mailform">
                <button
                  className="btn_type_2"
                  style={mailformStyle}
                  onClick={() => dispatch(push(RouteDir + '/contact'))}
                >
                  コチラから
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="fp_menu">
          <ul>
            <li onClick={() => dispatch(push(RouteDir + '/howto'))}>ご利用ガイド</li>
            <li onClick={() => dispatch(push(RouteDir + '/commercial_transaction'))}>特定商取引法に基づく表記</li>
            <li onClick={() => dispatch(push(RouteDir + '/privacy_policy'))}>プライバシーポリシー</li>
            {/* 一般画面：フッター：SNSリンク */}
            {/* <li className="official_sns">
              <div className="sns_logo_box">
                <span>公式SNS</span>
                <a href={config.COMPANY_SNS_URL_1} target="_blank">
                  <img src={process.env.PUBLIC_URL + '/images/footer_sns_icon_1.png'} alt="" />
                </a>
                <a href={config.COMPANY_SNS_URL_2} target="_blank">
                  <img src={process.env.PUBLIC_URL + '/images/footer_sns_icon_2.png'} alt="" />
                </a>
                <a href={config.COMPANY_SNS_URL_3} target="_blank">
                  <img src={process.env.PUBLIC_URL + '/images/footer_sns_icon_3.png'} alt="" />
                </a>
                <a href={config.COMPANY_SNS_URL_4} target="_blank">
                  <img src={process.env.PUBLIC_URL + '/images/footer_sns_icon_4.png'} alt="" />
                </a>
              </div>
            </li> */}
          </ul>
        </div>
        <div className="copylight_area">
          <p>
            Copyright © 2023 {config.COMPANY_NAME} <span>All rights reserved.</span>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Header;
