/* 
招待状サンプル
http://localhost:3000/pocket_ec/react/admin/orders/saved_design_export/invitation/dBDd8SAHbdnigXeKUlhX

席次表サンプル
http://localhost:3000/pocket_ec/react/admin/orders/saved_design_export/seating/2Xf6R6MX2fy8do3ug1eP
*/

/* 
処理手順メモ

保存データ種別・シリアル取得（queryParamから）
SVG画像名生成 or 取得
各SVG画像読み込み　済
  SVG画像処理
    ObjectURL(SVG)に変換　済
      アスペクト比取得
    ObjectURL(SVG)からObjectURL(PNG)生成　済
      長辺ピクセル数が5000を超える用に

    
PDF要素生成　済
  PNG画像ごとにPage要素作成　済
    PNG画像設置　済
    用紙サイズ設定　済
ダウンロード
  ダウンロードボタン表示制御
  ダウンロード可能イベント取得できるとよい

*/

/* 
SVG調整

黒枠非表示
透過しないように背景色を設定
出力するはがきは選択状態にあわせる
  FormData取得が必要
separateの場合ははがきを分割
combine, postcard_backsideのサイズ
付箋分割　保留

*/

import React, { useState } from 'react';
import { Page, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Image } from '@react-pdf/renderer';
import { useEffect } from 'react';
import { AdminsDir, ApiDir, PROGRAM_ERROR_ALERT_MSG, SAVED_SVG_DIR } from '../../common';
import { useDispatch } from 'react-redux';
import { loadingChangeAction } from '../../reducks/popups/actions';
import Loading from '../../components/public/Loading';
import { config } from '../../config';
import axios from 'axios';
import { changeSvgElemVisibilitiesByPurpose } from '../Public/EditorFunction';

const SavedDesignExport = (props) => {
  // console.log(props);
  const dispatch = useDispatch();

  const [orderNum, setOrderNum] = useState(props.match.params.orderNum); /* 注文番号 */
  const [designType, setDesignType] = useState(props.match.params.type); /* デザイン種別 */
  const [saveDataSerial, setSaveDataSerial] = useState(props.match.params.serial); /* 保存データシリアル */
  const [pdfFileName, setPdfFileName] = useState(designType + '_' + saveDataSerial); /* PDFファイル名 */

  const [pdfDocument, setPdfDocument] = useState(); /* PDF要素 */
  const [canDownloadPdf, setCanDownloadPdf] = useState(false); /* ダウンロード準備完了判定 */
  const [totalPageNum, setTotalPageNum] = useState(0); /* 総ページ数 */
  const [currentPageNum, setCurrentPageNum] = useState(0); /* 総ページ数 */

  // PDF表示設定
  const pdfStyles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      // backgroundColor: '#00ff00',/* 検証用緑 */
    },
    section: {
      margin: 0,
      padding: 0,
      flexGrow: 0,
    },
  });

  // SVGデータを取得
  const getSvgDataList = async (designType, designSerial) => {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams();
      params.append('getter', 'admin');
      params.append('designType', designType);
      params.append('designSerial', designSerial);
      axios.post(ApiDir + '/getSavedSVGData.php', params).then((response) => {
        if (response.data === 'nologin') {
          //未ログイン時はアラートを出してログイン画面に遷移
          window.alert('ログインしてください。');
          dispatch(push(AdminsDir + '/login'));
        } else if (response.data === 'error') {
          resolve(null);
        } else {
          resolve(response.data);
        }
      });
    });
  };

  // SVG内要素を確認用PDF出力用に表示調整
  const adjustSvgElemVisibilities = (svgDataList, designType) => {
    // SVG要素をCanvas要素内に読み込み
    const loadSvgOnCanvasElem = (canvasElem, svgDataList) => {
      svgDataList.map((value, index) => {
        canvasElem.innerHTML += value;
      });
    };

    // Canvas要素内のSVG要素をテキスト形式のリストとして取得
    const getSvgsFromCanvasAsTextList = (canvasElem) => {
      const svgElems = canvasElem.querySelectorAll('svg');
      let svgTextList = [];
      for (let i = 0; i < svgElems.length; i++) {
        svgTextList.push(svgElems[i].outerHTML);
      }
      return svgTextList;
    };

    // SVG内の要素を操作するために、一度画面に読み込み
    const tmpCanvas = document.querySelector('#tmpCanvas');
    loadSvgOnCanvasElem(tmpCanvas, svgDataList);

    // 確認用PDF出力用に表示調整
    changeSvgElemVisibilitiesByPurpose('print_pdf', designType);

    // 調整後のSVG要素をテキスト形式のリストとして取得
    const svgTextList = getSvgsFromCanvasAsTextList(tmpCanvas);

    return svgTextList;
  };

  // SVGデータからPNG形式のObjectURLを生成
  const createPngObjectURL = async (svgData) => {
    // SVGデータをObjectURL形式で読み込み
    const createImgObjectURL = async (svgData) => {
      const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      return window.URL.createObjectURL(svgBlob);
    };

    // 画像アスペクト比を取得
    const getImgAspectRatio = (imgObjectUrl) => {
      // 小数第3位以下切り捨て
      const truncateNumAfter3 = (number) => {
        const value = Number(number);
        const tmpValue = value * 100;
        return Math.floor(tmpValue) / 100;
      };

      return new Promise((resolve, reject) => {
        const img = new window.Image();
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          const aspectRatio = truncateNumAfter3(width / height);
          resolve(aspectRatio);
        };

        img.onerror = (error) => {
          console.log(error);
          window.alert(PROGRAM_ERROR_ALERT_MSG);
          throw '画像処理エラー';
        };

        img.src = imgObjectUrl;
      });
    };

    // SVGをPNG形式で　Canvasに描画する際のサイズ（単位：ピクセル）を計算
    const calcPngSize = (imgAspectRatio, imgShortSideMinPixel) => {
      let pngWidth, pngHeight;

      if (Number(imgAspectRatio) > 1) {
        // 横長
        pngWidth = imgShortSideMinPixel * imgAspectRatio;
        pngHeight = imgShortSideMinPixel;
      } else {
        // 正方形 or 縦長
        pngWidth = imgShortSideMinPixel;
        pngHeight = imgShortSideMinPixel / imgAspectRatio;
      }

      return [Math.round(pngWidth), Math.round(pngHeight)];
    };

    // SVGファイルをPNG形式に変換する
    const convertSvgToPng = (imgObjectURL, pngWidth, pngHeight) => {
      return new Promise((resolve, reject) => {
        // Canvas設定
        const canvas = document.createElement('canvas');
        canvas.width = pngWidth;
        canvas.height = pngHeight;
        const ctx = canvas.getContext('2d');
        // const ctx = canvas.getContext('2d', { alpha: false }); /* 透過させない */

        // Imageオブジェクト生成
        const img = new window.Image();

        // SVG画像読み込み完了時
        img.onload = () => {
          // CanvasにSVG画像を描画
          ctx.drawImage(img, 0, 0, pngWidth, pngHeight);

          // CanvasをBlob形式に変換
          canvas.toBlob((result) => {
            // console.log(result);
            const objectUrl = window.URL.createObjectURL(result);
            resolve(objectUrl);
          });
        };

        // SVG画像読み込み失敗時
        img.onerror = (error) => {
          window.alert(PROGRAM_ERROR_ALERT_MSG);
          throw 'SVG画像エラー';
          reject(error);
        };

        // SVG画像読み込み
        img.src = imgObjectURL;
      });
    };

    // SVG画像をObjectURL形式で読み込み
    const svgObjectURL = await createImgObjectURL(svgData);
    // console.log(svgObjectURL);

    // SVG画像のアスペクト比を取得
    const aspectRatio = await getImgAspectRatio(svgObjectURL);
    // console.log(aspectRatio);

    // 最適なPNG画像サイズを計算
    const [pngWidth, pngHeight] = calcPngSize(aspectRatio, config.IMG_SHORT_SIDE_MIN_PIXEL);
    console.log(pngWidth, pngHeight);

    // SVG画像をPNG形式に変換
    const pngObjectURL = await convertSvgToPng(svgObjectURL, pngWidth, pngHeight);
    // console.log(pngObjectURL);

    return [pngObjectURL, pngWidth, pngHeight];
  };

  // PDF用Page要素生成
  const createPdfPage = (pageStyle, sectionStyle, imgSrc, imgWidth, imgHeight) => {
    return (
      <Page size={[imgWidth, imgHeight]} style={pageStyle}>
        <View style={sectionStyle}>
          <Image src={imgSrc} />
        </View>
      </Page>
    );
  };

  // Page要素リストからPDFルート要素となるDocument要素生成
  const createPdfDocument = (pageList) => {
    return (
      <Document>
        {pageList &&
          pageList.map((page, index) => {
            return page;
          })}
      </Document>
    );
  };

  // PDFダウンロードボタンクリック
  const clickPdfDownloadBtn = () => {
    const downloadBtn = document.querySelector('#pdfDownloadBtn');
    if (downloadBtn) {
      downloadBtn.click();
    } else {
      console.log('download error');
      window.alert(PROGRAM_ERROR_ALERT_MSG);
    }
  };

  // 保存されたデザインからPDF生成
  const createSavedDesignPdf = async (designType, saveDataSerial) => {
    // 保存されたSVGファイル取得
    const svgDataList = await getSvgDataList(designType, saveDataSerial);

    // ファイル取得失敗
    if (svgDataList === null) {
      throw 'SVGデータ取得失敗';
    }

    const adjustedSvgDataList = adjustSvgElemVisibilities(svgDataList, designType);

    setTotalPageNum(adjustedSvgDataList.length);

    let pageList = [];
    for (let i = 0; i < adjustedSvgDataList.length; i++) {
      // 各SVGデータからPNG形式のObjectURL生成
      const [pngObjectURL, pngWidth, pngHeight] = await createPngObjectURL(adjustedSvgDataList[i]);

      // PDF用Page要素生成
      const page = createPdfPage(pdfStyles.page, pdfStyles.section, pngObjectURL, pngWidth, pngHeight);
      pageList.push(page);
      setCurrentPageNum((prev) => prev + 1);
    }

    // PDF用Document要素生成
    const pdf = createPdfDocument(pageList);
    return pdf;
  };

  // PDFファイル名を生成
  const createPdfFileName = (designType, orderNum) => {
    // 現在日時を取得
    const getNowDateTime = () => {
      // 2桁ゼロ埋め
      const zeroFillTwo = (number) => {
        return ('00' + number).slice(-2);
      };

      const now = new Date();
      const year = now.getFullYear();
      const month = zeroFillTwo(now.getMonth() + 1);
      const date = zeroFillTwo(now.getDate());
      const hours = zeroFillTwo(now.getHours());
      const minutes = zeroFillTwo(now.getMinutes());
      const seconds = zeroFillTwo(now.getSeconds());
      return `${year}-${month}-${date}_${hours}-${minutes}-${seconds}`;
    };

    let designName;
    if (designType === 'invitation') designName = '招待状';
    if (designType === 'seating') designName = '席次表';

    return `${orderNum}_${designName}`;
    // return `${orderNum}_${designName}_${getNowDateTime()}`;
  };

  useEffect(() => {
    setPdfFileName(createPdfFileName(designType, orderNum));
  }, [designType, orderNum]);

  // ページ読み込み時の処理
  useEffect(async () => {
    // ローディング開始
    dispatch(loadingChangeAction(true));

    try {
      // 保存されたデザインからPDF生成
      const pdf = await createSavedDesignPdf(designType, saveDataSerial);
      setPdfDocument(pdf);
    } catch (error) {
      console.error(error);
      window.alert(PROGRAM_ERROR_ALERT_MSG);
      // ローディング解除
      dispatch(loadingChangeAction(false));
    }
  }, []);

  // PDFがダウンロード可能になったときの処理
  useEffect(() => {
    if (!canDownloadPdf) {
      return;
    }

    // PDF自動ダウンロード
    clickPdfDownloadBtn();

    // ローディング解除
    dispatch(loadingChangeAction(false));

    // 画面を閉じる
    window.close();
  }, [canDownloadPdf]);

  return (
    <>
      <div id="tmpCanvas"></div>
      {pdfDocument && (
        <>
          {/* PDFプレビュー */}
          {/* https://react-pdf.org/components#pdfviewer */}
          {/* <PDFViewer width={1000} height={500}>
            {pdfDocument}
          </PDFViewer> */}

          {/* PDFダウンロードリンク */}
          {/* https://react-pdf.org/components#pdfdownloadlink */}
          <PDFDownloadLink document={pdfDocument} fileName={pdfFileName} style={{ textDecoration: 'none' }}>
            {({ blob, url, loading, error }) => {
              // console.log(blob, url, loading, error);
              if (blob && url && !loading) {
                setCanDownloadPdf(true);
                return (
                  <button id="pdfDownloadBtn" hidden>
                    PDF出力
                  </button>
                );
              }
              if (error) {
                console.log(error);
                window.alert(PROGRAM_ERROR_ALERT_MSG);
              }
            }}
          </PDFDownloadLink>
        </>
      )}
      <Loading />
      <div id="pdfExportStatusBox">
        <div className="status_text">
          {currentPageNum === 0 && totalPageNum === 0 ? (
            <>出力画像取得中</>
          ) : (
            <>
              {currentPageNum < totalPageNum ? (
                <>
                  出力画像確認中
                  <br />
                  {currentPageNum}/{totalPageNum}
                </>
              ) : (
                <>
                  PDFファイル生成中
                  <br />
                  <span className="small">1分ほどかかる場合がございますが、そのままお待ちください。</span>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SavedDesignExport;
