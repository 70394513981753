import React from 'react';
import { validateMaxLineNum } from '../../templates/Public/EditorFunction';
import { EditorTextArea, EditorTextInput } from '../UIkit';

const EditorSeatingAdditionalComment = (props) => {
  return (
    <>
      <h2>
        ＜席次表＞
        <br />
        追記文を入力
      </h2>
      <div className="edit_box">
        <h3>追記文</h3>
        <div className="col_1">
          <EditorTextArea
            // label="追記文"
            name="seating_additional_comment"
            rows="5"
            placeholder="ご芳名・お席順に失礼な点がございましたら慶事に免じご寛容のほどお願い申し上げます。"
            value={props.seatingAdditionalComment}
            onChange={(event) => {
              if (validateMaxLineNum(event, 1)) {
                props.onChangeSeatingAdditionalComment(event);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default EditorSeatingAdditionalComment;
