import React from 'react';
import { DataGrid, GridToolbar, jaJP } from '@mui/x-data-grid';
import { addIdToEachDataForDataGrid } from '../../MyDataGridLib';

// 会員に登録されている宛名リスト用テーブル
const MemberGuestListTable = (props) => {
  // Grid用カラム情報
  const tableCols = [
    { field: 'excel_row', headerName: 'Excel行番号', width: 100, headerAlign: 'center', align: 'center' },
    // {
    //   field: 'selectBtn',
    //   headerName: '選択',
    //   width: 50,
    //   headerAlign: 'center',
    //   align: 'right',
    //   renderCell: () => <input type="checkbox" defaultChecked={true} />,
    // },
    { field: 'family_name', headerName: '苗字', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'first_name', headerName: '名前', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'honorific', headerName: '敬称', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'wife_honorific', headerName: '令夫人', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint1_family_name', headerName: '苗字Ａ', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint1_first_name', headerName: '名前連名A', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint1_honorific', headerName: '敬称A', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'to_family', headerName: 'ご家族様', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint2_first_name', headerName: '名前連名B', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint2_honorific', headerName: '敬称B', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint3_first_name', headerName: '名前連名C', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'joint3_honorific', headerName: '敬称C', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'postal_code', headerName: '郵便番号', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'address_1', headerName: '住所１', width: 300, headerAlign: 'center', align: 'left' },
    { field: 'address_2', headerName: '住所２', width: 300, headerAlign: 'center', align: 'left' },
    { field: 'title', headerName: ' 会社名・役職', width: 300, headerAlign: 'center', align: 'center' },
  ];

  return (
    <div className="member_guest_table_wrapper">
      <DataGrid
        autoHeight // グリッドの行数時応じて変化
        // style={{ maxHeight: tableHeight, height: tableHeight }} // 高さ指定
        pageSize={10}
        // autoPageSize // pageSizeは最大行事応じて計算される
        // checkboxSelection // チェックボックス
        // checkboxSelectionVisibleOnly={(e)=>(console.log(e))} //チェックボックス選択時
        // columnBuffer={10}
        // rowBuffer={10}
        density="compact" // 行の高さ compact standard comfortable
        // disableExtendRowFullWidth // コンテナいっぱいに表示させない
        disableColumnFilter // フィルター非表示
        // editMode="cell"
        // logger={console}
        // onCellClick={(e) => console.log("click", e.row)}
        // rowSpacingType="border"
        // disableMultipleColumnsFiltering // 複数フィルタリング
        disableMultipleColumnsSorting={true} // 複数ソート
        disableSelectionOnClick // データの選択を無効にする
        showCellRightBorder
        showColumnRightBorder
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        // components={
        //   // props.data.Toolbar && {
        //     Toolbar: GridToolbar
        //   // }
        // }
        // components={{
        //   Toolbar: GridToolbar, // ツールバーを指定する
        // }}
        columns={tableCols}
        rows={addIdToEachDataForDataGrid(props.guestList)}
      />
      <p style={{ margin: '0', textAlign: 'center', fontSize: '0.9em', color: 'gray' }}>
        見切れている場合は横スクロールしてご覧ください。
      </p>
    </div>
  );
};

export default MemberGuestListTable;
