import { PublicImageDir } from '../../common';
//canvasとtransformの比率を計算する処理
const canvasViewboxMagnificationCalc = (target) => {
  if (target) {
    // //ターゲット要素の親要素.svg_wrapを取得
    const targetParentElement = target.closest('.svg_wrap');
    const res = targetParentElement.style.transform;
    const matrixArray = res.split(',');
    return Number(matrixArray[3]);
  }
};

//guest要素の基本情報
// const GW = 10; //guest要素の幅
// const GH = 5; //guest要素の高さ
const GW = 75; //guest要素の幅
const GH = 32; //guest要素の高さ
const GUEST_GAP_Y = 2; /* ゲスト間の余白 */

////////////////////////////
//
//追加要素の作成（席）
//
////////////////////////////

const makeAddElement = () => {
  //追加要素を作成
  const addElement = document.createElementNS('http://www.w3.org/2000/svg', 'g');

  //各属性、クラスを追加
  addElement.setAttribute('class', 'guest');
  // addElement.setAttribute('width', GW); /* 文字カスレ検証用 */
  // addElement.setAttribute('height', GH); /* 文字カスレ検証用 */

  //rect要素を作成
  const rectElement = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
  rectElement.setAttribute('width', GW);
  rectElement.setAttribute('height', GH);
  rectElement.setAttribute('fill-opacity', '0');
  rectElement.setAttribute('stroke-width', '0.5');
  rectElement.setAttribute('stroke-dasharray', '2,2');
  rectElement.setAttribute('stroke', 'rgba(0,0,0,.5)');

  //閉じるボタン要素群を作成
  const closeElement = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  closeElement.setAttribute('class', 'close_btn');
  closeElement.setAttribute('display', 'none');
  closeElement.setAttribute('onclick', 'closeClick(event)');
  closeElement.setAttribute('transform', `${'matrix(1,0,0,1,' + GW + ',0)'}`);

  //ボタン要素を作成
  //丸
  const closeCircleElement = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
  closeCircleElement.setAttribute('cx', '0');
  closeCircleElement.setAttribute('cy', '0');
  closeCircleElement.setAttribute('r', '6');
  closeCircleElement.setAttribute('fill', '#CC2727');
  closeCircleElement.setAttribute('style', 'pointer-events: auto;');

  //バツ
  const line1 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line1.setAttribute('x1', '-3');
  line1.setAttribute('x2', '3');
  line1.setAttribute('y1', '-3');
  line1.setAttribute('y2', '3');
  line1.setAttribute('fill', 'none');
  line1.setAttribute('stroke', '#FFFFFF');
  line1.setAttribute('style', 'stroke-width: 0.5; pointer-events: none;');

  const line2 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  line2.setAttribute('x1', '-3');
  line2.setAttribute('x2', '3');
  line2.setAttribute('y1', '3');
  line2.setAttribute('y2', '-3');
  line2.setAttribute('fill', 'none');
  line2.setAttribute('stroke', '#FFFFFF');
  line2.setAttribute('style', 'stroke-width: 0.5; pointer-events: none;');

  //rect要素を追加
  addElement.appendChild(rectElement);

  //ボタン群要素に丸とバツを追加
  closeElement.appendChild(closeCircleElement);
  closeElement.appendChild(line1);
  closeElement.appendChild(line2);

  //ボタン群を追加
  addElement.appendChild(closeElement);

  // 席の閉じるボタン表示切替
  const changeSeatCloseBtnVisibility = (visibility, event) => {
    const seatElem = event.target.closest('.guest');
    const closeBtn = seatElem.querySelector('.close_btn'); /* 閉じるボタン要素 */
    if (visibility === 'visible') {
      closeBtn.setAttribute('display', 'visible'); /* 表示 */
    } else if (visibility === 'hidden') {
      closeBtn.setAttribute('display', 'none'); /* 非表示 */
    }
  };

  // // 席要素にマウスホバー時に閉じるボタン表示
  // addElement.addEventListener('mouseenter', (event) => changeSeatCloseBtnVisibility('visible', event));

  // // 席要素にマウスホバー時に閉じるボタン非表示
  // addElement.addEventListener('mouseleave', (event) => changeSeatCloseBtnVisibility('hidden', event));

  return addElement;
};

////////////////////////////
//
//追加する丸席の要素を作成
//
////////////////////////////

const createCircleSeatElem = () => {
  const circleSeatElement = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  circleSeatElement.setAttribute('class', 'table_element_group');

  const guestNumber = 4; //片側に配置するguest要素の数

  //ドロップ要素の大きさ
  const DEWidth = GW;
  const DEHeight = GH * guestNumber + GUEST_GAP_Y * (guestNumber - 1);

  //guest要素の左右ずれ
  const GGap = 10;

  // 楕円テーブル設定
  const TABLE_ELLIPSE = {
    cx: DEWidth /* 中心x座標 */,
    cy: DEHeight / 2 /* 中心y座標 */,
    rx: 10 /* x軸半径 */,
    ry: DEHeight / 2 /* y軸半径 */,
    stroke: '#ca6716' /* 楕円テーブル枠線カラー */,
    strokeWidth: 0.1 /* 楕円テーブル枠線幅 */,
    fill: 'none',
  };

  // テーブル名表示欄設定
  // テーブル名用要素をまとめるg要素設定
  const TABLE_NAME_GROUP = {
    transformX: TABLE_ELLIPSE.cx - (TABLE_ELLIPSE.rx * 1.25) / 2,
    transformY: TABLE_ELLIPSE.cy - TABLE_ELLIPSE.ry / 2,
  };
  // テーブル名編集用rect要素設定
  const TABLE_NAME_RECT = {
    x: 0,
    y: 0,
    // x: TABLE_ELLIPSE.cx - (TABLE_ELLIPSE.rx * 1.25) / 2,
    // y: TABLE_ELLIPSE.cy - TABLE_ELLIPSE.ry / 2,
    width: TABLE_ELLIPSE.rx * 1.25,
    height: TABLE_ELLIPSE.ry,
  };

  // 席追加時に表示される長方形設定
  // const NEW_SEAT_DROP_RECT = {
  //   WIDTH: DEWidth,
  // };

  // 中心からの距離x
  const GUEST_DISTANCE_FROM_CENTER_X = 15;

  // 丸テーブル
  // const circleImage = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
  // circleImage.setAttribute('cx', DEWidth);
  // circleImage.setAttribute('cy', DEHeight / 2);
  // circleImage.setAttribute('r', 12);
  // // circleImage.setAttribute('r', (DEWidth / 3) * 2);
  // circleImage.setAttribute('stroke', '#0000FF');
  // circleImage.setAttribute('fill', 'none');

  // 楕円テーブルellipse要素生成
  const createEllipseTableElem = (param) => {
    // const ellipse = document.createElementNS('http://www.w3.org/2000/svg', 'ellipse');
    // ellipse.setAttribute('cx', param.cx);
    // ellipse.setAttribute('cy', param.cy);
    // ellipse.setAttribute('rx', param.rx);
    // ellipse.setAttribute('ry', param.ry);
    // ellipse.setAttribute('stroke', param.stroke);
    // ellipse.setAttribute('stroke-width', param.strokeWidth);
    // ellipse.setAttribute('fill', param.fill);
    // return ellipse;

    // 以下ページでテーブルPathを作成
    // https://yqnn.github.io/svg-path-editor/
    const tablePath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    tablePath.setAttribute('d', 'M 1 0 C 0 0 0 1 0 1 L 0 9 C 0 10 1 10 1 10 C 1 10 2 10 2 9 L 2 1 C 2 0 1 0 1 0');
    tablePath.setAttribute('transform', 'matrix(10,0,0,13,65,3)');
    tablePath.setAttribute('fill', param.fill);
    tablePath.setAttribute('stroke', param.stroke);
    tablePath.setAttribute('stroke-width', param.strokeWidth);
    return tablePath;
  };

  // テーブル名関連要素作成
  const createTableNameGroup = (param) => {
    // テーブル名編集用rect要素生成
    const createTableNameRect = (param) => {
      const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      rect.setAttribute('class', 'table_name_rect');
      rect.setAttribute('x', param.x);
      rect.setAttribute('y', param.y);
      rect.setAttribute('width', param.width);
      rect.setAttribute('height', param.height);
      rect.setAttribute('stroke', '#000000');
      rect.setAttribute('stroke-width', 0);
      rect.setAttribute('fill-opacity', '0');
      rect.setAttribute('opacity', '0.5');
      // rect.setAttribute('display', 'none');

      /* 
      テキスト情報としてテーブル名を保持
      テーブル名編集時にテーブル名テキストをセット
      次回編集時にフォームに復元
    */
      rect.setAttribute('data-table-name', '');
      return rect;
    };

    // テーブル名pathを格納するg要素生成
    const createTableNamePathsG = () => {
      const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
      g.setAttribute('class', 'table_name_paths');
      return g;
    };

    const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    g.setAttribute('transform', `matrix(1,0,0,1,${param.transformX},${param.transformY})`);
    g.setAttribute('class', 'table_name_group');

    // テーブル名表示用Rect生成
    const tableNameRect = createTableNameRect(TABLE_NAME_RECT);

    // テーブル名pathを格納するg要素生成
    const tableNamePathsG = createTableNamePathsG();

    g.appendChild(tableNameRect);
    g.appendChild(tableNamePathsG);
    return g;
  };

  const makeDropboxElement = (x, y, position) => {
    const dropBoxElement = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    dropBoxElement.setAttribute('class', `drop_box_element drop_box_${position}`);
    dropBoxElement.setAttribute('x', x);
    dropBoxElement.setAttribute('y', y);
    dropBoxElement.setAttribute('width', DEWidth);
    dropBoxElement.setAttribute('height', DEHeight);
    dropBoxElement.setAttribute('stroke', 'rgb(228,118,124)');
    dropBoxElement.setAttribute('fill-opacity', '0');
    dropBoxElement.setAttribute('opacity', '0');
    // dropBoxElement.setAttribute('display', 'none');
    dropBoxElement.setAttribute('transform', `translate(0,0)`);
    dropBoxElement.setAttribute('ondrop', `onDrop(event, '${position}')`);
    dropBoxElement.setAttribute('ondragover', 'onDragOver(event)');
    dropBoxElement.setAttribute('ondragleave', 'onDragLeave(event)');
    return dropBoxElement;
  };

  // テーブル要素生成
  const circleImage = createEllipseTableElem(TABLE_ELLIPSE);

  // テーブル名用要素をまとめるg要素生成
  const tableNameGroup = createTableNameGroup(TABLE_NAME_GROUP);

  const dropBoxElementLeft = makeDropboxElement(-GUEST_DISTANCE_FROM_CENTER_X, 0, 'left');
  const dropBoxElementRight = makeDropboxElement(DEWidth + GUEST_DISTANCE_FROM_CENTER_X, 0, 'right');
  // const dropBoxElementLeft = makeDropboxElement(-GUEST_DISTANCE_FROM_CENTER_X, GUEST_GAP_Y, 'left');
  // const dropBoxElementRight = makeDropboxElement(DEWidth + GUEST_DISTANCE_FROM_CENTER_X, GUEST_GAP_Y, 'right');

  //グループ内に各要素を追加
  circleSeatElement.appendChild(circleImage);
  circleSeatElement.appendChild(tableNameGroup);
  // circleSeatElement.appendChild(tableNamePathsG);
  circleSeatElement.appendChild(dropBoxElementLeft);
  circleSeatElement.appendChild(dropBoxElementRight);

  //初期のguest要素を追加

  //guest要素の合計の高さ
  const allGuestHeight = GH * guestNumber;

  // if (allGuestHeight < DEHeight) {
  //   //ドロップ要素よりguest要素の合計高さが小さい場合
  //   //要素の隙間を計算
  //   const guestElementSpace = (DEHeight - allGuestHeight) / (guestNumber - 1);

  //   //要素の開始位置を計算
  //   const leftX = GGap * -1;
  //   const rightX = DEWidth * 2 - GW + GGap;

  //   const addDefaultElement = (x, guestElementSpace, targetHeight, circleSeatElement) => {
  //     for (let i = 0; i < guestNumber; i++) {
  //       const addElement = makeAddElement();
  //       if (i === 0) {
  //         addElement.setAttribute('transform', 'matrix(1,0,0,1,' + x + ',0)');
  //       } else if (i === guestNumber - 1) {
  //         addElement.setAttribute('transform', 'matrix(1,0,0,1,' + x + ',' + (targetHeight - GH) + ')');
  //       } else {
  //         addElement.setAttribute('transform', 'matrix(1,0,0,1,' + x + ',' + (guestElementSpace + GH) * i + ')');
  //       }
  //       circleSeatElement.appendChild(addElement);
  //     }
  //   };
  //   //左右のデフォルトguest要素を追加
  //   addDefaultElement(leftX, guestElementSpace, DEHeight, circleSeatElement);
  //   addDefaultElement(rightX, guestElementSpace, DEHeight, circleSeatElement);
  // } else {
  //guest要素の初期位置を計算
  const startElementPositionY = 0 - (allGuestHeight - DEHeight) / 2;

  //要素の開始位置を計算
  const leftX = -GUEST_DISTANCE_FROM_CENTER_X;
  const rightX = GW + GUEST_DISTANCE_FROM_CENTER_X;

  const addDefaultElement = (guestPosition, guestX, startElementPositionY, circleSeatElement) => {
    const seatG = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    seatG.setAttribute('class', `seat_group_${guestPosition}`);
    seatG.setAttribute('transform', `translate(${guestX},0)`);
    // seatG.setAttribute('transform', `matrix(1,0,0,1,${guestX},0)`);

    for (let i = 0; i < guestNumber; i++) {
      const addElement = makeAddElement();

      const guestY = startElementPositionY + GH * i + GUEST_GAP_Y * (i - 1);
      addElement.setAttribute('transform', `translate(0,${guestY})`);
      // addElement.setAttribute('transform', `matrix(1,0,0,1,0,${guestY})`);
      // addElement.setAttribute('transform', `matrix(1,0,0,1,${guestX},${guestY})`);

      // addElement.setAttribute('transform', 'matrix(1,0,0,1,' + x + ',' + (startElementPositionY + GH * i) + ')');
      seatG.appendChild(addElement);
      // circleSeatElement.appendChild(addElement);
    }
    circleSeatElement.appendChild(seatG);
  };
  // //左右のデフォルトguest要素を追加
  addDefaultElement('left', leftX, startElementPositionY, circleSeatElement);
  addDefaultElement('right', rightX, startElementPositionY, circleSeatElement);
  // }

  return circleSeatElement;
};

// １つのテーブル単位となるg要素を作成
const createTableGroup = () => {
  const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  group.setAttribute('class', 'table_group svg_move_group');
  return group;
};

const clicktest = (event) => {
  window.alert('テーブルクリック');
};

// テーブル移動用rect要素生成
const createTableMoveRect = () => {
  const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
  rect.setAttribute('class', 'svg_move_element click_element event_none');
  rect.setAttribute('onmousedown', 'moveElementOnMouseDown(event)'); //動かす処理を追加
  rect.setAttribute('onmousemove', 'moveElementOnMouseMove(event)'); //動かす処理を追加
  rect.setAttribute('onmouseup', 'moveElementOnMouseUp(event)'); //動かす処理を追加
  rect.setAttribute('contextmenu', 'console.log("click")'); /* ダブルクリックで削除 */
  // rect.setAttribute('onclick', 'clicktest(event)'); /* ダブルクリックで削除 */
  rect.setAttribute('opacity', '0');
  rect.setAttribute('fill', '#FFF');
  rect.setAttribute('stroke-width', '1');
  rect.setAttribute('stroke', 'rgb(0, 0, 0)');
  return rect;
};

// テーブル削除用rect要素生成
const createTableDeleteRect = () => {
  const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
  rect.setAttribute('class', 'table_delete_rect');
  rect.setAttribute('onclick', 'deleteTable(event)'); /* テーブル削除処理 */
  rect.setAttribute('opacity', '0.5');
  rect.setAttribute('fill', '#a1a1a1');
  rect.setAttribute('stroke-width', '1');
  rect.setAttribute('stroke', 'rgb(0, 0, 0)');
  rect.setAttribute('display', 'none');
  return rect;
};

// テーブル名編集用rect要素を作成
const createTableNameEditRect = () => {
  const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
  rect.setAttribute('class', 'table_name_edit_rect');
  rect.setAttribute('onclick', 'editTableName(event)'); /* テーブル名編集処理 */
  rect.setAttribute('opacity', '0.5');
  rect.setAttribute('fill', '#a1a1a1');
  rect.setAttribute('stroke-width', '1');
  rect.setAttribute('stroke', 'rgb(0, 0, 0)');
  rect.setAttribute('display', 'none');
  return rect;
};

// テーブル要素を作成
const createTableElem = () => {
  // テーブルの必要要素を作成
  const group = createTableGroup(); /* 1つのテーブルg要素を作成 */
  const tableMoveRect = createTableMoveRect(); /* テーブル移動用rect要素を作成 */
  const tableDeleteRect = createTableDeleteRect(); /* テーブル削除用rect要素を作成 */
  const tableNameEditRect = createTableNameEditRect(); /* テーブル名編集用rect要素を作成 */
  const circleSeat = createCircleSeatElem(); /* テーブル主要要素を作成 */

  // g要素に各要素を追加
  group.appendChild(tableMoveRect);
  group.appendChild(circleSeat);
  group.appendChild(tableDeleteRect);
  group.appendChild(tableNameEditRect);

  return group;
};

// rect要素のサイズを対象要素にフィットするように調整
const adjustRectSizeToTargetElem = (targetElem, rectElem) => {
  const targetBBox = targetElem.getBBox(); /* 対象要素にフィットするRectサイズを取得 */
  rectElem.setAttribute('x', targetBBox.x);
  rectElem.setAttribute('y', targetBBox.y);
  rectElem.setAttribute('width', targetBBox.width);
  rectElem.setAttribute('height', targetBBox.height);
  // console.log(targetBBox);
  // console.log(rectElem);
};

////////////////////////////
//
//テーブルを初期レイアウトを描画する処理
//tableLayout:テーブルの数配置
//
////////////////////////////
export const addTableLayout = (tableLayout) => {
  const layoutArea = document.getElementById('add_table_area');
  const layoutAreaRect = layoutArea.getBoundingClientRect();

  const FOOTER_HEIGHT = 50; /* フッター注意書き部分高さ（50は仮の値） */
  const AREA_WIDTH = layoutAreaRect.width; //描画するSVGの幅
  const AREA_HEIGHT = layoutAreaRect.height - FOOTER_HEIGHT; //描画するSVGの高さ

  // レイアウト名からテーブル数を抽出
  const numberArray = tableLayout.split('-');
  const TABLE_NUM_COL = numberArray.length; /* 縦のテーブル数 */

  const bothsideMargin = 50; //描画する時の両サイドの余白
  const topsideMargin = 20; //描画する時のトップの余白
  const bottomsideMargin = 40; //描画する時のボトムの余白

  //均等割の位置を計算してSVGに描画
  for (let i = 0; i < TABLE_NUM_COL; i++) {
    //各要素の横の間隔を計算
    const n = numberArray[i]; //テーブル数を格納

    for (let k = 0; k < n; k++) {
      const group = createTableGroup();
      const tableMoveRect = createTableMoveRect();
      const tableDeleteRect = createTableDeleteRect();
      const tableNameEditRect = createTableNameEditRect();
      const circleSeat = createCircleSeatElem();

      //図形を挿入
      new Promise((resolve) => {
        group.appendChild(tableMoveRect);
        group.appendChild(circleSeat);
        group.appendChild(tableDeleteRect);
        group.appendChild(tableNameEditRect);
        resolve();
      }).then(() => {
        //大元のSVGの倍率を取得
        const seatingSeatSvg = document.getElementById('seating_seat_svg');
        const seatingSeatWrapElem = seatingSeatSvg.closest('.svg_wrap');
        // const mainSvg = document.getElementById('seating_svg_wrap');
        const mainRes = seatingSeatWrapElem.style.transform;
        const mainMatrixArray = mainRes.split(',');
        const Magnification = Number(mainMatrixArray[3]); //倍率

        // const groupDOMRect = group.getBoundingClientRect(); //挿入するテーブルのDOMRectを取得
        // console.log(groupDOMRect);

        // テーブル要素のサイズを取得
        const groupBBox = group.getBBox();
        const groupWidth = groupBBox.width;
        const groupHeight = groupBBox.height;

        //各要素の横の間隔を計算
        const spaceWidth = (AREA_WIDTH - bothsideMargin * 2 - groupWidth * n) / (n - 1);
        //各要素の縦の間隔を計算
        const spaceHeight =
          (AREA_HEIGHT - (topsideMargin + bottomsideMargin) - groupHeight * numberArray.length) /
          (numberArray.length - 1);

        //g要素の位置を変更
        group.setAttribute(
          'transform',
          `${
            'matrix(1,0,0,1,' +
            (k * (groupWidth + spaceWidth) + bothsideMargin) / Magnification +
            ',' +
            (i * (groupHeight + spaceHeight) + topsideMargin) / Magnification +
            ')'
          }`
        );
      });

      // テーブル要素を非表示設定に
      group.setAttribute('display', 'none'); /* 非表示：サイズ調整完了まで */

      //描画箇所に挿入
      layoutArea.appendChild(group);

      // 各モード用rect要素サイズを調整
      adjustRectSizeToTargetElem(group, tableMoveRect);
      adjustRectSizeToTargetElem(group, tableDeleteRect);
      adjustRectSizeToTargetElem(group, tableNameEditRect);

      // tableDeleteRect.setAttribute('display', 'none');

      // テーブル要素表示
      group.removeAttribute('display'); /* 表示：サイズ調整完了 */
    }
  }
};

////////////////////////////
//
//テーブルレイアウトを削除する処理
//
////////////////////////////
export const deleteTableLayout = () => {
  //描画されているSVGを取得
  const Svg = document.getElementById('add_table_area');
  //table_groupを全て取得する
  const Groups = Svg.getElementsByClassName('table_group');
  //取得した要素の削除
  const GroupsLength = Groups.length;
  console.log(Groups.length);
  for (let i = 0; i < GroupsLength; i++) {
    Svg.removeChild(Groups[0]);
  }
};

////////////////////////////
//
//テーブルレイアウトに席を追加する処理
//
////////////////////////////
//ドラッグ開始時の処理
export const onDragStart = (e) => {
  //ドロップ要素以外のイベントを停止
  const addTableArea = document.getElementById('add_table_area');
  const tableElementGroup = addTableArea.querySelectorAll('.table_element_group .guest');
  for (let i = 0; i < tableElementGroup.length; i++) {
    tableElementGroup[i].classList.add('event_none');
  }
  //ドロップ要素の見た目を変更しイベントを追加
  const dropElements = addTableArea.querySelectorAll('.drop_box_element');
  for (let i = 0; i < dropElements.length; i++) {
    // dropElements[i].setAttribute('stroke', 'rgb(228,118,124)');
    dropElements[i].setAttribute('opacity', '1');
    // dropElements[i].setAttribute('display', 'visible');
    dropElements[i].classList.add('ondrag');
  }
  //ゴーストの画像設定
  let addSeatImage = new Image();
  addSeatImage.src = PublicImageDir + '/addseatimage.png';
  let dataTransfer = e.dataTransfer;
  dataTransfer.setDragImage(addSeatImage, 0, 0);
  e.dataTransfer.effectAllowed = 'move';
  e.dataTransfer.setData('text', e.target.id);
};

//ドラッグ終了時の処理
export const onDragEnd = (e) => {
  //ドロップ要素以外のイベントを再開
  const addTableArea = document.getElementById('add_table_area');
  const tableElementGroup = addTableArea.querySelectorAll('.table_element_group .guest');
  for (let i = 0; i < tableElementGroup.length; i++) {
    tableElementGroup[i].classList.remove('event_none');
  }
  //ドロップ要素の見た目を変更しイベントを削除
  const dropElements = addTableArea.querySelectorAll('.drop_box_element');
  for (let i = 0; i < dropElements.length; i++) {
    // dropElements[i].setAttribute('stroke', 'rgb(182, 182, 182)');
    dropElements[i].setAttribute('opacity', '0');
    // dropElements[i].setAttribute('display', 'none');
    dropElements[i].classList.remove('ondrag');
  }
};

// テーブル追加
export const addNewCircleTable = async () => {
  // テーブルの必要要素を作成
  const group = createTableGroup(); /* 1つのテーブルg要素を作成 */
  const tableMoveRect = createTableMoveRect(); /* テーブル移動用rect要素を作成 */
  const tableDeleteRect = createTableDeleteRect(); /* テーブル削除用rect要素を作成 */
  const tableNameEditRect = createTableNameEditRect(); /* テーブル名編集用rect要素を作成 */
  const circleSeat = createCircleSeatElem(); /* テーブル主要要素を作成 */

  // g要素に各要素を追加
  group.appendChild(tableMoveRect);
  group.appendChild(circleSeat);
  group.appendChild(tableDeleteRect);
  group.appendChild(tableNameEditRect);

  // テーブル要素の位置調整（新規追加位置）
  group.setAttribute('transform', `${'matrix(1,0,0,1,' + 100 + ',' + 100 + ')'}`);

  // テーブル要素を非表示設定に
  group.setAttribute('display', 'none'); /* 非表示：サイズ調整完了まで */

  // 席次表SVGにテーブル要素を追加
  const layoutArea = document.getElementById('add_table_area');
  layoutArea.appendChild(group);

  // 各モード用rect要素サイズを調整
  adjustRectSizeToTargetElem(group, tableMoveRect);
  adjustRectSizeToTargetElem(group, tableDeleteRect);
  adjustRectSizeToTargetElem(group, tableNameEditRect);

  // tableDeleteRect.setAttribute('display', 'none');

  // テーブル要素表示
  group.removeAttribute('display'); /* 表示：サイズ調整完了 */

  setTimeout(() => {
    window.alert('テーブルを追加しました。\n配置を調整してください。');
  }, 100);
};

// 全テーブルg要素を取得
export const getTableGroups = () => {
  return document.querySelectorAll('.table_group');
};

// テーブル移動モード切替
export const toggleTableMoveMode = (state) => {
  // 引数チェック
  if (state !== 'start' && state !== 'end') {
    return false;
  }

  const tableGroups = getTableGroups(); /* 全テーブル要素取得 */

  // テーブル要素存在確認
  if (state === 'start') {
    if (tableGroups.length === 0) {
      window.alert('テーブルがありません。');
      return false;
    }
  }

  const addTableArea = document.getElementById('add_table_area');

  if (state === 'start') {
    //table_element_groupのイベントを停止用クラスを付与
    const tableElementGroup = addTableArea.getElementsByClassName('table_element_group');
    for (let i = 0; i < tableElementGroup.length; i++) {
      tableElementGroup[i].classList.add('event_none');
    }
  } else {
    //table_element_groupのevent_noneクラスを削除
    const addTableArea = document.getElementById('add_table_area');
    const tableElementGroup = addTableArea.getElementsByClassName('table_element_group');
    for (let i = 0; i < tableElementGroup.length; i++) {
      tableElementGroup[i].classList.remove('event_none');
    }
  }

  // テーブル要素内のテーブル移動用rect要素の表示・非表示を切り替える
  for (let i = 0; i < tableGroups.length; i++) {
    const tableMoveRect = tableGroups[i].querySelector('.svg_move_element');
    if (state === 'start') {
      // テーブル移動モード開始処理
      tableMoveRect.classList.remove('event_none');
      tableMoveRect.setAttribute('opacity', '1');
    } else {
      // テーブル移動モード終了処理
      tableMoveRect.classList.add('event_none');
      tableMoveRect.setAttribute('opacity', '0');
    }
  }

  return true;
};

// テーブル削除モード開始終了切替
export const toggleTableDeleteMode = (state) => {
  // 引数チェック
  if (state !== 'start' && state !== 'end') {
    return false;
  }

  const tableGroups = getTableGroups(); /* 全テーブル要素取得 */

  // テーブル削除モード開始前のみテーブル要素存在確認
  if (state === 'start') {
    if (tableGroups.length === 0) {
      window.alert('テーブルがありません。');
      return false;
    }
  }

  // テーブル要素内のテーブル削除用rect要素の表示・非表示を切り替える
  for (let i = 0; i < tableGroups.length; i++) {
    const tableDeleteRect = tableGroups[i].querySelector('.table_delete_rect');
    if (state == 'start') {
      // テーブル削除モード開始処理
      tableDeleteRect.removeAttribute('display'); /* 表示 */
    } else {
      // テーブル削除モード終了処理
      tableDeleteRect.setAttribute('display', 'none'); /* 非表示 */
    }
  }

  return true;
};

// テーブル名編集モード開始終了切替
export const toggleTableNameEditMode = (state) => {
  // 引数チェック
  if (state !== 'start' && state !== 'end') {
    return false;
  }

  const tableGroups = getTableGroups(); /* 全テーブル要素取得 */

  // テーブル名編集モード開始前のみテーブル要素存在確認
  if (state === 'start') {
    if (tableGroups.length === 0) {
      window.alert('テーブルがありません。');
      return false;
    }
  }

  // テーブル要素内のテーブル名編集用rect要素の表示・非表示を切り替える
  for (let i = 0; i < tableGroups.length; i++) {
    const tableNameEditRect = tableGroups[i].querySelector('.table_name_edit_rect');
    if (state === 'start') {
      // テーブル名編集モード開始処理
      tableNameEditRect.removeAttribute('display'); /* 表示 */
    } else {
      // テーブル名編集モード終了処理
      tableNameEditRect.setAttribute('display', 'none'); /* 非表示 */
    }
  }

  if (state === 'start') {
    resetTableNameEditState();
    changeTableNameInputVisibility('visible');
  } else if (state === 'end') {
    changeTableNameInputVisibility('hidden');
  }

  return true;
};

// SVGにテーブルが存在するかどうか
export const existTable = () => {
  const tableLayoutArea = document.querySelector('#add_table_area');
  const tableElems = tableLayoutArea.querySelectorAll('.table_group');
  return tableElems.length !== 0;
};
