// 開発環境　ここから =======================================================================

// export const HomeUrl = 'http://localhost:3000/pocket_ec/react/'; //変更必要
// export const HomeDir = '/pocket_ec'; //変更必要
// export const ApiDir = '/pocket_ec/api'; //変更必要
// export const JsDir = '/pocket_ec/js'; //変更必要
// export const ImagesDir = '/pocket_ec/images'; //変更必要
// export const RouteDir = '/pocket_ec/react'; //変更必要
// export const PublicImageDir = '/pocket_ec/react/images'; //変更必要
// export const AdminsDir = '/pocket_ec/react/admin'; //変更必要
// export const MypageDir = '/pocket_ec/react/mypage'; //変更必要
// export const NewsImageDir = '/pocket_ec/images/news'; //変更必要
// export const ItemImageDir = '/pocket_ec/images/items'; //変更必要
// export const FontsDir = '/pocket_ec/fonts';
// export const SAVED_SVG_DIR = '/pocket_ec/svg/saved/';

// // Squareサンドボックス環境（開発用アカウント）
// // export const sqAppId = 'sandbox-sq0idb-TX7Er3KW4cxyfAUBzxznIw'; /* 変更必要 */
// // // export const sqAppId = 'sandbox-sq0idb-fjis4fRz7vHaEmjWta-y4Q'; /* 環境２ */
// // export const sqLocationId = 'L9PS7Y0F82N8F'; /* 変更必要 */

// // Squareアドミンアカウント：サンドボックス
// export const sqAppId = 'sandbox-sq0idb-NQ0vumq7iiJSluF9BUDiFQ'; /* 変更必要 */
// export const sqLocationId = 'L519BC4EAYA0N'; /* 変更必要 */

// // Squareサンドボックス環境（本番用アカウント）
// // export const sqAppId = 'sandbox-sq0idb-6qX6Oya-Dlz110B-jPA2Vw'; /* 変更必要 */
// // export const sqLocationId = 'L5TW36W62P1F1'; /* 変更必要 */

// // 3Dセキュア　変更必要
// export const isThreeDSecureActivated = false; /* 無効 */
// // export const isThreeDSecureActivated = true /* 有効 */

// // 離島配送料
// export const ADDITIONAL_SHIPPING_FEE = 0; /* 変更必要 */
// // export const ADDITIONAL_SHIPPING_FEE = 2000; /* 変更必要 */

// 開発環境　ここまで =======================================================================

// 開発環境（ビルド後）　ここから =======================================================================

// export const HomeUrl = 'http://localhost/pocket_ec_build/'; //変更必要
// export const HomeDir = ''; //変更必要
// export const ApiDir = 'http://localhost/pocket_ec_build/api'; //変更必要
// export const JsDir = 'http://localhost/pocket_ec_build/js'; //変更必要
// export const ImagesDir = 'http://localhost/pocket_ec_build/images'; //変更必要
// export const RouteDir = ''; //変更必要
// export const PublicImageDir = 'http://localhost/pocket_ec_build/images'; //変更必要
// export const AdminsDir = 'http://localhost/pocket_ec_build/admin'; //変更必要
// export const MypageDir = 'http://localhost/pocket_ec_build/mypage'; //変更必要
// export const NewsImageDir = 'http://localhost/pocket_ec_build/images/news'; //変更必要
// export const ItemImageDir = 'http://localhost/pocket_ec_build/images/items'; //変更必要
// export const FontsDir = 'http://localhost/pocket_ec_build/fonts';
// export const SAVED_SVG_DIR = 'http://localhost/pocket_ec_build/svg/saved/';

// // Squareテスト決済環境
// export const sqAppId = 'sandbox-sq0idb-TX7Er3KW4cxyfAUBzxznIw'; /* 変更必要 */
// export const sqLocationId = 'L9PS7Y0F82N8F'; /* 変更必要 */

// // 3Dセキュア　変更必要
// export const isThreeDSecureActivated = false; /* 無効 */
// // export const isThreeDSecureActivated = true /* 有効 */

// // 離島配送料
// export const ADDITIONAL_SHIPPING_FEE = 0; /* 変更必要 */

// 開発環境（ビルド後）　ここまで =======================================================================

// 本番環境　ここから =======================================================================

export const HomeUrl = 'https://bridalpocket-app.com/'; //変更必要
export const RouteDir = '';
export const HomeDir = '';
export const ApiDir = '/api';
export const JsDir = '/js';
export const SvgDir = '/svg';
export const FontsDir = '/fonts';
export const ImagesDir = '/images';
export const PublicImageDir = '/images';
export const AdminsDir = '/admin';
export const MypageDir = '/mypage';
export const NewsImageDir = '/images/news';
export const ItemImageDir = '/images/items';
export const SAVED_SVG_DIR = '/svg/saved/';

// // Squareサンドボックス環境（開発用アカウント）
// export const sqAppId = 'sandbox-sq0idb-TX7Er3KW4cxyfAUBzxznIw'; /* 変更必要 */
// export const sqLocationId = 'L9PS7Y0F82N8F'; /* 変更必要 */

// // Squareアドミンアカウント：サンドボックス
// export const sqAppId = 'sandbox-sq0idb-NQ0vumq7iiJSluF9BUDiFQ'; /* 変更必要 */
// export const sqLocationId = 'L519BC4EAYA0N'; /* 変更必要 */

// Squareアドミンアカウント：本番環境
export const sqAppId = 'sq0idp-g5RbakGI3nKa8ekXY8z2dA'; /* 変更必要 */
export const sqLocationId = 'LCAZVBGFV45MQ'; /* 変更必要 */

// // Squareサンドボックス環境（本番用アカウント）
// export const sqAppId = 'sandbox-sq0idb-6qX6Oya-Dlz110B-jPA2Vw'; /* 変更必要 */
// export const sqLocationId = 'L5TW36W62P1F1'; /* 変更必要 */

// Square本番決済環境(Production)（本番用アカウント）
// export const sqAppId = ''; /* 変更必要 */
// export const sqLocationId = ''; /* 変更必要 */

// 3Dセキュア　変更必要
export const isThreeDSecureActivated = false; /* 無効 */
// export const isThreeDSecureActivated = true /* 有効 */

// 離島配送料
export const ADDITIONAL_SHIPPING_FEE = 0; /* 変更必要 */

// 本番環境　ここまで =======================================================================

// 宛名一括登録用テンプレートExcelファイルリンク
export const GUEST_ADD_EXCEL_TEMPLATE_URL =
  'https://bridalpocket-app.com/guest_list/template/ブライダルポケット_宛名一括登録用テンプレート.xlsx';

// 入力制限用　ここから =======================================================================

// パスワード検証用正規表現
export const REGEX_PASSWORD = /^[a-zA-Z0-9!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]{10,20}$/;

// メールアドレス検証用正規表現
// 参考：https://www.javadrive.jp/regex-basic/sample/index13.html#section2
export const REGEX_MAIL_ADDRESS =
  /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

export const REGEX_ITEM_PRICE = /^[0-9]{1,8}$/; // 商品価格（8桁）
export const REGEX_POSTAGE = /^[0-9]{1,8}$/; // 送料（8桁）
export const REGEX_STOCK_QUANTITY = /^[-]?[0-9]{1,8}$/; // 在庫数（8桁）
// export const REGEX_STOCK_QUANTITY = /^(([-]?[1-9][0-9]{,7})|0)$/; // 在庫数（8桁）
export const REGEX_TAX_RATE = /^[0-9]{1,3}$/; // 税率

// 電話番号
// 参考：https://rapicro.com/%E9%9B%BB%E8%A9%B1%E7%95%AA%E5%8F%B7%E3%81%AE%E6%AD%A3%E8%A6%8F%E8%A1%A8%E7%8F%BE%E3%81%AF%EF%BC%9F/
export const REGEX_TEL_NUMBER = /^0[-0-9]{9,12}$/;

// 生年月日
export const REGEX_BIRTHDAY = /^(19[0-9]{2}|20[0-9]{2})-(0?[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;

// 入力制限用　ここまで =======================================================================

// その他　ここから =======================================================================

export const PROGRAM_ERROR_ALERT_MSG = 'サーバーエラーが発生しました。\nサイト管理者にお問い合わせください。';

//エラー回避用古い情報（恐らくJA西彼のPaygentテスト用）
export const postUrl = '';
export const merchantId = '';
export const merchantName = '';
// export const postUrl = 'https://sandbox.paygent.co.jp/v/u/request'
// export const merchantId = '49225'
// export const merchantName = 'ＪＡ西彼'

// その他　ここまで =======================================================================
